import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { useHistory } from "react-router";

import { useAppSelector } from "../../store/Hooks";
import {
  getCurrentEvaluation,
  getCurrentHtmlReport,
  getCurrentShowReportLoader
} from "../../store/evaluation/Current";
import Loader from "../common/Loader";

const Report: React.FC<any> = (props: any) => {
  const history = useHistory();

  const html = useAppSelector(getCurrentHtmlReport);
  const currentEvaluation = useAppSelector(getCurrentEvaluation);
  const currentShowReportLoader = useAppSelector(getCurrentShowReportLoader);

  const contentLoaded = () => {
    const iframe: any = document.getElementById("reportIframe");
    const doc = iframe.contentDocument
      ? iframe.contentDocument
      : iframe.contentWindow.document;
    const element: any = doc.getElementById("hiperlink");

    if (element) {
      element.addEventListener("click", function () {
        if (element != null) {
          const section: any = doc.getElementById("section-4");
          history.push(`/evaluation/${currentEvaluation.id}/report#section-4`);
          section.scrollIntoView();
        }
      });
    }
  };

  return (
    <IonPage>
      <IonContent>
        <Loader visible={currentShowReportLoader} />
        <iframe
          id="reportIframe"
          width={`100%`}
          height={`100%`}
          srcDoc={html && html}
          onLoad={contentLoaded}
        ></iframe>
      </IonContent>
    </IonPage>
  );
};

export default Report;
