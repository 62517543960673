import axios from "axios";
import { makeApiCall, makeRequest } from "../helpers/global.helper";
const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;
// extensible
export const workstationEquipment: any = {
  id: null,
  idEquipment: null,
  idLaptopEquipment: null,
  idPrevious: null,
  idTray: null,
  idWorksurfaceEquipment: null,
  adHoc: null,
  appropiate_distance: null,
  appropiate_height: null,
  appropiately_positioned: null,
  comment: null,
  designated: null,
  displayPercentUsed: null,
  displaySecondMonitor: null,
  displaySecondMonitorComment: null,
  displaySecondMonitorPercentUsed: null,
  displaySize: null,
  displayTechnology: null,
  displayType: null,
  easilyAdjustableBrightness: null,
  easilyReadable: null,
  employerProvided: null,
  external: null,
  hasDock: null,
  hasDocumentHolder: null,
  hasGlare: null,
  hasMonitorArm: null,
  hasNumeric: null,
  hasRiser: null,
  hasSufficientSpace: null,
  hasSwivelTilt: null,
  hasTray: null,
  hasWristRest: null,
  inUse: null,
  keyboardTypeLaptop: null,
  keyboardTypes: null,
  keysReadable: null,
  label: null,
  manufacturer: null,
  model: null,
  movesSmoothly: null,
  name: null,
  operatingSystem: null,
  orientation: null,
  percentUse: null,
  phoneType: null,
  pointingDeviceType: null,
  position: null,
  positionHorizontal: null,
  positionVertical: null,
  positionedEasily: null,
  primary: null,
  riserAdhoc: null,
  riserAdjustable: null,
  riserDesignated: null,
  riserSuitableForDisplay: null,
  separateKeyboard: null,
  separatePointingDevice: null,
  size: null,
  tiltAngle: null,
  trayAdhoc: null,
  trayDesignated: null,
  trayManufacturer: null,
  trayModel: null,
  trayName: null,
  type: null,
  usageRanking: null,
  usesAsDisplay: null,
  usesDeviceTray: null,
  usesKeyboardNumeric: null,
  usesMonitorArm: null,
  usesRiser: null,
  usesSeparateKeyboard: null,
  usesSeparateNumeric: null,
  usesSeparatePointingDevice: null,
  usesWristRest: null,
  videoCameraType: null,
  whichHand: null,
  wristrestAdhoc: null,
  wristrestBuildIn: null,
  wristrestDesignated: null
};

// Gets worker by Id
export const getWsEquipmentsByIdEnvironment = (idEnvironment: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiBaseUrl}/environments/${idEnvironment}/workstationequipments`)
      .then(function ({ data }) {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

/**
 * Patches workstationequipment
 * @param id
 * @param data
 * @returns
 */
export const patchWorkstationEquipment =
  (data: any) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: `/workstationequipments`, method: "PATCH", data })
    );

    console.log("workstationEquipment: ", response.status);
  };
