import React, { useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";

import { useAppSelector } from "../../../../../store/Hooks";
import { getCurrentEquipment } from "../../../../../store/evaluation/environment/workstation/Equipments";

import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";

import "../../../../../css/equipment.css";
import Checkbox from "../workstations/Checkbox";

const EquipmentHardware: React.FC<any> = (props: any) => {
  const { updateValue } = props;

  const data = useAppSelector(getCurrentEquipment);

  const [label, setLabel] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(null);

  useEffect(() => {
    setLabel(data && data.label);
    setComment(data && data.comment);
  }, [data && data.id]);

  return (
    <>
      <IonRow>
        <Name nameLabel="Hardware Name" value={data && data.name} />
      </IonRow>
      <IonRow className="top-2 ion-align-items-center">
        <IonCol size="6">
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={(key: string, value: any) => {
              setLabel(value);
              updateValue(key, value);
            }}
            maxlength={16}
          />
        </IonCol>
        <IonCol size="6">
          <IonRow>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="primary"
                label="Primary"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <IonCol>
          <Input
            name="comment"
            value={comment}
            label="Comment"
            onBlur={(key: string, value: any) => {
              setComment(value);
              updateValue(key, value);
            }}
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default EquipmentHardware;
