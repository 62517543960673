import React from "react";
import { IonRow, IonCol, IonLabel } from "@ionic/react";

const AddRow: React.FC<any> = (props: any) => {
  const { label, buttons } = props;

  return (
    <IonRow>
      <IonCol>
        <IonLabel className="text-left bold top-1">{label}</IonLabel>
      </IonCol>
      <IonCol>{buttons}</IonCol>
    </IonRow>
  );
};

export default AddRow;
