import React, { useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getCurrentFurniture,
  getWorksurfaceIds
} from "../../../../../store/evaluation/environment/workstation/Furnitures";

import SelectForm from "../../../../common/controls/SelectForm";
import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";
import LengthLabel from "../../../../common/controls/LengthLabel";
import YesNoInput from "../../../../common/controls/YesNoInput";

import "../../../../../css/equipment.css";
import Emea from "../../../../common/controls/Emea";
import Checkbox from "../workstations/Checkbox";

const SeatingForm: React.FC<any> = (props: any) => {
  const { updateValue, getSelectedText, hasWorkSurfaceValue } = props;

  const dispatch = useAppDispatch();
  const [worksurfaceIds] = useState<any>(dispatch(getWorksurfaceIds()));
  const data = useAppSelector(getCurrentFurniture);

  const [label, setLabel] = useState<string | null>(null);
  const [idWorksurface, setIdWorksurface] = useState<number | null>(null);

  useEffect(() => {
    if (
      worksurfaceIds.length === 1 &&
      data &&
      !hasWorkSurfaceValue(data.idWorksurface)
    ) {
      updateValue("idWorksurface", worksurfaceIds && worksurfaceIds[0].id);
      setIdWorksurface(worksurfaceIds && worksurfaceIds[0].id);
    } else {
      setIdWorksurface(
        data &&
          data &&
          data.idWorksurface &&
          data.idWorksurface !== null &&
          parseInt(data.idWorksurface)
      );
    }
    setLabel(data && data.label);
  }, [data && data.id]);

  return (
    <>
      <IonRow>
        <Name nameLabel="Seat name" value={data && data.name} />
      </IonRow>
      <IonRow className="top-var">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="5"
          size-md="5"
          size-sm="5"
          size-sx="5"
          size="5"
        >
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={(key: string, value: any) => {
              setLabel(value);
              updateValue(key, value);
            }}
            maxlength={16}
          />
        </IonCol>
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="7"
          size-md="7"
          size-sm="7"
          size-sx="7"
          size="7"
        >
          <LengthLabel />
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data && data}
          property="shared"
          label="Shared"
          onChange={updateValue}
        />
      </IonRow>
      <hr className="separator top-2" />
      <IonRow className="top-2">
        <YesNoInput
          data={data && data}
          property="usesBackrest"
          label="Uses Separate Backrest"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="12"
          size-md="12"
          size-sm="12"
          size-sx="12"
          size="12"
        >
          <IonRow>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="backrestAdhoc"
                value={data && data.backrestAdhoc}
                label="(Backrest) Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentFurniture}
              />
            </IonCol>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="backrestDesignated"
                value={data && data.backrestDesignated}
                label="(Backrest) Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentFurniture}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesSeatrest"
          label="Uses Seatrest"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="12"
          size-md="12"
          size-sm="12"
          size-sx="12"
          size="12"
        >
          <IonRow>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="seatrestAdhoc"
                value={data && data.seatrestAdhoc}
                label="(Seatrest) Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentFurniture}
              />
            </IonCol>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="seatrestDesignated"
                value={data && data.seatrestDesignated}
                label="(Seatrest) Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentFurniture}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesFootrest"
          label="Uses Footrest"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="12"
          size-md="12"
          size-sm="12"
          size-sx="12"
          size="12"
        >
          <IonRow>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="footrestAdhoc"
                value={data && data.footrestAdhoc}
                label="(Footrest) Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentFurniture}
              />
            </IonCol>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="footrestDesignated"
                value={data && data.footrestDesignated}
                label="(Footrest) Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentFurniture}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol>
          <SelectForm
            label="Work Surface"
            options={worksurfaceIds}
            value={idWorksurface}
            selectedText={getSelectedText(worksurfaceIds, idWorksurface)}
            name="idWorksurface"
            onChange={(key: string, value: any) => {
              if (value && value !== "0" && value !== "") {
                setIdWorksurface(parseInt(value));
                updateValue(key, parseInt(value));
              }
            }}
            keyValue={{
              key: "id",
              value: "label"
            }}
            clearValue={() => {
              setIdWorksurface(null);
              updateValue("idWorksurface", null);
            }}
          />
        </IonCol>
      </IonRow>
      <hr className="separator top-var" />
      <Emea></Emea>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="adjustable"
          label="Adjustable"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="allowsAccess"
          label="Allow Access"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="movesFreely"
          label="Moves Freely"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="stable"
          label="Stable"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="supportsUser"
          label="Supports user"
          onChange={updateValue}
        />
      </IonRow>
    </>
  );
};

export default SeatingForm;
