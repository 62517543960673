import React, { useState } from "react";
import { IonList, IonItem, IonCol, IonImg, IonIcon } from "@ionic/react";
import { unlink, link } from "ionicons/icons";
import { useAppDispatch } from "../../../../../store/Hooks";

import Checkbox from "../../../../common/controls/Checkbox";
import RiskItem from "../risks/RiskItem";
import RecommendationItem from "../recommendations/RecommendationItem";
import ImageBox from "../../../../common/controls/ImageBox";

import { updateObjectFromList } from "../../../../../helpers/global.helper";

import { Popover } from "react-tiny-popover";

import classNames from "classnames";
import _ from "lodash";

import "react-image-lightbox/style.css";

const List: React.FC<any> = (props: any) => {
  const {
    items,
    getIcon,
    icon,
    action,
    isPicker,
    listUpdated,
    setSelectedItem,
    hasCustomItem,
    itemName,
    hideSmallIcon,
    pickOneElement,
    setPickedItems,
    itemClassName,
    category,
    rightList,
    removeItems,
    clickOnDescription,
    hideBoxIcon,
    groupedLeftList
  } = props;

  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState<number>(-1);

  const pathImage = `${process.env.REACT_APP_BIDB_IMAGE_ROOT_PATH}${process.env.REACT_APP_BIDB_WORKSTATION_IMAGE_PATH}`;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const handlePickItem = async (key: string, value: any, item: any) => {
    let list: any;
    let pickedObject: any;

    if (pickOneElement) {
      pickedObject = _.find(items, function (o) {
        return o.picker === true && item.id !== selectedId;
      });

      if (pickedObject) {
        pickedObject = _.omit(pickedObject, ["picked"]);
        list = await updateObjectFromList(
          [...items],
          pickedObject,
          "id",
          key,
          false
        );
      }
    }

    list = await updateObjectFromList(
      pickedObject && value && list ? list : [...items],
      item,
      "id",
      key,
      value
    );

    dispatch({
      type: listUpdated.type,
      payload: list
    });

    setSelectedId(item.id);

    if (pickOneElement) {
      if (value === true) {
        setSelectedItem(item);
      } else {
        setSelectedItem(undefined);
      }
    } else {
      const filtered = _.filter(list, { picker: true });

      if (!value && removeItems) {
        await dispatch(removeItems(item));
      }
      setPickedItems(filtered);
    }
  };

  const getItem = (itemName: string, item: any, index: number) => {
    const items: any = {
      riskItem: <RiskItem item={item} groupedLeftList={groupedLeftList} />,
      recommendationItem: (
        <RecommendationItem
          item={item}
          isPicker={isPicker}
          groupedLeftList={groupedLeftList}
          index={index}
        />
      )
    };

    return items[itemName];
  };

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const handleOpenLargeImage = (index: number) => {
    setIsPopoverOpen(!isPopoverOpen);
    setSelectedIndex(index);
  };

  return (
    <>
      <IonList className="box-list relative">
        {items &&
          items.map((item: any, index: number) => (
            <>
              <IonItem
                lines="none"
                className={classNames([
                  {
                    "row-item": !isPicker
                  },
                  item.picker && item.picker === true ? "item-checked" : "",
                  groupedLeftList && item.group && item.group === true
                    ? "background-item-group"
                    : "",
                  "cursor-pointer",
                  "picker-item"
                ])}
                key={index}
                onClick={() =>
                  !isPicker
                    ? action(item)
                    : handlePickItem(
                        "picker",
                        item.picker ? !item.picker : true,
                        item
                      )
                }
              >
                {(!hideBoxIcon || hideBoxIcon === undefined) && (
                  <IonCol
                    size={isPicker ? "1.5" : ""}
                    className={`${
                      groupedLeftList && item.group && item.group === true
                        ? "grouped-col"
                        : ""
                    }`}
                  >
                    <div className="img-border">
                      {item.imageUrl && (
                        <Popover
                          isOpen={isPopoverOpen && index === selectedIndex}
                          positions={["right"]} // if you'd like, you can limit the positions
                          content={
                            <ImageBox
                              item={item}
                              category={category}
                              closePopover={setIsPopoverOpen}
                            />
                          }
                          padding={15}
                          align="center"
                        >
                          <IonImg
                            src={
                              item.imageUrl &&
                              `${pathImage}/${category}/${item.imageUrl}`
                            }
                            className="img-item cursor-pointer"
                            onClick={() => handleOpenLargeImage(index)}
                          ></IonImg>
                        </Popover>
                      )}
                      {!hideSmallIcon && (
                        <IonImg
                          src={getIcon(item.category)}
                          className="type-item"
                        ></IonImg>
                      )}
                    </div>
                  </IonCol>
                )}
                <IonCol
                  onClick={() =>
                    clickOnDescription && !isPicker ? action(item) : null
                  }
                  className={classNames([
                    "box-detail",
                    clickOnDescription && !isPicker ? "cursor-pointer" : ""
                  ])}
                >
                  {hasCustomItem ? (
                    getItem(itemName, item, index)
                  ) : (
                    <div
                      className={`pl-05 pr-05 h-90 top-2 ${itemClassName}`}
                      id="target"
                    >
                      <div className="">
                        <p className="name-item mb-0">
                          Name:{" "}
                          <span className="name-value">{`${item.name}`}</span>
                        </p>
                      </div>
                      <>
                        <p className="name-item mb-0">
                          Availability:{" "}
                          <span className="name-value">
                            {item.availability}
                          </span>
                        </p>
                      </>
                      <div className={`h-44 m-h-44px max-h-44px`}>
                        <div
                          className={classNames(
                            {
                              "truncate-overflow": !item.picker ? true : false
                            },
                            "name-item mb-3 name-value text-12 relative  max-h-44px"
                          )}
                        >
                          {item.description}
                        </div>
                      </div>
                    </div>
                  )}
                </IonCol>
                <IonCol
                  size={isPicker ? "1.1" : ""}
                  className="flex ion-justify-content-end"
                >
                  {!isPicker ? (
                    <IonImg
                      src={icon}
                      onClick={() => (!isPicker ? action(item) : null)}
                      className={`vector-add ${
                        rightList === true ? "" : "-ml-4"
                      }`}
                    ></IonImg>
                  ) : (
                    <Checkbox
                      label=""
                      name="picker"
                      value={
                        item?.picker && item.picker && item.picker === true
                          ? true
                          : false
                      }
                      onChange={(key: string, value: any) =>
                        handlePickItem(key, value, item)
                      }
                    />
                  )}
                </IonCol>
              </IonItem>
            </>
          ))}
      </IonList>
    </>
  );
};

export default List;
