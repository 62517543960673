import React from "react";
import BaseBand from "../../../../common/BaseBand";
import BandContent from "./BandContent";

const Band: React.FC<any> = (props: any) => {
  const {
    isRowExpanded,
    type,
    chief,
    resolved,
    expandRowClick,
    onChange,
    item,
    onDelete
  } = props;

  return (
    <BaseBand
      expandRowClick={expandRowClick}
      message={"Are you sure that you want to delete this issue?"}
      content={
        <BandContent
          bandOnClick={expandRowClick}
          type={type}
          chief={chief}
          resolved={resolved}
          onChange={onChange}
          smallButtonClick={onChange}
          item={item}
        />
      }
      isRowExpanded={isRowExpanded}
      item={item}
      onDelete={onDelete}
    />
  );
};

export default Band;
