import { combineReducers } from "redux";
import issuesReducer from "./Issues";
import activitiesReducer from "./Activities";
import workstationReducer from "../environment/workstation/Index";
import risksReducer from "./Risks";
import recommendationsReducer from "./Recommendations";

export default combineReducers({
  issues: issuesReducer,
  activities: activitiesReducer,
  workstation: workstationReducer,
  risks: risksReducer,
  recommendations: recommendationsReducer
});
