import React from "react";
import {
  IonLabel,
  IonCol,
  IonRow,
  IonCheckbox,
  IonItem,
  IonButton
} from "@ionic/react";

import { SideType } from "../../../../../services/issue.service";
import { useAppDispatch } from "../../../../../store/Hooks";

import { getListByName } from "../../../../../store/catalogs/ValueList";

import SelectForm from "../../../../common/controls/SelectForm";
import Textarea from "../../../../common/Textarea";

const IssuesDiscomfortType: React.FC<any> = ({ setValueIssue, issue }) => {
  const dispatch = useAppDispatch();
  const bodyLocationList = dispatch(getListByName("Issue_Body_Location"));
  const severityList = dispatch(getListByName("Issue_Severity"));
  const persistenceList = dispatch(getListByName("Issue_Persistence"));
  const frequencyList = dispatch(getListByName("Issue_Frequency"));
  const durationList = dispatch(getListByName("Issue_Duration"));

  return (
    <>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="4"
          size-md="4"
          size-sm="4"
          size-sx="4"
          size="4"
        >
          <SelectForm
            label="Body location"
            options={bodyLocationList}
            value={issue && issue.bodyLocation}
            name={"bodyLocation"}
            onChange={(key: string, value: any) => {
              if (value && issue.bodyLocation != value) {
                setValueIssue(key, value);
              }
            }}
            clearValue={() => setValueIssue("bodyLocation", null)}
          />
        </IonCol>

        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="8"
          size-md="8"
          size-sm="8"
          size-sx="8"
          size="8"
          className="ion-align-items-end"
        >
          <IonRow>
            <IonLabel className="form-group-input-text-title single-label line-height-2">
              Side
            </IonLabel>
          </IonRow>
          <IonRow className="no-padding">
            <IonCol size="4" className="no-padding to-left">
              <IonButton
                onClick={() => {
                  setValueIssue("side", SideType.left.toString());
                }}
                className={
                  "no-maring btn btn-group-1 font-bold " +
                  (issue.side == SideType.left.toString()
                    ? "positive"
                    : "negative")
                }
              >
                LEFT
              </IonButton>
            </IonCol>
            <IonCol size="4" className="no-padding to-center">
              <IonButton
                onClick={() => {
                  setValueIssue("side", SideType.right.toString());
                }}
                className={
                  "no-maring btn btn-group-1 font-bold " +
                  (issue.side == SideType.right.toString()
                    ? "positive"
                    : "negative")
                }
              >
                RIGHT
              </IonButton>
            </IonCol>
            <IonCol size="4" className="no-padding to-right">
              <IonButton
                onClick={() => {
                  setValueIssue("side", SideType.both.toString());
                }}
                className={
                  "no-maring btn btn-group-1 font-bold " +
                  (issue.side == SideType.both.toString()
                    ? "positive"
                    : "negative")
                }
              >
                BOTH
              </IonButton>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>

      <IonRow className="pt-4">
        <IonCol size="3">
          <SelectForm
            label="Severity"
            options={severityList}
            value={issue && issue.severity}
            name={"severity"}
            onChange={(key: string, value: any) => {
              if (value && issue.severity != value) {
                setValueIssue(key, value);
              }
            }}
            clearValue={() => setValueIssue("severity", null)}
          />
        </IonCol>
        <IonCol size="3">
          <SelectForm
            label="Persistence"
            options={persistenceList}
            value={issue && issue.persistence}
            name={"persistence"}
            onChange={(key: string, value: any) => {
              if (value && issue.persistence != value) {
                setValueIssue(key, value);
              }
            }}
            clearValue={() => setValueIssue("persistence", null)}
          />
        </IonCol>
        <IonCol size="6">
          <SelectForm
            label="Frequency"
            options={frequencyList}
            value={issue && issue.frequency}
            name={"frequency"}
            onChange={(key: string, value: any) => {
              if (value && issue.frequency != value) {
                setValueIssue(key, value);
              }
            }}
            clearValue={() => setValueIssue("frequency", null)}
          />
        </IonCol>
      </IonRow>

      <IonRow className="pt-4">
        <IonCol size="6">
          <SelectForm
            label="Duration"
            options={durationList}
            value={issue && issue.duration}
            name={"duration"}
            onChange={(key: string, value: any) => {
              if (value && issue.duration != value) {
                setValueIssue(key, value);
              }
            }}
            clearValue={() => setValueIssue("duration", null)}
          />
        </IonCol>
      </IonRow>

      <IonRow className="pt-4">
        <IonCol>
          <Textarea
            label={"Description"}
            value={issue && issue.description ? issue.description : ""}
            onBlur={(e: any) => {
              let isDifferent = issue.description != e.target.innerText;
              if (isDifferent) setValueIssue("description", e.target.innerText);
            }}
          />
        </IonCol>
      </IonRow>

      <IonRow className="pt-4">
        <IonCol
          size-xxl="2"
          size-xl="2"
          size-lg="3"
          size-md="6"
          size-sm="6"
          size-sx="6"
          size="6"
        >
          <IonItem className="ion-no-padding comboBoxCss text-12">
            <IonCheckbox
              mode="md"
              onIonChange={(e: any) =>
                setValueIssue("adhocMitigation", e.detail.checked)
              }
              checked={issue.adhocMitigation || false}
            ></IonCheckbox>
            <IonLabel>&nbsp;Adhoc Mitigation</IonLabel>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className="pt-4">
        <IonCol>
          <Textarea
            label={"Adhoc Mitigation Description"}
            value={
              issue && issue.adhocMitigationDescription
                ? issue.adhocMitigationDescription
                : ""
            }
            onBlur={(e: any) => {
              let isDifferent =
                issue.adhocMitigationDescription != e.target.innerText;
              if (isDifferent)
                setValueIssue("adhocMitigationDescription", e.target.innerText);
            }}
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default IssuesDiscomfortType;
