import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonRow,
  IonCol,
  IonGrid,
  IonTitle,
  IonHeader,
  IonContent,
  IonToolbar,
  useIonToast
} from "@ionic/react";
import {
  IonLabel,
  IonInput,
  IonAlert,
  IonButton,
  IonItemDivider
} from "@ionic/react";

import { useDispatch } from "react-redux";

import SignInWithGoogle from "../components/login/SignInWithGoogle";

import axios from "axios";

import { resetStore } from "../store/User";

import "../css/login.css";

function validateEmail(email: string) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("cityslicka");
  const [iserror, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<any>();
  const [data, setData] = useState<any>();
  const [present, dismiss] = useIonToast();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: resetStore.type,
      payload: ""
    });
  }, []);
  /**
   * Shows login error messages
   */
  const handleShowErrorMessage = (message: string) => {
    const toastTime: number = parseInt(process.env.REACT_APP_BIDB_TOAST_TIME!);
    present(message, toastTime);
  };

  const handleLogin = () => {
    if (!email) {
      setMessage("Please enter a valid email");
      setIsError(true);
      console.log("Please enter a valid email");
      return;
    }
    if (validateEmail(email) === false) {
      setMessage("Your email is invalid");
      setIsError(true);
      return;
    }

    if (!password) {
      setMessage("Please enter your password");
      setIsError(true);
      return;
    }

    if (password.length < 6) {
      setMessage("Password must be of length 6 or greater");
      setIsError(true);
      return;
    }

    getUser(email);
  };

  const getUser = async (userEmail: string) => {
    const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;
    const apiUsers = process.env.REACT_APP_BIDB_API_USERS;

    await axios(`${apiBaseUrl}${apiUsers}?accountName=${userEmail}`).then(
      (res) => {
        if (res.data.rows[0]) {
          const user = res.data.rows[0];
          if (user.active !== true) {
            setData("");
            setError({ error: "Account not active" });
            // pop up modal set error and set message
            setMessage("Account not active");
            setIsError(true);
          } else if (
            !user.idEvaluator ||
            user.idEvaluator === undefined ||
            user.idEvaluator === null
          ) {
            setData("");
            setError({ error: "Evaluator record not found" });
            // pop up modal set error and set message
            setMessage("Evaluator record not found");
            setIsError(true);
          } else if (user.role !== "rerum") {
            setData("");
            setError({ error: "User not authorized" });
            // pop up modal set error and set message
            setMessage("User not authorized");
            setIsError(true);
          } else {
            setError("");
            setData(res.data.rows[0]);
          }
        } else {
          setData("");
          present(
            "Account not found",
            parseInt(process.env.REACT_APP_BIDB_TOAST_TIME!)
          );
          // setError({ error: "Account not found" }); // pop up modal set error and set message
          // setMessage("Account not found");
          // setIsError(true);
        }
      }
    );
  };
  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent fullscreen className="ion-padding ion-text-center main-view">
        <div className="fullheight xc">
          <IonGrid>
            <IonRow>
              <IonCol
                size-xxl="4"
                size-xl="4"
                size-lg="3"
                size-md="2"
                size-sm="12"
                size-sx="12"
                size="12"
              ></IonCol>
              <IonCol
                size-xxl="4"
                size-xl="4"
                size-lg="6"
                size-md="8"
                size-sm="12"
                size-sx="12"
                size="12"
                className="vertical-align login-box"
              >
                <div className="login-main-view">
                  <IonRow>
                    <IonCol>
                      <IonAlert
                        isOpen={iserror}
                        onDidDismiss={() => setIsError(false)}
                        cssClass="my-custom-class"
                        header={"Error!"}
                        message={message}
                        buttons={["Dismiss"]}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    {/*-- Header without a border --*/}
                    <IonHeader className="ion-no-border ion-text-left ">
                      <IonToolbar className="remove-white-background">
                        <IonTitle className="login-view-title no-padding">
                          Login
                        </IonTitle>
                      </IonToolbar>
                    </IonHeader>
                  </IonRow>
                  <IonRow>
                    <IonCol className="input-fields-col no-padding ion-margin-vertical">
                      <div className="form-group">
                        <IonLabel className="form-group-input-text-title">
                          Email
                        </IonLabel>
                        <IonInput
                          type="email"
                          // value={email}
                          onIonChange={(e) => setEmail(e.detail.value!)}
                          className="form-control input-lg form-group-input-text"
                          // placeholder="Email"
                          disabled
                        ></IonInput>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="input-fields-col no-padding ion-margin-vertical">
                      <div className="form-group">
                        <IonLabel className="form-group-input-text-title">
                          Password
                        </IonLabel>
                        <IonInput
                          type="password"
                          // value={password}
                          onIonChange={(e) => setPassword(e.detail.value!)}
                          className="form-control input-lg form-group-input-text"
                          // placeholder="Password"
                          disabled
                        ></IonInput>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div className="w-full">
                        <IonTitle style={{ paddingBottom: " 1.5rem" }}>
                          <a href="#" className="forgot-password-text">
                            Forgot Password?
                          </a>
                        </IonTitle>
                      </div>
                      <IonButton
                        expand="block"
                        onClick={handleLogin}
                        className="login-button"
                        disabled
                      >
                        Login
                      </IonButton>

                      <IonRow className="item-divider-login">
                        <IonCol>
                          <IonItemDivider className="remove-white-background" />
                        </IonCol>
                        <IonCol size="auto">
                          <p className="item-divider-text"> or</p>
                        </IonCol>
                        <IonCol>
                          <IonItemDivider className="remove-white-background" />
                        </IonCol>
                      </IonRow>
                      <div>
                        <SignInWithGoogle
                          showErrorMessage={handleShowErrorMessage}
                        ></SignInWithGoogle>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow></IonRow>
                </div>
              </IonCol>
              <IonCol
                size-xxl="4"
                size-xl="4"
                size-lg="3"
                size-md="2"
                size-sm="12"
                size-sx="12"
                size="12"
              >
                {" "}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
