import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import api from "./middleware/api";
import reducer from "./Reducer";

const persistConfig = {
  key: "root",
  storage
};

const rootReducer = (state: any, action: any) => {
  if (action.type === "user/resetStore") {
    state = undefined;
  }

  return reducer(state, action);
};

const persistedReducer: any = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
