import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
  name: "app",
  initialState: {
    orientation: "",
    device: ""
  },
  reducers: {
    orientationUpdated(app: any, action: PayloadAction<any>) {
      app.orientation = action.payload;
    },
    deviceUpdated(app: any, action: PayloadAction<any>) {
      app.device = action.payload;
    }
  }
});

export const { orientationUpdated, deviceUpdated } = slice.actions;
export default slice.reducer;

/**
 * Gets current orientation.
 */
export const getCurrentOrientation = createSelector(
  (state: any) => state.app,
  (app: any) => {
    return app.orientation;
  }
);

/**
 * Gets current device.
 */
export const getCurrentDevice = createSelector(
  (state: any) => state.app,
  (app: any) => {
    return app.device;
  }
);
