import React from "react";
import { IonCol, IonRow } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getRecommendationCategory,
  setIsFilteredRecommendationVsList,
  getOriginalRecommendationVs,
  recommendationSubCategoriesUpdated,
  recommendationvsUpdated,
  getCurrentRecommendationSubCategories
} from "../../../../../store/catalogs/Recommendations";

import Filters from "../add-modal/filters/Index";
import Category from "../add-modal/filters/Category";
import SubCategory from "../add-modal/filters/SubCategory";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import Search from "../add-modal/filters/Search";

const RecommendationFilters: React.FC<any> = (props: any) => {
  const {
    filters,
    setFilter,
    removeFilter,
    loadItems,
    showClearButton,
    updateFilterValue,
    sort,
    updateList
  } = props;

  const dispatch = useAppDispatch();
  const categories = useAppSelector(getRecommendationCategory);
  const subcategories = dispatch(getCurrentRecommendationSubCategories());
  const originalRecommendationVs = useAppSelector(getOriginalRecommendationVs);

  const setFilterValue = (
    key: string,
    value: string,
    contains: boolean = false
  ) => {
    if (value !== "") {
      setFilter(key, value, contains);

      if (key === "category") {
        dispatch({
          type: recommendationSubCategoriesUpdated.type,
          payload: dispatch(
            getListByName(`Recommendation_${value}_Subcategory`)
          )
        });
      }
    }
  };

  const handleIsFilterdList = (currentKey: string) => {
    let isFilteredList = false;

    for (const [key, value] of Object.entries(filters)) {
      if (key !== currentKey) {
        if (value !== "") {
          isFilteredList = true;
        } else {
          isFilteredList = false;
        }
      }
    }

    if (!isFilteredList) {
      dispatch({
        type: setIsFilteredRecommendationVsList.type,
        payload: false
      });
    }
  };

  return (
    <Filters>
      <IonRow>
        <IonCol size="6">
          <Category
            categories={categories}
            originalList={originalRecommendationVs}
            listFiltered={recommendationvsUpdated}
            setIsFilteredList={setIsFilteredRecommendationVsList}
            category={filters.category && filters.category.value}
            setFilterValue={setFilterValue}
            filters={filters}
            loadItems={loadItems}
            placeholder="Select category"
            removeFilter={removeFilter}
            sort={sort}
          />
        </IonCol>
        <IonCol size="6">
          <SubCategory
            subcategories={subcategories}
            originalList={originalRecommendationVs}
            listFiltered={recommendationvsUpdated}
            setIsFilteredList={setIsFilteredRecommendationVsList}
            subCategory={filters.subCategory && filters.subCategory.value}
            setFilterValue={setFilterValue}
            filters={filters}
            placeholder="Select subcategory"
            removeFilter={removeFilter}
            sort={sort}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Search
            name="search"
            label="Search"
            placeholder="Name, Description"
            list={originalRecommendationVs}
            filters={filters}
            fields={["name", "description"]}
            updateFilterValue={updateFilterValue}
            updateList={updateList}
            isFilteredList={handleIsFilterdList}
            showClearButton={showClearButton}
            sort={sort}
          />
        </IonCol>
      </IonRow>
    </Filters>
  );
};

export default RecommendationFilters;
