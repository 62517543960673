import React from "react";
import { IonIcon } from "@ionic/react";

import { checkboxOutline, stopOutline } from "ionicons/icons";
import BaseBand from "../../../../common/BaseBand";
import BandContent from "./BandContent";

const Band: React.FC<any> = (props: any) => {
  const { isRowExpanded, onClick, item, deleteRow, index, reasons } = props;

  return (
    <BaseBand
      expandRowClick={onClick}
      isRowExpanded={isRowExpanded}
      onDelete={deleteRow}
      item={item}
      message="Are you sure that you want to delete this recommendation?"
      content={
        <BandContent
          bandOnClick={onClick}
          category={item && item.category}
          subCategory={item && item.subCategory}
          type={item && item.type}
          name={item && item.name}
          index={index}
          expandRowClick={onClick}
        />
      }
      extraRightButtons={
        item &&
        item.category &&
        item.category.toLowerCase() !== "resource" && (
          <IonIcon
            className={`reason-icon ${
              reasons && reasons.length > 0 ? "check-green" : "check-grey"
            }`}
            icon={reasons && reasons.length > 0 ? checkboxOutline : stopOutline}
          ></IonIcon>
        )
      }
    />
  );
};

export default Band;
