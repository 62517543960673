import React from "react";
import { IonBadge } from "@ionic/react";

const Badge: React.FC<any> = (props: any) => {
  const { name, label, className, color, onClick, selected } = props;

  return (
    <IonBadge
      onClick={() => (onClick ? onClick(name, label, selected) : null)}
      className={`cursor-default text-uppercase rounded-27 text-9 text-white py-05 px-05 ${className} ${color}`}
    >
      {label}
    </IonBadge>
  );
};

export default Badge;
