import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "activities",
  initialState: {
    requestTypes: [],
    positions: [],
    percentWorkTimes: [],
    percentTimeStanding: [],
    frequencies: [],
    breaksFrequency: []
  },
  reducers: {
    activityTypesReceived(activities: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows) {
        activities.requestTypes = action.payload.rows[0].values;
      } else {
        activities.requestTypes = [];
      }
    },
    activityPositionsReceived(activities: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows) {
        activities.positions = action.payload.rows[0].values;
      } else {
        activities.positions = [];
      }
    },
    activityPercentWorkTimesReceived(
      activities: any,
      action: PayloadAction<any>
    ) {
      if (action.payload && action.payload.rows)
        activities.percentWorkTimes = action.payload.rows[0].values;
      else activities.percentWorkTimes = [];
    },
    activityPercentTimeStandingReceived(
      activities: any,
      action: PayloadAction<any>
    ) {
      if (action.payload && action.payload.rows) {
        activities.percentTimeStanding = action.payload.rows[0].values;
      } else {
        activities.percentTimeStanding = [];
      }
    },
    activityFrequenciesReceived(activities: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows) {
        activities.frequencies = action.payload.rows[0].values;
      } else {
        activities.frequencies = [];
      }
    },
    activityBreaksFrequencyReceived(
      activities: any,
      action: PayloadAction<any>
    ) {
      if (action.payload && action.payload.rows) {
        activities.breaksFrequency = action.payload.rows[0].values;
      } else {
        activities.breaksFrequency = [];
      }
    }
  }
});

export const {
  activityTypesReceived,
  activityPositionsReceived,
  activityPercentWorkTimesReceived,
  activityPercentTimeStandingReceived,
  activityFrequenciesReceived,
  activityBreaksFrequencyReceived
} = slice.actions;
export default slice.reducer;

/**
 * Gets activity types.
 */
export const getActivityTypes = createSelector(
  (state: any) => state.entities.catalogs.activities,
  (activities: any) => activities.requestTypes
);

/**
 * Gets activity positions.
 */
export const getActivityPositions = createSelector(
  (state: any) => state.entities.catalogs.activities,
  (activities: any) => activities.positions
);

/**
 * Get activity percent work times
 */
export const getActivityPercentWorkTimes = createSelector(
  (state: any) => state.entities.catalogs.activities,
  (activities) => activities.percentWorkTimes
);

/**
 * Get activity percent time standing
 */
export const getActivityPercentTimeStanding = createSelector(
  (state: any) => state.entities.catalogs.activities,
  (activities) => activities.percentTimeStanding
);

/**
 * Get activity frequencies.
 */
export const getActivityFrequencies = createSelector(
  (state: any) => state.entities.catalogs.activities,
  (activities) => activities.frequencies
);

/**
 * Get activity breaks frequency.
 */
export const getActivityBreaksFrequency = createSelector(
  (state: any) => state.entities.catalogs.activities,
  (activities) => activities.breaksFrequency
);
