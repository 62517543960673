import React from "react";
import { IonRow, IonLabel } from "@ionic/react";

const Emea: React.FC<any> = (props: any) => {
  return (
    <div className="title-equipment">
      <IonRow>
        <IonLabel className="text-left bold top-var">EMEA Only</IonLabel>
      </IonRow>
    </div>
  );
};

export default Emea;
