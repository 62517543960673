import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  IonList,
  IonItem,
  IonText,
  IonIcon,
  IonImg,
  IonSkeletonText
} from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import {
  caretForwardOutline,
  caretDownOutline,
  refreshOutline
} from "ionicons/icons";
import {
  environmentSubmenuOpenedClosed,
  getCurrentEvaluation,
  getCurrentEvaluationEnvironment,
  getCurrentOpenEnvironmentSubmenu,
  setEnvironment,
  setEnvironmentTabIndex,
  showReportLoaderUpdated
} from "../../../store/evaluation/Current";
import SubMenu from "./SubMenu";
import { iconImages } from "../../../services/tabs.service";
import { getCurrentShowSkeleton } from "../../../store/catalogs/Environments";
import {
  setCurrentEquipment,
  setEquipmentIndexSelected,
  setEquipmentTypeSelected,
  setNoEquipmentSelected,
  equipmentsReceived,
  sortedListUpdated,
  showEquipmentsSkeletonUpdated,
  selectedIdEnvironmentUpdated
} from "../../../store/evaluation/environment/workstation/Equipments";
import {
  setCurrentFurniture,
  setFurnitureIndexSelected,
  setFurnitureTypeSelected,
  setNoFurnitureSelected,
  sortedListUpdated as furnitureSortedListUpdated,
  furnituresListReceived,
  showFurnituresSkeletonUpdated,
  selectedIdFurnitureEnvironmentUpdated
} from "../../../store/evaluation/environment/workstation/Furnitures";
import { environmentIssuesUpdated } from "../../../store/evaluation/environment/Issues";
import { listUpdated } from "../../../store/evaluation/environment/Activities";
import { environmentRisksUpdated } from "../../../store/evaluation/environment/Risks";
import { environmentRecommendationsUpdated } from "../../../store/evaluation/environment/Recommendations";

const SideMenuContent: React.FC<any> = (props: any) => {
  const {
    idEvaluation,
    environments,
    setShowModal,
    getRecommendationReport,
    currentSelectedIndex,
    setCurrentSelectedIndex
  } = props;

  const openSubmenu = useAppSelector(getCurrentOpenEnvironmentSubmenu);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  //const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number>(-1);
  const [openReportSubmenu, setOpenReportSubmenu] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentEvaluation = useAppSelector(getCurrentEvaluation);
  const showSkeleton = useAppSelector(getCurrentShowSkeleton);
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);

  const itemRef: any = useRef();

  useEffect(() => {
    if (!openSubmenu) {
      setSelectedIndex(-1);
    }
  }, [openSubmenu]);

  useEffect(() => {
    setSelectedIndex(-1);
    setCurrentSelectedIndex(-1);

    itemRef.current.click();
  }, [currentEvaluation && currentEvaluation.id]);

  /**
   * Sets environment
   */
  const setCurrentEnvironment = async (
    index: number,
    clickedOnItem: boolean = false
  ) => {
    const environment = environments[index];
    dispatch({
      type: selectedIdEnvironmentUpdated.type,
      payload: environment.id
    });

    dispatch({
      type: selectedIdFurnitureEnvironmentUpdated.type,
      payload: environment.id
    });

    if (clickedOnItem) {
      if (selectedIndex !== index) {
        setSelectedIndex(-1);
      }
    }

    dispatch({
      type: setEnvironment.type,
      payload: environment
    });

    if (currentEnvironment && currentEnvironment.id !== environment.id) {
      dispatch({
        type: showEquipmentsSkeletonUpdated.type,
        payload: true
      });

      dispatch({
        type: showFurnituresSkeletonUpdated.type,
        payload: true
      });

      dispatch({
        type: environmentIssuesUpdated.type,
        payload: []
      });

      dispatch({
        type: listUpdated.type,
        payload: []
      });

      dispatch({
        type: equipmentsReceived.type,
        payload: []
      });

      dispatch({
        type: sortedListUpdated.type,
        payload: []
      });

      dispatch({
        type: furnituresListReceived.type,
        payload: []
      });

      dispatch({
        type: furnitureSortedListUpdated.type,
        payload: []
      });

      dispatch({
        type: environmentRisksUpdated.type,
        payload: []
      });

      dispatch({
        type: environmentRecommendationsUpdated.type,
        payload: []
      });

      dispatch({
        type: setEquipmentIndexSelected.type,
        payload: -1
      });

      dispatch({
        type: setCurrentEquipment.type,
        payload: undefined
      });

      dispatch({
        type: setEquipmentTypeSelected.type,
        payload: ""
      });

      dispatch({
        type: setNoEquipmentSelected.type,
        payload: true
      });

      dispatch({
        type: setFurnitureIndexSelected.type,
        payload: -1
      });

      dispatch({
        type: setCurrentFurniture.type,
        payload: undefined
      });

      dispatch({
        type: setFurnitureTypeSelected.type,
        payload: ""
      });

      dispatch({
        type: setNoFurnitureSelected.type,
        payload: true
      });
    }

    setCurrentSelectedIndex(index);
    goToEvaluationEnvironment();
  };

  const goToEvaluationEnvironment = () => {
    history.push(`/evaluation/${idEvaluation}/environment`);
  };

  const handleSelectIndex = (index: number) => {
    setCurrentEnvironment(index);

    let currentSelectedIndex = -1;
    let open = !openSubmenu;

    if (selectedIndex !== index) {
      currentSelectedIndex = index;
      open = true;
    }

    dispatch({
      type: environmentSubmenuOpenedClosed.type,
      payload: open
    });
    setSelectedIndex(currentSelectedIndex);
  };

  const handleClickSubMenu = (index: number) => {
    dispatch(setEnvironmentTabIndex(index));
    goToEvaluationEnvironment();
  };

  const handleOpenReportSubmenu = () => {
    setOpenReportSubmenu(!openReportSubmenu);

    history.push(`/evaluation/${idEvaluation}/report`);
  };

  const jumpTo = (section: string): any => {
    const iframe: any = document.getElementById("reportIframe");
    if (iframe !== null) {
      const doc = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow.document;
      const element: any = doc.getElementById(section);

      if (element !== null) {
        history.push(`/evaluation/${idEvaluation}/report`);

        setTimeout(() => {
          element.scrollIntoView();
        }, 200);
      }
    }
  };

  const showReportLoader = (value: boolean = false) => {
    dispatch({
      type: showReportLoaderUpdated.type,
      payload: value
    });
  };

  return (
    <IonList>
      <IonItem
        routerLink={`/evaluation/${idEvaluation}/basicinformation`}
        className="font-bold"
        ref={itemRef}
      >
        <p className="pl-2">Basic Information</p>
      </IonItem>
      <IonItem>
        <IonText className="font-bold pl-2">Environments</IonText>
        <div slot="end">
          <IonImg
            className="cursor-pointer"
            onClick={() => setShowModal(true)}
            src={iconImages["addIcon"]}
          ></IonImg>
        </div>
      </IonItem>
      {showSkeleton && (
        <>
          <IonItem>
            <IonSkeletonText animated />
          </IonItem>
          <IonItem>
            <IonSkeletonText animated />
          </IonItem>
          <IonItem>
            <IonSkeletonText animated />
          </IonItem>
        </>
      )}
      {environments &&
        environments.map((environment: any, index: number) => (
          <React.Fragment key={index}>
            <IonItem
              className={`cursor-pointer pl-2 ${
                currentSelectedIndex === index ? "bg-color" : ""
              }`}
              key={index}
            >
              <div className="flex ion-align-items-center">
                <IonIcon
                  icon={
                    openSubmenu && selectedIndex === index
                      ? caretDownOutline
                      : caretForwardOutline
                  }
                  onClick={() => handleSelectIndex(index)}
                />
                <IonItem
                  lines="none"
                  onClick={() => setCurrentEnvironment(index, true)}
                  className={`${
                    currentSelectedIndex === index ? "bg-color" : ""
                  }`}
                >
                  <IonText
                    className={`font-bold ${
                      currentSelectedIndex === index ? "text-blue-1f3061" : ""
                    }`}
                  >
                    {environment.type}: {environment.name}
                  </IonText>
                </IonItem>
              </div>
            </IonItem>
            {openSubmenu && selectedIndex === index && (
              <SubMenu onClick={handleClickSubMenu} />
            )}
          </React.Fragment>
        ))}
      <IonItem className="cursor-pointer">
        <div className="flex ion-align-items-center w-full">
          <IonIcon
            icon={!openReportSubmenu ? caretForwardOutline : caretDownOutline}
            onClick={handleOpenReportSubmenu}
          />
          <IonItem
            className="w-full"
            lines="none"
            routerLink={`/evaluation/${idEvaluation}/report`}
            onClick={() => {
              showReportLoader(true);
              setTimeout(() => {
                showReportLoader(false);
              }, 1000);
            }}
          >
            <IonText className="font-bold">Report Overview</IonText>
          </IonItem>
        </div>
        <IonIcon
          slot="end"
          className="refresh-icon"
          icon={refreshOutline}
          onClick={() => {
            history.push(`/evaluation/${idEvaluation}/report`);
            showReportLoader(true);
            getRecommendationReport();
          }}
        />
      </IonItem>
      {openReportSubmenu && (
        <>
          <IonItem className="cursor-pointer pl-2">
            <IonText className="font-bold pl-2">
              <a href="#section-01"></a>
              <HashLink
                className="font-normal"
                to={`/evaluation/${
                  currentEvaluation && currentEvaluation.id
                }/report#section-1`}
                onClick={() => jumpTo("section-1")}
              >
                Summary
              </HashLink>
            </IonText>
          </IonItem>
          <IonItem className="cursor-pointer pl-2">
            <IonText className="font-bold pl-2">
              <HashLink
                className="font-normal"
                to={`/evaluation/${
                  currentEvaluation && currentEvaluation.id
                }/report#section-2`}
                onClick={() => jumpTo("section-2")}
              >
                Concerns/Current Setup
              </HashLink>
            </IonText>
          </IonItem>
          <IonItem className="cursor-pointer pl-2">
            <IonText className="font-bold pl-2">
              <HashLink
                className="font-normal"
                to={`/evaluation/${
                  currentEvaluation && currentEvaluation.id
                }/report#section-3`}
                onClick={() => jumpTo("section-3")}
              >
                Risks
              </HashLink>
            </IonText>
          </IonItem>
          <IonItem className="cursor-pointer pl-2">
            <IonText className="font-bold pl-2">
              <HashLink
                className="font-normal"
                to={`/evaluation/${
                  currentEvaluation && currentEvaluation.id
                }/report#section-4`}
                onClick={() => jumpTo("section-4")}
              >
                Recommendations
              </HashLink>
            </IonText>
          </IonItem>
        </>
      )}
    </IonList>
  );
};

export default SideMenuContent;
