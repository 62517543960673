import React, { useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import { getCurrentEquipment } from "../../../../../store/evaluation/environment/workstation/Equipments";

import SelectForm from "../../../../common/controls/SelectForm";
import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";
import YesNoInput from "../../../../common/controls/YesNoInput";

import "../../../../../css/equipment.css";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import Checkbox from "../workstations/Checkbox";

const TabletComputerForm: React.FC<any> = (props: any) => {
  const { updateValue } = props;

  const dispatch = useAppDispatch();
  const data = useAppSelector(getCurrentEquipment);
  const tabletComputerPosition = dispatch(
    getListByName("Tablet_Computer_Position")
  );

  const [label, setLabel] = useState<string | null>(null);
  const [position, setPosition] = useState<string | null>(null);

  useEffect(() => {
    setLabel(data && data.label);
    setPosition(data && data.position);
  }, [data && data.id]);

  return (
    <>
      <IonRow>
        <Name nameLabel="Tablet Computer Name" value={data && data.name} />
      </IonRow>
      <IonRow className="top-2">
        <IonCol size="6" className="pt-08 equipment-label">
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={(key: string, value: any) => {
              setLabel(value);
              updateValue(key, value);
            }}
            maxlength={16}
          />
        </IonCol>
        <IonCol size="6">
          <SelectForm
            label="Position"
            options={tabletComputerPosition}
            value={position}
            name="position"
            onChange={(key: string, value: any) => {
              setPosition(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setPosition(null);
              updateValue("position", null);
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesSeparatePointingDevice"
          label="Uses Separate Pointing Device"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesSeparateKeyboard"
          label="Uses Separate Keyboard"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesSeparateRiser"
          label="Uses Riser"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="8"
          size-md="8"
          size-sm="8"
          size-sx="8"
          size="8"
        >
          <IonRow>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="riserAdhoc"
                label="(Riser) Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="riserDesignated"
                label="(Riser) Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default TabletComputerForm;
