import React, { useEffect, useState } from "react";
import { IonGrid } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import IssuesRow from "./IssuesRow";
import { saveEvaluationIssue } from "../../../../../services/evaluation.service";
import { saveIssueEnvironment } from "../../../../../services/environment.service";
import {
  assignIds,
  buildPayload,
  getToken,
  updateCurrentList
} from "../../../../../helpers/global.helper";
import {
  getCurrentEvaluation,
  getCurrentEvaluationEnvironment
} from "../../../../../store/evaluation/Current";
import TopBar from "./TopBar";

import {
  IssueType,
  issuePayloadProperties,
  issueEnvironmentPayloadProperties,
  deleteIssue,
  patchIssue
} from "../../../../../services/issue.service";
import {
  environmentIssuesUpdated,
  getCurrentEnvironmentIssues,
  getCurrentIssueEnvironmentsInRunTime,
  issueEnvironmentsUpdated,
  showCheckboxLoaderUpdated
} from "../../../../../store/evaluation/environment/Issues";
import {
  getEvaluationIssues,
  issuesUpdated
} from "../../../../../store/evaluation/Issues";
import { find } from "lodash";
import Loader from "../../../../common/Loader";

const isIssueDiscomfortType = (type: string) =>
  type.toLowerCase() === IssueType.discomfort.toString();
const isIssueRequestType = (type: string) =>
  type.toLowerCase() === IssueType.request.toString();

const IssuesTab: React.FC<any> = (props) => {
  const { updateEnvironmentsSideMenu, setShowEnvironmentModal } = props;
  const dispatch = useAppDispatch();
  const currentEvaluationIssues = useAppSelector(getEvaluationIssues);
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const currentEvaluation = useAppSelector(getCurrentEvaluation);
  const currentEnvironmentIssues = useAppSelector(getCurrentEnvironmentIssues);
  const issueDefaultExpanded: any = -1;
  const [issueIdExpanded, setIssueIdExpanded] =
    useState<any>(issueDefaultExpanded);
  const [allowIssuePersistence, setAllowIssuePersistence] = useState(false);
  const [currentIssue, setCurrentIssue] = useState<any>(undefined);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const setAllIssues = (issues: any) => {
    dispatch({
      type: issuesUpdated.type,
      payload: issues
    });
  };

  const [workingOn, setWorkingOn] = useState(false);

  // const environmentIssues = useAppSelector(getEnvironmentIssues);

  const isValidIssueId = (issue: any, id: any) => issue["id"] === id;

  const onClickAdd = async (type: IssueType) => {
    setShowLoader(true);
    let payload: any = await buildPayload(
      [{ type, chief: false }],
      issuePayloadProperties
    );

    let response: any = await dispatch(
      saveEvaluationIssue(currentEvaluation.id, payload)
    );

    if (response.status === 200) {
      const insertedIssues: any = await assignIds(
        payload,
        response.data.successes
      );

      // inserting relations
      payload = await buildPayload(
        insertedIssues,
        issueEnvironmentPayloadProperties,
        {
          idEnvironment: {
            key: "idEnvironment",
            value: currentEnvironment.id
          },
          idIssue: {
            key: "idIssue",
            keyValue: "id"
          }
        }
      );

      response = await dispatch(saveIssueEnvironment(payload));

      if (response.status === 200) {
        const list = await updateCurrentList(
          insertedIssues,
          currentEvaluationIssues,
          "desc"
        );

        dispatch({
          type: issuesUpdated.type,
          payload: list
        });

        const environmentIssuesList = await updateCurrentList(
          payload,
          currentEnvironmentIssues
        );

        dispatch({
          type: environmentIssuesUpdated.type,
          payload: environmentIssuesList
        });

        setIssueIdExpanded(insertedIssues[0].id);
        setCurrentIssue(list[0]);
      }
    }

    setShowLoader(false);
  };

  const onClickIssue = (issue: any) => {
    let newIssueIdExpanded = issueDefaultExpanded;
    if (issueIdExpanded !== issue.id) newIssueIdExpanded = issue.id;
    if (issue.id !== undefined) {
      let currentIssue = currentEvaluationIssues.filter((item: any) =>
        isValidIssueId(item, issue.id)
      );
      if (currentIssue.length > 0) {
        setAllowIssuePersistence(false);
        setCurrentIssue(currentIssue[0]);
      }
    }
    setIssueIdExpanded(newIssueIdExpanded);
  };

  const updateRow = (
    issueId: any,
    updatedIssue: any,
    allowPersistence: boolean = true
  ) => {
    const updatedIssues = currentEvaluationIssues.map((issue: any) =>
      isValidIssueId(issue, issueId) ? { ...issue, ...updatedIssue } : issue
    );

    setAllowIssuePersistence(allowPersistence);
    setAllIssues(updatedIssues);
  };

  const insertIssueEnvironment = async (payloadIssueEnvironment: any) => {
    dispatch(saveIssueEnvironment(payloadIssueEnvironment))
      .then(async (responseData: any) => {
        if (responseData["data"]["successes"]) {
          let newPayloadIssueEnvironment = {
            ...payloadIssueEnvironment,
            id: responseData.data.successes[0].id
          };
          const currentIssueEnvironmentsInRuntime = dispatch(
            getCurrentIssueEnvironmentsInRunTime()
          );
          const updatedRelationRows = await updateCurrentList(
            [newPayloadIssueEnvironment],
            currentIssueEnvironmentsInRuntime
          );
          dispatch({
            type: issueEnvironmentsUpdated.type,
            payload: updatedRelationRows
          });

          setTimeout(() => {
            dispatch({
              type: showCheckboxLoaderUpdated.type,
              payload: false
            });
          }, 500);
        }
      })
      .catch((error) => console.log("error-saveIssueEnviroments:", error));
  };

  const onAddEnvironment = (issueId: number, data: any) => {
    let payloadIssueEnvironment = {
      idIssue: issueId,
      token: getToken(),
      idEnvironment: data.id
    };
    insertIssueEnvironment(payloadIssueEnvironment);
  };

  const removeIssue = async (issueId: any = undefined) => {
    if (issueId !== undefined) {
      dispatch(deleteIssue(issueId));

      let updatedIssues = currentEvaluationIssues.filter(
        (issue: any) => !isValidIssueId(issue, issueId)
      );

      setAllIssues(updatedIssues);
      updateTotalEnvironmentIssues(issueId);
    }
  };

  const updateTotalEnvironmentIssues = (issueId: any) => {
    let updatedIssues = currentEnvironmentIssues.filter(
      (env: any) => env.idIssue !== issueId
    );
    dispatch({
      type: environmentIssuesUpdated.type,
      payload: updatedIssues
    });
  };

  const getById = (id: number) => {
    return find(fechIssuesByEnvironment(), { id });
  };

  const setValueIssue = (
    property: string,
    value: any,
    issueId: any = undefined
  ) => {
    let issue: any = issueId === undefined ? currentIssue : getById(issueId);

    if (value !== undefined && issue[property] !== value) {
      console.log("currentIssue :>> ", issue);
      const updateId = issueId ? issueId : issue.id;
      let newIssue: any = { id: updateId };
      newIssue[property] = value;
      updateRow(updateId, newIssue);
      setCurrentIssue(newIssue);
    }
  };

  useEffect(() => {
    if (currentEnvironment !== undefined) {
      setIssueIdExpanded(issueDefaultExpanded);
    }
  }, [currentEnvironment]);

  useEffect(() => {
    const saveIssue = async () => {
      let updatedData = { ...currentIssue, token: getToken() };
      await dispatch(saveEvaluationIssue(currentEvaluation.id, updatedData))
        .then((responseData: any) => {
          if (responseData["successes"] !== undefined) {
            return responseData.successes[0].id;
          }
          return undefined;
        })
        .then((issueId: any) => {
          if (issueId !== undefined) {
            let payloadIssueEnvironment = {
              idIssue: issueId,
              idEnvironment: currentEnvironment.id,
              token: getToken()
            };
            insertIssueEnvironment(payloadIssueEnvironment)
              .then(() => {
                let newIssue = { ...updatedData, id: issueId };
                updateRow(undefined, newIssue, false);
                setCurrentIssue(newIssue);
                setIssueIdExpanded(issueId);
                setWorkingOn(false);
              })
              .catch((error) => {
                setWorkingOn(false);
                console.log("error-saveEvaluationIssue:", error);
              });
          }
        })
        .catch((error) => {
          setWorkingOn(false);
          console.log("error-saveIssue:", error);
        });
    };

    const updateIssue = async () => {
      let updatedData = { ...currentIssue, token: getToken() };
      dispatch(patchIssue(updatedData));
    };

    if (currentIssue && allowIssuePersistence) {
      if (currentIssue["id"] === undefined) saveIssue();
      else updateIssue();
    }
  }, [currentIssue]);

  const handleAddRequest = () => {
    onClickAdd(IssueType.request);
  };

  const handleAddDiscomfort = () => {
    onClickAdd(IssueType.discomfort);
  };

  const fechIssuesByEnvironment = () => {
    const issuesEnvironment: any = currentEvaluationIssues.filter(
      (issue: any) =>
        currentEnvironmentIssues
          ? currentEnvironmentIssues.filter(
              (env: any) => env.idIssue === issue.id
            ).length > 0 || issue.id === undefined
          : false
    );

    return issuesEnvironment;
  };

  return (
    <div className="relative">
      <Loader visible={showLoader} />
      <IonGrid>
        <TopBar
          handleAddRequest={handleAddRequest}
          handleAddDiscomfort={handleAddDiscomfort}
        />
        {currentEvaluationIssues &&
          fechIssuesByEnvironment().map((issue: any, index: number) => {
            return (
              <IssuesRow
                currentIssue={currentIssue}
                index={index}
                key={index}
                issue={issue}
                onClickIssue={onClickIssue}
                updateRow={updateRow}
                deleteRow={removeIssue}
                onClickOpenEnvironmentForm={async (env: any) => {
                  onAddEnvironment(issue.id, env);
                  updateEnvironmentsSideMenu();
                }}
                issueIdExpanded={issueIdExpanded}
                setValueIssue={setValueIssue}
                isIssueRequestType={isIssueRequestType(issue.type)}
                isIssueDiscomfortType={isIssueDiscomfortType(issue.type)}
                insertIssueEnvironment={insertIssueEnvironment}
                setShowEnvironmentModal={setShowEnvironmentModal}
              />
            );
          })}
      </IonGrid>
    </div>
  );
};

export default IssuesTab;
