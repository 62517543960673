import React from "react";
import { IonItem, IonIcon, IonLabel } from "@ionic/react";
import { informationCircle } from "ionicons/icons";

const LengthLabel: React.FC<any> = (props: any) => {
  return (
    <IonItem className="comboBoxCss">
      <IonIcon color="black" icon={informationCircle}></IonIcon>
      <IonLabel className="text-12">
        &nbsp;Label length limited to 16 characters
      </IonLabel>
    </IonItem>
  );
};

export default LengthLabel;
