import React, { useEffect, useState } from "react";
import { IonRow, IonCol } from "@ionic/react";

import { getCurrentFurniture } from "../../../../../store/evaluation/environment/workstation/Furnitures";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";

import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";
import LengthLabel from "../../../../common/controls/LengthLabel";
import YesNoInput from "../../../../common/controls/YesNoInput";
import SelectForm from "../../../../common/controls/SelectForm";
import Emea from "../../../../common/controls/Emea";
import { getListByName } from "../../../../../store/catalogs/ValueList";

const StorageForm: React.FC<any> = (props: any) => {
  const { updateValue } = props;

  const dispatch = useAppDispatch();
  const data = useAppSelector(getCurrentFurniture);
  const storageType = dispatch(getListByName("Storage_Type"));
  const storageLocation = dispatch(getListByName("Storage_Location"));

  const [label, setLabel] = useState<string | null>(null);
  const [storageTypeValue, setStorageTypeValue] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);

  useEffect(() => {
    setLabel(data && data.label);
    setStorageTypeValue(data && data.storageType);
    setLocation(data && data.storageLocation);
  }, [data && data.id]);

  return (
    <>
      <IonRow>
        <Name nameLabel="Storage name" value={data && data.name} />
      </IonRow>
      <IonRow className="top-var">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="5"
          size-md="5"
          size-sm="5"
          size-sx="5"
          size="5"
        >
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={(key: string, value: any) => {
              setLabel(value);
              updateValue(key, value);
            }}
            maxlength={16}
          />
        </IonCol>

        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="7"
          size-md="7"
          size-sm="7"
          size-sx="7"
          size="7"
        >
          <LengthLabel />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <SelectForm
            label="Type"
            options={storageType}
            value={storageTypeValue}
            name="storageType"
            onChange={(key: string, value: any) => {
              setStorageTypeValue(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setStorageTypeValue(null);
              updateValue("storageType", null);
            }}
          />
        </IonCol>
        <IonCol>
          <SelectForm
            label="Location"
            options={storageLocation}
            value={location}
            name="storageLocation"
            onChange={(key: string, value: any) => {
              setLocation(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setLocation(null);
              updateValue("storageLocation", null);
            }}
          />
        </IonCol>
      </IonRow>
      <hr className="separator top-var" />
      <Emea />
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="easilyAccessed"
          label="Easily Accessed"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="easilyMoved"
          label="Easily"
          onChange={updateValue}
        />
      </IonRow>
    </>
  );
};

export default StorageForm;
