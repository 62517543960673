import React, { useState, useEffect } from "react";
import { IonRow, IonCol } from "@ionic/react";
import SortMenu from "./SortMenu";
import _ from "lodash";
import classNames from "classnames";
import { reactLocalStorage } from "reactjs-localstorage";

const TableHeader: React.FC<any> = (props: any) => {
  const { sortTable, sortColumns, sortDirections, disableBaseMethods } = props;

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
    column: "",
    index: -1
  });
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedSortColumns, setSelectedSortColumns] = useState<any>([]);

  useEffect(() => {
    if (disableBaseMethods) {
      const sortedColumns = reactLocalStorage.getObject("selectedSortColumns");
      if (sortedColumns) {
        setSelectedSortColumns(sortedColumns);
      }
    }
  }, []);

  /**
   * Handles sort menu
   * @param e
   */
  const handleSortMenu = (e: any, column: string, index: number = -1) => {
    if (!disableBaseMethods) {
      e.persist();
      setShowPopover({
        showPopover: true,
        event: e,
        column,
        index
      });
    }
  };

  /**
   * Handles sort table
   * @param column
   * @param direction
   */
  const handleSortTable = (
    column: string = "",
    direction: string = "",
    index: number = -1
  ) => {
    setShowPopover({
      showPopover: false,
      event: undefined,
      column: "",
      index: -1
    });
    setSelectedIndex(index);

    const selectedSortColumnIndex = _.indexOf(selectedSortColumns, column);
    if (selectedSortColumnIndex === -1) {
      const temp: string[] = [...selectedSortColumns, column];
      setSelectedSortColumns(temp);
      reactLocalStorage.setObject("selectedSortColumns", temp);
    }

    // Removing column when clear it
    if (index === -1) {
      let temp = [...selectedSortColumns];
      delete temp[selectedSortColumnIndex];
      temp = temp.filter((el) => {
        return el !== null;
      });

      setSelectedSortColumns(temp);
      reactLocalStorage.setObject("selectedSortColumns", temp);
    }

    sortTable(column, direction);
  };

  /**
   * Gets directions
   */
  const getDirection = (column: string) => {
    if (sortColumns && sortColumns.length === 0) {
      return "";
    }

    const columnIndex: number = _.indexOf(sortColumns, column);

    if (columnIndex === -1) {
      return "";
    }

    return sortDirections[columnIndex];
  };

  /**
   * Gets selected sort column class
   * @param column
   */
  const getSelectedSortColumnClass = (
    column: string,
    direction: string,
    index: number
  ) => {
    return classNames(
      (selectedIndex === index && getDirection(column) === direction) ||
        (_.indexOf(selectedSortColumns, column) !== -1 &&
          getDirection(column) === direction)
        ? "icon-button-selected"
        : "icon-button",
      { "cursor-initial": disableBaseMethods }
    );
  };

  return (
    <>
      <SortMenu
        popoverState={popoverState}
        setShowPopover={setShowPopover}
        onSortTable={handleSortTable}
      />
      <IonRow>
        <IonCol size=".75">
          <div className="filter-text-wrapper">
            <div className="filter-text">Type</div>
          </div>
        </IonCol>
        <IonCol size=".75">
          <div className="filter-text-wrapper">
            <div className="filter-text"></div>
          </div>
        </IonCol>
        <IonCol size="1.5">
          <div className="filter-text-wrapper header-search-remove-margin">
            <label className="filter-text">Date</label>
            <div
              className="filter-text-icons"
              onClick={(e: any) => {
                handleSortMenu(e, "appointmentDate", 2);
              }}
            >
              <button
                className={getSelectedSortColumnClass(
                  "appointmentDate",
                  "asc",
                  2
                )}
              >
                ▲
              </button>
              <button
                className={getSelectedSortColumnClass(
                  "appointmentDate",
                  "desc",
                  2
                )}
              >
                ▼
              </button>
            </div>
          </div>
        </IonCol>
        <IonCol size="1">
          <div className="filter-text-wrapper header-search-remove-margin">
            <label className="filter-text">Time</label>
            <div
              className="filter-text-icons"
              onClick={(e: any) => {
                handleSortMenu(e, "appointmentTime", 3);
              }}
            >
              <button
                className={getSelectedSortColumnClass(
                  "appointmentTime",
                  "asc",
                  3
                )}
              >
                ▲
              </button>
              <button
                className={getSelectedSortColumnClass(
                  "appointmentTime",
                  "desc",
                  3
                )}
              >
                ▼
              </button>
            </div>
          </div>
        </IonCol>
        <IonCol size="1">
          <div className="filter-text-wrapper header-search-remove-margin ion-justify-content-center">
            <label className="filter-text">Status</label>
            <div
              className="filter-text-icons"
              onClick={(e: any) => {
                handleSortMenu(e, "status", 4);
              }}>
              <button
                className={getSelectedSortColumnClass(
                  "status",
                  "asc",
                  4
                )}
              >
                ▲
              </button>
              <button
                className={getSelectedSortColumnClass(
                  "status",
                  "desc",
                  4
                )}
              >
                ▼
              </button>
            </div>
          </div>
        </IonCol>
        <IonCol size="1.75">
          <div className="filter-text-wrapper header-search-remove-margin">
            <label className="filter-text">Worker name</label>
            <div
              className="filter-text-icons"
              onClick={(e: any) => {
                handleSortMenu(e, "workerName", 5);
              }}
            >
              <button
                className={getSelectedSortColumnClass("workerName", "asc", 5)}
              >
                ▲
              </button>
              <button
                className={getSelectedSortColumnClass("workerName", "desc", 5)}
              >
                ▼
              </button>
            </div>
          </div>
        </IonCol>
        <IonCol size="1.75">
          <div className="filter-text-wrapper header-search-remove-margin">
            <label className="filter-text">Company</label>
            <div
              className="filter-text-icons"
              onClick={(e: any) => {
                handleSortMenu(e, "organizationName", 6);
              }}
            >
              <button
                className={getSelectedSortColumnClass(
                  "organizationName",
                  "asc",
                  6
                )}
              >
                ▲
              </button>
              <button
                className={getSelectedSortColumnClass(
                  "organizationName",
                  "desc",
                  6
                )}
              >
                ▼
              </button>
            </div>
          </div>
        </IonCol>
        <IonCol size="3.5">
          <div className="filter-text-wrapper">
            <label className="filter-text">Location</label>
          </div>
        </IonCol>
      </IonRow>
    </>
  );
};

export default TableHeader;
