import React from "react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import { getIfNewSession, userLogged } from "../../../../../store/User";
import {
  getNoFurnitureSelected,
  getSelectedFurnitureIndex,
  getSelectedType,
  getSortedEnvironmentFurnitures,
  setCurrentFurniture,
  setShowAddWorkstationFurnitureModal,
  setFurnitureIndexSelected,
  setFurnitureTypeSelected,
  setNoFurnitureSelected,
  getShowAddWorkstationFurnitureModal,
  getCurrentShowFurnituresSkeleton,
  getCurrentSelectedFurniture,
  idSelected,
  updateEnvironmentFurnitures
} from "../../../../../store/evaluation/environment/workstation/Furnitures";

import { useLongPress } from "../../../../../helpers/global.helper";
import FurnitureWrapper from "./FurnitureWrapper";
import TouchFurnitureWrapper from "./TouchFurnitureWrapper";
import Wrapper from "./Wrapper";
import { furnituresUpdated } from "../../../../../store/catalogs/Furnitures";

const FurnitureContainer: React.FC<any> = (props: any) => {
  const { isTouch } = props;

  const dispatch = useAppDispatch();
  const environmentFurnitures: any = useAppSelector(
    getSortedEnvironmentFurnitures
  );
  const type: string = useAppSelector(getSelectedType);
  const isNewSession = useAppSelector(getIfNewSession);
  const noSelectedFurniture = useAppSelector(getNoFurnitureSelected);
  const currentShowFurnituresSkeleton = useAppSelector(
    getCurrentShowFurnituresSkeleton
  );

  const openModal = () => {
    dispatch({
      type: setShowAddWorkstationFurnitureModal.type,
      payload: true
    });
  };

  const openWorkstationFurnitureModal = useAppSelector(
    getShowAddWorkstationFurnitureModal
  );

  /**
   * Shows form component
   */
  const showFormComponent = async (furniture: any) => {
    if (isNewSession) {
      dispatch({
        type: userLogged.type,
        payload: false
      });
    }

    const currentFurniture = dispatch(getCurrentSelectedFurniture());

    dispatch({
      type: setCurrentFurniture.type,
      payload: currentFurniture
    });

    dispatch({
      type: setFurnitureTypeSelected.type,
      payload: furniture.type
    });

    dispatch({
      type: setNoFurnitureSelected.type,
      payload: false
    });
  };

  const [onStart, onEnd] = useLongPress(() => console.log("modal"), 1000);

  const resetList = (list: any) => {
    setTimeout(() => {
      dispatch({
        type: furnituresUpdated.type,
        payload: list
      });
    }, 350);
  };

  return (
    <>
      {!isTouch ? (
        <FurnitureWrapper>
          <Wrapper
            openWorkstationFurnitureModal={openWorkstationFurnitureModal}
            environmentFurnitures={environmentFurnitures}
            openModal={openModal}
            showFormComponent={showFormComponent}
            getSelectedFurnitureIndex={getSelectedFurnitureIndex}
            setFurnitureIndexSelected={setFurnitureIndexSelected}
            updateSortedList={updateEnvironmentFurnitures}
            setNoFurnitureSelected={setNoFurnitureSelected}
            setFurnitureTypeSelected={setFurnitureTypeSelected}
            isNewSession={isNewSession}
            noSelectedFurniture={noSelectedFurniture}
            type={type}
            showSkeleton={currentShowFurnituresSkeleton}
            resetList={resetList}
            idSelected={idSelected}
          />
        </FurnitureWrapper>
      ) : (
        <TouchFurnitureWrapper>
          <Wrapper
            openWorkstationFurnitureModal={openWorkstationFurnitureModal}
            environmentFurnitures={environmentFurnitures}
            openModal={openModal}
            showFormComponent={showFormComponent}
            getSelectedFurnitureIndex={getSelectedFurnitureIndex}
            setFurnitureIndexSelected={setFurnitureIndexSelected}
            updateSortedList={updateEnvironmentFurnitures}
            setNoFurnitureSelected={setNoFurnitureSelected}
            setFurnitureTypeSelected={setFurnitureTypeSelected}
            isNewSession={isNewSession}
            noSelectedFurniture={noSelectedFurniture}
            type={type}
            idSelected={idSelected}
          />
        </TouchFurnitureWrapper>
      )}
    </>
  );
};

export default FurnitureContainer;
