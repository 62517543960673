import React, { useState, useEffect } from "react";
import { IonCol, IonRow, IonLabel, IonButton } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getCurrentEquipment,
  getLaptopIds
} from "../../../../../store/evaluation/environment/workstation/Equipments";

import SelectForm from "../../../../common/controls/SelectForm";
import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";
import YesNoInput from "../../../../common/controls/YesNoInput";
import Emea from "../../../../common/controls/Emea";

import "../../../../../css/equipment.css";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import _ from "lodash";
import Checkbox from "../workstations/Checkbox";

const DisplayForm: React.FC<any> = (props) => {
  const { updateValue, getSelectedText, hasLaptopValue } = props;

  const dispatch = useAppDispatch();
  const data = useAppSelector(getCurrentEquipment);
  const displayPosition = dispatch(getListByName("Display_Position"));
  const displayPercentUsed = dispatch(getListByName("Display_Percent_Used"));
  const displaySize = dispatch(getListByName("Display_Size"));
  const displayTechnology = dispatch(getListByName("Display_Technology"));

  const [laptopIds] = useState<any>(dispatch(getLaptopIds()));
  const [label, setLabel] = useState<string | null>(null);
  const [laptopId, setLaptopId] = useState<number | null>();
  const [position, setPosition] = useState<string | null>(null);
  const [percentUsed, setPercentUsed] = useState<string | null>(null);
  const [displayType, setDisplayType] = useState<string | null>(null);
  const [orientation, setOrientation] = useState<string | null>(null);
  const [size, setSize] = useState<string | null>(null);
  const [displayTechnologyValue, setDisplayTechnologyValue] = useState<
    string | null
  >();

  useEffect(() => {
    setLaptopId(
      data &&
        data &&
        data.idLaptopEquipment &&
        data.idLaptopEquipment !== null &&
        parseInt(data.idLaptopEquipment)
    );
    setLabel(data && data.label);
    setPosition(data && data.position);
    setPercentUsed(
      data && data.percentUsed && (~~(data.percentUsed * 100)).toString()
    );
    setDisplayType(data && data.displayType);
    setOrientation(data && data.orientation);
    setSize(data && data.size);
    setDisplayTechnologyValue(data && data.displayTechnology);
  }, [data && data.id]);

  return (
    <>
      <IonRow>
        <Name nameLabel="Display Name" value={data && data.name} />
      </IonRow>
      <IonRow className="top-2">
        <IonCol size="6" className="pt-09 equipment-label">
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={(key: string, value: any) => {
              setLabel(value);
              updateValue(key, value);
            }}
            maxlength={16}
          />
        </IonCol>
        <IonCol size="6">
          <SelectForm
            label="Laptop"
            options={laptopIds}
            value={laptopId}
            selectedText={getSelectedText(laptopIds, laptopId)}
            name="idLaptopEquipment"
            onChange={(key: string, value: any) => {
              if (value && value !== 0 && value !== "") {
                setLaptopId(parseInt(value));
                updateValue(key, parseInt(value));
              }
            }}
            keyValue={{
              key: "id",
              value: "label"
            }}
            clearValue={() => {
              setLaptopId(null);
              updateValue("idLaptopEquipment", null);
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <IonCol size="6">
          <SelectForm
            label="Position"
            options={displayPosition}
            value={position}
            name="position"
            onChange={(key: string, value: any) => {
              setPosition(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setPosition(null);
              updateValue("position", null);
            }}
          />
        </IonCol>
        <IonCol size="6">
          <SelectForm
            label="Percent Used"
            options={displayPercentUsed}
            value={percentUsed}
            name="percentUsed"
            onChange={(key: string, value: any) => {
              if (value) {
                setPercentUsed(value);
                updateValue(key, parseInt(value) / 100);
              }
            }}
            clearValue={() => {
              setPercentUsed(null);
              updateValue("percentUsed", null);
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <IonCol size="6">
          <SelectForm
            label="Size"
            options={displaySize}
            value={size}
            name="size"
            onChange={(key: string, value: any) => {
              setSize(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setSize(null);
              updateValue("size", null);
            }}
          />
        </IonCol>
        <IonCol size="6">
          <SelectForm
            label="Display Technology"
            options={displayTechnology}
            value={displayTechnologyValue}
            name="displayTechnology"
            onChange={(key: string, value: any) => {
              setDisplayTechnologyValue(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setDisplayTechnologyValue(null);
              updateValue("displayTechnology", null);
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow className="top-var">
        <IonLabel className="form-group-input-text-title"> Type </IonLabel>
      </IonRow>
      <IonRow>
        <IonCol size="4" className="to-left">
          <IonButton
            onClick={() => {
              let value: string | null = "FLAT";

              if (displayType === "FLAT") {
                value = null;
              }

              setDisplayType(value);
              updateValue("displayType", value);
            }}
            className={
              "btn no-maring btn-group-2 " +
              (displayType?.toLowerCase() === "flat"
                ? "positive"
                : "negative") +
              " font-bold"
            }
          >
            FLAT
          </IonButton>
        </IonCol>
        <IonCol size="4" className="to-center">
          <IonButton
            onClick={() => {
              let value: string | null = "CURVED";

              if (displayType === "CURVED") {
                value = null;
              }

              setDisplayType(value);
              updateValue("displayType", value);
            }}
            className={
              "btn no-maring btn-group-2 " +
              (displayType?.toLowerCase() === "curved"
                ? "positive"
                : "negative") +
              " font-bold"
            }
          >
            CURVED
          </IonButton>
        </IonCol>
        <IonCol size="4" className="to-right">
          <IonButton
            onClick={() => {
              let value: string | null = "CINTIQ";

              if (displayType === "CINTIQ") {
                value = null;
              }

              setDisplayType(value);
              updateValue("displayType", value);
            }}
            className={
              "btn no-maring btn-group-2 " +
              (displayType?.toLowerCase() === "cintiq"
                ? "positive"
                : "negative") +
              " font-bold"
            }
          >
            CINTIQ
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow className="top-var">
        <IonLabel className="form-group-input-text-title">
          {" "}
          Orientation{" "}
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonCol size="6" className="to-left">
          <IonButton
            onClick={() => {
              let value: string | null = "HORIZONTAL";

              if (orientation === "HORIZONTAL") {
                value = null;
              }

              setOrientation(value);
              updateValue("orientation", value);
            }}
            className={
              "btn no-maring btn-group-2 " +
              (orientation?.toLowerCase() === "horizontal"
                ? "positive"
                : "negative") +
              " font-bold"
            }
          >
            HORIZONTAL
          </IonButton>
        </IonCol>
        <IonCol size="6" className="to-right">
          <IonButton
            onClick={() => {
              let value: string | null = "VERTICAL";

              if (orientation === "VERTICAL") {
                value = null;
              }

              setOrientation(value);
              updateValue("orientation", value);
            }}
            className={
              "btn no-maring btn-group-2 " +
              (orientation?.toLowerCase() === "vertical"
                ? "positive"
                : "negative") +
              " font-bold"
            }
          >
            VERTICAL
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesMonitorArm"
          label="Uses Monitor Arm"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesRiser"
          label="Uses Riser"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="8"
          size-md="8"
          size-sm="8"
          size-sx="8"
          size="8"
        >
          <IonRow>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="riserAdhoc"
                label="Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="riserDesignated"
                value={data && data.riserDesignated}
                label="Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <hr className="separator top-var" />
      <Emea />
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="appropriateDistance"
          label="Appropriate Distance"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="hasSwivelTilt"
          label="Swivel Tilt"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="easilyReadable"
          label="Easily Readable"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="appropriateHeight"
          label="Appropriate Height"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="hasGlare"
          label="Free of Glare"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="easilyAdjustableBrightness"
          label="Easily Adjustable Brightness"
          onChange={updateValue}
        />
      </IonRow>
    </>
  );
};

export default DisplayForm;
