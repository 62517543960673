import React from "react";
import { IonRow, IonCol } from "@ionic/react";

import LinkRiskModal from "../risks/LinkRiskModal";
import { useAppSelector } from "../../../../../store/Hooks";
import { getEnvironments } from "../../../../../store/evaluation/Current";

const LinkRisks: React.FC<any> = (props: any) => {
  const { risks, title, saveSelectedRisks } = props;
  const environments = useAppSelector(getEnvironments);

  return (
    <IonRow>
      <IonCol size="6" className="bold to-left top-1">
        Risks
      </IonCol>
      <IonCol size="6" className="to-right top-1 text-regular-size bold">
        <LinkRiskModal
          risks={risks}
          title={title}
          filterEnvironments={environments}
          saveSelectedRisks={saveSelectedRisks}
        />
      </IonCol>
    </IonRow>
  );
};

export default LinkRisks;
