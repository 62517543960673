import React from "react";
import { IonList, IonItem, IonCol, IonText, IonButton } from "@ionic/react";
import _ from "lodash";

const CorrectiveLenseButtons: React.FC<any> = (props: any) => {
  let row = { ...props.correctiveLenses };
  const {
    correctiveLenses: lenses,
    getCorrectiveLenseName,
    updateCorrectiveLenses,
    rowIndex,
    type
  } = props;
  _.unset(row, "id");

  return (
    <IonList>
      {Object.keys(row).map((key: any, index: number) => (
        <IonItem key={index}>
          <IonCol className="w-463">
            <IonText className="float-start text-16-bold">
              {getCorrectiveLenseName(key)}
            </IonText>
          </IonCol>
          <IonCol className="mr-24">
            <IonButton
              onClick={() =>
                updateCorrectiveLenses(
                  lenses.id,
                  key,
                  true,
                  rowIndex,
                  type,
                  row
                )
              }
              color={lenses[key] === true ? "primary" : "light"}
              className="btn"
            >
              YES
            </IonButton>
            <IonButton
              onClick={() =>
                updateCorrectiveLenses(
                  lenses.id,
                  key,
                  false,
                  rowIndex,
                  type,
                  row
                )
              }
              color={lenses[key] === false ? "primary" : "light"}
              className="float-end btn"
            >
              NO
            </IonButton>
          </IonCol>
        </IonItem>
      ))}
    </IonList>
  );
};

export default CorrectiveLenseButtons;
