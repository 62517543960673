/**
 * Gets request options.
 * @param path
 * @param method
 * @param body
 * @returns
 */
export const getRequestOptions = (
  path: string = "",
  method: string = "",
  body: any = {}
) => {
  // arn:aws:iam::*:user/*
  const url: any = process.env.REACT_APP_BIDB_API_HOSTNAME;
  let properties: any = {
    service: "execute-api",
    region: process.env.REACT_APP_BIDB_API_REGION,
    hostname: url,
    path,
    method,
    body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };

  if (method == "GET") {
    delete properties.body;
  }

  return properties;
};
