import React, { useEffect, useRef } from "react";
import { IonCol, IonRow } from "@ionic/react";

import { useAppDispatch } from "../../../../../store/Hooks";
import { getListByName } from "../../../../../store/catalogs/ValueList";

import BaseSelectMultiCheckbox from "../../../../common/BaseSelectMultiCheckbox";
import Textarea from "../../../../common/Textarea";

const IssuesRequestType: React.FC<any> = ({ setValueIssue, issue }) => {
  const chairRef = useRef<any>();

  const dispatch = useAppDispatch();
  const issueRequestTypes = dispatch(getListByName("Issue_Request_Types"));

  const updateRequestList = (value: string, checked: boolean) => {
    let requestTypes: any = [value];

    if (issue.requestTypes !== undefined && issue.requestTypes !== "") {
      requestTypes = issue.requestTypes.filter(
        (request: string) => request != value
      );

      if (checked) requestTypes = [...requestTypes, value];
    }

    if (requestTypes.length === 0) {
      requestTypes.push("");
    } else {
      let validRequestTypes = [];
      for (const [, requestType] of requestTypes.entries()) {
        if (issueRequestTypes.includes(requestType)) {
          validRequestTypes.push(requestType);
        }
      }

      requestTypes = validRequestTypes;
    }

    return requestTypes;
  };

  const isChecked = (key: string) => {
    return issue && issue.requestTypes && issue.requestTypes.includes(key);
  };

  useEffect(() => {
    if (chairRef) chairRef.current?.focus();
  }, []);

  return (
    <>
      <IonRow>
        <IonRow className="top-2">
          <IonCol className="bold to-left top-1">Request Type</IonCol>
        </IonRow>
      </IonRow>
      {issueRequestTypes && issueRequestTypes.length > 0 && (
        <IonRow className="top-2">
          <BaseSelectMultiCheckbox
            source={issueRequestTypes}
            name={"requestTypes"}
            onClickButton={(name: any, value: boolean) =>
              setValueIssue("requestTypes", updateRequestList(name, value))
            }
            isChecked={isChecked}
          />
        </IonRow>
      )}
      <IonRow className="top-2">
        <IonCol>
          <Textarea
            label={"Description"}
            value={issue && issue.description ? issue.description : ""}
            onBlur={(e: any) => {
              let isDifferent = issue.description != e.target.innerText;
              if (isDifferent) setValueIssue("description", e.target.innerText);
            }}
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default IssuesRequestType;
