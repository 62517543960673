import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { getPlatforms } from "@ionic/react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonGrid,
  IonRow
} from "@ionic/react";

import ToolBar from "../components/layout/Toolbar";
import SearchBox from "../components/dashboard/SearchBox";
import Table from "../components/evaluation/Table";
import Loader from "../components/common/Loader";

import { useAppDispatch, useAppSelector } from "../store/Hooks";
import {
  getCurrentEvaluations,
  setEvaluations,
  setSortColumnDirection,
  setOriginalList,
  loadEvaluations
} from "../store/Evaluations";
import { recommendationVsReceived } from "../store/catalogs/Recommendations";
import { riskVsReceived } from "../store/catalogs/Risks";
import { equipmentsReceived } from "../store/catalogs/Equipments";
import { furnituresReceived } from "../store/catalogs/Furnitures";
import { listReceived } from "../store/catalogs/ValueList";
import {
  idUpdated,
  setEvaluation,
  timestampUpdated
} from "../store/evaluation/Current";
import { deviceUpdated, orientationUpdated } from "../store/App";

import { apiCall } from "../store/api";
import {
  sortTable,
  sortColumns,
  sortDirections
} from "../services/evaluation.service";
import { makeRequest } from "../helpers/global.helper";

import "../css/bidb.css";
import "../css/dashboard.css";

const valuelistsApi = process.env.REACT_APP_BIDB_API_VALUELISTS;

const Dashboard: React.FC = () => {
  const history: any = useHistory();
  const dispatch: any = useAppDispatch();
  const evaluations = useAppSelector(getCurrentEvaluations);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const getOrientation = () => {
    //const orientationType: string = window.screen.orientation.type;
    let orientation = "";
    const mqOrientation = window.matchMedia("(orientation: portrait)").matches;

    if (mqOrientation) {
      orientation = "portrait";
    } else {
      orientation = "landscape";
    }

    return orientation;
  };

  useEffect(() => {
    dispatch({
      type: orientationUpdated.type,
      payload: getOrientation()
    });

    /*  window.screen.orientation.onchange = function () {
      dispatch({
        type: orientationUpdated.type,
        payload: getOrientation()
      });
    }; */
    window
      .matchMedia("(orientation: portrait)")
      .addEventListener("change", e => {
        console.log(e.matches);
        dispatch({
          type: orientationUpdated.type,
          payload: getOrientation()
        });
      });

    const platforms = getPlatforms();
    let device = "";

    if (platforms.indexOf("desktop") >= 0) {
      device = "desktop";

      dispatch({
        type: deviceUpdated.type,
        payload: device
      });

      return;
    }

    if (platforms.indexOf("tablet") >= 0) {
      device = "tablet";
      dispatch({
        type: deviceUpdated.type,
        payload: device
      });
      return;
    }

    if (platforms.indexOf("mobile") >= 0) {
      device = "mobile";
      dispatch({
        type: deviceUpdated.type,
        payload: device
      });
    }
  }, []);

  const sortRows = async (
    rows: any,
    column: string = "",
    direction: string = ""
  ) => {
    dispatch(setEvaluations(await sortTable(rows, column, direction)));
    setShowSpinner(false);

    if (sortColumns.length > 0 && sortDirections.length > 0) {
      dispatch(
        setSortColumnDirection({
          sortColumns: sortColumns,
          sortDirections: sortDirections
        })
      );
    }
  };

  const fetchRows = async (search: string = "", dateObject: any = null) => {
    const hack: string = search;

    if (search === "loadingOriginalData") {
      search = "";
    }

    let evaluations = await setStatusColumn(
      await dispatch(
        loadEvaluations({
          search,
          dateObject
        })
      )
    );

    await sortRows(evaluations);

    // this is a hack to cache the original list
    if (hack === "loadingOriginalData") {
      await dispatch(setOriginalList());
    }
  };

  const setStatusColumn = async (evaluations: any) => {
    let evaluationsWithStatus = evaluations?.map((eva: any) => {
      return {
        ...eva,
        evalStatus: eva.status,
        status: getStatus(eva.appointmentConfirmed, eva.appointmentCanceled)
      };
    });
    return evaluationsWithStatus;
  };

  const getStatus = (appointmentConfirmed: any, appointmentCanceled: any) => {
    let status: number = 1;
    if (appointmentCanceled) status = 3;
    else {
      if (
        !appointmentConfirmed ||
        (appointmentConfirmed && appointmentConfirmed == false)
      )
        status = 2;
    }
    return status;
  };

  /**
   * Sets evaluation
   */
  const setEvaluationBaseData = async (index: number) => {
    dispatch({
      type: timestampUpdated.type,
      payload: Math.round(+new Date() / 1000)
    });

    setShowSpinner(true);
    const evaluation = { ...evaluations[index] };

    // We set the evaluation data fetched from evaluations/{id} endpoint
    if (evaluation) {
      const { id } = evaluation;

      dispatch({
        type: idUpdated.type,
        payload: id
      });

      try {
        const response: any = await dispatch(
          makeRequest({
            endpoint: `/evaluations/${id && id}`,
            method: "GET"
          })
        );

        if (response.data) {
          dispatch({
            type: setEvaluation.type,
            payload: response.data
          });

          if (id) {
            setShowSpinner(false);
            history.push(`/evaluation/${id && id}/basicinformation`);
          }
        }
      } catch (e) {
        setShowSpinner(false);
        console.log(e);
      }
    }
  };

  /**
   * Caching data
   */
  useEffect(() => {
    (async () => {
      dispatch(
        apiCall({
          url: `${valuelistsApi}`,
          onSuccess: listReceived.type
        })
      );
      dispatch(
        apiCall({
          url: `${process.env.REACT_APP_BIDB_API_EQUIPMENTS}`,
          onSuccess: equipmentsReceived.type
        })
      );
      dispatch(
        apiCall({
          url: `${process.env.REACT_APP_BIDB_API_FURNITURES}`,
          onSuccess: furnituresReceived.type
        })
      );
      dispatch(
        apiCall({
          url: `${process.env.REACT_APP_BIDB_API_RECOMMENDATIONVS}`,
          onSuccess: recommendationVsReceived.type
        })
      );
      dispatch(
        apiCall({
          url: `${process.env.REACT_APP_BIDB_API_RISKVS}`,
          onSuccess: riskVsReceived.type
        })
      );
    })();
  }, []);

  useEffect(() => {
    const init = async () => {
      setShowSpinner(true);
      await fetchRows("loadingOriginalData");
    };

    init();
  }, []);

  return (
    <IonPage>
      <Loader visible={showSpinner} />
      <IonHeader>
        <IonToolbar className="toolbar-header">
          <ToolBar />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-text-center">
        <IonGrid className="content">
          <IonRow className="searchbox">
            <SearchBox fetchEvaluations={fetchRows} />
          </IonRow>
          <IonRow className="separate"></IonRow>
          <Table
            evaluations={evaluations}
            sortRows={sortRows}
            setEvaluation={setEvaluationBaseData}
            sortColumns={sortColumns}
            sortDirections={sortDirections}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Dashboard);
