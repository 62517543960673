import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "issues",
  initialState: {
    list: []
  },
  reducers: {
    issuesReceived(issues: any, action: PayloadAction<any>) {
      const { payload } = action;
      // The second one is used to initialize main list.
      if (payload.rows && payload.rows.length > 0) {
        issues.list = payload.rows;
      } else {
        issues.list = [];
      }
    },
    issuesUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.list = action.payload;
    }
  }
});

export const { issuesReceived, issuesUpdated } = slice.actions;
export default slice.reducer;

/**
 * Gets issues.
 */
export const getEvaluationIssues = createSelector(
  (state: any) => state.evaluation.issues,
  (issues: any) => issues.list
);

/**
 * Gets grouped evaluation issues. Discomfort first.
 */
export const getGroupedEvaluationIssues = createSelector(
  (state: any) => state.evaluation.issues,
  (issues: any) => (issues.list ? _.sortBy(issues.list, ["type"], ["asc"]) : [])
);
