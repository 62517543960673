import { makeRequest } from "../helpers/global.helper";
const evaluatorsApi = process.env.REACT_APP_BIDB_API_EVALUATORS;

// Gets evaluator by Id
export const getEvaluatorById =
  (id: number) => async (dispatch: any, getState: any) => {
    return await dispatch(
      makeRequest({
        endpoint: `${evaluatorsApi}/${id}`,
        method: "GET"
      })
    );
  };

/**
 * Get locations evaluators
 * @param data
 */
export const getEvaluatorLocations =
  (id: number) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({
        endpoint: `${evaluatorsApi}/${id}/locations`,
        method: "GET"
      })
    );

    return response;
  };
