import React from "react";
import {
  IonButton,
  IonContent,
  IonRow,
  IonCol,
  IonLabel,
  IonText
} from "@ionic/react";

import BaseModal from "../common/BaseModal";

import moment from "moment";
import { getSortedColumnClass } from "../../helpers/global.helper";

const ReviewDuplicateModal: React.FC<any> = (props: any) => {
  const {
    openDuplicateModal,
    setOpenDuplicateModal,
    rows,
    selectWorker,
    handleSort,
    sort,
    onDidDismiss,
    workerData,
    onSaveWorker
  } = props;

  const columns = [
    {
      name: "Name",
      sort: "nameFirst,nameLast"
    },
    {
      name: "Location",
      removeSortIcons: true
    },
    {
      name: "Floor",
      sort: "employeeFloorName"
    },
    {
      name: "Desk",
      sort: "employeeDeskNumber"
    },
    {
      name: "Created date",
      sort: "timestampInserted"
    },
    {
      name: "Created by",
      sort: "",
      removeSortIcons: true
    },
    {
      name: "Action",
      sort: "",
      removeSortIcons: true
    }
  ];

  const getSelectedSortColumnClass = (column: string, direction: string) => {
    return getSortedColumnClass(column, direction, sort);
  };

  const closeModal = (value: boolean) => {
    setOpenDuplicateModal(value);
  };

  return (
    <BaseModal
      cssClass="review-duplicates"
      title="Review Duplicates"
      isOpen={openDuplicateModal}
      closeModal={closeModal}
      onDidDismiss={onDidDismiss}
      content={
        <>
          <IonRow className="ml-12 mr-12 mt-20">
            <IonCol>
              <p className="subtitle-modal2 text-16">
                A worker with this name already exists.
              </p>
              <IonLabel className="text-left title-name text-regular-size">
                <b>
                  If the worker already exists, just click "Select" in the
                  corresponding line. If the worker is still new, click on the
                  "No match, create new".
                </b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <div className="ml-24 mr-24">
            <IonRow className="mt-10">
              {columns.map((column: any, index: number) => (
                <IonCol className=" ml-24" key={index}>
                  <div className="filter-text-wrapper header-search-remove-margin">
                    <label className="filter-text">{column.name}</label>
                    {!column.removeSortIcons && (
                      <div className="filter-text-icons">
                        <button
                          className={`icon-button ${getSelectedSortColumnClass(
                            column.sort,
                            "asc"
                          )}`}
                          onClick={() => {
                            if (rows.length > 0 && column.sort != "") {
                              handleSort(column.sort, "asc");
                            }
                          }}
                        >
                          ▲
                        </button>
                        <button
                          className={`icon-button ${getSelectedSortColumnClass(
                            column.sort,
                            "desc"
                          )}`}
                          onClick={() => {
                            if (rows.length > 0 && column.sort != "") {
                              handleSort(column.sort, "desc");
                            }
                          }}
                        >
                          ▼
                        </button>
                      </div>
                    )}
                  </div>
                </IonCol>
              ))}
            </IonRow>
            <IonContent className="box-list-3">
              {rows.map((row: any, index: number) => (
                <IonRow
                  className={`separate-table-rows py-08 ion-align-items-center ${
                    (index + 1) % 2 == 0 ? "bg-color-FAFAFA" : ""
                  }`}
                  key={index}
                >
                  <IonCol className="ml-24">
                    <div className="filter-text-wrapper ion-text-left">
                      <IonText className="row-data-text">{`${row.nameFirst} ${row.nameLast}`}</IonText>
                    </div>
                  </IonCol>

                  <IonCol>
                    <div className="filter-text-wrapper ion-text-left">
                      <IonText className="row-data-text"></IonText>
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className="filter-text-wrapper ion-text-left">
                      <IonText className="row-data-text">{`${row.employeeDeskNumber}`}</IonText>
                    </div>
                  </IonCol>

                  <IonCol>
                    <div className="filter-text-wrapper ion-text-left">
                      <IonText className="row-data-text">{`${row.employeeFloorName}`}</IonText>
                    </div>
                  </IonCol>

                  <IonCol>
                    <div className="filter-text-wrapper ion-text-left">
                      <IonText className="row-data-text">{`${moment(
                        row.timestampInserted
                      ).format("MM/DD/YYYY")}`}</IonText>
                    </div>
                  </IonCol>

                  <IonCol>
                    <div className="filter-text-wrapper ion-text-left">
                      <IonText className="row-data-text"></IonText>
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className="filter-text-wrapper ion-text-left">
                      <IonButton fill="clear" onClick={() => selectWorker(row)}>
                        <b>SELECT</b>
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              ))}
            </IonContent>
          </div>
        </>
      }
      footer={
        <IonRow className="px-24px">
          <IonCol>
            <IonButton
              color="medium"
              className="ion-float-left btn-custom-modal btn-cancel2"
              onClick={() => setOpenDuplicateModal(false)}
            >
              CANCEL
            </IonButton>
            <IonButton
              className="ion-float-right font-bold"
              onClick={() => onSaveWorker(workerData)}
            >
              NO MATCH, CREATE NEW
            </IonButton>
          </IonCol>
        </IonRow>
      }
    />
  );
};

export default ReviewDuplicateModal;
