import React, { useEffect, useState } from "react";

import { useAppDispatch } from "../../../../../../store/Hooks";

import SelectForm from "../../../../../common/controls/SelectForm";

import { filterList } from "../../../../../../services/tabs.service";

import { omit } from "lodash";

const Category: React.FC<any> = (props: any) => {
  const {
    categories,
    listFiltered,
    setIsFilteredList,
    category,
    setFilterValue,
    originalList,
    filters,
    sort,
    placeholder,
    removeFilter
  } = props;

  const dispatch = useAppDispatch();

  const [localCategory, setLocalCategory] = useState(null);

  useEffect(() => {
    setLocalCategory(category);
  }, [category]);

  /**
   * Handles onChange
   */
  const handleOnChange = async (key: any, value: any) => {
    if (value && value !== "") {
      setFilterValue(key, value);

      let filtersObject = { ...filters };

      filtersObject[key] = {
        sign: "=",
        value
      };

      const filtered = await filterList(originalList, filtersObject, sort);

      dispatch({
        type: listFiltered.type,
        payload: filtered
      });

      dispatch({
        type: setIsFilteredList.type,
        payload: true
      });
    }
  };

  const clearValue = async () => {
    setLocalCategory(null);
    const key = "category";
    let filtered: any;
    removeFilter(key);

    let temp = { ...filters };

    if (temp[key]) {
      temp = omit(temp, [key]);
    }

    if (Object.keys(temp).length > 0) {
      filtered = await filterList(originalList, temp, sort);
    } else {
      filtered = originalList;

      dispatch({
        type: setIsFilteredList.type,
        payload: false
      });
    }

    dispatch({
      type: listFiltered.type,
      payload: filtered
    });
  };

  return (
    <SelectForm
      label="Category"
      options={categories}
      name="category"
      onChange={handleOnChange}
      value={localCategory}
      placeholder={placeholder}
      clearValue={clearValue}
    />
  );
};

export default Category;
