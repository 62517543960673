import React from "react";

import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const columns = [
  {
    name: "Name",
    size: "3",
    sort: "nameFirst,nameLast"
  },
  {
    name: "Floor",
    size: "4",
    sort: "employeeFloorName"
  },
  {
    name: "Desk",
    size: "4",
    sort: "employeeDeskNumber"
  }
];

const Table: React.FC<any> = (props: any) => {
  const { rows, onClickTableBody, selectedIndex, handleSort, sort } = props;

  return (
    <>
      <TableHeader
        columns={columns}
        rows={rows}
        handleSort={handleSort}
        sort={sort}
      />
      <TableBody
        data={rows}
        onClick={onClickTableBody}
        selectedIndex={selectedIndex}
      />
    </>
  );
};

export default Table;
