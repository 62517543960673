import React from "react";
import { IonCol } from "@ionic/react";

import Checkbox from "../../../../common/controls/Checkbox";
import Pills from "./Pills";
import SmallButton from "./SmallButton";
import { useAppDispatch } from "../../../../../store/Hooks";
import { getListByName } from "../../../../../store/catalogs/ValueList";

const BandContent: React.FC<any> = (props: any) => {
  const {
    type,
    chief,
    resolved,
    smallButtonClick,
    onChange,
    item,
    bandOnClick
  } = props;
  const dispatch = useAppDispatch();
  const issueRequestTypes = dispatch(getListByName("Issue_Request_Types"));

  const getTypes = (requestTypes: any) => {
    let validRequestTypes = [];

    if (requestTypes) {
      for (const [, requestType] of requestTypes.entries()) {
        if (issueRequestTypes.includes(requestType)) {
          validRequestTypes.push(requestType);
        }
      }

      return validRequestTypes.join(",");
    }

    return "";
  };

  return (
    <>
      <IonCol>
        <div className="flex band-content">
          <div
            className="flex-column w-full"
            onClick={() => (bandOnClick ? bandOnClick() : null)}
          >
            <Pills type={type} chief={chief} resolved={resolved} />
            <div className="flex">
              <p
                className="mt-0 mb-0 w-auto"
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
              >
                {item.type.toLowerCase() === "request"
                  ? getTypes(item.requestTypes)
                  : item.bodyLocation}
              </p>
            </div>
          </div>
          <div className="flex ion-align-items-center">
            <SmallButton
              className="w-185"
              buttonLabel={
                chief ? "UNMARK CHIEF COMPLAINT" : "MARK CHIEF COMPLAINT"
              }
              onClick={() => smallButtonClick("chief", !chief, item.id)}
              chief={chief}
            />
            <Checkbox
              label="Resolved"
              name="resolved"
              value={resolved}
              onChange={(key: any, value: any) => onChange(key, value, item.id)}
            />
          </div>
        </div>
      </IonCol>
    </>
  );
};

export default BandContent;
