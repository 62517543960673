import * as actions from "../api";
import {
  getTemporaryCredentials,
  makeApiCall,
  refreshGoogleIdToken,
  signRequest
} from "../../helpers/global.helper";
import moment from "moment";
import {
  getOAuth2Headers,
  getUserGoogleCredentials,
  getUserGoogleResponse,
  googleCredentialsReceived,
  googleTokenIdSet
} from "../User";

const api =
  ({ dispatch }: { dispatch: any }) =>
  (next: any) =>
  async (action: any) => {
    if (action === undefined) {
      return;
    }

    if (action.type !== actions.apiCall.type) {
      next(action);

      return;
    }

    const { url, method, data, onSuccess, onError, isGoogleAuth } =
      action.payload;
    let headers: any;

    if (!isGoogleAuth) {
      headers = await dispatch(getOAuth2Headers());
    } else {
      const userGoogleCredentials: any = dispatch(getUserGoogleCredentials());
      const userGoogleResponse: any = dispatch(getUserGoogleResponse());

      const now = moment();
      const expirationDate = moment(userGoogleCredentials.expirationDate);
      let temporaryCredentials: any;

      if (now > expirationDate) {
        const response: any = await refreshGoogleIdToken(userGoogleResponse);
        temporaryCredentials = await getTemporaryCredentials(response.id_token);

        dispatch({
          type: googleCredentialsReceived.type,
          payload: temporaryCredentials
        });

        dispatch({
          type: googleTokenIdSet.type,
          payload: response.id_token
        });
      } else {
        temporaryCredentials = userGoogleCredentials;
      }

      headers = await signRequest(url, temporaryCredentials);
    }

    const methodRequest = method ? method : "GET";

    const response = await makeApiCall(url, methodRequest, data, {}, headers);

    if (response.status === 200) {
      dispatch(actions.apiCallSuccess(response.data));

      if (onSuccess && typeof onSuccess === "string") {
        dispatch({
          type: onSuccess,
          payload: response.data
        });
      }

      if (onSuccess && typeof onSuccess === "function") {
        dispatch(onSuccess(response.data));
      }
    } else {
      dispatch(actions.apiCallFailed(response.status));

      if (onError) {
        dispatch({
          type: onError,
          payload: response
        });
      }
    }
  };

export default api;
