import React, { useEffect, useState } from "react";
import { IonRow, IonCol, IonLabel, IonImg, useIonAlert } from "@ionic/react";

import SelectForm from "../../../../common/controls/SelectForm";

import deleteVector from "../../../../../assets/delete.png";
import {
  insertResourceProcess,
  updateObjectFromList
} from "../../../../../helpers/global.helper";
import { deleteActivityEnvironment } from "../../../../../services/environment.service";
import EnvironmentCheckboxes from "../../EnvironmentCheckboxes";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getCurrentEvaluationEnvironment,
  getEnvironments
} from "../../../../../store/evaluation/Current";
import _, { isArray } from "lodash";
import BaseSelectMultiButton from "../../../../common/BaseSelectMultiButton";
import Band from "./Band";
import {
  activitiesUpdated,
  getActivities
} from "../../../../../store/evaluation/Activities";
import Emea from "../../../../common/controls/Emea";
import YesNoInput from "../../../../common/controls/YesNoInput";
import LinkRisks from "./LinkRisks";
import { getEvaluationRisks } from "../../../../../store/evaluation/Risks";
import { apiCall } from "../../../../../store/api";
import {
  activityEnvironmentsReceived,
  activityEnvironmentsUpdated,
  activityRisksReceived,
  activityRisksUpdated,
  getCurrentActivitiesRisks,
  getCurrentActivityEnvironments,
  getCurrentActivityEnvironmentsInRunTime,
  getCurrentShowCheckboxLoader,
  showCheckboxLoaderUpdated
} from "../../../../../store/evaluation/environment/Activities";
import {
  deleteActivityRisk,
  patchActivity
} from "../../../../../services/activity.service";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import Textarea from "../../../../common/Textarea";

const ActivitiesRow: React.FC<any> = (props) => {
  const {
    activity,
    onClickActivity,
    onDeleteActivity,
    activityIdExpanded,
    insertActivityEnvironment,
    getRiskvsName
  } = props;

  const [environmentsChecked, setEnvironmentsChecked] = useState<any>([]);

  const dispatch = useAppDispatch();
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const currentActivities = useAppSelector(getActivities);
  const currentActivityEnvironments = useAppSelector(
    getCurrentActivityEnvironments
  );
  const environments = useAppSelector(getEnvironments);
  const risks = useAppSelector(getEvaluationRisks);
  const activityTypes = dispatch(getListByName("Activity_Type"));
  const activityPercentWorkTimes = dispatch(
    getListByName("Activity_Percent_Work_Time")
  );
  const activityPercentTimeStanding = dispatch(
    getListByName("Activity_Percent_Time_Standing")
  );
  const activityPositions = dispatch(getListByName("Activity_Position"));
  const activityFrequencies = dispatch(getListByName("Activity_Frequency"));
  const activityBreaksFrequency = dispatch(
    getListByName("Activity_Breaks_Frequency")
  );
  const [presentAlert] = useIonAlert();
  const activityRisks = useAppSelector(getCurrentActivitiesRisks);

  const getActivityEnvironmentId = (idEnvironment: number) => {
    const currentActivityEnvironmentsInRuntime = dispatch(
      getCurrentActivityEnvironmentsInRunTime()
    );
    let activityEnvironment = currentActivityEnvironmentsInRuntime.filter(
      (env: any) =>
        env.idEnvironment == idEnvironment && env.idActivity == activity.id
    );
    return activityEnvironment.length > 0 ? activityEnvironment[0].id : 0;
  };

  const updateEnvironmentActivity = async (
    environmentId: number,
    isChecked: boolean
  ) => {
    if (isChecked) {
      let payloadActivityEnvironment = {
        idActivity: activity.id,
        idEnvironment: environmentId
      };
      insertActivityEnvironment(payloadActivityEnvironment);
    } else {
      let idActivityEnvironment = getActivityEnvironmentId(environmentId);
      const currentActivityEnvironmentsInRuntime = dispatch(
        getCurrentActivityEnvironmentsInRunTime()
      );
      const updatedRelationRows = currentActivityEnvironmentsInRuntime.filter(
        (activityEnvironment: any) =>
          activityEnvironment.id !== idActivityEnvironment
      );

      dispatch({
        type: activityEnvironmentsUpdated.type,
        payload: updatedRelationRows
      });

      dispatch(deleteActivityEnvironment(idActivityEnvironment));

      dispatch({
        type: showCheckboxLoaderUpdated.type,
        payload: false
      });
    }
  };

  const isActivityExpanded = () => activity["id"] === activityIdExpanded;

  const updateWorkingPositionsList = (value: string, checked: boolean) => {
    let activityWorkingPositions = [];
    if (
      activity["workingPositions"] !== undefined &&
      isArray(activity["workingPositions"])
    )
      activityWorkingPositions = activity.workingPositions;

    let workingPositions = activityWorkingPositions.filter(
      (workingPos: string) => workingPos != value
    );
    if (checked) workingPositions = [...workingPositions, value];
    return workingPositions;
  };

  const isWorkingPositionsChecked = (key: string) =>
    activity.workingPositions?.includes(key);

  const isChecked = (property: string, value: string) =>
    activity[property] == value;

  const existsRisk = (riskId: number) =>
    activityRisks?.filter((risk: any) => risk.idRisk === riskId).length > 0;

  const saveSelectedRisks = async (riskList: any) => {
    // validating selected values don't exist
    let filtered = riskList.filter(
      (risk: any) =>
        activityRisks.filter((actrisk: any) => actrisk.idRisk === risk.id)
          .length === 0
    );

    dispatch(
      insertResourceProcess(
        filtered,
        ["idActivity", "idRisk"],
        {
          idActivity: {
            key: "idActivity",
            value: activityIdExpanded && activityIdExpanded
          },
          idRisk: {
            key: "idRisk",
            keyValue: "id"
          }
        },
        `/activityrisks`
      )
    )
      .then((rows: any) => {
        if (rows.length > 0) {
          let temp = [...activityRisks, ...rows];

          dispatch({
            type: activityRisksUpdated.type,
            payload: temp
          });
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const getRiskName = (riskId: number) => {
    let risk = risks.filter((risk: any) => risk.id === riskId);
    return risk?.length > 0
      ? `${risk[0]["subCategory"] ?? ""}: ${
          risk[0]["description"] ?? ""
        } ${getRiskvsName(risk[0].idRiskV)}`
      : "Not found";
  };

  const deleteRisk = async (riskId: number) => {
    let newActivityRisks = activityRisks.filter(
      (risk: any) => risk.id != riskId
    );

    dispatch(deleteActivityRisk(riskId));

    dispatch({
      type: activityRisksUpdated.type,
      payload: newActivityRisks
    });
  };

  useEffect(() => {
    if (activityIdExpanded > 0 && activity.id == activityIdExpanded) {
      dispatch(
        apiCall({
          url: `/activities/${activityIdExpanded}/risks`,
          onSuccess: activityRisksReceived.type
        })
      );

      dispatch({
        type: activityEnvironmentsReceived.type,
        payload: []
      });

      setTimeout(() => {
        // Getting activity environments.
        dispatch(
          apiCall({
            url: `/activities/${activityIdExpanded}/environments`,
            onSuccess: activityEnvironmentsReceived.type
          })
        );
      }, 300);
    }
  }, [activityIdExpanded && activityIdExpanded]);

  useEffect(() => {
    if (currentActivityEnvironments) {
      let environmentsChecked = currentActivityEnvironments.map(
        (env: any) => env.idEnvironment
      );

      setEnvironmentsChecked(environmentsChecked);
    }
  }, [currentActivityEnvironments]);

  const handleClickActivity = () => {
    onClickActivity(activity);
  };

  const handlePatch = async (key: string, value: any) => {
    if (value !== undefined && value !== activity[key]) {
      const payload = {
        id: activity && activity.id,
        [key]: value
      };

      dispatch(patchActivity(payload));

      const updatedActivities = await updateObjectFromList(
        [...currentActivities],
        payload,
        "id",
        key,
        value
      );

      dispatch({
        type: activitiesUpdated.type,
        payload: updatedActivities
      });
    }
  };

  return (
    <>
      <Band
        isRowExpanded={isActivityExpanded}
        onClick={handleClickActivity}
        onDelete={onDeleteActivity}
        item={activity}
      />
      {isActivityExpanded() && (
        <>
          <IonRow className="ion-justify-content-end">
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="4"
              size-md="4"
              size-sm="4"
              size-sx="4"
              size="4"
            >
              <SelectForm
                label="Type"
                options={activityTypes}
                value={activity && activity.type}
                name="type"
                onChange={handlePatch}
                clearValue={() => handlePatch("type", null)}
              />
            </IonCol>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="8"
              size-md="8"
              size-sm="8"
              size-sx="8"
              size="8"
              className="ion-align-items-end"
            >
              <IonRow>
                <IonLabel className="form-group-input-text-title single-label line-height-2">
                  Positions
                </IonLabel>
              </IonRow>
              <IonRow className="no-padding">
                <BaseSelectMultiButton
                  source={activityPositions}
                  ionColClass="no-padding to-left"
                  ionButtonClass="no-maring btn btn-group-1 font-bold"
                  onClickButton={(value: any) =>
                    handlePatch(
                      "workingPositions",
                      updateWorkingPositionsList(
                        value,
                        !isWorkingPositionsChecked(value)
                      )
                    )
                  }
                  isChecked={(value: string) =>
                    isWorkingPositionsChecked(value)
                  }
                />
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="">
              <SelectForm
                label="Percent work time"
                options={activityPercentWorkTimes}
                value={
                  activity &&
                  activity.percentWorkTime &&
                  activity.percentWorkTime !== null &&
                  (~~(activity.percentWorkTime * 100)).toString().trim()
                }
                name={"percentWorkTime"}
                onChange={(name: any, value: any) => {
                  if (value) {
                    handlePatch(name, parseFloat(value) / 100);
                  }
                }}
                clearValue={() => handlePatch("percentWorkTime", null)}
              />
            </IonCol>
            <IonCol className="">
              {activity.workingPositions &&
                activity.workingPositions.indexOf("Standing") >= 0 && (
                  <SelectForm
                    label="Percent of time standing"
                    options={activityPercentTimeStanding}
                    value={
                      activity &&
                      activity.percentOfTimeStanding &&
                      activity.percentOfTimeStanding !== null &&
                      (~~(activity.percentOfTimeStanding * 100))
                        .toString()
                        .trim()
                    }
                    name={"percentOfTimeStanding"}
                    onChange={(name: any, value: any) => {
                      if (value) {
                        handlePatch(name, parseFloat(value) / 100);
                      }
                    }}
                    clearValue={() =>
                      handlePatch("percentOfTimeStanding", null)
                    }
                  />
                )}
            </IonCol>
          </IonRow>
          <IonRow className="top-2">
            <IonCol>
              <IonRow>
                <IonLabel className="form-group-input-text-title">
                  {" "}
                  Activity Frequency{" "}
                </IonLabel>
              </IonRow>
              <IonRow>
                <BaseSelectMultiButton
                  source={activityFrequencies}
                  ionColSize={3}
                  ionColClass="to-right"
                  ionButtonClass="btn no-maring btn-group-2 font-bold"
                  onClickButton={(value: any) => {
                    if (activity["frequency"] == value) {
                      value = null;
                    }

                    handlePatch("frequency", value);
                  }}
                  isChecked={(value: string) => isChecked("frequency", value)}
                />
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="top-2">
            <IonCol>
              <IonRow>
                <IonLabel className="form-group-input-text-title">
                  {" "}
                  Breaks Frequency{" "}
                </IonLabel>
              </IonRow>
              <IonRow>
                <BaseSelectMultiButton
                  source={activityBreaksFrequency}
                  ionColClass="to-right"
                  ionButtonClass="btn no-maring btn-group-2 font-bold"
                  onClickButton={(value: any) => {
                    if (activity["breaksFrequency"] == value) {
                      value = null;
                    }

                    handlePatch("breaksFrequency", value);
                  }}
                  isChecked={(value: string) =>
                    isChecked("breaksFrequency", value)
                  }
                />
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="top-2">
            <IonCol>
              <Textarea
                label={"Comment"}
                value={activity && activity.comment}
                onBlur={(e: any) => {
                  handlePatch("comment", e.target.innerText);
                }}
              />
            </IonCol>
          </IonRow>
          <Emea />
          <IonRow className="pt-1">
            <YesNoInput
              data={activity && activity}
              label="Familiar with Keyboard Shortcut; Uses Them"
              property="usesKeyboardShortcuts"
              onChange={handlePatch}
            />
          </IonRow>
          <IonRow>
            <YesNoInput
              data={activity && activity}
              label="Software Suited for Task"
              property="softwareSuitedToTask"
              onChange={handlePatch}
            />
          </IonRow>
          {isActivityExpanded() && environments && (
            <>
              <hr className="separator top-2" />
              <EnvironmentCheckboxes
                environments={environments}
                environmentsChecked={environmentsChecked}
                idEnvironmentBlocked={
                  currentEnvironment && currentEnvironment.id
                }
                onChangeEnvironment={updateEnvironmentActivity}
                showAddButton={true}
                getCurrentShowCheckboxLoader={getCurrentShowCheckboxLoader}
                showCheckboxLoaderUpdated={showCheckboxLoaderUpdated}
              />

              <LinkRisks
                risks={risks}
                title={`Link Risks To Activity${
                  activity.type ? ": " + activity.type : ""
                }`}
                environments={environments}
                saveSelectedRisks={saveSelectedRisks}
              />
              {activityRisks?.map((risk: any, index: any) => (
                <IonRow className="mb-16" key={index}>
                  <IonCol className="background-row-color">
                    <IonRow>
                      <IonCol className="text-left">
                        <div className="float-start">
                          <IonLabel>{getRiskName(risk.idRisk)}</IonLabel>
                        </div>
                      </IonCol>
                      <IonCol>
                        <div className="float-end">
                          <IonImg
                            src={deleteVector}
                            className="cursor-pointer"
                            onClick={() =>
                              presentAlert({
                                cssClass: "my-css",
                                message:
                                  "Are you sure that you want to unlink this risk?",
                                buttons: [
                                  "Cancel",
                                  {
                                    text: "Ok",
                                    handler: () => deleteRisk(risk.id)
                                  }
                                ]
                              })
                            }
                          ></IonImg>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ActivitiesRow;
