import { IonLabel, IonImg } from "@ionic/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CALENDAR from "../../assets/calendar_today.png";

const SearchDate = (props: any) => {
  const {
    onChangeAppointmentDateGteLte,
    appointmentDateGte,
    setAppointmentDateGte,
    appointmentDateLte,
    setAppointmentDateLte
  } = props;

  const handleAppointmentDateGte = (date: any) => {
    setAppointmentDateGte(date);

    onChangeAppointmentDateGteLte(date, appointmentDateLte);
  };

  const handleAppointmentDateLte = (date: any) => {
    setAppointmentDateLte(date);

    onChangeAppointmentDateGteLte(appointmentDateGte, date);
  };

  return (
    <div className="calendar form-group sub-column flex ion-align-items-center">
      <IonLabel className="form-group-input-text-title date-label">
        Date
      </IonLabel>
      <div className="search-date-wrapper">
        <div className="date-wrapper">
          <DatePicker
            className="search-date form-control input-lg form-group-input-text margin-remover"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            selected={appointmentDateGte}
            onChange={(date: any) => handleAppointmentDateGte(date)}
          />
          <span className="date-separator">-</span>
          <DatePicker
            className="search-date form-control input-lg form-group-input-text margin-remover"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            selected={appointmentDateLte}
            onChange={(date: any) => handleAppointmentDateLte(date)}
          />
        </div>
        <IonImg
          className="icon-image calendar"
          src={CALENDAR}
          alt="calendar"
        ></IonImg>
      </div>
    </div>
  );
};

export default SearchDate;
