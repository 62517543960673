import React, { useEffect, useState } from "react";
import { IonButton, IonCol, IonIcon, IonImg, IonRow } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import IssuesDiscomfortType from "./IssuesDiscomfortType";
import IssuesRequestType from "./IssueRequestType";
import EnvironmentCheckboxes from "../../EnvironmentCheckboxes";
import { getToken } from "../../../../../helpers/global.helper";
import {
  getCurrentEvaluationEnvironment,
  getEnvironments
} from "../../../../../store/evaluation/Current";
import Band from "./Band";
import {
  getCurrentIssueEnvironments,
  getCurrentIssueEnvironmentsInRunTime,
  getCurrentShowCheckboxLoader,
  issueEnvironmentsReceived,
  issueEnvironmentsUpdated,
  showCheckboxLoaderUpdated
} from "../../../../../store/evaluation/environment/Issues";
import { addSharp } from "ionicons/icons";
import { apiCall } from "../../../../../store/api";
import { deleteIssueEnvironment } from "../../../../../services/issue.service";

const IssuesRow: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const currentIssueEnvironments = useAppSelector(getCurrentIssueEnvironments);

  const environments = useAppSelector(getEnvironments);
  const [environmentsChecked, setEnvironmentsChecked] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const {
    index,
    issue,
    onClickIssue,
    isIssueRequestType,
    isIssueDiscomfortType,
    issueIdExpanded,
    insertIssueEnvironment,
    setValueIssue,
    deleteRow,
    setShowEnvironmentModal
  } = props;

  const getIssueEnvironmentId = (idEnvironment: number) => {
    const currentIssueEnvironmentsInRuntime = dispatch(
      getCurrentIssueEnvironmentsInRunTime()
    );

    let issueEnvironment = currentIssueEnvironmentsInRuntime.filter(
      (env: any) =>
        env.idEnvironment == idEnvironment && env.idIssue == issue.id
    );
    return issueEnvironment.length > 0 ? issueEnvironment[0].id : 0;
  };

  const updateEnvironmentIssue = async (
    environmentId: number,
    isChecked: boolean
  ) => {
    if (isChecked) {
      let payloadIssueEnvironment = {
        idIssue: issue.id,
        idEnvironment: environmentId,
        token: getToken()
      };
      insertIssueEnvironment(payloadIssueEnvironment);
    } else {
      let idIssueEnvironment = getIssueEnvironmentId(environmentId);
      const currentIssueEnvironmentsInRuntime = dispatch(
        getCurrentIssueEnvironmentsInRunTime()
      );
      let updatedEnvironmentsIssue = currentIssueEnvironmentsInRuntime.filter(
        (env: any) => env.id != idIssueEnvironment
      );
      dispatch({
        type: issueEnvironmentsUpdated.type,
        payload: updatedEnvironmentsIssue
      });

      dispatch(deleteIssueEnvironment(idIssueEnvironment));

      dispatch({
        type: showCheckboxLoaderUpdated.type,
        payload: false
      });
    }
  };

  const isIssueExpanded = () => issue.id === issueIdExpanded;

  useEffect(() => {
    if (currentIssueEnvironments) {
      let environmentsChecked = currentIssueEnvironments.map(
        (env: any) => env.idEnvironment
      );

      setEnvironmentsChecked(environmentsChecked);
    }
  }, [currentIssueEnvironments]);

  useEffect(() => {
    if (isIssueExpanded()) {
      dispatch({
        type: issueEnvironmentsReceived.type,
        payload: []
      });

      setTimeout(() => {
        dispatch(
          apiCall({
            url: `/issues/${issueIdExpanded && issueIdExpanded}/environments`,
            onSuccess: issueEnvironmentsReceived.type
          })
        );
      }, 300);
    }
  }, [issueIdExpanded]);

  const handlePatch = (key: string, value: any) => {
    if (value !== undefined) {
      setValueIssue(key, value, issue.id);
    }
  };

  const handleExpandRowClick = (index: number) => {
    onClickIssue(issue);
    setSelectedIndex(selectedIndex === -1 ? index : -1);
  };

  const handleDeleteRow = () => {
    deleteRow(issue.id);
  };

  return (
    <>
      <Band
        expandRowClick={() => handleExpandRowClick(index)}
        type={issue && issue.type}
        isRowExpanded={() => isIssueExpanded()}
        chief={issue && issue.chief}
        resolved={issue && issue.resolved}
        onChange={handlePatch}
        item={issue}
        onDelete={handleDeleteRow}
      />
      {isIssueExpanded() && (
        <>
          {isIssueRequestType && (
            <IssuesRequestType
              key={issue.id}
              setValueIssue={setValueIssue}
              issue={issue}
            />
          )}
          {isIssueDiscomfortType && (
            <IssuesDiscomfortType
              key={issue.id}
              setValueIssue={setValueIssue}
              issue={issue}
            />
          )}
          {currentIssueEnvironments && (
            <>
              <IonRow className="top-1">
                <IonCol size="6" className="bold to-left top-1">
                  Environment
                </IonCol>
                <IonCol>
                  <IonImg className="cursor-pointer"></IonImg>
                  <IonButton
                    fill="clear"
                    className="ion-float-right"
                    onClick={() => setShowEnvironmentModal()}
                  >
                    <IonIcon src={addSharp}></IonIcon>&nbsp;ADD ENVIRONMENT
                  </IonButton>
                </IonCol>
              </IonRow>
              <EnvironmentCheckboxes
                environments={environments}
                environmentsChecked={environmentsChecked}
                idEnvironmentBlocked={currentEnvironment.id}
                onChangeEnvironment={updateEnvironmentIssue}
                getCurrentShowCheckboxLoader={getCurrentShowCheckboxLoader}
                showCheckboxLoaderUpdated={showCheckboxLoaderUpdated}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default IssuesRow;
