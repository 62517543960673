import React, { useEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonPage,
  IonGrid,
  IonContent
} from "@ionic/react";
import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";

import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import {
  getCurrentEvaluationEnvironment,
  environmentCorrectiveLensesReceived,
  getEnvironmentCorrectiveLenses
} from "../../../store/evaluation/Current";
import { apiCall } from "../../../store/api";

import BasicInformationContent from "./BasicInformationContent";
import EnvironmentTabs from "./tabs/EnvironmentTabs";

import "../../../css/bidb.css";
import "../../../css/equipment.css";
import "../../../css/environment-basic-information.css";

const BasicInformation: React.FC<any> = (props) => {
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const { updateEnvironmentsSideMenu, setShowEnvironmentModal } = props;
  const [showBasicInformationComponent, setShowBasicInformationComponent] =
    useState(false);
  const { idEvaluation } = props;
  const dispatch = useAppDispatch();
  const correctivelenses = useAppSelector(getEnvironmentCorrectiveLenses);

  useEffect(() => {
    if (currentEnvironment) {
      dispatch(
        apiCall({
          url: `/environments/${currentEnvironment.id}/correctivelenses`,
          onSuccess: environmentCorrectiveLensesReceived.type
        })
      );
    }
  }, [currentEnvironment && currentEnvironment.id]);

  return (
    <IonPage>
      <IonContent>
        <IonGrid className="ml-24 mr-24">
          <IonRow>
            <IonCol>
              <div className="mt-4 environment-title flex ion-justify-content-between">
                <IonText className="text-color text-24 font-bold font-lato">
                  {currentEnvironment &&
                    `${
                      currentEnvironment.type ? currentEnvironment.type : ""
                    }: ${
                      currentEnvironment.name ? currentEnvironment.name : ""
                    }`}
                </IonText>
                <div className="cursor-pointer icon-wrapper bg-color-e9eaef h-36 w-36 rounded-27 flex ion-justify-content-center ion-align-items-center">
                  <IonIcon
                    className="cursor-pointer"
                    icon={
                      showBasicInformationComponent
                        ? chevronUpOutline
                        : chevronDownOutline
                    }
                    onClick={() =>
                      setShowBasicInformationComponent(
                        showBasicInformationComponent ? false : true
                      )
                    }
                  ></IonIcon>
                </div>
              </div>
            </IonCol>
          </IonRow>

          {showBasicInformationComponent && (
            <BasicInformationContent correctivelenses={correctivelenses} />
          )}

          <IonRow className="p-0">
            <IonCol className="p-0">
              <EnvironmentTabs
                className="p-0 flex"
                select={0}
                idEvaluation={idEvaluation}
                updateEnvironmentsSideMenu={updateEnvironmentsSideMenu}
                currentEnvironment={currentEnvironment}
                setShowEnvironmentModal={setShowEnvironmentModal}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default BasicInformation;
