import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getRiskVs,
  getOriginalRiskVs,
  riskVsUpdated,
  riskSubCategoriesUpdated,
  getOriginalRiskSubCategories,
  riskSubCategoriesReceived,
  riskCategoriesReceived
} from "../../../../../store/catalogs/Risks";

import BaseAddModal from "../add-modal/BaseAddModal";
import RiskFilters from "./RiskFilters";
import _, { find, orderBy } from "lodash";
import {
  environmentRisksUpdated,
  getEnvironmentRisks,
  getIsFilteredRiskVs,
  isFilteredRisksSet,
  getCurrentRiskRecommendations
} from "../../../../../store/evaluation/environment/Risks";
import {
  insertResourceProcess,
  updateCurrentList
} from "../../../../../helpers/global.helper";
import {
  getCurrentEvaluation,
  getCurrentEvaluationEnvironment
} from "../../../../../store/evaluation/Current";
import {
  getEvaluationRisks,
  risksUpdated
} from "../../../../../store/evaluation/Risks";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import { apiCall } from "../../../../../store/api";
import {
  getCurrentLinkedRisks,
  recommendationsRisksReceived
} from "../../../../../store/evaluation/environment/Recommendations";

const AddRiskModal: React.FC<any> = (props: any) => {
  const {
    isOpen,
    isPicker,
    closeModal,
    recommendationIdExpanded,
    updateRiskRecommendations,
    onClickRisk,
    groupedLeftList,
    isAttach
  } = props;
  const dispatch = useAppDispatch();

  const currentRisks = useAppSelector(getEvaluationRisks);
  const riskVs = useAppSelector(getRiskVs);
  const originalRiskVs = useAppSelector(getOriginalRiskVs);
  const isFilteredRiskVsList = useAppSelector(getIsFilteredRiskVs);
  const currentEvaluation = useAppSelector(getCurrentEvaluation);
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const currentEnvironmentRisks = useAppSelector(getEnvironmentRisks);
  const originalRiskSubCategories = useAppSelector(
    getOriginalRiskSubCategories
  );
  const currentRiskRecommendations = useAppSelector(
    getCurrentRiskRecommendations
  );
  const currentLinkedRisks = useAppSelector(getCurrentLinkedRisks);

  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: riskCategoriesReceived.type,
        payload: dispatch(getListByName("Risk_Category"))
      });

      dispatch({
        type: riskSubCategoriesReceived.type,
        payload: dispatch(getListByName("Risk_Subcategory_All"))
      });
    }
  }, [isOpen]);

  const [filters, setFilters] = useState<any>({});

  const initializeFilter = () => {
    handleRemoveFilter();
  };

  const grouped = () => {
    const currentRiskVs = [...riskVs];

    let temp = currentRiskVs.map((riskVs: any) => {
      let rvc = { ...riskVs };
      if (rvc.group) {
        delete rvc.group;
      }

      if (rvc.linked) {
        delete rvc.linked;
      }

      return rvc;
    });

    temp = temp.map((riskV: any) => {
      let environmentRiskRow: any = find(currentRisks, (o: any) => {
        return o.idRiskV === riskV.id;
      });

      if (environmentRiskRow) {
        let linkedRow = find(currentLinkedRisks, (o: any) => {
          return o.risk.idRiskV === riskV.id;
        });

        if (linkedRow) {
          riskV = Object.assign({}, riskV, { linked: true });
        }

        return Object.assign({}, riskV, { group: true });
      }

      return riskV;
    });

    temp = orderBy(temp, ["group", "sortOrder"], ["asc", "asc"]);

    dispatch({
      type: riskVsUpdated.type,
      payload: temp
    });
  };

  useEffect(() => {
    if (groupedLeftList) {
      grouped();
    }
  }, [isOpen && riskVs.length]);

  const existsRiskByRiskVs = (idRiskv: any) => {
    let risks = currentRisks.filter(
      (risk: any) =>
        risk.idRiskV === idRiskv &&
        currentEnvironmentRisks?.filter(
          (envRisk: any) => envRisk.idRisk === risk.id
        ).length > 0
    );

    return risks.length > 0 ? risks[0] : null;
  };

  const handleSave = async (
    selectedItems: any,
    attachToRisks: boolean = false
  ) => {
    // Filter duplicates
    setShowLoader(true);
    let filtered: any = [];
    let alreadyInserted: any = [];

    const updatedSelectedItems = selectedItems.map((item: any) => {
      return { ...item, bodyPositions: ["Seated"] };
    });

    updatedSelectedItems.map((selectedItem: any) => {
      let risk = existsRiskByRiskVs(selectedItem.id);
      if (risk == null) filtered.push(selectedItem);
      else alreadyInserted.push(risk);
    });

    if (filtered.length > 0) {
      dispatch(
        insertResourceProcess(
          filtered,
          ["category", "subCategory", "bodyPositions"],
          {
            idRiskV: {
              key: "idRiskV",
              keyValue: "id"
            }
          },
          `/evaluations/${currentEvaluation && currentEvaluation.id}/risks`
        )
      )
        .then(async (rows: any) => {
          // Environment risks.
          if (rows.length > 0) {
            const insertedEnvironmentRisks: any = await dispatch(
              insertResourceProcess(
                rows,
                ["idEnvironment", "idRisk"],
                {
                  idEnvironment: {
                    key: "idEnvironment",
                    value: currentEnvironment && currentEnvironment.id
                  },
                  idActivity: {
                    key: "idRisk",
                    keyValue: "id"
                  }
                },
                `/riskenvironments`
              )
            );

            // Before update list, we attach environment risks to risks, because risk environments is used when linking acitivitis risks
            let temp: any = [];
            rows.map((row: any) => {
              let filtered = insertedEnvironmentRisks.filter(
                (envRisk: any) => envRisk.idRisk == row.id
              );

              row.environments = filtered;

              temp.push(row);
            });

            rows = temp;

            const updatedRows = await updateCurrentList(
              rows,
              currentRisks,
              "desc"
            );

            dispatch({
              type: risksUpdated.type,
              payload: updatedRows
            });

            if (onClickRisk) {
              onClickRisk(updatedRows[0]);
            }

            const updatedEnvironmentRiskRows = await updateCurrentList(
              insertedEnvironmentRisks,
              currentEnvironmentRisks
            );

            dispatch({
              type: environmentRisksUpdated.type,
              payload: updatedEnvironmentRiskRows
            });

            if (!attachToRisks) {
              setShowLoader(false);
              closeModal();
            } else {
              await attachRisks([...rows, ...alreadyInserted]);
            }
          }
        })
        .catch((e: any) => console.log(e));
    } else {
      if (!attachToRisks) {
        setShowLoader(false);
        closeModal();
      } else {
        await attachRisks(alreadyInserted);
      }
    }
  };

  const attachRisks = async (insertedRisks: any) => {
    if (insertedRisks && insertedRisks.length > 0) {
      const adjuster = {
        idRecommendation: {
          key: "idRecommendation",
          value: recommendationIdExpanded
        },
        idRisk: {
          key: "idRisk",
          keyValue: "id"
        }
      };

      let insertedRisksFiltered = insertedRisks.filter(
        (rec: any) =>
          currentRiskRecommendations?.filter(
            (riskRec: any) => riskRec.idRecommendation === rec.id
          ).length === 0
      );

      dispatch(
        insertResourceProcess(
          insertedRisksFiltered,
          ["idRecommendation", "idRisk"],
          adjuster,
          `/riskrecommendations`
        )
      )
        .then(async (riskRecommendations: any) => {
          updateRiskRecommendations(riskRecommendations);
          dispatch(
            apiCall({
              url: `/recommendations/${
                recommendationIdExpanded && recommendationIdExpanded
              }/risks`,
              onSuccess: recommendationsRisksReceived.type
            })
          );
          setShowLoader(false);
          closeModal();
        })
        .catch((e: any) => console.log(e));
    } else {
      setShowLoader(false);
      closeModal();
    }
  };

  const handleSetFilter = (key: string, value: any, contains: boolean) => {
    if (value !== "") {
      let temp = { ...filters };

      temp[key] = {
        sign: !contains ? "=" : "contains",
        value
      };

      setFilters(temp);
    }
  };

  const handleRemoveFilter = (key: string = "") => {
    if (key !== "") {
      let temp = { ...filters };

      if (temp[key]) {
        temp = _.omit(temp, [key]);
      }

      setFilters(temp);
    } else {
      setFilters({});
      resetSubCategories();
    }
  };

  const handleResetList = () => {
    if (!isOpen) {
      setFilters({});

      dispatch({
        type: riskVsUpdated.type,
        payload: originalRiskVs
      });

      resetSubCategories();
    }
  };

  const resetSubCategories = () => {
    dispatch({
      type: riskSubCategoriesUpdated.type,
      payload: originalRiskSubCategories
    });
  };

  const handleAttachRisks = async (pickedItems: any) => {
    await handleSave(
      [...pickedItems],
      isAttach !== undefined ? isAttach : false
    );
  };

  return (
    <BaseAddModal
      showLoader={showLoader}
      onDidDismiss={handleResetList}
      closeModal={closeModal}
      // Filters
      setIsFilteredList={isFilteredRisksSet}
      filters={
        <RiskFilters
          filters={filters}
          setFilter={handleSetFilter}
          removeFilter={handleRemoveFilter}
          sort={{
            columns: ["sortOrder"],
            directions: ["asc"]
          }}
          isAttach={isAttach}
          grouped={grouped}
        />
      }
      initializeFilter={initializeFilter}
      // Content
      leftList={riskVs}
      getItems={getRiskVs}
      isOpen={isOpen}
      title="Add Risk"
      isFilteredList={isFilteredRiskVsList}
      // Footer
      saveButtonLabel={`ADD <<>> SELECTED RISKS`}
      buttonSaveClasses={"btn-add-selected ml-1"}
      //
      listUpdated={riskVsUpdated}
      originalList={originalRiskVs}
      onSave={isPicker ? handleSave : handleAttachRisks}
      isPicker={isPicker}
      hasCustomItem={true}
      itemName="riskItem"
      groupedLeftList={groupedLeftList}
    />
  );
};

export default AddRiskModal;
