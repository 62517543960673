import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { addSharp } from "ionicons/icons";

const AddButton: React.FC<any> = (props: any) => {
  const { label, onClick } = props;

  return (
    <IonButton
      onClick={onClick}
      className="float-end w-240 control-btn font-bold"
    >
      <IonIcon icon={addSharp}></IonIcon> {label}
    </IonButton>
  );
};

export default AddButton;
