import React, { useState, useEffect, useRef } from "react";
import { Route, withRouter, useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { IonReactRouter } from "@ionic/react-router";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonSplitPane,
  IonRouterOutlet,
  IonImg
} from "@ionic/react";

import "../css/evaluation.css";
import classNames from "classnames";

import {
  environmentsReceived,
  getCurrentEvaluation,
  commentsReceived,
  htmlReportReceived,
  showReportLoaderUpdated,
  getCurrentShowEnvironmentLoader,
  idUpdated,
  getCurrentId,
  getIsOpenScratchPadModal,
  isOpenScratchPadModalUpdated,
  getCurrentScratchPadHeader,
  getCurrentTimestamp
} from "../store/evaluation/Current";
import { apiCall } from "../store/api";

import { patchEvaluation } from "../services/evaluation.service";
import { evaluationUpdated } from "../store/evaluation/Current";

import { useAppDispatch, useAppSelector } from "../store/Hooks";
import { issuesReceived } from "../store/evaluation/Issues";
import { activitiesReceived } from "../store/evaluation/Activities";
import { risksReceived } from "../store/evaluation/Risks";
import { loadEvaluationRecommendations } from "../store/evaluation/Recommendations";

import Toolbar from "../components/layout/Toolbar";
import SideMenu from "../components/evaluation/Menu/SideMenu";
import TopNavigation from "../components/evaluation/TopNavigation";
import BasicInformation from "../components/evaluation/BasicInformation";
import EnvironmentBasicInformation from "../components/evaluation/environment/BasicInformation";
import { organizationRegionEquipmentsReceived } from "../store/evaluation/OrganizationRegionEquipments";
import { organizationRegionFurnituresReceived } from "../store/evaluation/OrganizationRegionFurnitures";
import AddEnvironmentModal from "../components/evaluation/environment/AddEnvironmentModal";
import Report from "../components/evaluation/Report";
import { showSkeletonUpdated } from "../store/catalogs/Environments";
import { makeRequest } from "../helpers/global.helper";
import Loader from "../components/common/Loader";
import ReactModal from "react-modal-resizable-draggable";
import { iconImages } from "../services/tabs.service";
import Textarea from "../components/common/Textarea";

const EvaluationContainer: React.FC = () => {
  const [showEvaluationsTable, setShowEvaluationsTable] = useState(false);
  const [isOpenTopNavigation, setIsOpenTopNavigation] = useState(false);
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const [openEnvironmentForm, setOpenEnvironmentForm] = useState(false);
  const [scratchPadData, setScratchPadData] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const evaluation = useAppSelector(getCurrentEvaluation);
  const currentShowEnvironmentLoader = useAppSelector(
    getCurrentShowEnvironmentLoader
  );

  const currentSelectedId: any = useAppSelector(getCurrentId);
  const isOpenModal: boolean = useAppSelector(getIsOpenScratchPadModal);
  const scratchPadHeader: any = useAppSelector(getCurrentScratchPadHeader);
  const currentTimestamp = useAppSelector(getCurrentTimestamp);

  const rowsRef = useRef([]);
  rowsRef.current = [];
  const history: any = useHistory();
  let textAreaRef: any = useRef(null);

  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number>(-1);

  const showTable = () => {
    setShowEvaluationsTable(!showEvaluationsTable);
  };

  const fetchEnvironments = () => {
    let evaluationsEnvironments: any =
      process.env.REACT_APP_BIDB_API_EVALUATIONS_ENVIRONMENTS;
    evaluationsEnvironments = evaluationsEnvironments.replace(
      "<<idEvaluation>>",
      currentSelectedId
    );

    dispatch(
      apiCall({
        url: evaluationsEnvironments,
        onSuccess: environmentsReceived.type
      })
    );

    dispatch({
      type: showSkeletonUpdated.type,
      payload: false
    });
  };

  const fetchComments = () => {
    let evaluationsComments =
      process.env.REACT_APP_BIDB_API_EVALUATIONS_COMMENTS;
    evaluationsComments = evaluationsComments?.replace(
      "<<idEvaluation>>",
      currentSelectedId && currentSelectedId
    );
    dispatch(
      apiCall({
        url: evaluationsComments,
        onSuccess: commentsReceived.type
      })
    );
  };

  const fetchIssues = () => {
    let evaluationsIssues = process.env.REACT_APP_BIDB_API_EVALUATIONS_ISSUES;
    evaluationsIssues = evaluationsIssues?.replace(
      "<<idEvaluation>>",
      currentSelectedId
    );
    dispatch(
      apiCall({
        url: evaluationsIssues,
        onSuccess: issuesReceived.type
      })
    );
  };

  const fetchActivities = async () => {
    let evaluationsActivities =
      process.env.REACT_APP_BIDB_API_EVALUATIONS_ACTIVITIES;
    evaluationsActivities = evaluationsActivities?.replace(
      "<<idEvaluation>>",
      currentSelectedId
    );
    dispatch(
      apiCall({
        url: evaluationsActivities,
        onSuccess: activitiesReceived.type
      })
    );
  };

  const fetchRisks = async () => {
    let evaluationsRisks = process.env.REACT_APP_BIDB_API_EVALUATIONS_RISKS;
    evaluationsRisks = evaluationsRisks?.replace(
      "<<idEvaluation>>",
      currentSelectedId
    );
    dispatch(
      apiCall({
        url: evaluationsRisks,
        onSuccess: risksReceived.type
      })
    );
  };

  const fetchRecommendations = async () => {
    dispatch(loadEvaluationRecommendations(currentSelectedId));
  };

  const fetchOrganizationRegionEquipments = async () => {
    const organizationRegionEquipmentsEndpoint =
      process.env.REACT_APP_BIDB_API_ORGANIZATION_REGION_EQUIPMENTS;
    if (evaluation && evaluation.regionId && evaluation.idOrganization) {
      const url = organizationRegionEquipmentsEndpoint
        ?.replace("<<regionId>>", evaluation?.regionId)
        .replace("<<idOrganization>>", evaluation?.idOrganization);

      dispatch(
        apiCall({
          url,
          onSuccess: organizationRegionEquipmentsReceived.type
        })
      );
    }
  };

  const fetchOrganizationRegionFurnitures = async () => {
    const organizationRegionFurnituresEndpoint =
      process.env.REACT_APP_BIDB_API_ORGANIZATION_REGION_FURNITURES;
    if (evaluation && evaluation.regionId && evaluation.idOrganization) {
      const url = organizationRegionFurnituresEndpoint
        ?.replace("<<regionId>>", evaluation?.regionId)
        .replace("<<idOrganization>>", evaluation?.idOrganization);

      dispatch(
        apiCall({
          url,
          onSuccess: organizationRegionFurnituresReceived.type
        })
      );
    }
  };

  const getRecommendationReport = async () => {
    if (evaluation && evaluation.id) {
      const url = `/recommendationreport/${
        evaluation && evaluation.id
      }?format=html`;

      const response: any = await dispatch(
        makeRequest({
          method: "GET",
          endpoint: url
        })
      );

      dispatch({
        type: htmlReportReceived.type,
        payload: response.data
      });

      dispatch({
        type: showReportLoaderUpdated.type,
        payload: false
      });
    }
  };

  useEffect(() => {
    if (evaluation !== undefined && currentSelectedId > -1) {
      fetchEnvironments();
      fetchComments();
      fetchIssues();
      fetchActivities();
      fetchRisks();
      fetchRecommendations();
      fetchOrganizationRegionEquipments();
      fetchOrganizationRegionFurnitures();
      getRecommendationReport();

      dispatch({
        type: idUpdated.type,
        payload: -1
      });

      if (evaluation && evaluation.scratchPad !== undefined) {
        setScratchPadData(evaluation.scratchPad);
      }
    }
  }, [evaluation && evaluation.id === currentSelectedId && currentTimestamp]);

  useEffect(() => {
    if (!isOpenModal) {
      idleTimer.pause();
    } else {
      idleTimer.start();
    }
  }, [isOpenModal]);

  const onIdle = () => {
    saveScratchPadData(
      textAreaRef && textAreaRef.current ? textAreaRef.current.innerText : ""
    );
    idleTimer.start();
  };

  const onActive = () => {
    idleTimer.pause();
  };

  const idleTimer = useIdleTimer({
    timeout: process.env.REACT_APP_BIDB_IDLE_TIME
      ? parseInt(process.env.REACT_APP_BIDB_IDLE_TIME)
      : 120000,
    onIdle,
    onActive,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange"
    ],
    startManually: true
  });

  const goBack = async (route: string) => {
    dispatch({
      type: isOpenScratchPadModalUpdated.type,
      payload: false
    });

    dispatch({
      type: environmentsReceived.type,
      payload: {
        rows: []
      }
    });

    dispatch({
      type: commentsReceived.type,
      payload: {
        rows: []
      }
    });

    history.push(route);
  };

  const collapseMenu = async (value: boolean) => {
    setCollapsedMenu(!value);
  };

  const handleOpenEnvironmentForm = () => {
    const value = !openEnvironmentForm;
    setOpenEnvironmentForm(value);
  };

  const closeScratchPadModal = async () => {
    dispatch({
      type: isOpenScratchPadModalUpdated.type,
      payload: false
    });
  };

  const saveScratchPadData = async (content: string | null) => {
    const currentScratchPadData = evaluation.scratchPad
      ? evaluation.scratchPad
      : "";

    if (content !== currentScratchPadData) {
      const data = {
        id: evaluation.id,
        scratchPad: content
      };

      dispatch({
        type: evaluationUpdated.type,
        payload: {
          scratchPad: content
        }
      });

      dispatch(patchEvaluation(data));
    }
  };

  const copyRef = (ref: any) => {
    textAreaRef = ref;
  };

  return (
    <>
      <IonPage>
        <ReactModal
          isOpen={isOpenModal}
          onRequestClose={() => null}
          initHeight={400}
          minWidth={650}
          minHeight={300}
        >
          <div className="title-wrapper"></div>
          <div className="title">{`Scratch Pad For ${
            scratchPadHeader && scratchPadHeader.workerName
          } of ${scratchPadHeader && scratchPadHeader.company}`}</div>
          <IonImg
            className="cursor-pointer object-fit sp-close-modal-button"
            onClick={() => closeScratchPadModal()}
            src={iconImages["closeIcon"]}
          />

          <div className="body">
            <Textarea
              containerCss="h-full"
              value={scratchPadData}
              onBlur={(e: any) => {
                saveScratchPadData(e.target.innerText);
                setScratchPadData(e.target.innerText);
              }}
              copyRef={copyRef}
            />
          </div>
        </ReactModal>
        <IonReactRouter>
          <Loader visible={currentShowEnvironmentLoader} />
          <IonHeader>
            <IonToolbar className="toolbar-header">
              <Toolbar
                backButtonIsPresent={true}
                title="Evaluations"
                withNavigation={true}
                goBack={goBack}
              />
            </IonToolbar>
            <IonToolbar>
              <TopNavigation
                showEvaluationsTable={showEvaluationsTable}
                setShowEvaluationsTable={showTable}
                ref={rowsRef}
                setIsOpenTopNavigation={setIsOpenTopNavigation}
                evaluation={evaluation}
              />
            </IonToolbar>
          </IonHeader>
          <IonContent
            className={classNames("ion-text-center", {
              absolute: isOpenTopNavigation
            })}
          >
            <AddEnvironmentModal
              showModal={openEnvironmentForm}
              setShowModal={setOpenEnvironmentForm}
              currentSelectedIndex={currentSelectedIndex}
              setCurrentSelectedIndex={setCurrentSelectedIndex}
            />
            <IonSplitPane
              className={classNames({
                "collapsed-split": collapsedMenu
              })}
              contentId="main"
              when="md"
            >
              <SideMenu
                idEvaluation={evaluation && evaluation.id}
                isOpenTopNavigation={isOpenTopNavigation}
                collapseMenu={collapseMenu}
                collapsedMenu={collapsedMenu}
                setShowModal={handleOpenEnvironmentForm}
                getRecommendationReport={getRecommendationReport}
                currentSelectedIndex={currentSelectedIndex}
                setCurrentSelectedIndex={setCurrentSelectedIndex}
              />
              <IonRouterOutlet
                className={classNames({
                  absolute: isOpenTopNavigation,
                  "top-30": isOpenTopNavigation,
                  "bg-black": isOpenTopNavigation,
                  "opacity-10": isOpenTopNavigation
                })}
                id="main"
              >
                <Route
                  path="/evaluation/:id/basicinformation"
                  render={() => <BasicInformation />}
                  exact={true}
                ></Route>
                <Route
                  path="/evaluation/:id/environment"
                  render={() => (
                    <EnvironmentBasicInformation
                      idEvaluation={evaluation && evaluation.id}
                      updateEnvironmentsSideMenu={fetchEnvironments}
                      setShowEnvironmentModal={handleOpenEnvironmentForm}
                    />
                  )}
                  exact={true}
                ></Route>
                <Route
                  path="/evaluation/:id/report"
                  render={() => <Report />}
                  exact={true}
                />
              </IonRouterOutlet>
            </IonSplitPane>
          </IonContent>
        </IonReactRouter>
      </IonPage>
    </>
  );
};

// export default EvaluationContainer;
export default withRouter(EvaluationContainer);
