import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
  name: "evaluation",
  initialState: {
    current: undefined,
    environments: [],
    environment: {},
    issues: [],
    currentIssueEnvironments: [],
    environmentIssues: [],
    environmentCorrectiveLenses: [],
    menu: {
      options: [
        {
          opened: 0,
          name: "Issue",
          total: 0
        },
        {
          opened: 0,
          name: "Equipment",
          total: 0
        },
        {
          opened: 0,
          name: "Issues",
          total: 0
        },
        {
          opened: 0,
          name: "Issues",
          total: 0
        }
      ]
    },
    totalEnvironmentIssues: 0,
    environmentEquipments: [],
    isOpenEnvironmentEquipmentModal: false,
    activities: [],
    currentActivityEnvironments: [],
    environmentActivities: [],

    currentRiskEnvironments: [],
    environmentRisks: [],
    currentRiskRecommendations: []
  },
  reducers: {
    setEvaluation(evaluation: any, action: PayloadAction<any>) {
      evaluation.current = action.payload;
    },
    updateCurrentEvaluation(evaluation: any, action: PayloadAction<any>) {
      for (const [key, value] of Object.entries(action.payload)) {
        evaluation.current[key] = value;
      }
    },
    environmentsReceived(evaluation: any, action: PayloadAction) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluation.environments = [...payload.rows];
      }
    },
    setEnvironment(evaluation: any, action: PayloadAction<any>) {
      evaluation.environment = action.payload;
    },
    updateCurrentEnvironment(evaluation: any, action: PayloadAction<any>) {
      for (const [key, value] of Object.entries(action.payload)) {
        evaluation.environment[key] = value;
      }
    },
    issuesReceived(evaluation: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluation.issues = payload.rows;
      } else {
        evaluation.issues = [];
      }
    },
    updateIssues(evaluation: any, action: PayloadAction<any>) {
      evaluation.issues = action.payload;
    },
    updateEnvironments(evaluation: any, action: PayloadAction<any>) {
      evaluation.environments.push(action.payload);
    },
    setEnvironmentIssues(evaluation: any, action: PayloadAction<any>) {
      evaluation.environmentIssues = action.payload;
    },
    addIssueCurrentEnvironment(evaluation: any, action: PayloadAction<any>) {
      if (!evaluation.environmentIssues) evaluation.environmentIssues = [];
      evaluation.environmentIssues.push(action.payload);
    },
    setCurrentIssueEnvironments(evaluation: any, action: PayloadAction<any>) {
      evaluation.currentIssueEnvironments = action.payload;
    },
    addEnvironmentCurrentIssue(evaluation: any, action: PayloadAction<any>) {
      if (!evaluation.currentIssueEnvironments)
        evaluation.currentIssueEnvironments = [];
      evaluation.currentIssueEnvironments.push(action.payload);
    },
    environmentCorrectiveLensesReceived(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluation.environmentCorrectiveLenses = payload.rows;
      }
    },
    setEnvironmentCorrectiveLenses(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      let temp: Array<any> =
        evaluation.environmentCorrectiveLenses[0][action.payload.type];
      temp.push(action.payload.data);

      evaluation.environmentCorrectiveLenses[0][action.payload.type] = temp;
    },
    updateEnvironmentCorrectiveLenses(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.environmentCorrectiveLenses[0][action.payload.type][
        action.payload.index
      ][action.payload.key] = action.payload.value;
    },
    deleteEnvironmentCorrectiveLenses(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.environmentCorrectiveLenses[0][action.payload.type].splice(
        action.payload.index,
        1
      );
    },
    setShowEnvironmentEquipmentModal(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.isOpenEnvironmentEquipmentModal = action.payload;
    },
    setEnvironmentActivities(evaluation: any, action: PayloadAction<any>) {
      evaluation.environmentActivities = action.payload;
    },
    addActivityCurrentEnvironment(evaluation: any, action: PayloadAction<any>) {
      if (!evaluation.environmentActivities)
        evaluation.environmentActivities = [];
      evaluation.environmentActivities.push(action.payload);
    },
    setCurrentActivityEnvironments(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.currentActivityEnvironments = action.payload;
    },
    addEnvironmentCurrentActivity(evaluation: any, action: PayloadAction<any>) {
      if (!evaluation.currentActivityEnvironments)
        evaluation.currentActivityEnvironments = [];
      evaluation.currentActivityEnvironments.push(action.payload);
    },
    setCurrentRiskEnvironments(evaluation: any, action: PayloadAction<any>) {
      evaluation.currentRiskEnvironments = action.payload;
    },
    setEnvironmentRisks(evaluation: any, action: PayloadAction<any>) {
      evaluation.environmentRisks = action.payload;
    },
    setCurrentRiskRecommendations(evaluation: any, action: PayloadAction<any>) {
      evaluation.currentRiskRecommendations = action.payload;
    }
  }
});

export const {
  setEvaluation,
  updateCurrentEvaluation,
  environmentsReceived,
  setEnvironment,
  updateCurrentEnvironment,
  issuesReceived,
  updateIssues,
  updateEnvironments,
  setEnvironmentIssues,
  setCurrentIssueEnvironments,
  addEnvironmentCurrentIssue,
  addIssueCurrentEnvironment,
  environmentCorrectiveLensesReceived,
  setEnvironmentCorrectiveLenses,
  updateEnvironmentCorrectiveLenses,
  deleteEnvironmentCorrectiveLenses,
  setShowEnvironmentEquipmentModal,
  setEnvironmentActivities,
  setCurrentActivityEnvironments,
  addEnvironmentCurrentActivity,
  addActivityCurrentEnvironment,
  setCurrentRiskEnvironments,
  setEnvironmentRisks,
  setCurrentRiskRecommendations
} = slice.actions;
export default slice.reducer;

/**
 * Gets environments
 */
export const getEnvironments = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.environments
);

/**
 * Gets current evaluation
 */
export const getCurrentEvaluation = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.current
);

/**
 * Gets evaluation issues
 */
export const getIssues = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.issues
);

/**
 * Gets issue environments
 */
export const getCurrentIssueEnvironments = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.currentIssueEnvironments
);

/**
 * Gets environment corrective lenses
 */
export const getEnvironmentCorrectiveLenses = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.environmentCorrectiveLenses
);

/**
 * Shows environment equipment modal
 */
export const showEnvironmentEquipmentModal = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.isOpenEnvironmentEquipmentModal
);

/**
 * Gets environment Activities
 */
export const getCurrentEnvironmentActivities = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.environmentActivities
);

/**
 * Gets Activity environments
 */
export const getCurrentActivityEnvironments = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.currentActivityEnvironments
);

export const getTotalEnvironmentActivities = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) =>
    evaluation.environmentActivities
      ? evaluation.environmentActivities.length
      : 0
);

/**
 * Gets Risk environments
 */
export const getCurrentRiskEnvironments = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.currentRiskEnvironments
);

/**
 * Gets Risk recommendations
 */
export const getCurrentRiskRecommendations = createSelector(
  (state: any) => state.evaluation,
  (evaluation: any) => evaluation.currentRiskRecommendations
);
