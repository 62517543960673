import React, { useState } from "react";
import {
  IonButton,
  IonContent,
  IonRow,
  IonCol,
  IonModal,
  IonHeader,
  IonList,
  IonBadge,
  IonLabel,
  IonGrid,
  IonImg
} from "@ionic/react";

import { iconImages } from "../../../../../services/tabs.service";

import { useAppSelector } from "../../../../../store/Hooks";
import { getGroupedEvaluationIssues } from "../../../../../store/evaluation/Issues";

import { IssueType } from "../../../../../services/issue.service";
import _ from "lodash";
import Loader from "../../../../common/Loader";

const IssueSelector: React.FC<any> = (props: any) => {
  const { openIssueSelector, setOpenIssueSelector, attachIssues, showLoader } =
    props;

  const [issuesList, setIssuesList] = useState(
    useAppSelector(getGroupedEvaluationIssues)
  );
  const [selectedIssues, setSelectedIssues] = useState<any>([]);

  const getColor = (type: string) => {
    type = type.toLowerCase();
    return type == "discomfort"
      ? "00cef7"
      : type == "resolved"
      ? "42d392"
      : type == "chief-complaint"
      ? "ff9532"
      : "973ac3";
  };

  const removeIssue = async (issue: any) => {
    let temp = [...issuesList];
    temp.push(issue);

    temp = await groupIssues(temp);

    setIssuesList(temp);

    const index = _.findIndex(selectedIssues, { id: issue.id });
    temp = [...selectedIssues];
    temp.splice(index, 1);

    setSelectedIssues(temp);
  };

  const addIssue = async (issue: any) => {
    let temp = [...selectedIssues];
    temp.push(issue);

    temp = await groupIssues(temp);

    setSelectedIssues(temp);

    const index = _.findIndex(issuesList, { id: issue.id });
    temp = [...issuesList];
    temp.splice(index, 1);

    setIssuesList(temp);
  };

  const handleAttachIssues = async () => {
    attachIssues(selectedIssues);
  };

  const handleClearSelection = async () => {
    const issues: any = [...selectedIssues];
    let temp = [...issuesList];
    for (const [, issue] of issues.entries()) {
      temp.push(issue);
    }

    temp = await groupIssues(temp);
    setIssuesList(temp);

    setSelectedIssues([]);
  };

  const groupIssues = async (rows: any) => {
    return _.sortBy(rows, ["type"], ["asc"]);
  };

  const getIssueRow = (issue: any, index: number, remove: boolean = false) => {
    return (
      <React.Fragment key={index}>
        <IonGrid
          className="ion-no-padding ml-12 mr-12 cursor-pointer"
          onClick={() => {
            !remove ? addIssue(issue) : removeIssue(issue);
          }}
        >
          <IonRow className={index === 0 ? "" : "mt-3"} key={index}>
            <IonCol size="auto" className="ion-justify-content-start">
              <IonBadge
                className={
                  "text-uppercase text-10 rounded-27 text-white py-03 mr-05 bg-color-" +
                  getColor(issue.type)
                }
              >
                {issue.type}
              </IonBadge>
              {issue && issue.chief && (
                <IonBadge
                  className={
                    "text-uppercase text-10 rounded-27 text-white py-03 mr-05 bg-color-" +
                    getColor("chief-complaint")
                  }
                >
                  {"Chief Complaint"}
                </IonBadge>
              )}
              {issue?.resolved && (
                <IonBadge
                  className={
                    "text-uppercase text-10 rounded-27 text-white py-03 bg-color-" +
                    getColor("resolved")
                  }
                >
                  {"Resolved"}
                </IonBadge>
              )}
            </IonCol>
            <IonCol>
              <IonImg
                src={
                  !remove ? iconImages["addIcon"] : iconImages["unselectIcon"]
                }
                color="primary"
                className="ion-float-end vector-add"
                onClick={() => {
                  !remove ? addIssue(issue) : removeIssue(issue);
                }}
              ></IonImg>
            </IonCol>
          </IonRow>
          {issue?.type.toLowerCase() === IssueType.discomfort && (
            <IonRow className="ion-justify-content-between mt-2">
              <IonCol size="auto">
                <IonLabel className="y-custom-font-normal">
                  Location&nbsp;&nbsp;
                  <span className="y-custom-font-dark">
                    {issue.bodyLocation}
                  </span>
                </IonLabel>
              </IonCol>
              <IonCol size="auto">
                <IonLabel className="ion-float-end y-custom-font-normal">
                  Side&nbsp;&nbsp;
                  <span className="y-custom-font-dark">
                    {issue && issue.side && issue.side.length > 10
                      ? "---"
                      : issue.side
                      ? issue.side
                      : ""}
                  </span>
                </IonLabel>
              </IonCol>
            </IonRow>
          )}
          {issue && issue.type.toLowerCase() === IssueType.request && (
            <IonRow className="mt-2">
              <IonCol size="auto">
                <IonLabel className="y-custom-font-normal">
                  Type&nbsp;&nbsp;
                  <span className="y-custom-font-dark">
                    {`${
                      issue
                        ? issue.requestTypes
                          ? issue.requestTypes[0] === "empty"
                            ? ""
                            : issue.requestTypes.join(",")
                          : ""
                        : ""
                    }`}
                  </span>
                </IonLabel>
              </IonCol>
            </IonRow>
          )}
          <IonRow className="mt-1">
            <IonCol>
              <IonLabel className="y-custom-font-normal">
                {issue.description === "" ? "---" : issue.description}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        <hr className="separator mr-1" />
      </React.Fragment>
    );
  };

  return (
    <>
      <IonModal
        isOpen={openIssueSelector}
        cssClass="size-modal-3"
        swipeToClose={true}
        onDidDismiss={() => setOpenIssueSelector(false)}
      >
        <Loader visible={showLoader} />
        <IonHeader className="ion-justify-content-between px-18 header-modal">
          <IonRow className="ion-align-items-center">
            <IonCol className="">
              <p className="title-modal">Select Issues</p>
            </IonCol>
            <IonCol className="">
              <IonImg
                src={iconImages["closeIcon"]}
                color="primary"
                className="ion-float-end vector-add"
                onClick={() => {
                  setOpenIssueSelector(false);
                }}
              ></IonImg>
            </IonCol>
          </IonRow>
        </IonHeader>
        <IonRow className="ml-12 mr-12">
          <IonCol size="6">
            <p className="subtitle-modal2 text-16">
              {issuesList !== undefined ? issuesList.length : 0} Issues in this
              Evaluation
            </p>
            <IonContent className="box-list2 top-1">
              <IonList>
                {issuesList &&
                  issuesList.map((issue: any, index: number) =>
                    getIssueRow(issue, index)
                  )}
              </IonList>
            </IonContent>
          </IonCol>

          <IonCol size="6" className="mt-1">
            <p className="subtitle-modal2 text-16">
              Selected {selectedIssues.length} Issues
            </p>
            <IonContent className="box-list2">
              <IonList>
                {selectedIssues.map((issue: any, index: number) =>
                  getIssueRow(issue, index, true)
                )}
              </IonList>
            </IonContent>
          </IonCol>
        </IonRow>

        <IonRow className="px-24px">
          <IonCol className="display-flex mt-2">
            <span
              className="cursor-pointer display-flex"
              onClick={() => {
                handleClearSelection();
              }}
            >
              <IonImg
                className="icon-clearselection"
                src={iconImages["clearSelectionIcon"]}
                alt="clearfilter"
              ></IonImg>
              <p className="clear-selection">Clear selection</p>
            </span>
          </IonCol>
          <IonCol className="display-flex ion-justify-content-end">
            <IonButton
              color="medium"
              className="btn-custom-modal btn-cancel2"
              onClick={() => setOpenIssueSelector(false)}
            >
              Cancel
            </IonButton>
            <IonButton
              disabled={selectedIssues.length === 0}
              className="font-bold ml-1"
              onClick={() => handleAttachIssues()}
            >
              ADD {selectedIssues.length} SELECTED ISSUES
            </IonButton>
          </IonCol>
        </IonRow>
      </IonModal>
    </>
  );
};

export default IssueSelector;
