import React from "react";
import { IonItem, IonText, IonBadge } from "@ionic/react";

const SubItem: React.FC<any> = (props: any) => {
  const { label, total, onClick } = props;

  return (
    <IonItem className="cursor-pointer" onClick={onClick}>
      <IonText>
        <a className="ml-2 decoration-underline">{label}</a>
      </IonText>
      <IonBadge className="rounded-8 px-075 py-020" slot="end" color="light">
        {total}
      </IonBadge>
    </IonItem>
  );
};

export default SubItem;
