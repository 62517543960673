import React from "react";

import Badge from "../../../../common/controls/Badge";

const colors: any = {
  request: "bg-color-973ac3",
  discomfort: "bg-text-00cef7",
  resolved: "bg-color-42d392",
  chief: "bg-color-ff9532"
};

const Pills: React.FC<any> = (props: any) => {
  const { type, chief, resolved } = props;

  const handleOnClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className="flex">
      <Badge label={type} color={colors[type]} className="mr-03" />
      {chief === true && (
        <Badge
          label="CHIEF COMPLAINT"
          className="mr-03"
          color={colors["chief"]}
        />
      )}
      {resolved && <Badge label="RESOLVED" color={colors["resolved"]} />}
    </div>
  );
};

export default Pills;
