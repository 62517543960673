import axios from "axios";
import _ from "lodash";

import laptopIcon from "../assets/laptop-mac.png";
import displayIcon from "../assets/desktop-windows.png";
import poitingDeviceIcon from "../assets/cursor.png";
import keyboardIcon from "../assets/keyboard.png";
import tabletComputerIcon from "../assets/ipad.png";
import videoCameraIcon from "../assets/videocam.png";
import AccesoryIcon from "../assets/cable.png";
import hardwareIcon from "../assets/tool.png";
import deleteIcon from "../assets/delete.png";
import { makeApiCall, makeRequest } from "../helpers/global.helper";

const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;
const apiEvaluations = process.env.REACT_APP_BIDB_API_EVALUATIONS;
const apiActivities = process.env.REACT_APP_BIDB_API_ACTIVITIES;

export const imageIcons: any = {
  laptops: laptopIcon,
  displays: displayIcon,
  pointingdevices: poitingDeviceIcon,
  mouse: poitingDeviceIcon,
  keyboards: keyboardIcon,
  tabletcomputers: tabletComputerIcon,
  videocameras: videoCameraIcon,
  accessories: AccesoryIcon,
  hardware: hardwareIcon,
  delete: deleteIcon
};

/**
 *
 * @param id
 * @returns
 */
export const deleteCorrectivelensesGlasses =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/correctivelensesglasses/${id}`,
        method: "DELETE"
      })
    );

    console.log(response.status);
  };

/**
 *
 * @param id
 * @returns
 */
export const deleteCorrectivelensesContacts =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/correctivelensescontacts/${id}`,
        method: "DELETE"
      })
    );

    console.log(response.status);
  };

/**
 *
 * @param correctivelensesData
 * @returns
 */
export const updateCorrectivelensesGlasses =
  (correctivelensesData: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/correctivelensesglasses`,
        method: "PATCH",
        data: correctivelensesData
      })
    );

    return response;
  };
/**
 *
 * @param correctivelensesData
 * @returns
 */
export const updateCorrectivelensesContacts =
  (correctivelensesData: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/correctivelensescontacts`,
        method: "PATCH",
        data: correctivelensesData
      })
    );

    return response;
  };

/**
 *
 * @param id
 * @returns
 */
export const getCorrectiveLenses = async (id: number) => {
  const url = `${apiBaseUrl}/environments/${id}/correctivelenses`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

/**
 *
 * @param id
 * @param glassesData
 * @returns
 */

export const saveCorrectivelenses =
  (id: number, data: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/environments/${id}/correctivelenses`,
        method: "POST",
        data: data
      })
    );

    return response;
  };

/**
 * Updates environment data
 * @param environmentData
 * @returns
 */
export const updateEnvironment =
  (data: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/environments`,
        method: "PATCH",
        data: data
      })
    );

    console.log(response.status);
  };

/**
 *
 * @param id
 * @param environmentData
 * @returns
 */
export async function saveEnvironment(id: number, environmentData: any) {
  try {
    const response = await axios({
      url: `${apiBaseUrl}${apiEvaluations}/${id}/environments`,
      method: "POST",
      data: environmentData
    });
    console.log(response.status);
    console.log(response.statusText);
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

/**
 * Gets environments
 * @param id
 * @returns
 */
export const getEnvironments = async (id: number) => {
  const url = `${apiBaseUrl}${apiEvaluations}/${id}/environments`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

/**
 * Saves issue environment
 * @param data
 */
export const saveIssueEnvironment =
  (data: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/issueenvironments`,
        method: "POST",
        data: data
      })
    );

    return response;
  };

/**
 * Gets environment issues
 * @param id
 * @returns
 */
export const getEnvironmentIssues = async (id: number) => {
  const url = `${apiBaseUrl}/environments/${id}/issues`;
  const response = await axios.get(url);
  return response.data.rows;
};

/**
 * Saves activity environment
 * @param data
 */
export const saveActivityEnvironment =
  (data: any) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: `/activityenvironments`, method: "POST", data })
    );
    return response.data;
  };

/**
 * Deletes activity environment
 * @param id
 */
export const deleteActivityEnvironment =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({ endpoint: `/activityenvironments/${id}`, method: "DELETE" })
    );

    console.log(response.status);
  };

/**
 * Deletes activity environments
 * @param id
 */
export const deleteActivityEnvironments = async (id: number) => {
  const response = await axios.delete(
    `${apiBaseUrl}${apiActivities}/${id}/environments`
  );
  return response;
};

/**
 * Deletes activity risks
 * @param id
 */
export const deleteActivityRisks =
  async (id: number) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({
        endpoint: `${apiActivities}/${id}/risks`,
        method: "DELETE"
      })
    );

    console.log(response.status);
  };

/**
 * Saves activity risk
 * @param data
 */
export const saveActivityRisk = async (data: any) => {
  const response = await axios.post(`${apiBaseUrl}/activityrisks`, data);
  return response.data;
};
