import React from "react";
import { IonBackdrop, IonSpinner } from "@ionic/react";

import "../../css/common.css";

const Loader: React.FC<any> = (props: any) => {
  const { visible, cssSpinnerClass } = props;

  return (
    <>
      {visible && (
        <>
          <IonBackdrop
            className="loader-backdrop"
            visible={visible}
            tappable={false}
          />
          <IonSpinner
            className={cssSpinnerClass ? cssSpinnerClass : "loader-spinner"}
          />
        </>
      )}
    </>
  );
};

export default Loader;
