import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { categoryTypes, groupList } from "../../../../services/tabs.service";
import _, { filter, find, findIndex } from "lodash";

const slice = createSlice({
  name: "workstationFurnitures",
  initialState: {
    list: [],
    environmentFurnitures: {},
    showAddWorkstationFurnitureModal: false,
    selectedFurnitureIndex: -1,
    currentFurniture: undefined,
    selectedType: "",
    noFurnitureSelected: false,
    sortedList: [],
    isFilteredList: false,
    filters: {
      category: "",
      subCategory: "",
      manufacturer: ""
    },
    showEquipmentPicker: false,
    selectedId: -1,
    total: 0,
    showFurnituresSkeleton: false,
    selectedIdEnvironment: -1
  },
  reducers: {
    furnituresListReceived(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        workstationFurnitures.list = payload.rows[0];
      } else {
        workstationFurnitures.list = {};
      }
    },
    setShowAddWorkstationFurnitureModal(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.showAddWorkstationFurnitureModal = action.payload;
    },
    setFurnitureIndexSelected(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.selectedFurnitureIndex = action.payload;
    },
    setFurnitureTypeSelected(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.selectedType = action.payload;
    },
    setCurrentFurniture(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.currentFurniture = action.payload;
    },
    currentFurnitureUpdated(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.currentFurniture[`${action.payload.key}`] =
        action.payload.value;
    },
    updateList(workstationFurnitures: any, action: PayloadAction<any>) {
      workstationFurnitures.list[`${action.payload.type}`] =
        action.payload.list;
    },
    updateSortedList(workstationFurnitures: any, action: PayloadAction<any>) {
      workstationFurnitures.sortedList = action.payload;
      workstationFurnitures.total = action.payload.length;
    },
    setNoFurnitureSelected(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.noFurnitureSelected = action.payload;
    },
    setFurnituresSorted(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.sortedList = action.payload;
      workstationFurnitures.total = action.payload.length;
    },
    isFilteredFurnituresSet(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.isFilteredList = action.payload;
    },
    filtersUpdated(workstationFurnitures: any, action: PayloadAction<any>) {
      workstationFurnitures.filters[action.payload.key] = action.payload.value;
    },
    sortedListUpdated(workstationFurnitures: any, action: PayloadAction<any>) {
      workstationFurnitures.sortedList = action.payload;
      workstationFurnitures.total = action.payload.length;
    },
    showFurniturePickerSet(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.showFurniturePicker = action.payload;
    },
    showFurnituresSkeletonUpdated(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.showFurnituresSkeleton = action.payload;
    },
    idSelected(workstationFurnitures: any, action: PayloadAction<any>) {
      workstationFurnitures.selectedId = action.payload;
    },
    environmentFurnituresReceived(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.environmentFurnitures = action.payload;
    },
    selectedIdFurnitureEnvironmentUpdated(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.selectedIdEnvironment = action.payload;
    },
    environmentFurnituresUpdated(
      workstationFurnitures: any,
      action: PayloadAction<any>
    ) {
      workstationFurnitures.environmentFurnitures = action.payload;
    }
  }
});

export const {
  furnituresListReceived,
  setShowAddWorkstationFurnitureModal,
  setFurnitureIndexSelected,
  setCurrentFurniture,
  currentFurnitureUpdated,
  updateList,
  updateSortedList,
  setFurnitureTypeSelected,
  setNoFurnitureSelected,
  setFurnituresSorted,
  isFilteredFurnituresSet,
  sortedListUpdated,
  filtersUpdated,
  showFurniturePickerSet,
  showFurnituresSkeletonUpdated,
  idSelected,
  environmentFurnituresReceived,
  selectedIdFurnitureEnvironmentUpdated,
  environmentFurnituresUpdated
} = slice.actions;

export default slice.reducer;

/**
 * Gets show equipments skeleton.
 */
export const getCurrentShowFurnituresSkeleton = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) => workstationFurnitures.showFurnituresSkeleton
);

/**
 * Gets current total environment furnitures.
 * @returns
 */
export const getCurrentTotalEnvironmentFurnitures =
  () => (dispatch: any, getState: any) => {
    const { selectedIdEnvironment, environmentFurnitures } =
      getState().evaluation.environment.workstation.workstationFurnitures;

    const currentEnvironmentFurnitures: any = environmentFurnitures[
      selectedIdEnvironment
    ]
      ? environmentFurnitures[selectedIdEnvironment]
      : {};

    return currentEnvironmentFurnitures.sorted
      ? currentEnvironmentFurnitures.sorted.length
      : 0;
  };

/**
 * Gets show add modal.
 */
export const getShowAddWorkstationFurnitureModal = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) => {
    return workstationFurnitures.showAddWorkstationFurnitureModal;
  }
);

/**
 * Gets selected type.
 */
export const getSelectedType = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) => workstationFurnitures.selectedType
);

/**
 * Gets no furniture selected.
 */
export const getNoFurnitureSelected = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) => workstationFurnitures.noFurnitureSelected
);

/**
 * Gets sorted environment furnitures.
 */
export const getSortedEnvironmentFurnitures = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) =>
    workstationFurnitures.environmentFurnitures[
      workstationFurnitures.selectedIdEnvironment
    ]
      ? workstationFurnitures.environmentFurnitures[
          workstationFurnitures.selectedIdEnvironment
        ].sorted
      : []
);

/**
 * Gets is filtered list.
 */
export const getIsFilteredList = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) => workstationFurnitures.isFilteredList
);

/**
 * Gets current furniture.
 */
export const getCurrentFurniture = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) => workstationFurnitures.currentFurniture
);

/**
 * Gets selected furniture
 * @returns
 */
export const getSelectedFurniture = () => (dispatch: any, getState: any) => {
  return getState().evaluation.environment.workstation.workstationFurnitures
    .currentFurniture;
};

/**
 * Gets current selected furniture.
 * @returns
 */
export const getCurrentSelectedFurniture =
  () => (dispatch: any, getState: any) => {
    const { environmentFurnitures, selectedId, selectedIdEnvironment } =
      getState().evaluation.environment.workstation.workstationFurnitures;
    const { sorted } = environmentFurnitures[selectedIdEnvironment];
    const furniture = find(sorted, { id: selectedId });

    return furniture;
  };

/**
 * Updates workstation furnitures with corresponding current environment
 * @returns
 */
export const updateFurnituresList =
  (currentFurniture: any = undefined) =>
  (dispatch: any, getState: any) => {
    const { environmentFurnitures, selectedId, selectedIdEnvironment } =
      getState().evaluation.environment.workstation.workstationFurnitures;
    const currentEnvironmentFurnitures: any = {
      ...environmentFurnitures[selectedIdEnvironment]
    };
    const { list, sorted } = currentEnvironmentFurnitures;

    if (currentFurniture === undefined) {
      currentFurniture = find(sorted, { id: selectedId });
    }

    if (list) {
      const furnitures = list[`${currentFurniture.type}`]
        ? list[`${currentFurniture.type}`]
        : [];
      let index = findIndex(furnitures, {
        id: currentFurniture.id
      });

      if (index === -1) {
        index = 0;
      }

      let temp = [...furnitures];
      temp[index] = currentFurniture;

      // Updating the unsorted list
      let tempList = { ...list };
      tempList[`${currentFurniture.type}`] = temp;

      // Updating sorted list
      index = findIndex(sorted, {
        id: currentFurniture.id
      });

      if (index === -1) {
        index = 0;
      }

      let tempSortedList = [];
      tempSortedList = [...sorted];
      tempSortedList[index] = currentFurniture;
      let tempEnvironmentFurnitures = { ...environmentFurnitures };

      tempEnvironmentFurnitures[selectedIdEnvironment] = {
        list: tempList,
        sorted: tempSortedList
      };
      dispatch({
        type: environmentFurnituresUpdated.type,
        payload: tempEnvironmentFurnitures
      });
      dispatch({
        type: setCurrentFurniture.type,
        payload: currentFurniture
      });
    }
  };

/**
 * Gets selected furniture index.
 */
export const getSelectedFurnitureIndex = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) => workstationFurnitures.selectedFurnitureIndex
);

/**
 * Gets worksurface ids.
 */
export const getWorksurfaceIds = () => (dispatch: any, getState: any) => {
  const { selectedIdEnvironment, environmentFurnitures } =
    getState().evaluation.environment.workstation.workstationFurnitures;

  const currentEnvironmentFurnitures =
    environmentFurnitures[selectedIdEnvironment];
  const sortedList = currentEnvironmentFurnitures.sorted
    ? currentEnvironmentFurnitures.sorted
    : [];

  return filter(sortedList, function (o: any) {
    return o.type.toLowerCase() === "worksurfaces";
  });
};

/**
 * Gets category.
 * @returns
 */
export const getCategory =
  (furnitureCategories: any) => (dispatch: any, getState: any) => {
    const { selectedType } =
      getState().evaluation.environment.workstation.workstationFurnitures;
    const categories = categoryTypes["furnitures"];

    let category = _.findKey(categories, function (o) {
      return o.toLowerCase() === selectedType.toLowerCase();
    });

    const index = _.findIndex(furnitureCategories, function (e: any) {
      return e.toLowerCase() === category;
    });

    category = furnitureCategories[index];

    return category;
  };

/**
 * Sorts list
 * @returns
 */
export const sortEnvironmentFurnitures =
  (data: any) => async (dispatch: any, getState: any) => {
    const { selectedIdEnvironment, environmentFurnitures } =
      getState().evaluation.environment.workstation.workstationFurnitures;

    const rows = data.rows.length > 0 ? data.rows[0] : {};
    let currentEnvironmentFurnitures: any = { ...environmentFurnitures };
    currentEnvironmentFurnitures[selectedIdEnvironment] = {
      list: rows
    };

    const groupedAndSorted: any = await groupList(
      rows,
      {
        columns: ["name"],
        directions: ["asc"]
      },
      "furnitures"
    );

    currentEnvironmentFurnitures[selectedIdEnvironment] = {
      ...currentEnvironmentFurnitures[selectedIdEnvironment],
      sorted: groupedAndSorted
    };

    dispatch({
      type: setFurnituresSorted.type,
      payload: groupedAndSorted
    });

    // Storing all evaluation => environment => furnitures
    dispatch({
      type: environmentFurnituresReceived.type,
      payload: currentEnvironmentFurnitures
    });
  };

/**
 * Gets shows furniture picker.
 */
export const getShowFurniturePicker = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationFurnitures,
  (workstationFurnitures: any) => workstationFurnitures.showFurniturePicker
);

/**
 * Gets current selected id.
 */
export const getCurrentSelectedId = () => (dispatch: any, getState: any) => {
  return getState().evaluation.environment.workstation.workstationFurnitures
    .selectedId;
};

/**
 * Gets total environment furnitures.
 */
export const getTotalEnvironmentFurnitures = createSelector(
  (state: any) => {
    return state.evaluation.environment.workstation.workstationFurnitures;
  },
  (workstationFurnitures: any) => workstationFurnitures.total
);

export const updateEnvironmentFurnitures =
  (data: any) => (dispatch: any, getState: any) => {
    const { selectedIdEnvironment, environmentFurnitures } =
      getState().evaluation.environment.workstation.workstationFurnitures;

    let currentEnvironmentFurnitures: any = environmentFurnitures[
      selectedIdEnvironment
    ]
      ? { ...environmentFurnitures[selectedIdEnvironment] }
      : {};

    currentEnvironmentFurnitures.sort = data;

    let tempEnvironmentFurnitures = { ...environmentFurnitures };
    tempEnvironmentFurnitures[selectedIdEnvironment] = {
      list: currentEnvironmentFurnitures.list
        ? currentEnvironmentFurnitures.list
        : {},
      sorted: data
    };

    dispatch({
      type: environmentFurnituresUpdated.type,
      payload: tempEnvironmentFurnitures
    });
  };
