import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonRow,
  IonText,
  IonIcon,
  IonBadge,
  IonInput,
  IonLabel,
  IonButton,
  useIonAlert
} from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import { addSharp, chevronUpOutline, chevronDownOutline } from "ionicons/icons";
import "../../../css/equipment.css";
import SelectForm from "../../common/controls/SelectForm";
import CorrectiveLenseButtons from "./CorrectiveLenseButtons";
import {
  updateEnvironment,
  saveCorrectivelenses,
  updateCorrectivelensesContacts,
  updateCorrectivelensesGlasses,
  deleteCorrectivelensesContacts,
  deleteCorrectivelensesGlasses
} from "../../../services/environment.service";
import CheckBoxForm from "./CheckBoxForm";
import {
  updateCurrentEnvironment,
  setEnvironmentCorrectiveLenses,
  updateEnvironmentCorrectiveLenses,
  deleteEnvironmentCorrectiveLenses,
  getEnvironments,
  environmentsUpdated,
  getCurrentEvaluationEnvironment,
  getCurrentEnvironment
} from "../../../store/evaluation/Current";
import _ from "lodash";
import { updateObjectFromList } from "../../../helpers/global.helper";
import { getListByName } from "../../../store/catalogs/ValueList";

const BasicInformationContent: React.FC<any> = (props: any) => {
  const dispatch = useAppDispatch();
  const { correctivelenses } = props;
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const listGlasses =
    correctivelenses && correctivelenses.length > 0
      ? correctivelenses[0].glasses
      : [];
  const listContacts =
    correctivelenses && correctivelenses.length > 0
      ? correctivelenses[0].contacts
      : [];

  const environmentTypes = dispatch(getListByName("Environment_Type"));
  const environmentTimesHereWeekly = dispatch(
    getListByName("Environment_Time_Here_Weekly")
  );
  const environmentSpaceTypes = dispatch(
    getListByName("Environment_Space_Type")
  );
  const environmentOfficeSpaceTypes = dispatch(
    getListByName("Environment_Office_Space_Type")
  );
  const environmentAssignmentTypes = dispatch(
    getListByName("Environment_Assignment_Type")
  );
  const environmentRoomTypes = dispatch(getListByName("Environment_Room_Type"));
  const [isHome, setIsHome] = useState<boolean>();
  const [isOfficeMeetingRoom, setIsOfficeMeetingRoom] = useState<boolean>();
  const [isOfficeRoom, setIsOfficeRoom] = useState<boolean>();
  const currentEnvironments = useAppSelector(getEnvironments);
  const [, setOptionsSpaceType] = useState<any>([]);

  useEffect(() => {
    if (currentEnvironment.type) {
      updateSpaceType(currentEnvironment.type.toLowerCase());
    }
  }, [currentEnvironment.id]);

  useEffect(() => {
    currentEnvironment.type && currentEnvironment.type.toLowerCase() === "home"
      ? setIsHome(true)
      : setIsHome(false);

    if (
      currentEnvironment.type &&
      currentEnvironment.type.toLowerCase() === "office" &&
      currentEnvironment.spaceType &&
      currentEnvironment.spaceType.toLowerCase() !== "meeting room"
    ) {
      setIsOfficeMeetingRoom(true);
    } else {
      setIsOfficeMeetingRoom(false);
    }

    if (
      currentEnvironment.type &&
      currentEnvironment.type.toLowerCase() === "office" &&
      currentEnvironment.spaceType &&
      currentEnvironment.spaceType.toLowerCase() === "meeting room"
    ) {
      setIsOfficeRoom(true);
    } else {
      setIsOfficeRoom(false);
    }
  }, [
    currentEnvironment.id && currentEnvironment.type,
    currentEnvironment.id && currentEnvironment.spaceType
  ]);

  const updateSpaceType = (type: string) => {
    if (type === "office") {
      setOptionsSpaceType(environmentOfficeSpaceTypes);
    } else {
      setOptionsSpaceType(environmentSpaceTypes);
    }
  };

  /**
   * Add correctivelense
   * @param id
   * @param type
   */
  const addCorrectivelense = async (id: number, type: string) => {
    let data: any = {
      [type]: [
        {
          blueLightFilter: null,
          distant: null,
          multiFocal: null,
          reading: null
        }
      ]
    };

    const response: any = await dispatch(saveCorrectivelenses(id, data));

    if (response.status === 200) {
      let row = response?.data.successes[0];
      data = {
        id: row.id,
        ...data[type][0],
        type: type
      };

      dispatch({
        type: setEnvironmentCorrectiveLenses.type,
        payload: {
          type,
          data
        }
      });
    }
  };

  const [glassesClickedIndex, setGlassesClickedIndex] = useState<number>(-1);
  const [contactsClickedIndex, setContactsClickedIndex] = useState<number>(-1);

  const openCloseSubmenuGlasses = async (index: number) => {
    let currentIndex = -1;

    if (glassesClickedIndex !== index) {
      currentIndex = index;
    }

    setGlassesClickedIndex(currentIndex);
  };

  const openCloseSubmenuContacts = async (index: number) => {
    let currentIndex = -1;

    if (contactsClickedIndex !== index) {
      currentIndex = index;
    }

    setContactsClickedIndex(currentIndex);
  };

  const [present] = useIonAlert();

  const deleteCorrectiveLenses = async (
    id: number,
    index: number,
    type: string
  ) => {
    let response;

    if (type === "glasses") {
      dispatch(deleteCorrectivelensesGlasses(id));
    } else {
      dispatch(deleteCorrectivelensesContacts(id));
    }

    dispatch(
      deleteEnvironmentCorrectiveLenses({
        type,
        index
      })
    );
  };

  /**
   * Updates environment value
   * @param key
   * @param value
   */
  const updateEnvironmentValue = async (key: string, value: any) => {
    const environment = dispatch(getCurrentEnvironment());

    if (value !== undefined && environment[key] !== value) {
      let data: any = {
        id: environment.id,
        [key]: value
      };

      dispatch(updateEnvironment(data));

      dispatch({
        type: updateCurrentEnvironment.type,
        payload: {
          [key]: value
        }
      });

      const list = await updateObjectFromList(
        [...currentEnvironments],
        data,
        "id",
        key,
        value
      );

      dispatch({
        type: environmentsUpdated.type,
        payload: list
      });
    }
  };

  /**
   * Get corrective lense name
   * @param key
   */
  const getCorrectiveLenseName = (key: any) => {
    let name: any = "";
    const clone = { ...key };

    for (let i = 0; i < key.length; i++) {
      if (clone[i].toLowerCase() !== key[i]) {
        name += " ";
      }
      name += i === 0 ? key[i].toUpperCase() : key[i];
    }

    return name;
  };

  /**
   * Updates corrective lenses
   */
  const updateCorrectiveLenses = async (
    id: number,
    key: string,
    value: any,
    index: number,
    type: string,
    row: any
  ) => {
    const data = {
      id,
      [key]: value
    };

    value = value !== row[key] ? value : ""; // allow unselect

    let response: any;
    if (type === "glasses") {
      response = await dispatch(updateCorrectivelensesGlasses(data));
    } else {
      response = await dispatch(updateCorrectivelensesContacts(data));
    }

    if (response.status === 200) {
      dispatch(
        updateEnvironmentCorrectiveLenses({
          type,
          index,
          key,
          value
        })
      );
    }
  };

  return (
    <>
      <IonRow>
        <IonCol>
          <SelectForm
            label="Environment Type"
            options={environmentTypes}
            value={currentEnvironment && currentEnvironment.type}
            name="type"
            onChange={updateEnvironmentValue}
            clearValue={() => updateEnvironmentValue("type", null)}
          />
        </IonCol>
        <IonCol>
          <SelectForm
            label="Time here weekly"
            options={environmentTimesHereWeekly}
            value={
              currentEnvironment &&
              currentEnvironment.percentTimeHereWeekly &&
              (~~(currentEnvironment.percentTimeHereWeekly * 100)).toString()
            }
            name="percentTimeHereWeekly"
            onChange={(key: string, value: any) => {
              if (value && value !== null) {
                updateEnvironmentValue(key, value / 100);
              }
            }}
            clearValue={() =>
              updateEnvironmentValue("percentTimeHereWeekly", null)
            }
          />
        </IonCol>
      </IonRow>
      <IonRow className="display-flex align-items-center">
        <IonCol>
          <SelectForm
            label="Space Type"
            options={
              currentEnvironment &&
              currentEnvironment?.type &&
              currentEnvironment.type.toLowerCase() === "office"
                ? environmentOfficeSpaceTypes
                : environmentSpaceTypes
            }
            value={currentEnvironment && currentEnvironment.spaceType}
            name="spaceType"
            onChange={(key: string, value: any) => {
              if (
                value &&
                value !== null &&
                currentEnvironment.spaceType !== value
              ) {
                updateEnvironmentValue(key, value);
              }
            }}
            clearValue={() => updateEnvironmentValue("spaceType", null)}
          />
        </IonCol>
        <IonCol>
          {isOfficeMeetingRoom && (
            <SelectForm
              label="Assignment Type"
              options={environmentAssignmentTypes}
              value={currentEnvironment && currentEnvironment.assignmentType}
              name="assignmentType"
              onChange={updateEnvironmentValue}
              clearValue={() => updateEnvironmentValue("assignmentType", null)}
            />
          )}
          {isOfficeRoom && (
            <SelectForm
              label="Room Type"
              options={environmentRoomTypes}
              value={currentEnvironment && currentEnvironment.roomType}
              name="roomType"
              onChange={updateEnvironmentValue}
              clearValue={() => updateEnvironmentValue("roomType", null)}
            />
          )}
          {isHome && (
            <IonRow className="w-full">
              <div className="mt-15 w-50pct">
                <CheckBoxForm
                  name="sharedSpace"
                  checked={currentEnvironment && currentEnvironment.sharedSpace}
                  onChange={(e: any) => {
                    const { checked } = e.target;
                    if (currentEnvironment.sharedSpace !== checked) {
                      updateEnvironmentValue(e.target.name, e.target.checked);
                    }
                  }}
                  csscheckbox="ml-15"
                  csstitle="ml-12"
                  title="Shared space"
                />
              </div>
              <div className="mt-15 w-50pct">
                <CheckBoxForm
                  name="plannedSpace"
                  checked={
                    currentEnvironment && currentEnvironment.plannedSpace
                  }
                  onChange={(e: any) => {
                    const { checked } = e.target;
                    const environment: any = dispatch(getCurrentEnvironment());

                    if (
                      environment &&
                      environment.type.toLowerCase() === "home" &&
                      typeof checked === "boolean" &&
                      currentEnvironment.plannedSpace !== checked
                    ) {
                      updateEnvironmentValue(e.target.name, e.target.checked);
                    }
                  }}
                  csscheckbox="ml-15"
                  csstitle="ml-12"
                  title="Planned Space"
                />
              </div>
            </IonRow>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <div className="form-group custom-input matching-element">
            <IonLabel className="form-group-input-text-title matching-element-label">
              Name
            </IonLabel>
            <IonInput
              className="ion-pl-06 form-control input-lg form-group-input-text text-left"
              type="text"
              name="name"
              onBlur={(e: any) =>
                updateEnvironmentValue(e.target.name, e.target.value)
              }
              value={currentEnvironment && currentEnvironment.name}
              required
            />
          </div>
        </IonCol>
        <IonCol className="mr-24"></IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-margin-vertical">
          <div className="form-group custom-input matching-element">
            <IonLabel className="form-group-input-text-title matching-element-label">
              Comment
            </IonLabel>
            <IonInput
              className="ion-pl-06 form-control input-lg form-group-input-text"
              type="text"
              name="comment"
              onBlur={(e: any) =>
                updateEnvironmentValue(e.target.name, e.target.value)
              }
              value={currentEnvironment && currentEnvironment.comment}
              required
            />
          </div>
        </IonCol>
      </IonRow>
      <IonRow className="mb-23">
        <IonCol>
          <div className="border-1"></div>
        </IonCol>
      </IonRow>
      <IonRow className="display-flex align-items-center mb-25">
        <IonCol className="w-463" sizeLg="6" sizeMd="3">
          <IonText className="float-start text-16-bold">
            Corrective Lenses
          </IonText>
        </IonCol>
        <IonCol>
          <IonButton
            className="btn"
            onClick={() =>
              addCorrectivelense(
                currentEnvironment && currentEnvironment.id,
                "glasses"
              )
            }
          >
            <IonIcon icon={addSharp}></IonIcon> ADD GLASSES
          </IonButton>
          <IonButton
            className="float-end btn"
            onClick={() =>
              addCorrectivelense(
                currentEnvironment && currentEnvironment.id,
                "contacts"
              )
            }
          >
            <IonIcon icon={addSharp}></IonIcon> ADD CONTACTS
          </IonButton>
        </IonCol>
      </IonRow>
      {listGlasses &&
        listGlasses.map((lenses: any, index: number) => (
          <div key={index}>
            <IonRow className="display-flex align-items-center mb-16">
              <IonCol
                className="background-row-color display-flex align-items-center"
                key={index}
              >
                <div className="w-34">
                  <IonIcon
                    className="cursor-pointer"
                    onClick={() => openCloseSubmenuGlasses(index)}
                    icon={
                      glassesClickedIndex === index
                        ? chevronUpOutline
                        : chevronDownOutline
                    }
                  ></IonIcon>
                </div>
                <div className="w-75">
                  <IonLabel>#{index + 1}</IonLabel>
                </div>
                <div className="w-75">
                  <IonBadge
                    color="success"
                    className="text-uppercase rounded-27 text-12 bg-70 text-46a3a7 w-75 letter-spacing-012"
                  >
                    glasses
                  </IonBadge>
                </div>
                <div className="w-full">
                  <IonButton
                    color="danger"
                    className="float-end"
                    onClick={() =>
                      present({
                        cssClass: "my-css",
                        message:
                          "Are you sure that you want to delete this corrective lens?",
                        buttons: [
                          "Cancel",
                          {
                            text: "Ok",
                            handler: (d) =>
                              deleteCorrectiveLenses(
                                lenses.id,
                                index,
                                "glasses"
                              )
                          }
                        ],
                        onDidDismiss: (e) => console.log("did dismiss")
                      })
                    }
                  >
                    Delete
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
            {glassesClickedIndex === index && (
              <CorrectiveLenseButtons
                rowIndex={index}
                correctiveLenses={lenses}
                getCorrectiveLenseName={getCorrectiveLenseName}
                updateCorrectiveLenses={updateCorrectiveLenses}
                type="glasses"
              />
            )}
          </div>
        ))}
      {listContacts &&
        listContacts.map((lenses: any, index: number) => (
          <div key={index}>
            <IonRow className="display-flex align-items-center mb-24">
              <IonCol
                className="background-row-color display-flex align-items-center"
                key={index}
              >
                <div className="w-34">
                  <IonIcon
                    className="cursor-pointer"
                    onClick={() => openCloseSubmenuContacts(index)}
                    icon={
                      contactsClickedIndex === index
                        ? chevronUpOutline
                        : chevronDownOutline
                    }
                  ></IonIcon>
                </div>
                <div className="w-75">
                  <IonLabel>#{index + 1}</IonLabel>
                </div>
                <div className="w-88">
                  <IonBadge
                    color="danger"
                    className="text-uppercase rounded-27 text-12 text-da4178 bg-218 w-75 letter-spacing-012 w-88"
                  >
                    contacts
                  </IonBadge>
                </div>
                <div className="w-full">
                  <IonButton
                    color="danger"
                    className="float-end"
                    onClick={() =>
                      present({
                        cssClass: "my-css",
                        message:
                          "Are you sure that you want to delete this corrective lens?",
                        buttons: [
                          "Cancel",
                          {
                            text: "Ok",
                            handler: (d) =>
                              deleteCorrectiveLenses(
                                lenses.id,
                                index,
                                "contacts"
                              )
                          }
                        ],
                        onDidDismiss: (e) => console.log("did dismiss")
                      })
                    }
                  >
                    Delete
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
            {contactsClickedIndex === index && (
              <CorrectiveLenseButtons
                rowIndex={index}
                correctiveLenses={lenses}
                getCorrectiveLenseName={getCorrectiveLenseName}
                updateCorrectiveLenses={updateCorrectiveLenses}
                type="contacts"
              />
            )}
          </div>
        ))}
    </>
  );
};

export default BasicInformationContent;
