import React from "react";
import { IonCol, IonRow } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getRiskSubCategories,
  getOriginalRiskVs,
  riskVsUpdated,
  riskSubCategoriesUpdated
} from "../../../../../store/catalogs/Risks";

import Filters from "../add-modal/filters/Index";
import Category from "../add-modal/filters/Category";
import SubCategory from "../add-modal/filters/SubCategory";
import Name from "../add-modal/filters/Name";
import {
  getRiskFilters,
  isFilteredRisksSet,
  filtersUpdated
} from "../../../../../store/evaluation/environment/Risks";
import { getListByName } from "../../../../../store/catalogs/ValueList";

const RiskFilters: React.FC<any> = (props: any) => {
  const { filters, setFilter, removeFilter, sort, isAttach, grouped } = props;

  const dispatch = useAppDispatch();
  const categories = dispatch(getListByName("Risk_Category"));
  const subcategories = useAppSelector(getRiskSubCategories);
  const originalRiskVs = useAppSelector(getOriginalRiskVs);
  const riskFilters = useAppSelector(getRiskFilters);

  const setFilterValue = (
    key: string,
    value: string,
    contains: boolean = false
  ) => {
    if (value && value !== "") {
      if (key === "category") {
        dispatch({
          type: riskSubCategoriesUpdated.type,
          payload: dispatch(getListByName(`Risk_${value}_Subcategory`))
        });
      }

      setFilter(key, value, contains);

      dispatch({
        type: filtersUpdated.type,
        payload: {
          key,
          value
        }
      });
    }
  };

  const handleIsFilterdList = (currentKey: string) => {
    let isFilteredList = false;

    for (const [key, value] of Object.entries(riskFilters)) {
      if (key !== currentKey) {
        if (value !== "") {
          isFilteredList = true;
        } else {
          isFilteredList = false;
        }
      }
    }

    if (!isFilteredList) {
      dispatch({
        type: isFilteredRisksSet.type,
        payload: false
      });
    }
  };

  return (
    <Filters>
      <IonRow>
        <IonCol size="6">
          <Category
            categories={categories}
            originalList={originalRiskVs}
            listFiltered={riskVsUpdated}
            setIsFilteredList={isFilteredRisksSet}
            category={filters && filters.category && filters.category.value}
            setFilterValue={setFilterValue}
            filters={filters}
            placeholder="Select category"
            removeFilter={removeFilter}
            sort={sort}
          />
        </IonCol>
        <IonCol size="6">
          <SubCategory
            subcategories={subcategories}
            originalList={originalRiskVs}
            listFiltered={riskVsUpdated}
            setIsFilteredList={isFilteredRisksSet}
            subCategory={
              filters && filters.subCategory && filters.subCategory.value
            }
            setFilterValue={setFilterValue}
            filters={filters}
            placeholder="Select subcategory"
            removeFilter={removeFilter}
            sort={sort}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6">
          <Name
            list={originalRiskVs}
            listFiltered={riskVsUpdated}
            setIsFilteredList={isFilteredRisksSet}
            onInput={setFilterValue}
            onIsFilteredList={handleIsFilterdList}
            value={filters && filters.name && filters?.name.value}
            filters={filters}
            removeFilter={removeFilter}
            placeholder="Search risk name"
            sort={sort}
            isAttach={isAttach}
            grouped={grouped}
          />
        </IonCol>
      </IonRow>
    </Filters>
  );
};

export default RiskFilters;
