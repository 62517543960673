import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _, { omitBy } from "lodash";
import { setPickerPropertyToList } from "../../services/tabs.service";

const slice = createSlice({
  name: "recommendations",
  initialState: {
    recommendationVs: [],
    originalRecommendationVs: [],
    recommendationCategory: [],
    showAddRecommendationModal: false,
    recommendationPriorities: [],
    recommendationActionItems: [],
    recommendationResponsibleParties: [],
    types: [],
    subCategories: [],
    originalSubCategories: [],
    reasonFilters: [],
    pickedItems: []
  },
  reducers: {
    recommendationVsReceived(recommendations: any, action: PayloadAction<any>) {
      const { payload } = action;

      // In this reducer we update 2 list, because of filter in add recommendation modal.
      // The second one is used to initialize main list.
      if (payload.rows && payload.rows.length > 0) {
        recommendations.recommendationVs = payload.rows;
        recommendations.originalRecommendationVs = payload.rows;
      } else {
        recommendations.recommendationVs = [];
        recommendations.originalRecommendationVs = [];
      }
    },
    recommendationvsUpdated(recommendations: any, action: PayloadAction<any>) {
      recommendations.recommendationVs = action.payload;
    },
    reasonFiltersReceived(recommendations: any, action: PayloadAction<any>) {
      recommendations.reasonFilters = action.payload;
    },
    recommendationCategoryReceived(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      recommendations.recommendationCategory = action.payload;
    },
    setIsFilteredRecommendationVsList(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      recommendations.isFilteredRecommendationVsList = action.payload;
    },
    recommendationPriorityReceived(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        recommendations.recommendationPriorities = payload.rows[0].values;
      } else {
        recommendations.recommendationPriorities = [];
      }
    },
    recommendationActionItemReceived(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        recommendations.recommendationActionItems = payload.rows[0].values;
      } else {
        recommendations.recommendationActionItems = [];
      }
    },
    recommendationResponsiblePartyReceived(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        recommendations.recommendationResponsibleParties =
          payload.rows[0].values;
      } else {
        recommendations.recommendationResponsibleParties = [];
      }
    },
    recommendationTypesReceived(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        recommendations.types = payload.rows[0].values;
      } else {
        recommendations.types = [];
      }
    },
    recommendationSubCategoriesReceived(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      recommendations.subCategories = action.payload;
      recommendations.originalSubCategories = action.payload;
    },
    recommendationSubCategoriesUpdated(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      recommendations.subCategories = action.payload;
    },
    recommendationSubCategoriesReset(
      recommendations: any,
      action: PayloadAction<any>
    ) {
      recommendations.subCategories = action.payload;
    },
    pickedItemsUpdated(
      organizationRegionEquipments: any,
      action: PayloadAction<any>
    ) {
      organizationRegionEquipments.pickedItems = action.payload;
    }
  }
});

export const {
  recommendationVsReceived,
  recommendationvsUpdated,
  recommendationCategoryReceived,
  setIsFilteredRecommendationVsList,
  recommendationPriorityReceived,
  recommendationActionItemReceived,
  recommendationResponsiblePartyReceived,
  recommendationTypesReceived,
  recommendationSubCategoriesReceived,
  recommendationSubCategoriesUpdated,
  recommendationSubCategoriesReset,
  reasonFiltersReceived,
  pickedItemsUpdated
} = slice.actions;
export default slice.reducer;

/**
 * Gets recommendation vs.
 */
export const getRecommendationVs = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => recommendations.recommendationVs
);

/**
 * Gets current recommendation reason filters.
 */
export const getCurrentRecommendationReasonFilters = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => recommendations.reasonFilters
);

/**
 * Gets recommendation category.
 */
export const getRecommendationCategory = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => recommendations.recommendationCategory
);

/**
 * Gets is filtered recommendation vs list.
 */
export const getIsFilteredRecommendationVsList = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => recommendations.isFilteredRecommendationVsList
);

/**
 * Gets original recommendation vs list.
 */
export const getOriginalRecommendationVs = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => recommendations.originalRecommendationVs
);

/**
 * Gets filters.
 */
export const getFilters = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => {
    return recommendations.recommendationFilters;
  }
);

/**
 * Gets recommendation priorities.
 */
export const getRecommendationPriorities = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => {
    return recommendations.recommendationPriorities;
  }
);

/**
 * Gets recommendation action items.
 */
export const getRecommendationActionItems = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => {
    return recommendations.recommendationActionItems;
  }
);

/**
 * Gets recommendation responsible parties.
 */
export const getRecommendationResponsibleParties = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => {
    return recommendations.recommendationResponsibleParties;
  }
);

/**
 * Gets recommendation types.
 */
export const getRecommendationTypes = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => recommendations.types
);

/**
 * Gets recommendation subcategories.
 */
export const getCurrentRecommendationSubCategories =
  () => (dispatch: any, getState: any) => {
    return getState().entities.catalogs.recommendations.subCategories;
  };

/**
 * Gets recommendation original subcategories.
 */
export const getRecommendationOriginalSubCategories = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => recommendations.originalSubCategories
);

/**
 * Load items.
 * @returns
 */
export const loadItems = () => async (dispatch: any, getState: any) => {
  const { recommendationVs, pickedItems } =
    getState().entities.catalogs.recommendations;

  if (pickedItems.length > 0) {
    const temp = await setPickerPropertyToList(recommendationVs, pickedItems);

    dispatch({
      type: recommendationvsUpdated.type,
      payload: temp
    });
  }
};

/**
 * Gets selected picked items.
 */
export const getCurrentSelectedPickedItems = createSelector(
  (state: any) => state.entities.catalogs.recommendations,
  (recommendations: any) => recommendations.pickedItems
);

/**
 * Remove items
 */
export const removeItems =
  (item: any) => async (dispatch: any, getState: any) => {
    const { pickedItems } = getState().entities.catalogs.recommendations;

    const filtered = _.filter(pickedItems, function (o) {
      return o.id !== item.id;
    });

    dispatch({
      type: pickedItemsUpdated.type,
      payload: filtered
    });
  };

/**
 * Reset items.
 */
export const resetRecommendationVs =
  () => async (dispatch: any, getState: any) => {
    const { originalRecommendationVs } =
      getState().entities.catalogs.recommendations;

    if (originalRecommendationVs) {
      dispatch({
        type: recommendationvsUpdated.type,
        payload: originalRecommendationVs
      });
    }
  };
