import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { createSelector } from "reselect";

const slice = createSlice({
  name: "environmentIssues",
  initialState: {
    list: [],
    total: 0,
    environments: [],
    showCheckboxLoader: false
  },
  reducers: {
    environmentIssuesReceived(
      environmentIssues: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        environmentIssues.list = payload.rows;
      } else {
        environmentIssues.list = [];
      }
    },
    environmentIssuesUpdated(
      environmentIssues: any,
      action: PayloadAction<any>
    ) {
      environmentIssues.list = action.payload;
      environmentIssues.total = action.payload.length;
    },
    issueEnvironmentsReceived(
      environmentIssues: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        environmentIssues.environments = payload.rows;
      } else {
        environmentIssues.environments = [];
      }
    },
    issueEnvironmentsUpdated(
      environmentIssues: any,
      action: PayloadAction<any>
    ) {
      environmentIssues.environments = action.payload;
    },
    totalEnvironmentIssuesUpdated(
      environmentIssues: any,
      action: PayloadAction<any>
    ) {
      environmentIssues.total = action.payload;
    },
    showCheckboxLoaderUpdated(
      environmentIssues: any,
      action: PayloadAction<any>
    ) {
      environmentIssues.showCheckboxLoader = action.payload;
    }
  }
});

export const {
  environmentIssuesReceived,
  environmentIssuesUpdated,
  issueEnvironmentsReceived,
  issueEnvironmentsUpdated,
  totalEnvironmentIssuesUpdated,
  showCheckboxLoaderUpdated
} = slice.actions;
export default slice.reducer;

/**
 * Gets current showChekboxLoader.
 */
export const getCurrentShowCheckboxLoader = createSelector(
  (state: any) => {
    return state.evaluation.environment.issues;
  },
  (issues: any) => issues.showCheckboxLoader
);

/**
 * Gets issues.
 */
export const getCurrentEnvironmentIssues = createSelector(
  (state: any) => {
    return state.evaluation.environment.issues;
  },
  (issues: any) => issues.list
);

export const getCurrentTotalEnvironmentIssues =
  (
    currentEvaluationIssues: any = undefined,
    currentEnvironmentIssues: any = undefined
  ) =>
  (dispatch: any, getState: any) => {
    if (
      currentEvaluationIssues != undefined &&
      currentEnvironmentIssues != undefined
    ) {
      const total: any = currentEvaluationIssues.filter((issue: any) =>
        currentEnvironmentIssues
          ? currentEnvironmentIssues.filter(
              (env: any) => env.idIssue === issue.id
            ).length > 0 || issue.id === undefined
          : false
      ).length;

      return total;
    } else {
      return 0;
    }
  };

export const getTotalEnvironmentIssues = createSelector(
  (state: any) => {
    return state.evaluation.environment.issues;
  },
  (issues: any) => issues.total
);

/**
 * Gets current issue environments.
 */
export const getCurrentIssueEnvironments = createSelector(
  (state: any) => {
    return state.evaluation.environment.issues;
  },
  (issues: any) => issues.environments
);

/**
 * Gets current issue environments in runtime.
 * @returns
 */
export const getCurrentIssueEnvironmentsInRunTime =
  () => (dispatch: any, getState: any) => {
    const { environments } = getState().evaluation.environment.issues;

    return environments;
  };

/**
 * Updates total environment issues.
 * @returns
 */
export const updateTotalEnvironmentIssues =
  (currentEvaluationIssues: any) => (dispatch: any, getState: any) => {
    const currentEnvironmentIssues =
      getState().evaluation.environment.issues.list;
    const environmentIssues: any = currentEvaluationIssues.filter(
      (issue: any) =>
        currentEnvironmentIssues
          ? currentEnvironmentIssues.filter(
              (env: any) => env.idIssue === issue.id
            ).length > 0 || issue.id === undefined
          : false
    );

    dispatch({
      type: totalEnvironmentIssuesUpdated.type,
      payload: environmentIssues.length
    });
  };
