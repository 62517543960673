import React, { useEffect, useState } from "react";
import {
  getCurrentLocationIndex,
  getCurrentOrganizationIndex,
  organizationIndexUpdated
} from "../../../store/appointments/Index";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";

import BaseSideMenu from "../../common/BaseSideMenu";

const Menu: React.FC<any> = (props: any) => {
  const { items, subItems, contentId, onLocationClick } = props;
  const [parentIndex, setParentIndex] = useState<number>(-1);

  const dispatch = useAppDispatch();

  const currentOrganizationIndex = useAppSelector(getCurrentOrganizationIndex);
  const currentLocationIndex = useAppSelector(getCurrentLocationIndex);

  const handleSetParentIndex = (index: number) => {
    setParentIndex(index);

    dispatch({
      type: organizationIndexUpdated.type,
      payload: index
    });
  };

  useEffect(() => {
    setParentIndex(currentOrganizationIndex);
  }, [currentOrganizationIndex && currentOrganizationIndex]);

  return (
    <BaseSideMenu
      contentId={contentId}
      titleBarClasses={{
        "text-blue-1f3061": true
      }}
      title="Clients"
      menuOptions={items}
      subItems={subItems}
      itemName="organizationName"
      hideLeftIcon={true}
      id="organizationName"
      subItemName="locationName"
      parentIndex={parentIndex}
      setParentIndex={handleSetParentIndex}
      onSubItemClick={onLocationClick}
      currentOrganizationIndex={currentOrganizationIndex}
      currentLocationIndex={currentLocationIndex}
    />
  );
};

export default Menu;
