import axios from "axios";
import { makeRequest } from "../helpers/global.helper";
const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;
const organizationsApi = process.env.REACT_APP_BIDB_API_ORGANIZATIONS;
// Gets organization by Id
export const getOrganizationById =
  (id: number) => async (dispatch: any, getState: any) => {
    return await dispatch(
      makeRequest({
        endpoint: `${organizationsApi}/${id}`,
        method: "GET"
      })
    );
  };
