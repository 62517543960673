import React from "react";
import {
  IonMenu,
  IonIcon,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonMenuButton
} from "@ionic/react";
import { useAppSelector } from "../../../store/Hooks";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import classNames from "classnames";
import SideMenuContent from "./SideMenuContent";
import CollapsedSideMenuContent from "../CollapsedSideMenuContent";
import { getEnvironments } from "../../../store/evaluation/Current";

const SideMenu: React.FC<any> = (props: any) => {
  const { getRecommendationReport } = props;
  const environments = useAppSelector(getEnvironments);

  const {
    idEvaluation,
    collapseMenu,
    collapsedMenu,
    isOpenTopNavigation,
    setShowModal,
    currentSelectedIndex,
    setCurrentSelectedIndex
  } = props;

  return (
    <IonMenu
      id="evaluationSideMenu"
      side="start"
      className={classNames("ion-text-center y-side-menu", {
        absolute: isOpenTopNavigation,
        "top-30": isOpenTopNavigation,
        "collapsed-menu": collapsedMenu
      })}
      menuId="main-menu"
      contentId="main"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonMenuButton
              menu="main-menu"
              autoHide={false}
              onClick={() => collapseMenu(collapsedMenu)}
            >
              <IonIcon
                icon={
                  !collapsedMenu ? chevronBackOutline : chevronForwardOutline
                }
              ></IonIcon>
            </IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!collapsedMenu ? (
          <SideMenuContent
            idEvaluation={idEvaluation}
            environments={environments}
            setShowModal={setShowModal}
            getRecommendationReport={getRecommendationReport}
            currentSelectedIndex={currentSelectedIndex}
            setCurrentSelectedIndex={setCurrentSelectedIndex}
          />
        ) : (
          <CollapsedSideMenuContent />
        )}
      </IonContent>
    </IonMenu>
  );
};

export default SideMenu;
