import React from "react";
import { IonImg } from "@ionic/react";

import { iconImages } from "../../../services/tabs.service";

const ImageBox: React.FC<any> = (props: any) => {
  const { item, category, closePopover } = props;
  const pathImage = `${process.env.REACT_APP_BIDB_IMAGE_ROOT_PATH}${process.env.REACT_APP_BIDB_WORKSTATION_IMAGE_PATH}`;

  return (
    <div className="image-box">
      <IonImg
        src={item.imageUrl && `${pathImage}/${category}/${item.imageUrl}`}
        className="image"
      ></IonImg>
      <div
        className="rounded-27 border-solid cursor-pointer object-fit absolute z-1 right-4 top-4 bg-white"
        onClick={() => closePopover(false)}
      >
        <IonImg
          className="h-15 w-15 "
          src={iconImages["unselectIcon"]}
        ></IonImg>
      </div>
    </div>
  );
};

export default ImageBox;
