import React, { useEffect, useState } from "react";
import { IonIcon, IonLabel } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

import { findIndex, isObject } from "lodash";
import classNames from "classnames";

import "../../../css/select-form.css";

import Select from "react-select";

const SelectForm: React.FC<any> = (props: any) => {
  const {
    options,
    value,
    name,
    setValue,
    onChange,
    className,
    selectedText,
    placeholder,
    clearValue,
    isDanger
  } = props;

  const [showClearButton, setShowClearButton] = useState<boolean | null>(false);
  const [tbd, setTbd] = useState<string>("TBD");

  useEffect(() => {
    if (clearValue && isValidValue(value) && isPresentValue(value)) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  }, [clearValue]);

  /**
   * Handles onChange
   */
  const handleOnChange = (e: any) => {
    const onChangeMethod = setValue ? setValue : onChange;

    if (onChangeMethod) {
      onChangeMethod(e.target.name, e.target.value);
    }
  };

  const isPresentValue = (value: any) => {
    // Checking is the array is a single array or an array of objects
    let isSingleArray: boolean = true;

    for (const [, o] of options.entries()) {
      if (isObject(o)) {
        isSingleArray = false;
        break;
      }
    }

    let index: number = -1;

    if (isSingleArray) {
      index = options.indexOf(value);
    } else {
      index = findIndex(options, { id: value });
    }

    if (index >= 0) {
      return true;
    }

    return false;
  };

  const isValidValue = (value: any) => {
    if (value) {
      return true;
    }

    return false;
  };

  const validOptions = (options: any) => {
    return options.map((option: any) => {
      if (option.id) {
        return {
          value: option.id,
          label: option.label
        };
      } else {
        return {
          value: option,
          label: option
        };
      }
    });
  };

  const selectStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    control: (styles: any) => ({
      ...styles,
      width: "100%",
      borderStyle: "none",
      minHeight: "30px",
      boxShadow: null,
      backgroundColor: "white"
    }),
    singleValue: (provided: any) => ({
      ...provided,
      height: "100%",
      color: "black",
      paddingTop: "3px"
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isDisabled
        ? undefined
        : state.isSelected
        ? "#f2f2f2"
        : state.isFocused
        ? "#f2f2f2"
        : undefined,
      color: state.isDisabled ? "#CCC" : state.isSelected ? "black" : "#293a3e",
      cursor: state.isDisabled ? "not-allowed" : "default",
      borderBottom: "1px solid #E8E8E8",
      background: state.isSelected ? "#E0E0E0" : null,
      "&:hover": {
        background: state.isFocused ? "#f2f2f2" : null
      },
      position: "relative"
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 50
    })
  };

  const validValue = (value: any): any => {
    if (value !== undefined) {
      if (typeof value === "string") {
        return {
          value: value,
          label: value
        };
      }

      if (value === null) {
        value = "";
      }

      return {
        value: value,
        label: selectedText ? selectedText : ""
      };
    }
  };

  return (
    <div
      className={classNames(
        `${
          !value
            ? isDanger
              ? "select-box-danger"
              : "select-box"
            : value === tbd
            ? "select-box-danger"
            : "select-box"
        } relative`,
        {
          [className]: className ? true : false
        }
      )}
      style={{ paddingRight: showClearButton ? "33px" : "10px" }}
    >
      <IonLabel
        className={`matching-element-label ${
          isDanger ? "label-danger" : "label-text"
        }`}
      >
        {props.label}
      </IonLabel>
      <Select
        className="select"
        value={value !== undefined && validValue(value)}
        name={name}
        options={options && validOptions(options)}
        placeholder={placeholder}
        styles={selectStyles}
        onChange={(e: any) => {
          const event: any = {
            target: {
              name: name,
              value: e ? e.value : null
            }
          };
          handleOnChange(event);
        }}
      />

      {showClearButton && (
        <IonIcon
          style={{
            right: showClearButton ? "8px" : 0
          }}
          className="cursor-pointer clear-button"
          onClick={clearValue}
          icon={closeCircleOutline}
        ></IonIcon>
      )}
    </div>
  );
};

export default SelectForm;
