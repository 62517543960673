import React from "react";

import AddRow from "../../../../common/controls/AddRow";
import AddButton from "../../../../common/controls/AddButton";

const TopBar: React.FC<any> = (props: any) => {
  const { onClickAddResource } = props;

  return (
    <AddRow
      label="Recommendations"
      buttons={
        <AddButton label="ADD RECOMMENDATIONS" onClick={onClickAddResource} />
      }
    />
  );
};

export default TopBar;
