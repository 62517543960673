import React, { useState } from "react";
import { IonCol, IonItem, IonRow, IonButton, IonIcon } from "@ionic/react";
import { addSharp } from "ionicons/icons";

import { AddEnvironmentModal } from "./AddEnvironmentModal";
import Checkbox from "./tabs/Checkbox";

const EnvironmentCheckboxes: React.FC<any> = (props) => {
  const {
    environments,
    environmentsChecked,
    onChangeEnvironment,
    idEnvironmentBlocked,
    showAddButton,
    getCurrentShowCheckboxLoader,
    showCheckboxLoaderUpdated
  } = props;
  const [openEnvironmentForm, setOpenEnvironmentForm] = useState(false);

  const handleOpenEnvironmentForm = () => {
    const value = !openEnvironmentForm;
    setOpenEnvironmentForm(value);
  };

  const handleOnChange = (environmentId: number, checked: boolean) => {
    onChangeEnvironment(environmentId, checked);
  };

  return (
    <>
      {showAddButton && (
        <IonRow className="top-1">
          <IonCol size="6" className="bold to-left top-1">
            Environment
          </IonCol>
          <IonCol size="6" className="to-right top-1 text-regular-size bold">
            <IonButton
              fill="clear"
              className="ion-float-right"
              onClick={() => handleOpenEnvironmentForm()}
            >
              <IonIcon src={addSharp}></IonIcon>&nbsp;ADD ENVIRONMENT
            </IonButton>
            <AddEnvironmentModal
              showModal={openEnvironmentForm}
              setShowModal={handleOpenEnvironmentForm}
            />
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        {environments !== undefined &&
          environments.map((environment: any, index: number) => {
            return (
              <IonCol
                size-xxl="3"
                size-xl="3"
                size-lg="3"
                size-md="6"
                size-sm="6"
                size-sx="12"
                size="12"
                className="aligned-middle to-left"
                key={environment.id}
              >
                <IonItem
                  className="ion-no-padding comboBoxCss text-12"
                  key={index}
                  onKeyPress={(e: any) => {
                    if (e.code === "Enter") {
                      e.target.children[0].checked = !(
                        e.target.children[0].value === "on"
                      );
                      e.target.children[0].click();
                      e.stopPropagation();
                    }
                  }}
                >
                  <Checkbox
                    index={index}
                    environmentId={environment.id}
                    label={`${environment.type}:${environment.name}`}
                    idEnvironmentBlocked={idEnvironmentBlocked}
                    environmentsChecked={environmentsChecked}
                    onChange={(environmentId: number, value: boolean) =>
                      handleOnChange(environmentId, value)
                    }
                    getCurrentShowCheckboxLoader={getCurrentShowCheckboxLoader}
                    showCheckboxLoaderUpdated={showCheckboxLoaderUpdated}
                  />
                </IonItem>
              </IonCol>
            );
          })}
      </IonRow>
    </>
  );
};

export default EnvironmentCheckboxes;
