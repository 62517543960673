import React from "react";
import { IonItem, IonCol } from "@ionic/react";

import _ from "lodash";

import "../../../css/dashboard.css";
import { getSortedColumnClass } from "../../../helpers/global.helper";

const TableHeader = (props: any) => {
  const { columns, handleSort, rows, sort } = props;

  const getSelectedSortColumnClass = (column: string, direction: string) => {
    return getSortedColumnClass(column, direction, sort);
  };

  return (
    <IonItem
      className={`ion-justify-content-center ${
        rows.length > 0 ? "right-3" : ""
      }`}
      lines="none"
    >
      {columns.map((column: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <IonCol
              size={column.size}
              className={`${index == 0 && rows.length > 0 ? "left-4" : ""}`}
            >
              <div className="filter-text-wrapper">
                <div className="filter-text">{column.name}</div>
                <div className="filter-text-icons">
                  <button
                    className={`icon-button ${getSelectedSortColumnClass(
                      column.sort,
                      "asc"
                    )}`}
                    onClick={() => {
                      if (rows.length > 0 && column.sort != "") {
                        handleSort(column.sort, "asc");
                      }
                    }}
                  >
                    ▲
                  </button>
                  <button
                    className={`icon-button ${getSelectedSortColumnClass(
                      column.sort,
                      "desc"
                    )}`}
                    onClick={() => {
                      if (rows.length > 0 && column.sort != "") {
                        handleSort(column.sort, "desc");
                      }
                    }}
                  >
                    ▼
                  </button>
                </div>
              </div>
            </IonCol>
          </React.Fragment>
        );
      })}
    </IonItem>
  );
};

export default TableHeader;
