import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import _ from "lodash";

import IssuesTab from "./issues/IssuesTab";

import "react-tabs/style/react-tabs.css";
import "../../../../css/environment-tabs.css";
import ActivitiesTab from "./activities/ActivitiesTab";
import RisksTab from "./risks/RisksTab";
import RecommendationsTab from "./recommendations/RecommendationsTab";

import { useAppDispatch, useAppSelector } from "../../../../store/Hooks";

import EquipmentTab from "./equipment/EquipmentTab";

import { apiCall } from "../../../../store/api";
import FurnitureTab from "./furnitures/FurnitureTab";
import {
  showFurnituresSkeletonUpdated,
  sortEnvironmentFurnitures
} from "../../../../store/evaluation/environment/workstation/Furnitures";
import {
  showEquipmentsSkeletonUpdated,
  sortEnvironmentEquipments
} from "../../../../store/evaluation/environment/workstation/Equipments";
import {
  getCurrentEvaluation,
  getEnvironments,
  getEnvironmentSelectedTabIndex,
  setEnvironmentTabIndex,
  showEnvironmentLoaderUpdated
} from "../../../../store/evaluation/Current";
import {
  insertResourceProcess,
  makeRequest
} from "../../../../helpers/global.helper";
import { getEvaluationIssues } from "../../../../store/evaluation/Issues";
import { getActivities } from "../../../../store/evaluation/Activities";
import { getEvaluationRisks } from "../../../../store/evaluation/Risks";
import { getRecommendations } from "../../../../store/evaluation/Recommendations";
import {
  environmentIssuesReceived,
  getCurrentEnvironmentIssues,
  updateTotalEnvironmentIssues
} from "../../../../store/evaluation/environment/Issues";
import {
  listReceived,
  getCurrentActivityEnvironments,
  updateTotalEnvironmentActivities
} from "../../../../store/evaluation/environment/Activities";
import {
  getCurrentEnvironmentRecommendation,
  getRecommendationsIssues,
  recommendationsIssuesUpdated,
  getCurrentEnvironmentRecommendations,
  updateTotalEnvironmentRecommendations,
  loadEnvironmentRecommendations
} from "../../../../store/evaluation/environment/Recommendations";
import {
  environmentRisksReceived,
  updateTotalEnvironmentRisks,
  getEnvironmentRisks
} from "../../../../store/evaluation/environment/Risks";
import IssueSelector from "./issues/IssueSelector";
import { getRiskVs } from "../../../../store/catalogs/Risks";
import useLongPress from "../../../../helpers/useLongPress";
import {
  getShowAddWorkstationEquipmentModal,
  setShowAddWorkstationEquipmentModal
} from "../../../../store/evaluation/environment/workstation/Equipments";
import {
  getShowAddWorkstationFurnitureModal,
  setShowAddWorkstationFurnitureModal
} from "../../../../store/evaluation/environment/workstation/Furnitures";
import AddEquipmentModal from "../../../../components/evaluation/environment/tabs/equipment/AddEquipmentModal";
import { equipmentsUpdated } from "../../../../store/catalogs/Equipments";
import AddFurnitureModal from "../../../../components/evaluation/environment/tabs/furnitures/AddFurnitureModal";
import { furnituresUpdated } from "../../../../store/catalogs/Furnitures";
import AddRiskModal from "../../../../components/evaluation/environment/tabs/risks/AddRiskModal";
import {
  setShowAddRiskModal,
  getShowAddRiskModal
} from "../../../../store/evaluation/environment/Risks";
import AddRecommendationModal from "../../../../components/evaluation/environment/tabs/recommendations/AddRecommendationModal";
import {
  getShowAddRecommendationModal,
  showAddRecommendationModalSet
} from "../../../../store/evaluation/environment/Recommendations";

const EnvironmentTabs: React.FC<any> = (props) => {
  const {
    environment,
    idEvaluation,
    updateEnvironmentsSideMenu,
    currentEnvironment,
    setShowEnvironmentModal
  } = props;

  const dispatch = useAppDispatch();
  const selectedTabIndex = useAppSelector(getEnvironmentSelectedTabIndex);
  const currentEnvironmentRecommendation = useAppSelector(
    getCurrentEnvironmentRecommendation
  );
  const recommendationsIssues = useAppSelector(getRecommendationsIssues);
  const evaluationEnvironments = useAppSelector(getEnvironments);

  const style = "__tab";
  const [priority, setPriority] = useState([]);
  const [priorityValue, setPriorityValue] = useState("");

  const [actionItem, setActionItem] = useState([]);
  const [actionItemValue, setActionItemValue] = useState("");

  const [responsibleParty, setResponsibleParty] = useState([]);
  const [responsiblePartyValue, setResponsiblePartyValue] = useState("");
  const [recommendationIssues, setRecommendationIssues] = useState([]);
  const [recommendationEnvironment, setRecommendationEnvironment] = useState(
    []
  );
  const [recommendationRisks, setRecommendationRisks] = useState([]);
  const [tooFarBtnSelected, setTooFarBtnSelected] = useState<any>(0);
  const [tooCloseBtnSelected, setTooCloseBtnSelected] = useState<any>(0);
  const [offCenterBtnSelected, setOffCenterBtnSelected] = useState<any>(0);
  const [arrangedTooWideBtnSelected, setArrangedTooWideBtnSelected] =
    useState<any>(0);
  const [notOptimallyArrangedBtnSelected, setNotOptimallyArrangedBtnSelected] =
    useState<any>(0);

  const [evaluationsRecommendations, setEvaluationsRecommendations] = useState(
    []
  );
  const [openIssueSelector, setOpenIssueSelector] = useState<boolean>(false);
  const ngr: any = navigator;
  const [isTouch] = useState<boolean>(
    "ontouchstart" in window || ngr.msMaxTouchPoints > 0
  );

  const riskVs = useAppSelector(getRiskVs);

  const currentEvaluationIssues = useAppSelector(getEvaluationIssues);
  const currentEnvironmentIssues = useAppSelector(getCurrentEnvironmentIssues);

  const currentEvaluationActivities = useAppSelector(getActivities);
  const currentEnvironmentActivities = useAppSelector(
    getCurrentActivityEnvironments
  );

  const currentEvaluationRisks = useAppSelector(getEvaluationRisks);
  const currentEnvironmentRisks = useAppSelector(getEnvironmentRisks);

  const currentEvaluationRecommendations = useAppSelector(getRecommendations);
  const currentEnvironmentRecommendations = useAppSelector(
    getCurrentEnvironmentRecommendations
  );

  const currentEvaluation = useAppSelector(getCurrentEvaluation);

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const openWorkstationEquipmentModal: any = useAppSelector(
    getShowAddWorkstationEquipmentModal
  );

  const openWorkstationFurnitureModal = useAppSelector(
    getShowAddWorkstationFurnitureModal
  );

  const showAddRiskModal = useAppSelector(getShowAddRiskModal);

  const showAddRecommendationModal = useAppSelector(
    getShowAddRecommendationModal
  );

  const getEnvironmentIssues = (id: any) => {
    dispatch(
      apiCall({
        url: `/environments/${id}/issues`,
        onSuccess: environmentIssuesReceived.type
      })
    );
  };

  const getEnvironmentActivities = (id: number) => {
    dispatch(
      apiCall({
        url: `/environments/${id}/activities`,
        onSuccess: listReceived.type
      })
    );
  };

  const getWorkstationEquipments = async (id: number) => {
    const rows = await dispatch(
      makeRequest({
        endpoint: `/environments/${id}/workstationequipments`,
        method: "GET"
      })
    );

    dispatch(sortEnvironmentEquipments(rows.data));

    dispatch({
      type: showEquipmentsSkeletonUpdated.type,
      payload: false
    });
  };

  const getWorkstationFurnitures = async (id: number) => {
    const rows = await dispatch(
      makeRequest({
        endpoint: `/environments/${id}/workstationfurnitures`,
        method: "GET"
      })
    );

    dispatch(sortEnvironmentFurnitures(rows.data));

    dispatch({
      type: showFurnituresSkeletonUpdated.type,
      payload: false
    });
  };

  const setEnvironmentRisks = (id: number) => {
    dispatch(
      apiCall({
        url: `/environments/${id}/risks`,
        onSuccess: environmentRisksReceived.type
      })
    );
  };

  const getEnvironmentRecommendations = (id: number) => {
    dispatch(loadEnvironmentRecommendations(id));
  };

  useEffect(() => {
    if (currentEvaluation) {
      getEnvironmentIssues(currentEnvironment && currentEnvironment.id);
      getEnvironmentActivities(currentEnvironment && currentEnvironment.id);
      getWorkstationEquipments(currentEnvironment && currentEnvironment.id);
      getWorkstationFurnitures(currentEnvironment && currentEnvironment.id);
      setEnvironmentRisks(currentEnvironment && currentEnvironment.id);
      getEnvironmentRecommendations(
        currentEnvironment && currentEnvironment.id
      );

      setTimeout(() => {
        dispatch({
          type: showEnvironmentLoaderUpdated.type,
          payload: false
        });
      }, 1000);
    }
  }, [currentEnvironment && currentEnvironment.id]);

  const setTabIndex = (tabIndex: number) => {
    dispatch(setEnvironmentTabIndex(tabIndex));
  };

  const handleOpenIssuePicker = () => {
    setOpenIssueSelector(!openIssueSelector);
  };

  const handleAttachRecommendationIssues = async (selectedItems: any) => {
    setShowLoader(true);

    const rows: any = await dispatch(
      insertResourceProcess(
        selectedItems,
        ["idIssue", "idRecommendation"],
        {
          idIssue: {
            key: "idIssue",
            keyValue: "id"
          },
          idRecommendation: {
            key: "idRecommendation",
            value:
              currentEnvironmentRecommendation &&
              currentEnvironmentRecommendation.id
          }
        },
        "/issuerecommendations"
      )
    );

    let temp: any = [...recommendationsIssues, ...rows];

    dispatch({
      type: recommendationsIssuesUpdated.type,
      payload: temp
    });

    setShowLoader(false);
    handleOpenIssuePicker();
  };

  useEffect(() => {
    dispatch(updateTotalEnvironmentIssues(currentEvaluationIssues));
  }, [currentEnvironmentIssues && currentEnvironmentIssues.length]);

  useEffect(() => {
    dispatch(updateTotalEnvironmentActivities(currentEvaluationActivities));
  }, [currentEnvironmentActivities && currentEnvironmentActivities.length]);

  useEffect(() => {
    dispatch(updateTotalEnvironmentRisks(currentEvaluationRisks));
  }, [currentEnvironmentRisks && currentEnvironmentRisks.length]);

  useEffect(() => {
    dispatch(
      updateTotalEnvironmentRecommendations(currentEvaluationRecommendations)
    );
  }, [
    currentEnvironmentRecommendations &&
      currentEnvironmentRecommendations.length
  ]);

  const getRiskvsNameById = (id: any) => {
    const row = _.find(riskVs, { id: id });
    return row ? row.name : "";
  };

  // start catch press long
  const onLongPress = (e: any, index: number) => {
    switch (index) {
      case 2:
        dispatch({
          type: setShowAddWorkstationEquipmentModal.type,
          payload: true
        });
        break;
      case 3:
        dispatch({
          type: setShowAddWorkstationFurnitureModal.type,
          payload: true
        });
        break;
      case 4:
        dispatch({
          type: setShowAddRiskModal.type,
          payload: true
        });
        break;
      case 5:
        dispatch({
          type: showAddRecommendationModalSet.type,
          payload: true
        });
        break;
      default:
        console.log(index);
        break;
    }
  };
  const pressOnClick = (e: any, index: number) => {
    setTabIndex(index);
  };
  const longPressEquipment = useLongPress(
    {
      onLongPress,
      pressOnClick
    },
    {
      delay: 300,
      shouldPreventDefault: true,
      index: 2
    }
  );

  const longPressFurniture = useLongPress(
    {
      onLongPress,
      pressOnClick
    },
    {
      delay: 300,
      shouldPreventDefault: true,
      index: 3
    }
  );

  const longPressRisk = useLongPress(
    {
      onLongPress,
      pressOnClick
    },
    {
      delay: 300,
      shouldPreventDefault: true,
      index: 4
    }
  );

  const longPressRecommendations = useLongPress(
    {
      onLongPress,
      pressOnClick
    },
    {
      delay: 300,
      shouldPreventDefault: true,
      index: 5
    }
  );
  // end catch press long

  const resetList = (list: any) => {
    setTimeout(() => {
      dispatch({
        type: equipmentsUpdated.type,
        payload: list
      });
    }, 350);
  };

  const resetListFurniture = (list: any) => {
    setTimeout(() => {
      dispatch({
        type: furnituresUpdated.type,
        payload: list
      });
    }, 350);
  };

  const closeModalRisk = () => {
    dispatch({
      type: setShowAddRiskModal.type,
      payload: false
    });
  };

  return (
    <>
      <AddEquipmentModal
        isOpen={openWorkstationEquipmentModal}
        resetList={resetList}
      />
      <AddFurnitureModal
        isOpen={openWorkstationFurnitureModal}
        resetList={resetListFurniture}
      />
      <AddRiskModal
        isOpen={showAddRiskModal}
        closeModal={closeModalRisk}
        isPicker={false}
      />
      <AddRecommendationModal
        isOpen={showAddRecommendationModal}
        isPicker={false}
        isAttach={false}
      />
      {openIssueSelector && (
        <IssueSelector
          openIssueSelector={openIssueSelector}
          setOpenIssueSelector={setOpenIssueSelector}
          attachIssues={handleAttachRecommendationIssues}
          showLoader={showLoader}
        />
      )}
      <Tabs
        selectedIndex={selectedTabIndex ? selectedTabIndex : 0}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          <Tab className={style}>Issues</Tab>
          <Tab className={style}>Activities</Tab>
          <Tab className={style} {...longPressEquipment}>
            Current Equipment
          </Tab>
          <Tab className={style} {...longPressFurniture}>
            Current Furniture
          </Tab>
          <Tab className={style} {...longPressRisk}>
            Risks
          </Tab>
          <Tab className={style} {...longPressRecommendations}>
            Recommendations
          </Tab>
        </TabList>
        <TabPanel id="issues">
          <IssuesTab
            updateEnvironmentsSideMenu={updateEnvironmentsSideMenu}
            setShowEnvironmentModal={setShowEnvironmentModal}
          />
        </TabPanel>
        <TabPanel id="activitiesTab">
          <ActivitiesTab
            updateEnvironmentsSideMenu={updateEnvironmentsSideMenu}
            getRiskvsName={getRiskvsNameById}
          />
        </TabPanel>
        <TabPanel id="equipment" className="y-custom-parent">
          <EquipmentTab id="equipmentd" />
        </TabPanel>
        <TabPanel id="furniture">
          <FurnitureTab isTouch={isTouch} />
        </TabPanel>
        <TabPanel id="risks">
          <RisksTab
            getRiskvsName={getRiskvsNameById}
            setShowEnvironmentModal={setShowEnvironmentModal}
          />
        </TabPanel>
        <TabPanel id="recommendations">
          <RecommendationsTab
            idEvaluation={idEvaluation}
            idEnvironment={environment?.id}
            priority={priority}
            setPriority={setPriority}
            actionItem={actionItem}
            evaluationsRecommendations={evaluationsRecommendations}
            setEvaluationsRecommendations={setEvaluationsRecommendations}
            setActionItem={setActionItem}
            priorityValue={priorityValue}
            setPriorityValue={setPriorityValue}
            actionItemValue={actionItemValue}
            setActionItemValue={setActionItemValue}
            responsibleParty={responsibleParty}
            setResponsibleParty={setResponsibleParty}
            setNewReasonsFilter={setNewReasonsFilter}
            responsiblePartyValue={responsiblePartyValue}
            tooFarBtnSelected={tooFarBtnSelected}
            setTooFarBtnSelected={setTooFarBtnSelected}
            tooCloseBtnSelected={tooCloseBtnSelected}
            setTooCloseBtnSelected={setTooCloseBtnSelected}
            offCenterBtnSelected={offCenterBtnSelected}
            arrangedTooWideBtnSelected={arrangedTooWideBtnSelected}
            notOptimallyArrangedBtnSelected={notOptimallyArrangedBtnSelected}
            setOffCenterBtnSelected={setOffCenterBtnSelected}
            setResponsiblePartyValue={setResponsiblePartyValue}
            setArrangedTooWideBtnSelected={setArrangedTooWideBtnSelected}
            recommendationIssues={recommendationIssues}
            recommendationRisks={recommendationRisks}
            recommendationEnvironment={recommendationEnvironment}
            setNotOptimallyArrangedBtnSelected={
              setNotOptimallyArrangedBtnSelected
            }
            handleOpenIssuePicker={handleOpenIssuePicker}
            environments={evaluationEnvironments}
            setShowEnvironmentModal={setShowEnvironmentModal}
            getRiskvsName={getRiskvsNameById}
          />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default EnvironmentTabs;
function setActionItem(actionItem: any) {
  throw new Error("Function not implemented.");
}

function setResponsibleParty(responsibleParty: any) {
  throw new Error("Function not implemented.");
}

function setNewReasonsFilter(newReasonsFilter: any) {
  throw new Error("Function not implemented.");
}
