import React, { useEffect, useState } from "react";
import { IonCheckbox, IonLabel } from "@ionic/react";

import { useAppSelector } from "../../../../../store/Hooks";

const Checkbox: React.FC<any> = (props: any) => {
  const { name, label, onChange, getCurrentWorkstation } = props;
  const currentWorstation: any = useAppSelector(getCurrentWorkstation);
  const [value, setValue] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setValue(currentWorstation && currentWorstation[name]);
  }, [currentWorstation && currentWorstation.id]);

  return (
    <div className={`flex ion-align-items-center ml-10 mr-10`}>
      <IonCheckbox
        name={name}
        checked={value}
        onClick={(e: any) => {
          setValue(e.target.checked);
          onChange(e.target.name, e.target.checked);
        }}
        mode="md"
      ></IonCheckbox>
      <IonLabel>&nbsp;{label}</IonLabel>
    </div>
  );
};

export default Checkbox;
