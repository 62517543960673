import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IonRow,
  IonCol,
  IonImg,
  IonInput,
  IonLabel,
  IonModal,
  IonButton,
  IonContent
} from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../store/Hooks";

import {
  getCurrentEvaluation,
  environmentsUpdated,
  getEnvironments,
  setEnvironment,
  setEnvironmentTabIndex
} from "../../../store/evaluation/Current";
import "../../../css/modal-equipment.css";

import Checkbox from "../../common/controls/Checkbox";
import SelectForm from "../../../components/common/controls/SelectForm";

import { iconImages } from "../../../services/tabs.service";
import { getListByName } from "../../../store/catalogs/ValueList";
import { saveEnvironment } from "../../../services/evaluation.service";
import Loader from "../../common/Loader";
import { selectedIdEnvironmentUpdated } from "../../../store/evaluation/environment/workstation/Equipments";

const defaultValues: any = {
  type: null,
  name: null,
  comment: null,
  spaceType: null,
  assignmentType: null,
  percentTimeHereWeekly: null,
  sharedSpace: null,
  plannedSpace: null
};

export const AddEnvironmentModal: React.FC<any> = (props: any) => {
  const {
    showModal,
    setShowModal,
    onDidDismiss,
    currentSelectedIndex,
    setCurrentSelectedIndex
  } = props;

  const [data, setData] = useState<any>();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentEvaluation = useAppSelector(getCurrentEvaluation);
  const currentEnvironments = useAppSelector(getEnvironments);
  const environmentTypes = dispatch(getListByName("Environment_Type"));
  const timeHereWeekly = dispatch(
    getListByName("Environment_Time_Here_Weekly")
  );
  const spaceTypes = dispatch(getListByName("Environment_Space_Type"));
  const officeSpaceTypes = dispatch(
    getListByName("Environment_Office_Space_Type")
  );
  const assignmentTypes = dispatch(
    getListByName("Environment_Assignment_Type")
  );
  const roomTypes = dispatch(getListByName("Environment_Room_Type"));

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const handleChange = (key: string, value: any) => {
    if (value !== undefined && data[key] != value) {
      let currentData: any = {
        [key]: value
      };

      if (key === "spaceType") {
        if (data["name"] !== value) {
          currentData = {
            ...currentData,
            name: value
          };
        }
      }

      if (key === "type") {
        currentData = {
          ...currentData,
          spaceType: null,
          name: ""
        };
      }

      setData({
        ...data,
        ...currentData
      });
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setShowLoader(true);

    const response: any = await dispatch(
      saveEnvironment(currentEvaluation && currentEvaluation.id, data)
    );

    if (response.status === 200) {
      let dataTemp = { ...data };
      dataTemp.id = response.data.successes[0].id;
      let temp = [...currentEnvironments];
      temp.push(dataTemp);

      await dispatch({
        type: environmentsUpdated.type,
        payload: temp
      });

      const index = temp.findIndex(t => t.id === dataTemp.id);

      setData(defaultValues);
      setCurrentNewEnvironment(dataTemp, index);
      setShowLoader(false);
      showModal && setShowModal(false);
    }
  };

  const setCurrentNewEnvironment = (environment: any, index: number) => {
    dispatch({
      type: selectedIdEnvironmentUpdated.type,
      payload: environment.id
    });

    dispatch({
      type: setEnvironment.type,
      payload: environment
    });

    dispatch(setEnvironmentTabIndex(0));
    setCurrentSelectedIndex(index);
    goToEvaluationEnvironment();
  };

  const goToEvaluationEnvironment = () => {
    history.push(`/evaluation/${currentEvaluation.id}/environment`);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      setData(defaultValues);
    }
  }, [showModal]);

  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={() => (onDidDismiss ? onDidDismiss() : null)}
      cssClass="size-modal-2"
      swipeToClose={true}
      backdropDismiss={false}
    >
      <Loader visible={showLoader} />
      <IonRow className="px-24px header-modal">
        <IonCol
          size-xxl="11"
          size-xl="11"
          size-lg="11"
          size-md="11"
          size-sm="11"
          size-sx="11"
          size="11"
        >
          <p className="title-modal">Add Environment</p>
        </IonCol>

        <IonCol
          size-xxl="1"
          size-xl="1"
          size-lg="1"
          size-md="1"
          size-sm="1"
          size-sx="1"
          size="1"
          className="flex ion-align-items-center ion-justify-content-end"
        >
          <IonImg
            className="cursor-pointer"
            onClick={() => handleClose()}
            src={iconImages["closeIcon"]}
          ></IonImg>
        </IonCol>
      </IonRow>

      <IonContent>
        <form onSubmit={handleSubmit}>
          <IonRow className="px-24px top-2">
            <IonCol size="6">
              <SelectForm
                label="Environment Type"
                name="type"
                options={environmentTypes}
                value={data && data.type !== null && data.type}
                onChange={(key: string, value: string) => {
                  if (value !== null) {
                    handleChange(key, value);
                  }
                }}
                clearValue={() => handleChange("type", null)}
              />
            </IonCol>
            <IonCol size="6">
              <SelectForm
                label="Time here weekly"
                name="percentTimeHereWeekly"
                options={timeHereWeekly}
                value={
                  data && data.percentTimeHereWeekly * 100 > 0
                    ? (data.percentTimeHereWeekly * 100).toString()
                    : ""
                }
                onChange={(key: string, value: any) =>
                  handleChange(key, parseInt(value) / 100)
                }
                clearValue={() => handleChange("percentTimeHereWeekly", null)}
              />
            </IonCol>
          </IonRow>
          <IonRow className="px-24px top-2">
            <IonCol size="6">
              <SelectForm
                label="Space Type"
                name="spaceType"
                options={
                  data && data.type && data.type.toLowerCase() !== "office"
                    ? spaceTypes
                    : officeSpaceTypes
                }
                value={data && data.spaceType !== null && data.spaceType}
                onChange={(key: string, value: string) => {
                  if (value && value !== null) {
                    handleChange(key, value);
                  }
                }}
                clearValue={() => handleChange("spaceType", null)}
              />
            </IonCol>
            <IonCol size="6">
              {data &&
                data.type === "Office" &&
                data.spaceType !== null &&
                data.spaceType &&
                data.spaceType !== "Meeting Room" && (
                  <SelectForm
                    label="Assignment Type"
                    name="assignmentType"
                    options={assignmentTypes}
                    value={data && data.assignmentType}
                    onChange={handleChange}
                    clearValue={() => handleChange("assignmentType", null)}
                  />
                )}
              {data && data.type === "Home" && (
                <IonRow>
                  <IonCol>
                    <div className="float-start mt-20">
                      <Checkbox
                        name="sharedSpace"
                        label="Shared space"
                        value={data && data.sharedSpace}
                        onChange={handleChange}
                      />
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className="float-end mt-20">
                      <Checkbox
                        name="plannedSpace"
                        label="Planned space"
                        value={data && data.plannedSpace}
                        onChange={handleChange}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              )}
              {data &&
                data.type === "Office" &&
                data.spaceType === "Meeting Room" && (
                  <SelectForm
                    label="Room Type"
                    name="roomType"
                    options={roomTypes}
                    value={data.roomType}
                    onChange={handleChange}
                    clearValue={() => handleChange("roomType", null)}
                  />
                )}
            </IonCol>
          </IonRow>
          <IonRow className="px-24px top-2">
            <IonCol size="6">
              <div className="form-group custom-input">
                <IonLabel className="form-group-input-text-title">
                  Name
                </IonLabel>
                <IonInput
                  type="text"
                  name="name"
                  onIonBlur={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className="form-control input-lg form-group-input-text text-left"
                  required
                  value={data && data.name}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="px-24px top-2">
            <IonCol size="12">
              <div className="form-group custom-input">
                <IonLabel className="form-group-input-text-title">
                  Comment
                </IonLabel>
                <IonInput
                  type="text"
                  name="comment"
                  onIonBlur={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className="form-control input-lg form-group-input-text text-left"
                />
              </div>
            </IonCol>
          </IonRow>
          <div className="px-24px content-btn-2 float-end py-1 absolute right-0 bottom-0">
            <IonButton
              onClick={() => handleCancel()}
              color="medium"
              className="font-bold"
            >
              Cancel
            </IonButton>
            <IonButton type="submit" className="ml-2 font-bold">
              Add Environment
            </IonButton>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default AddEnvironmentModal;
