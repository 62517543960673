import _ from "lodash";
import axios from "axios";
import Cookie from "js-cookie";
import USER from "../assets/user.png";
import MONITOR from "../assets/monitor.png";
import RED_CANCEL from "../assets/red_cancel.png";
import GREEN_CHECK from "../assets/green_check.png";
import RED_QUESTION from "../assets/red_question.png";
import { makeApiCall, makeRequest } from "../helpers/global.helper";

const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;
const apiEvaluations = process.env.REACT_APP_BIDB_API_EVALUATIONS;
const apiIssues = process.env.REACT_APP_BIDB_API_ISSUES;
const apiActivities = process.env.REACT_APP_BIDB_API_ACTIVITIES;
const apiRisks = process.env.REACT_APP_BIDB_API_RISKS;

let highPriorities: any = process.env.REACT_APP_BIDB_HIGH_PRIORITIES;
highPriorities = highPriorities.split(",");

const sortDefaultColumns: any[] = ["appointmentDate", "appointmentTime"];
const sortDefaultDirections: any[] = ["desc", "asc"];

export let sortDirections: any[] = [];
export let sortColumns: any[] = [];
export let user: any;

const highPrioritiesObject: any = {
  "worker’s comp": "W",
  accommodations: "A"
};

/**
 *
 * @param id
 * @param environmentData
 * @returns
 */

export const getEvaluation = async (id: number) => {
  const url = `${apiBaseUrl}${apiEvaluations}/${id}`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

export const getComments = async (id: number) => {
  const url = `${apiBaseUrl}${apiEvaluations}/${id}/comments`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

/**
 * Saves environment
 * @param id
 * @param environmentData
 * @returns
 */
export const saveEnvironment =
  (id: number, data: any) => async (dispatch: any, getState: any) => {
    try {
      const response = await dispatch(
        makeRequest({
          endpoint: `${apiEvaluations}/${id}/environments`,
          method: "POST",
          data
        })
      );

      return response;
    } catch (e) {
      console.log(e);
    }
  };

/**
 * Gets environments
 * @param id
 * @returns
 */
export const getEnvironments = async (id: number) => {
  const url = `${apiBaseUrl}${apiEvaluations}/${id}/environments`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

/**
 * Gets environment furnitures
 * @param id
 */
export const getEnvironmentFurnitures = async (id: number) => {
  const url = `${apiBaseUrl}/environments/${id}/workstationfurnitures`;
  const response: any = await axios.get(url);

  return response.data;
};

/**
 * Gets environment activities
 * @param id
 */
export const getEnvironmentActivities = async (id: number) => {
  const url = `${apiBaseUrl}/environments/${id}/activities`;
  const response: any = await axios.get(url);

  return response.data;
};

/**
 * Gets environment issues
 * @param id
 */
export const getIssueEnvironments = async (id: number) => {
  const url = `${apiBaseUrl}${apiIssues}/${id}/environments`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

export const getEvaluations = async (
  idEvaluator: number,
  search: string = "",
  dateObject: any = null
) => {
  let params: any = {
    active: true,
    limit: process.env.REACT_APP_BIDB_LIMIT,
    idEvaluator
  };

  const url = `${apiBaseUrl}${apiEvaluations}`;

  /**
   * If user is searching, need to make a call with corresponding query string parameters
   */
  if (search !== "") {
    params = { ...params, search };
    try {
      const response = await Promise.all([
        await axios.get(url, {
          params
        })
      ]);

      let data: any = response.map((response) => response.data);

      let rows: any = [];
      for (const [, object] of data.entries()) {
        if (object.rows) {
          rows = [].concat(object.rows);
        }
      }

      data.rows = rows;

      return data;
    } catch (e) {
      console.log(e);
    }
  }
  console.log(dateObject);
  if (dateObject) {
    // appointmentDateGte
    let response;

    if (dateObject.appointmentDateGte && dateObject.appointmentDateLte) {
      params = {
        ...params,
        appointmentDateGte: dateObject.appointmentDateGte,
        appointmentDateLte: dateObject.appointmentDateLte
      };
      response = await axios.get(url, {
        params
      });
    }

    if (dateObject.appointmentDateGte && !dateObject.appointmentDateLte) {
      params = {
        ...params,
        appointmentDateGte: dateObject.appointmentDateGte
      };
      response = await axios.get(url, {
        params
      });
    }

    // appointmentDateLte
    if (!dateObject.appointmentDateGte && dateObject.appointmentDateLte) {
      params = {
        ...params,
        appointmentDateLte: dateObject.appointmentDateLte
      };
      response = await axios.get(url, {
        params
      });
    }

    // appointmentDate
    if (dateObject.appointmentDate) {
      params = {
        ...params,
        appointmentDateGte: dateObject.appointmentDate
      };
      response = await axios.get(url, {
        params
      });
    }

    return response?.data;
  }

  const endpoint = `${apiEvaluations}`;

  const response: any = await makeApiCall(endpoint, "GET", {}, params);

  return response.data;
};

/**
 * Sorts column or multiple columns
 * @param column
 * @param direction
 */
export const sortTable = async (
  evaluations: any,
  column: any = "",
  direction: string = ""
) => {
  const rows = [...evaluations];
  const columnIndex: number = _.indexOf(sortColumns, column);
  let columns: any[] = [];
  let directions: any[] = [];

  // Clering sort without sorting. (User clicks on sort clear button with any sort applied)
  // If so, we don't need resorting the current rows
  if (columnIndex === -1 && column !== "" && direction === "") {
    if (sortColumns.length === 0 && sortDirections.length === 0) {
      return _.orderBy(rows, sortDefaultColumns, sortDefaultDirections);
    }
  }

  // This means removing the sorted column in question from array
  if (columnIndex !== -1 && column !== "" && direction === "") {
    let currentSortColumns = [...sortColumns];
    let currentSortDirections = [...sortDirections];
    delete currentSortColumns[columnIndex];
    delete currentSortDirections[columnIndex];

    let filteredSC: any[] = currentSortColumns.filter((el) => {
      return el !== null;
    });
    let filteredSD: any[] = currentSortDirections.filter((el) => {
      return el !== null;
    });

    if (filteredSC.length === 0 && filteredSD.length === 0) {
      filteredSC = sortDefaultColumns;
      filteredSD = sortDefaultDirections;
      sortColumns = [];
      sortDirections = [];
    } else {
      sortColumns = filteredSC;
      sortDirections = filteredSD;
    }

    return _.orderBy(rows, filteredSC, filteredSD);
  }

  if (columnIndex === -1) {
    if (column !== "" && direction !== "") {
      sortColumns = [...sortColumns, column];
      sortDirections = [...sortDirections, direction];
    }

    if (sortColumns.length > 0) {
      columns = sortColumns;
    }

    if (sortDirections.length > 0) {
      directions = sortDirections;
    }

    if (columns.length === 0) {
      columns = sortDefaultColumns;
    }

    if (directions.length === 0) {
      directions = sortDefaultDirections;
    }
  } else {
    let currentSortDirections = [...sortDirections];
    currentSortDirections[columnIndex] = direction;
    sortDirections = currentSortDirections;

    columns = sortColumns;
    directions = currentSortDirections;
  }

  return _.orderBy(rows, columns, directions);
};

/**
 * Fetches evaluations
 * @param user
 */
export const fetchEvaluations = async (
  search: string = "",
  dateObject: any = null
) => {
  let idEvaluator = 0;

  if (user) {
    idEvaluator = user.idEvaluator;
  } else {
    user = JSON.parse(Cookie.get("user") || "");
    idEvaluator = user.idEvaluator;
  }

  const data = await getEvaluations(idEvaluator, search, dateObject);

  return data.rows;
};

/**
 * Gets icon type
 * @param type
 * @returns
 */
export const getIconType = (type: string) => {
  let iconType = "E";

  if (type !== undefined) {
    switch (type.toLowerCase()) {
      case "evaluation":
        iconType = "E";
        break;

      case "recheck":
        iconType = "RC";
        break;

      case "sweep":
        iconType = "SW";
        break;
    }
  }

  return iconType;
};

/**
 * Gets location evaluator icon
 */
export const getLocationEvaluatorIcon = (locationEvaluator: any) => {
  let iconType = USER;

  switch (locationEvaluator.toLowerCase()) {
    case "onsite":
      iconType = USER;
      break;

    case "virtual":
      iconType = MONITOR;
      break;
  }

  return iconType;
};

/**
 * Gets status icon
 */
export const getStatusIcon = (
  appointmentConfirmed: any,
  appointmentCanceled: any,
  evaluationStatus: string
) => {
  if (
    appointmentCanceled ||
    (evaluationStatus && evaluationStatus.toLowerCase() === "cancelled") ||
    (evaluationStatus && evaluationStatus.toLowerCase() === "canceled")
  ) {
    return RED_CANCEL;
  }

  if (appointmentConfirmed === null || !appointmentConfirmed) {
    return RED_QUESTION;
  }

  if (appointmentConfirmed) {
    return GREEN_CHECK;
  }
};

/**
 * Gets evaluation comments
 */
export const getEvaluationComments = (id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiBaseUrl}${apiEvaluations}/${id}/comments`)
      .then(function ({ data }) {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

/**
 * Saves evaluation comment
 * @param id
 * @param data
 */
export const saveEvaluationComment =
  (parameters: any) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({
        endpoint: `${apiEvaluations}/${parameters.id}/comments`,
        method: "POST",
        data: parameters.data
      })
    );

    return response;
  };

/**
 * Post evaluation
 * @param data
 * @returns
 */
export const postEvaluation = async (data: any) => {
  const response = await axios.post(`${apiBaseUrl}${apiEvaluations}`, data);

  return response;
};

/**
 * Patches evaluation
 * @param id
 * @param data
 * @returns
 */
export const patchEvaluation =
  (data: any) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: `${apiEvaluations}`, method: "PATCH", data })
    );

    console.log(response.status);
  };

/**
 * Saves evaluation issue
 * @param id
 * @param data
 */
export const saveEvaluationIssue =
  (id: number, data: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `${apiEvaluations}/${id}/issues`,
        method: "POST",
        data: data
      })
    );

    return response;
  };

/**
 * Gets evaluation issues
 * @param id
 */
export const getEvaluationIssues = async (id: number) => {
  const response = await axios.get(
    `${apiBaseUrl}${apiEvaluations}/${id}/issues`
  );

  return response.data.rows;
};

/**
 * Gets evaluation issues
 * @param id
 */
export const getEvaluationActivities = async (id: number) => {
  const response = await axios.get(
    `${apiBaseUrl}${apiEvaluations}/${id}/activities`
  );
  return response.data.rows;
};

/**
 * Saves evaluation activity
 * @param id
 * @param data
 */
export const saveEvaluationActivity = async (id: number, data: any) => {
  const response = await axios.post(
    `${apiBaseUrl}${apiEvaluations}/${id}/activities`,
    data
  );

  return response.data;
};

/**
 * Gets environment activities
 * @param id
 */
export const getActivityEnvironments = async (id: number) => {
  const url = `${apiBaseUrl}${apiActivities}/${id}/environments`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

/**
 * Patches activity
 * @param data
 * @returns
 */
export const patchActivity = async (data: any) => {
  const response = await axios.patch(`${apiBaseUrl}${apiActivities}`, data);

  return response;
};

/**
 * Saves evaluation risk
 * @param id
 * @param data
 */
export const saveEvaluationRisk = async (id: number, data: any) => {
  const response = await axios.post(
    `${apiBaseUrl}${apiEvaluations}/${id}/risks`,
    data
  );

  return response.data;
};

/**
 * Gets activity risks
 * @param id
 */
export const getActivityRisks = async (id: number) => {
  const url = `${apiBaseUrl}${apiActivities}/${id}/risks`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

/**
 * Gets risk environments
 * @param id
 */
export const getRiskEnvironments = async (id: number) => {
  const url = `${apiBaseUrl}${apiRisks}/${id}/environments`;
  const response: any = await axios.get(url);

  return response.data.rows;
};

/**
 * post evaluation recommendations
 * @param id
 */
export const postEvaluationRecommendations = async (id: number) => {
  const body = {
    token: "f0767ee2-41b7-11ec-8ddb-6b7f4f4ae7a7",
    comment: "Working",
    options: ["Good"],
    priority: "As Needed",
    actionItem: "Discuss with Manager",
    measurements: "120",
    category: "Adjustment",
    idRecommendationv: 44,
    subCategory: "Anti-Fatigue Mat"
  };
  const response = await axios.post(
    `${apiBaseUrl}${apiEvaluations}/${id}/recommendations`,
    body
  );

  return response.data;
};

/**
 * high priority evaluation
 * @param priority
 * @returns
 */
export const isHighPriority = (priority: string) => {
  let isHigh = false;
  for (const [, p] of highPriorities.entries()) {
    if (p.toLowerCase() === priority) {
      isHigh = true;

      break;
    }
  }

  return isHigh;
};

/**
 * Gets high priority letter.
 * @param priority
 * @returns
 */
export const getHighPriorityLetter = (priority: string) => {
  let letter: string = "";
  let highPriorityValue = highPrioritiesObject[priority];

  if (highPriorityValue) {
    letter = highPriorityValue;
  }

  return letter;
};

export const deleteComment =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/comments/${id}`,
        method: "DELETE"
      })
    );

    //const response: any = await makeApiCall(`/comments/${id}`, "DELETE");

    console.log(response.status);
  };

/**
 * Gets evaluation by id.
 * @param id
 * @returns
 */
export const getEvaluationById =
  (id: number) => async (dispatch: any, getState: any) => {
    return await dispatch(
      makeRequest({ endpoint: `/evaluation/${id}`, method: "GET" })
    );
  };

/**
 * Checks if evaluation is completed
 * @param evaluation
 * @returns
 */
export const checkIfEvaluationCompleted = (evaluation: any) => {
  let evaluationStatus: string;

  if (evaluation && evaluation.evalStatus) {
    evaluationStatus = evaluation && evaluation.evalStatus;
  } else {
    evaluationStatus =
      evaluation && evaluation.status && typeof evaluation.status === "string"
        ? evaluation.status
        : "";
  }

  let isCompleted: boolean =
    evaluation &&
    evaluationStatus &&
    evaluationStatus.toLowerCase() === "completed"
      ? true
      : false;

  return isCompleted;
};
