import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "evaluator",
  initialState: {
    locations: []
  },
  reducers: {
    organizationLocationsReceived(evaluator: any, action: PayloadAction<any>) {
      const payload = action.payload;
      evaluator.locations = payload.rows;
    },
    updateOrganizationLocations(evaluator: any, action: PayloadAction<any>) {
      evaluator.locations = action.payload;
    }
  }
});

export const { organizationLocationsReceived, updateOrganizationLocations } =
  slice.actions;
export default slice.reducer;

/**
 * Gets organizations
 */
export const getCurrentOrganizations = createSelector(
  (state: any) => state.evaluator,
  (evaluator: any) => {
    return _.uniqBy(evaluator.locations, "organizationName");
  }
);

/**
 * Gets evaluator locations
 */
export const getCurrentOrganizationLocations = createSelector(
  (state: any) => state.evaluator,
  (evaluator: any) => evaluator.locations
);
