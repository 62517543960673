import React from "react";
import { IonLabel, IonCol } from "@ionic/react";

import Pills from "./Pills";
const BandContent: React.FC<any> = (props: any) => {
  const { category, subCategory, type, name, bandOnClick } = props;

  return (
    <>
      <IonCol
        className="flex"
        onClick={() => (bandOnClick ? bandOnClick() : null)}
      >
        <div className="ion-float-start mt-10">
          <Pills
            category={category}
            subCategory={subCategory}
            type={type}
            name={name}
          />
        </div>
        <div className="mt-10 wrap-text w-full">
          <IonLabel
            className="text-16"
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            {name}
          </IonLabel>
        </div>
      </IonCol>
    </>
  );
};

export default BandContent;
