import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getCurrentRows,
  getRecommendations,
  recommendationsUpdated,
  rowsUpdated
} from "../../../../../store/evaluation/Recommendations";
import {
  currentEnvironmentRecommendationSet,
  getCurrentEnvironmentRecommendations,
  getShowAddRecommendationModal,
  showAddRecommendationModalSet
} from "../../../../../store/evaluation/environment/Recommendations";
import { getShowEquipmentPicker } from "../../../../../store/evaluation/environment/workstation/Equipments";
import {
  getIsFilteredOrganizationRegionEquipments,
  getOrganizationRegionEquipmentFilters,
  getOrganizationRegionEquipmentManufacturers,
  getOrganizationRegionEquipments,
  getOrganizationRegionEquipmentSubcategories,
  getOriginalGroupedOrganizationRegionEquipments,
  organizationRegionEquipmentFiltersUpdated,
  organizationRegionEquipmentsUpdated
} from "../../../../../store/evaluation/OrganizationRegionEquipments";

import RecommendationRow from "./RecommendationRow";
import AddRecommendationModal from "./AddRecommendationModal";
import TopBar from "./TopBar";
import AddEquipmentModal from "../equipment/AddEquipmentModal";
import AddFurnitureModal from "../furnitures/AddFurnitureModal";

import { getShowFurniturePicker } from "../../../../../store/evaluation/environment/workstation/Furnitures";
import {
  getIsFilteredOrganizationRegionFurnitures,
  getOrganizationRegionFurnitureFilters,
  getOrganizationRegionFurnitureManufacturers,
  getOrganizationRegionFurnitures,
  getOrganizationRegionFurnitureSubcategories,
  getOriginalGroupedOrganizationRegionFurnitures,
  organizationRegionFurnitureFiltersUpdated,
  organizationRegionFurnituresUpdated
} from "../../../../../store/evaluation/OrganizationRegionFurnitures";
import { sortBy } from "lodash";
import { reasonFiltersReceived } from "../../../../../store/catalogs/Recommendations";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import {
  getCurrentRiskRecommendations,
  riskRecommendationsUpdated
} from "../../../../../store/evaluation/environment/Risks";
import AddRiskModal from "../risks/AddRiskModal";
import { getCurrentEvaluationEnvironment } from "../../../../../store/evaluation/Current";

const RecommendationsTab: React.FC<any> = (props: any) => {
  const {
    setEvaluationsRecommendations,
    recommendationEnvironment,
    handleOpenIssuePicker,
    environments,
    setShowEnvironmentModal,
    getRiskvsName
  } = props;

  const dispatch = useAppDispatch();
  const showAddRecommendationModal = useAppSelector(
    getShowAddRecommendationModal
  );
  const recommendations = useAppSelector(getRecommendations);
  const currentEnvironmentRecommendations = useAppSelector(
    getCurrentEnvironmentRecommendations
  );

  // environment workstation workstationequipments
  const showEquipmentPicker = useAppSelector(getShowEquipmentPicker);
  const showFurniturePicker = useAppSelector(getShowFurniturePicker);

  const recommendationDefaultExpanded: any = 0;
  const [recommendationIdExpanded, setRecommendationIdExpanded] = useState<any>(
    recommendationDefaultExpanded
  );
  const [openRiskModal, setOpenRiskModal] = useState<boolean>(false);

  const currentRecommendationCategories = dispatch(
    getListByName("Recommendation_Category")
  );

  const currentRiskRecommendations = useAppSelector(
    getCurrentRiskRecommendations
  );

  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const environmentRecommendations = useAppSelector(getCurrentRows);

  /**
   * Checks if category is equipment of furniture.
   */
  const checkIfCategoryIsEquipmentFurniture = (data: any) => {
    if (
      data?.category.toLowerCase() === "equipment" ||
      data?.category.toLowerCase() === "furniture"
    ) {
      return true;
    }

    return false;
  };

  const onClickRecommendation = async (recommendation: any) => {
    let newRecommendationIdExpanded = recommendationDefaultExpanded;
    if (recommendationIdExpanded !== recommendation.id)
      newRecommendationIdExpanded = recommendation.id;

    setRecommendationIdExpanded(newRecommendationIdExpanded);

    dispatch({
      type: currentEnvironmentRecommendationSet.type,
      payload: recommendation
    });

    // We get recommendation reason filters  (equipment - furniture, type)
    let list: any;

    if (checkIfCategoryIsEquipmentFurniture(recommendation)) {
      if (checkIfCategoryIsEquipmentFurniture(recommendation)) {
        list = dispatch(
          getListByName(
            `${recommendation?.category}_${recommendation?.type}_REASON_FILTER`
          )
        );
      }

      dispatch({
        type: reasonFiltersReceived.type,
        payload: list
      });
    }
  };

  /**
   * Handles add recommendation click
   */
  const handleShowAddRecommendationModal = () => {
    dispatch({
      type: showAddRecommendationModalSet.type,
      payload: true
    });
  };

  const updateRecommendationsList = (list: any) => {
    dispatch({
      type: recommendationsUpdated.type,
      payload: list
    });
  };

  useEffect(() => {
    dispatch({
      type: rowsUpdated.type,
      payload: getRecommendationsByEnvironment()
    });
  }, [
    currentEnvironment && currentEnvironment.id,
    currentEnvironmentRecommendations.length,
    recommendations
  ]);

  const getRecommendationsByEnvironment = () => {
    let sortedByCategory: any = {};

    currentRecommendationCategories?.map(
      (recommendationCategory: any, index: number) => {
        sortedByCategory = {
          ...sortedByCategory,
          [recommendationCategory]: index
        };
      }
    );

    let filtered = recommendations.filter(
      (recommendation: any) =>
        currentEnvironmentRecommendations &&
        currentEnvironmentRecommendations.filter(
          (envRecommendation: any) =>
            envRecommendation.idRecommendation === recommendation.id
        ).length > 0
    );

    filtered = filtered.map((recommendation: any) => {
      return {
        ...recommendation,
        order: sortedByCategory[recommendation.category] || 0
      };
    });

    filtered = sortBy(filtered, ["recommendationV.sortOrder"], ["asc"]);

    return filtered;
  };

  const handleUpdateRiskRecommendations = async (riskRecommendations: any) => {
    let temp = [...currentRiskRecommendations, ...riskRecommendations];

    dispatch({
      type: riskRecommendationsUpdated.type,
      payload: temp
    });
  };

  const handleShowRiskModal = () => {
    setOpenRiskModal(!openRiskModal);
  };

  return (
    <>
      <AddRecommendationModal
        onClickRecommendation={onClickRecommendation}
        isOpen={showAddRecommendationModal}
        isPicker={false}
        isAttach={false}
      />
      <AddEquipmentModal
        isOpen={showEquipmentPicker}
        isPicker={true}
        getOrganizationRegionEquipments={getOrganizationRegionEquipments}
        getOriginalGroupedOrganizationRegionEquipments={
          getOriginalGroupedOrganizationRegionEquipments
        }
        getIsFilteredOrganizationRegionEquipments={
          getIsFilteredOrganizationRegionEquipments
        }
        getOrganizationRegionEquipmentSubcategories={
          getOrganizationRegionEquipmentSubcategories
        }
        getOrganizationRegionEquipmentManufacturers={
          getOrganizationRegionEquipmentManufacturers
        }
        getOrganizationRegionEquipmentFilters={
          getOrganizationRegionEquipmentFilters
        }
        organizationRegionEquipmentsUpdated={
          organizationRegionEquipmentsUpdated
        }
        organizationRegionEquipmentFiltersUpdated={
          organizationRegionEquipmentFiltersUpdated
        }
        recommendationIdExpanded={recommendationIdExpanded}
        pickOneElement={true}
      />
      <AddFurnitureModal
        isOpen={showFurniturePicker}
        isPicker={true}
        getOrganizationRegionFurnitures={getOrganizationRegionFurnitures}
        getOriginalGroupedOrganizationRegionFurnitures={
          getOriginalGroupedOrganizationRegionFurnitures
        }
        getIsFilteredOrganizationRegionFurnitures={
          getIsFilteredOrganizationRegionFurnitures
        }
        getOrganizationRegionFurnitureSubcategories={
          getOrganizationRegionFurnitureSubcategories
        }
        getOrganizationRegionFurnitureManufacturers={
          getOrganizationRegionFurnitureManufacturers
        }
        getOrganizationRegionFurnitureFilters={
          getOrganizationRegionFurnitureFilters
        }
        organizationRegionFurnituresUpdated={
          organizationRegionFurnituresUpdated
        }
        organizationRegionFurnitureFiltersUpdated={
          organizationRegionFurnitureFiltersUpdated
        }
        recommendationIdExpanded={recommendationIdExpanded}
        pickOneElement={true}
      />

      <AddRiskModal
        isOpen={openRiskModal}
        isPicker={false}
        closeModal={handleShowRiskModal}
        recommendationIdExpanded={recommendationIdExpanded}
        updateRiskRecommendations={handleUpdateRiskRecommendations}
        isAttach={true}
        groupedLeftList={true}
      />
      <TopBar
        label="Recommendations"
        onClickAddResource={handleShowAddRecommendationModal}
      />
      {environmentRecommendations.map((recommendation: any, index: number) => {
        return (
          <RecommendationRow
            key={index}
            index={index}
            data={recommendation}
            onClickRecommendation={onClickRecommendation}
            recommendationIdExpanded={recommendationIdExpanded}
            setEvaluationsRecommendations={setEvaluationsRecommendations}
            recommendationEnvironment={recommendationEnvironment}
            handleOpenIssuePicker={handleOpenIssuePicker}
            environments={environments}
            recommendations={recommendations}
            updateRecommendationsList={updateRecommendationsList}
            setShowEnvironmentModal={setShowEnvironmentModal}
            getRiskvsName={getRiskvsName}
            updateRiskRecommendations={handleUpdateRiskRecommendations}
            handleShowRiskModal={handleShowRiskModal}
          />
        );
      })}
    </>
  );
};

export default RecommendationsTab;
