import React from "react";
import { IonCol, IonItem, IonCheckbox, IonLabel } from "@ionic/react";

const BaseSelectMultiCheckbox: React.FC<any> = (props: any) => {
  const { source, onClickButton, isChecked } = props;

  return (
    <>
      {source &&
        source.map((value: any, index: number) => (
          <IonCol
            size-xxl="3"
            size-xl="2"
            size-lg="4"
            size-md="6"
            size-sm="6"
            size-sx="6"
            size="6"
            className="aligned-middle to-left"
            key={index}
          >
            <IonItem
              className="ion-no-padding comboBoxCss font-size12px w-full"
              key={index}
              onKeyPress={(e: any) => {
                if (e.code === "Enter") {
                  e.target.children[0].checked = !(
                    e.target.children[0].value === "on"
                  );
                  e.target.children[0].click();
                  e.stopPropagation();
                }
              }}
            >
              <IonCheckbox
                onClick={(e: any) => onClickButton(value, e.target.checked)}
                checked={isChecked(value)}
                mode="md"
              ></IonCheckbox>
              <IonLabel className="font-10px">&nbsp;{value}</IonLabel>
            </IonItem>
          </IonCol>
        ))}
    </>
  );
};

export default BaseSelectMultiCheckbox;
