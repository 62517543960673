import React, { useEffect, useState } from "react";
import { IonIcon, IonItem, IonList, IonPopover, IonText } from "@ionic/react";
import { arrowBackOutline, ellipsisVerticalOutline } from "ionicons/icons";

import classNames from "classnames";
import Cookies from "js-cookie";
import { useGoogleLogout } from "react-google-login";

import USER from "../../assets/user_toolbar.png";

const googleClientId: any = process.env.REACT_APP_GOOGLE_CLIENT_ID?.toString();

const Toolbar: React.FC<any> = (props: any) => {
  let { title, withNavigation, backButtonIsPresent, goBack } = props;

  const [applicationName] = useState(process.env.REACT_APP_BIDB_NAME);
  if (!title) {
    title = "My Evaluation List";
  }

  let toolbarNavigationClass = "toolbar_navigation";

  if (withNavigation) {
    toolbarNavigationClass += "";
  }

  const user = JSON.parse(Cookies.get("user") || "");

  const [popoverState, setShowPopover] = useState<any>({
    showPopover: undefined,
    event: undefined
  });
  const [userLogout, setUserLogout] = useState<boolean>(false);

  const onLogoutSuccess = (response: any = undefined) => {
    console.log(response);
  };

  const onFailure = (e: any = undefined) => {
    console.log(e);
  };

  const { signOut } = useGoogleLogout({
    clientId: googleClientId,
    onLogoutSuccess,
    onFailure
  });

  /**
   * Handles go back
   */
  const handleGoBack = () => {
    goBack("/dashboard");
  };

  const handleClick = () => {
    setUserLogout(true);
    setShowPopover({
      showPopover: false,
      event: undefined
    });
  };

  useEffect(() => {
    if (userLogout === true) {
      signOut();
      window.location.href = "/login";
    }
  }, [userLogout]);

  return (
    <header className="toolbar">
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({
            showPopover: false,
            event: undefined
          })
        }
      >
        <IonList>
          <IonItem className="cursor-pointer" onClick={handleClick}>
            <IonText>Logout</IonText>
          </IonItem>
        </IonList>
      </IonPopover>
      <nav className={toolbarNavigationClass}>
        <div
          className={classNames(
            "toolbar_logo header-title flex ion-align-items-center w-20pct",
            {
              "cursor-pointer": backButtonIsPresent,
              "cursor-default": !backButtonIsPresent
            }
          )}
          onClick={() => {
            if (backButtonIsPresent) {
              handleGoBack();
            }
          }}
        >
          {backButtonIsPresent && (
            <IonIcon className="text-24" icon={arrowBackOutline}></IonIcon>
          )}
          <span
            className={classNames({
              "ml-05": backButtonIsPresent
            })}
          >
            {" "}
            {title}{" "}
          </span>
        </div>
        <div className="spacer text-24 text-white">{applicationName}</div>
        <div className="toolbar_navigation-item">
          <div>
            {
              <>
                <p className="user-profile-name">{user.name}</p>
                <p className="user-profile-sub-name">{`${user.role[0].toUpperCase()}${user.role.slice(
                  1
                )}`}</p>
              </>
            }
          </div>
          <div className="ml-1 flex w-auto ion-align-items-center">
            <img className="h-full w-32" src={USER} alt="logo" />
            <IonIcon
              className="text-26 text-white cursor-pointer"
              icon={ellipsisVerticalOutline}
              onClick={(e: any) => {
                e.persist();
                setShowPopover({
                  showPopover: true,
                  event: e
                });
              }}
            ></IonIcon>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Toolbar;
