import _, { find, filter, orderBy, uniqBy } from "lodash";
import moment from "moment";
import addIcon from "../assets/add.png";
import laptopIcon from "../assets/laptop-mac.png";
import displayIcon from "../assets/desktop-windows.png";
import cursorIcon from "../assets/cursor.png";
import keyboardIcon from "../assets/keyboard.png";
import tabletComputerIcon from "../assets/ipad.png";
import videoCameraIcon from "../assets/videocam.png";
import accessoryIcon from "../assets/accessory.png";
import toolIcon from "../assets/tool.png";
import clearFilterIcon from "../assets/clear_filter.png";
import clearSelectionIcon from "../assets/clear_selection.png";
import unselectIcon from "../assets/unselect.png";
import deleteIcon from "../assets/delete.png";
import closeIcon from "../assets/x.png";
import editIcon from "../assets/edit.png";
import searchIcon from "../assets/search.png";
import chairIcon from "../assets/chair.png";
import cupboardIcon from "../assets/cupboard.png";
import deskIcon from "../assets/desk.png";

import { workstationEquipment } from "./workstationequipment.service";
import { workstationFurniture } from "./workstationfurniture.service";
import { makeRequest } from "../helpers/global.helper";
import { getLaptopIds } from "../store/evaluation/environment/workstation/Equipments";

export const workstationTypes: any = {
  equipments: workstationEquipment,
  furnitures: workstationFurniture
};

interface Filter {
  key: string;
  value: string;
}

/**
 * This is the order required in frontend side
 */
export const categoryTypes: any = {
  equipments: {
    laptop: "laptops",
    display: "displays",
    mouse: "pointingDevices",
    keyboard: "keyboards",
    "tablet computer": "tabletComputers",
    "video camera": "videoCameras",
    accessory: "accessories",
    hardware: "hardware"
  },
  furnitures: {
    "work surface": "workSurfaces",
    seating: "seats",
    storage: "storage",
    accessory: "accessories"
  }
};

export const iconImages: any = {
  addIcon,
  laptopIcon,
  displayIcon,
  cursorIcon,
  keyboardIcon,
  tabletComputerIcon,
  videoCameraIcon,
  accessoryIcon,
  toolIcon,
  clearFilterIcon,
  clearSelectionIcon,
  unselectIcon,
  deleteIcon,
  closeIcon,
  editIcon,
  searchIcon,
  chairIcon,
  cupboardIcon,
  deskIcon
};

export const icons: any = {
  laptops: iconImages["laptopIcon"],
  displays: iconImages["displayIcon"],
  pointingdevices: iconImages["cursorIcon"],
  keyboards: iconImages["keyboardIcon"],
  tabletcomputers: iconImages["tabletComputerIcon"],
  videocameras: iconImages["videoCameraIcon"],
  accessories: iconImages["accessoryIcon"],
  hardware: iconImages["toolIcon"],
  seats: iconImages["chairIcon"],
  worksurfaces: iconImages["deskIcon"],
  storage: iconImages["cupboardIcon"]
};

export const equipmentMapping: any = {
  monitor: "Display",
  webcam: "Video Camera"
};

/**
 * Groups list
 */
export const groupList = async (list: any, sort: any, category: string) => {
  let groupedAndSorted: any = [];

  if (list) {
    let isObject: boolean = true;

    if (list instanceof Array) {
      isObject = false;
    }

    const categories = categoryTypes[category];

    for (const [categoryKey, value] of Object.entries(categories)) {
      let key: any = value;
      let rows;

      if (isObject) {
        rows = list[`${key}`];
      } else {
        rows = _.filter(list, function (o) {
          return o.category.toLowerCase() === categoryKey.toLowerCase();
        });
      }

      if (rows || (rows && rows.length > 0)) {
        let sorted: any = _.orderBy(
          rows,
          sort.columns.map((column: any) => {
            return (row: any) => {
              let value = row[column];
              return value != null ? value.toString().toLowerCase() : "";
            };
          }),
          sort.directions
        );

        for (const [, row] of sorted.entries()) {
          let updatedRow = {
            ...row,
            type: value
          };
          groupedAndSorted.push(updatedRow);
        }
      }
    }
  }

  return groupedAndSorted;
};

/**
 * Sanitizes payload.
 */
export const sanitizePayload = async (template: any, payload: any) => {
  let sanitizedPayload: any = [];
  let resource: any = {};

  for (const [, property] of Object.entries(payload)) {
    let p: any = property;
    resource[p] = template[p];

    sanitizedPayload.push(resource);
  }

  return sanitizedPayload;
};

/**
 * Builds environment workstation payload
 * @param rows
 */
export const buildEnvironmentWorstationPayload = async (
  rows: any,
  useTemplate: boolean = false,
  type: string,
  id: string,
  isPayload: boolean = true
) => {
  const categories = categoryTypes[type];

  let object: any = {};

  for (const [index, row] of rows.entries()) {
    let category = categories[`${row.category.toLowerCase()}`];

    if (category) {
      let array = [];
      let current: any;

      if (object[`${category}`]) {
        array = object[`${category}`];
        current = isPayload
          ? await getWorkstationPayload(row, useTemplate, type, id, index)
          : row;
        array.push(current);
        object[`${category}`] = array;
      } else {
        current = isPayload
          ? await getWorkstationPayload(row, useTemplate, type, id, index)
          : row;
        array.push(current);
        object[`${category}`] = array;
      }
    }
  }

  return object;
};

/**
 * Gets workstation equipment and furniture payload (complex payloads)
 * @param row
 */
const getWorkstationPayload = async (
  row: any,
  useTemplate: boolean = false,
  type: string,
  id: string,
  index: number
) => {
  let object: any = useTemplate ? { ...workstationTypes[type] } : {};

  object[id] = row.id;

  // For workstation equipment and furniture
  //  label should be filled with first 16 characters from abbreviation property.
  object.label = row.name.substring(0, 16 - 1);
  object.name = row.name;
  object.uuid = `${moment().unix()}${index}`;

  return object;
};

/**
 * Filters list
 */
export const filterList = async (
  list: any,
  filters: any,
  sort: any = undefined
) => {
  let filtered: any = list;
  let containsResult: any = [];
  let contains: boolean = false;

  for (const [k, v] of Object.entries(filters)) {
    let val: any = v;
    if (val.sign === "=") {
      // If contains length is greater than 0 and get here, that means that there are different filters in no order and we need to apply a filter through the current array.
      if (containsResult.length > 0) {
        filtered = containsResult;
      }

      filtered = filter(filtered, { [k]: val.value });
      contains = false;
    } else {
      contains = true;
      let result = filter(filtered, function (o) {
        return o[k]
          .toLowerCase()
          .trim()
          .includes(val.value.toLowerCase().trim());
      });

      containsResult = [...containsResult, ...result];
    }
  }

  if (contains) {
    filtered = containsResult;
  }

  filtered = uniqBy(filtered, "id");

  if (sort && Object.keys(sort).length > 0) {
    filtered = orderBy(filtered, sort.columns, sort.directions);
  }

  return filtered;
};

/**
 * Inserts  workstation process.
 * @param items
 * @param useTemplate
 * @param type
 * @param idWorkstation
 * @param endpoint
 * @param sortedWorkstationList
 * @returns
 */
export const insertWorkstationProcess =
  (
    items: any,
    useTemplate: boolean = false,
    type: string,
    idWorkstation: string,
    endpoint: string,
    sortedWorkstationList: any
  ) =>
  async (dispatch: any, getState: any) => {
    let payload = await buildEnvironmentWorstationPayload(
      items,
      useTemplate,
      type,
      idWorkstation.toString()
    );

    let workstations: any = [];
    const response = await dispatch(
      makeRequest({ endpoint, method: "POST", data: payload })
    );

    if (response.status === 200) {
      // adding the id
      payload = await groupList(
        payload,
        {
          columns: ["id"],
          directions: ["asc"]
        },
        type
      );

      const laptopIds = dispatch(getLaptopIds());

      for (let [, object] of payload.entries()) {
        let workStationTemplate: any = { ...workstationTypes[type] };
        let returnedObject = find(response.data.successes, {
          uuid: object.uuid
        });
        workStationTemplate.id = returnedObject.id;
        workStationTemplate.label = object.label;
        workStationTemplate.name = object.name;
        workStationTemplate[idWorkstation.toString()] =
          object[idWorkstation.toString()];
        workStationTemplate.type = object.type;

        if (
          laptopIds.length === 1 &&
          (object.type === "displays" ||
            object.type === "keyboards" ||
            object.type === "pointingDevices")
        ) {
          workStationTemplate.idLaptopEquipment = laptopIds[0].id;
        }

        workstations.push(workStationTemplate);
      }

      // This is used for updating original list.
      let payloadWithCategory: any = [];

      for (let [, row] of workstations.entries()) {
        row = {
          ...row,
          category: _.findKey(categoryTypes[type], function (o) {
            return o.toLowerCase() === row.type.toLowerCase();
          })
        };

        payloadWithCategory.push(row);
      }

      const payloadWithIds: any = await buildEnvironmentWorstationPayload(
        payloadWithCategory,
        false,
        type,
        idWorkstation,
        false
      );

      // Before merge arrays, we get first one in order to get its index
      const toBeSelected = workstations[0];
      let temp: any = [...sortedWorkstationList, ...workstations];

      // We fake the category to all elements so that we can sort them
      let workstationsWithCategory: any = [];
      for (let [, row] of temp.entries()) {
        row = {
          ...row,
          category: _.findKey(categoryTypes[type], function (o) {
            return o.toLowerCase() === row.type.toLowerCase();
          })
        };

        workstationsWithCategory.push(row);
      }

      temp = await buildEnvironmentWorstationPayload(
        workstationsWithCategory,
        true,
        type,
        idWorkstation,
        false
      );

      temp = await groupList(
        temp,
        {
          columns: ["id"],
          directions: ["asc"]
        },
        type
      );

      const indexToBeSelected = _.findIndex(temp, {
        id: toBeSelected.id
      });

      const currentWorkstation = temp[indexToBeSelected];

      return {
        indexToBeSelected,
        currentWorkstation,
        toBeSelected,
        temp,
        payloadWithIds
      };
    }
  };

export const deleteWorkstation =
  (url: string) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: url, method: "DELETE" })
    );

    console.log(response.status);
  };

export const setPickerPropertyToList = async (list: any, pickedItems: any) => {
  const temp = _.map([...list], (recommendation: any) => {
    let found = _.find(pickedItems, { id: recommendation.id });
    let tempRecommendation = { ...recommendation };

    if (found) {
      tempRecommendation.picker = true;
    }

    return tempRecommendation;
  });

  return temp;
};

/**
 * BEGIN METHODS FOR SAVING ROWS IN TABS
 */

/**
 * Gets rows to be inserted.
 * @param selectedItems
 * @retun Returns rows that have not being duplicated to be inserted and rows already stored in order to use their ids.
 */
export const getRowsToBeInserted =
  (
    selectedItems: any,
    currentRowId: string,
    currentEnvironmentRowId: string,
    currentRows: any,
    currentEnvironmentRows: any,
    getLastIdTemp: any,
    idTempUpdated: any
  ) =>
  async (dispatch: any, getState: any) => {
    let filtered: any = [];
    let alreadyInserted: any = [];

    /*
    We add to the rows a temporary id, this is so that we can manipulate the row (patch, delete, keep displayed in tab)
  */
    let x = await dispatch(getLastIdTemp());
    console.log(x);
    selectedItems.map(async (selectedItem: any) => {
      let row = await validateDuplicatedRow(
        selectedItem.id,
        currentRowId,
        currentEnvironmentRowId,
        currentRows,
        currentEnvironmentRows
      );

      if (row == null) {
        let temp = { ...selectedItem };
        temp.idTemp = x;
        filtered.push(temp);
        --x;

        dispatch({
          type: idTempUpdated.type,
          payload: x
        });
      } else {
        alreadyInserted.push(row);
      }
    });

    return {
      filtered,
      alreadyInserted
    };
  };

/**
 * Validates if row is duplicated, if so, row is returned, otherwise null.
 * @param id
 * @param collection
 */
export const validateDuplicatedRow = async (
  id: number,
  currentRowId: string,
  currentEnvironmentRowId: string,
  currentRows: any,
  currentEnvironmentRows: any
) => {
  let existingRow = currentRows.filter(
    (row: any) =>
      row[currentRowId] == id &&
      currentEnvironmentRows?.filter(
        (environmentRow: any) =>
          environmentRow[currentEnvironmentRowId] == row.id
      ).length > 0
  );

  return existingRow.length > 0 ? existingRow[0] : null;
};

/**
 * END METHODS FOR SAVING ROWS IN TABS
 */
