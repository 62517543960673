import { Redirect, Route } from "react-router-dom";
import { useEffect } from "react";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./css/dashboard.css";

// Pages
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import EvaluationContainer from "./pages/EvaluationContainer";
import Appointments from "./pages/Appointments";

import { store } from "./store/ConfigureStore";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";

const persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              <Route path="/login" component={Login} exact />
              <Route path="/dashboard" component={Dashboard} exact={true} />
              <Route
                path="/evaluation/:id/basicinformation"
                component={EvaluationContainer}
                exact={true}
              />
              <Route
                path="/evaluation/:id/environment"
                component={EvaluationContainer}
                exact={true}
              />
              <Route
                path="/evaluation/:id/report"
                component={EvaluationContainer}
                exact={true}
              />
              <Route
                path="/appointments"
                component={Appointments}
                exact={true}
              />
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </PersistGate>
    </Provider>
  );
}

export default App;
