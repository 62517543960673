import { makeApiCall, makeRequest } from "../helpers/global.helper";

const apiActivities = process.env.REACT_APP_BIDB_API_ACTIVITIES;

export const activityPayloadProperties = [
  "idPreviousActivity",
  "workingPositions"
];

export const environmentActivityPayloadProperties = [
  "idEnvironment",
  "idActivity"
];

/**
 * Deletes activity
 * @param id
 * @returns
 */
export const deleteActivity =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({ endpoint: `${apiActivities}/${id}`, method: "DELETE" })
    );

    console.log(response.status);
  };

/**
 * Deletes activity risk
 * @param id
 */
export const deleteActivityRisk =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({ endpoint: `/activityrisks/${id}`, method: "DELETE" })
    );

    console.log(response.status);
  };

/**
 * Patches activity.
 * @param payload
 */
export const patchActivity =
  (payload: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({ endpoint: `/activities`, method: "PATCH", data: payload })
    );

    console.log(response.status);
  };
