import React, { useEffect, useRef } from "react";
import { IonLabel } from "@ionic/react";
import classNames from "classnames";

const Textarea: React.FC<any> = (props: any) => {
  const {
    label,
    value,
    onBlur,
    containerCss,
    contentEditable,
    copyRef,
    onClick
  } = props;
  const textareaRef: any = useRef();

  useEffect(() => {
    textareaRef.current.innerText = value ? value : "";

    if (copyRef) {
      copyRef(textareaRef);
    }
  }, [value]);

  return (
    <div
      className={classNames("z-0 m-h-50px", {
        "border border-gray overflow-auto rounded-4 ":
          contentEditable !== undefined ? contentEditable : true,
        [containerCss]: containerCss ? true : false
      })}
      onClick={onClick ? onClick : null}
    >
      <IonLabel className="text-black-4F4F4F z-2 pl-1 pr-1 left-3 bg-white absolute -top-05 font-12px font-style-normal font-normal">
        {label}
      </IonLabel>
      <div
        ref={textareaRef}
        className={classNames(
          "text-left pl-2 pr-2 pt-4 pb-2 w-full font-16px font-lato relative outline-0 z-0",
          { [containerCss]: containerCss ? true : false }
        )}
        contentEditable={contentEditable !== undefined ? contentEditable : true}
        onBlur={(e) => onBlur(e)}
      ></div>
    </div>
  );
};

export default Textarea;
