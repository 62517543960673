import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { groupList } from "../../services/tabs.service";
import _ from "lodash";

const slice = createSlice({
  name: "organizationRegionFurnitures",
  initialState: {
    list: [],
    originalList: [],
    originalGroupedList: [],
    isFilteredList: false,
    filteredList: [],
    filters: {
      category: "",
      subCategory: "",
      manufacturer: ""
    }
  },
  reducers: {
    organizationRegionFurnituresReceived(
      organizationRegionFurnitures: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        organizationRegionFurnitures.list = payload.rows;
        organizationRegionFurnitures.originalList = payload.rows;
      } else {
        organizationRegionFurnitures.list = [];
      }
    },
    organizationRegionFurnituresUpdated(
      organizationRegionFurnitures: any,
      action: PayloadAction<any>
    ) {
      organizationRegionFurnitures.list = action.payload;
    },
    isFilteredOrganizationRegionFurnituresSet(
      organizationRegionFurnitures: any,
      action: PayloadAction<any>
    ) {
      organizationRegionFurnitures.isFilteredList = action.payload;
    },
    organizationRegionFurnituresFiltered(
      organizationRegionFurnitures: any,
      action: PayloadAction<any>
    ) {
      organizationRegionFurnitures.filteredList = action.payload;
    },
    organizationRegionFurnitureFiltersUpdated(
      organizationRegionFurnitures: any,
      action: PayloadAction<any>
    ) {
      organizationRegionFurnitures.filters[action.payload.key] =
        action.payload.value;
    },
    organizationRegionFurnituresGrouped(
      organizationRegionFurnitures: any,
      action: PayloadAction<any>
    ) {
      organizationRegionFurnitures.originalGroupedList = action.payload;
    }
  }
});

export const {
  organizationRegionFurnituresReceived,
  organizationRegionFurnituresUpdated,
  isFilteredOrganizationRegionFurnituresSet,
  organizationRegionFurnituresFiltered,
  organizationRegionFurnitureFiltersUpdated,
  organizationRegionFurnituresGrouped
} = slice.actions;
export default slice.reducer;

/**
 * Gets organization region furnitures.
 */
export const getOrganizationRegionFurnitures = createSelector(
  (state: any) => state.evaluation.organizationRegionFurnitures,
  (organizationRegionFurnitures: any) => organizationRegionFurnitures.list
);

/**
 * Gets original organization region furnitures.
 */
export const getOriginalOrganizationRegionFurnitures = createSelector(
  (state: any) => state.evaluation.organizationRegionFurnitures,
  (organizationRegionFurnitures: any) =>
    organizationRegionFurnitures.originalList
);

/**
 * Gets original grouped list.
 * @returns
 */
export const getOriginalGroupedOrganizationRegionFurnituresList =
  () => async (dispatch: any, getState: any) => {
    const { originalList } = getState().evaluation.organizationRegionFurnitures;

    const groupedAndSorted: any = await groupList(
      originalList,
      {
        columns: ["name"],
        directions: ["asc"]
      },
      "furnitures"
    );

    dispatch({
      type: organizationRegionFurnituresUpdated.type,
      payload: groupedAndSorted
    });

    dispatch({
      type: organizationRegionFurnituresGrouped.type,
      payload: groupedAndSorted
    });

    return groupedAndSorted;
  };

/**
 * Gets original grouped organization region furnitures.
 */
export const getOriginalGroupedOrganizationRegionFurnitures = createSelector(
  (state: any) => state.evaluation.organizationRegionFurnitures,
  (organizationRegionFurnitures: any) =>
    organizationRegionFurnitures.originalList
);

/**
 * Sorts list
 * @returns
 */
export const filterCurrentOrganizationRegionFurnitures =
  (furnitureFilters: any = undefined) =>
  async (dispatch: any, getState: any) => {
    const { originalList } = getState().evaluation.organizationRegionFurnitures;
    let list: any = originalList;

    if (
      furnitureFilters &&
      furnitureFilters.category &&
      furnitureFilters.category.value
    ) {
      list = _.filter(originalList, {
        category: furnitureFilters.category.value
      });
    }

    if (
      furnitureFilters &&
      furnitureFilters.subCategory &&
      furnitureFilters.subCategory.value
    ) {
      list = _.filter(originalList, {
        subCategory: furnitureFilters.subCategory.value
      });
    }

    // Filter value does not exist in category values.
    if (furnitureFilters !== undefined && list.length == 0) {
      list = originalList;
    }

    dispatch({
      type: organizationRegionFurnituresUpdated.type,
      payload: list
    });
  };

/**
 * Gets is filtered list.
 */
export const getIsFilteredOrganizationRegionFurnitures = createSelector(
  (state: any) => state.evaluation.organizationRegionFurnitures,
  (organizationRegionFurnitures: any) =>
    organizationRegionFurnitures.isFilteredList
);

/**
 * Gets organization region furniture filters.
 */
export const getOrganizationRegionFurnitureFilters = createSelector(
  (state: any) => state.evaluation.organizationRegionFurnitures,
  (organizationRegionFurnitures: any) => organizationRegionFurnitures.filters
);

/**
 * Gets organization region furniture subcategories.
 */
export const getOrganizationRegionFurnitureSubcategories = createSelector(
  (state: any) => state.evaluation.organizationRegionFurnitures,
  (organizationRegionFurnitures: any) => {
    const { originalList } = organizationRegionFurnitures;

    // Here we remove duplicates
    let filtered = _.map(_.uniqBy(originalList, "subCategory"), "subCategory");
    filtered = _.filter(filtered, function (o) {
      return o !== "";
    });

    return filtered.sort((a, b) => a.localeCompare(b));
  }
);

/**
 * Gets organization region furniture manufacturers.
 */
export const getOrganizationRegionFurnitureManufacturers = createSelector(
  (state: any) => state.evaluation.organizationRegionFurnitures,
  (organizationRegionFurnitures: any) => {
    const { originalList } = organizationRegionFurnitures;

    let filtered = _.map(
      _.uniqBy(originalList, "manufacturer"),
      "manufacturer"
    );

    filtered = _.filter(filtered, function (o) {
      return o !== "";
    });

    return filtered.sort((a, b) => a.localeCompare(b));
  }
);
