import React from "react";

import AddRow from "../../../../common/controls/AddRow";
import AddButton from "../../../../common/controls/AddButton";

const TopBar: React.FC<any> = (props: any) => {
  const { onClickAddResource } = props;

  return (
    <AddRow
      label="Risks"
      buttons={<AddButton label="ADD RISKS" onClick={onClickAddResource} />}
    />
  );
};

export default TopBar;
