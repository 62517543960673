import { makeRequest } from "../helpers/global.helper";

export const issuePayloadProperties = ["type", "chief"];
export const issueEnvironmentPayloadProperties = ["idEnvironment", "idIssue"];
const apiIssues = process.env.REACT_APP_BIDB_API_ISSUES;

export enum IssueType {
  discomfort = "discomfort",
  request = "request"
}

export enum SideType {
  left = "Left",
  right = "Right",
  both = "Both"
}

/**
 * Deletes issue
 * @param id
 * @returns
 */
export const deleteIssue =
  (id: number) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({
        endpoint: `${apiIssues}/${id}`,
        method: "DELETE"
      })
    );

    console.log(response.status);
  };

/**
 * Patches issue
 * @param data
 * @returns
 */
export const patchIssue =
  (data: any) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({
        endpoint: `${apiIssues}`,
        method: "PATCH",
        data
      })
    );

    console.log(response.status);
  };

/**
 * Deletes issue recommendation.
 * @param id
 * @returns
 */
export const deleteIssueRecommendation =
  (id: number) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: `/issuerecommendations/${id}`, method: "DELETE" })
    );

    console.log(response.status);
  };

/**
 * Deletes issue environment
 * @param id
 */
export const deleteIssueEnvironment =
  (id: number) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: `/issueenvironments/${id}`, method: "DELETE" })
    );

    console.log(response.status);
  };
