import React, { useState } from "react";
import { IonCol, IonGrid, IonRow, IonButton, useIonToast } from "@ionic/react";

import { useAppDispatch } from "../../store/Hooks";

import BaseModal from "../../components/common/BaseModal";
import Input from "../common/controls/Input";
import { makeRequest } from "../../helpers/global.helper";

const AddWorkerModal: React.FC<any> = (props: any) => {
  const {
    isOpen,
    closeModal,
    onSaveWorker,
    isLocationSelected,
    openReviewDuplicates
  } = props;

  const [present] = useIonToast();

  const [firstName, setFirtName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  const dispatch = useAppDispatch();

  const handleFirstName = (key: string, value: string) => {
    setFirtName(value);
  };

  const handleLastName = (key: string, value: string) => {
    setLastName(value);
  };

  const handleOnClick = async () => {
    if (firstName === undefined || firstName === "") {
      present({
        message: "Type first name",
        position: "top",
        duration: 1000
      });
      return;
    }

    if (lastName === undefined || lastName === "") {
      present({
        message: "Type last name",
        position: "top",
        duration: 1000
      });
      return;
    }

    const data = {
      nameFirst: firstName,
      nameLast: lastName
    };

    // Validates duplicate
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/workers?name=${firstName}`,
        method: "GET"
      })
    );

    if (
      response.status == 200 &&
      response.data.rows &&
      response.data.rows.length > 0
    ) {
      openReviewDuplicates(data, response.data.rows);
    } else {
      setFirtName("");
      setLastName("");
      onSaveWorker(data);
    }
  };

  return (
    <BaseModal
      cssClass="worker-modal"
      title="Add new employee"
      isOpen={isOpen}
      closeModal={closeModal}
      content={
        <IonGrid className="relative top-1 px-24px w-full">
          <IonRow>
            <IonCol>
              <Input label="First name" onInput={handleFirstName} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Input label="Last name" onInput={handleLastName} />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      footer={
        <IonGrid className="px-24px">
          <IonRow>
            <IonCol>
              <IonButton
                color="medium"
                className="bg-cancel font-bold rounded-4 mr-1"
                onClick={closeModal}
              >
                CANCEL
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                disabled={
                  !isLocationSelected && firstName != "" && lastName != ""
                }
                className="font-bold w-280"
                onClick={handleOnClick}
              >
                ADD A NEW EMPLOYEE
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      }
    />
  );
};

export default AddWorkerModal;
