import React from "react";

import AddButton from "../../../../common/controls/AddButton";

const AddButtons: React.FC<any> = (props: any) => {
  const { handleAddRequest, handleAddDiscomfort } = props;

  return (
    <div className="flex ion-justify-content-end">
      <AddButton label="ADD REQUEST" onClick={handleAddRequest} />
      <AddButton label="ADD DISCOMFORT" onClick={handleAddDiscomfort} />
    </div>
  );
};

export default AddButtons;
