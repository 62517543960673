import React, { useState } from "react";
import { useIonToast, IonRow, IonCol, IonLabel, IonButton } from "@ionic/react";

import { useAppSelector, useAppDispatch } from "../../store/Hooks";
import { getCurrentUser } from "../../store/User";

import { saveEnvironment } from "../../services/evaluation.service";
import { insertResourceProcess } from "../../helpers/global.helper";

import {
  getAppointmentMinutes,
  getCurrentEvaluationTypes
} from "../../store/catalogs/Evaluations";

import SelectForm from "../common/controls/SelectForm";
import BaseModal from "../common/BaseModal";

import DatePicker from "react-datepicker";

import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import {
  evaluationsUpdated,
  getCurrentEvaluations
} from "../../store/Evaluations";
import {
  idUpdated,
  setEvaluation,
  environmentsUpdated,
  getEnvironments,
  setEnvironmentTabIndex
} from "../../store/evaluation/Current";

const AddAppointmentModal: React.FC<any> = (props: any) => {
  const { isOpen, closeModal, worker, location } = props;

  const user = useAppSelector(getCurrentUser);
  const currentEvaluationTypes = useAppSelector(getCurrentEvaluationTypes);
  const appointmentMinutes = useAppSelector(getAppointmentMinutes);
  const currentEvaluations = useAppSelector(getCurrentEvaluations);
  const dispatch: any = useAppDispatch();
  const [present] = useIonToast();

  const [evaluationType, setEvaluationType] = useState<string>("");

  const [hour, setHour] = useState<string>("");
  const [minute, setMinute] = useState<string>("");
  const [amPm, setAmPm] = useState<string>("");
  const [appointmentDate, setAppointmentDate] = useState<any>(new Date());
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const handleOnChange = (name: string, value: string) => {
    setEvaluationType(value);
  };

  const currentEnvironments = useAppSelector(getEnvironments);

  /**
   * hanldle time combo
   */
  const handleTime = (name: any, value: any) => {
    switch (name) {
      case "hour":
        setHour(value);

        const hour = parseInt(value);

        if (hour >= 8 && hour <= 12) {
          setAmPm("AM");
        }

        if (hour >= 1 && hour <= 7) {
          setAmPm("PM");
        }

        break;
      case "minute":
        setMinute(value);
        break;
    }
  };

  /**
   * save appointment
   */
  const handleSave = async () => {
    if (evaluationType === undefined || evaluationType === "") {
      present({
        message: "Select evaluation type",
        position: "top",
        duration: 1000
      });
      return;
    }

    if (hour === "") {
      present({
        message: "Select hour",
        position: "top",
        duration: 1000
      });
      return;
    }

    if (minute === "") {
      present({
        message: "Select minutes",
        position: "top",
        duration: 1000
      });
      return;
    }

    if (amPm === "") {
      present({
        message: "Select AM/PM",
        position: "top",
        duration: 1000
      });
      return;
    }

    setShowLoader(true);

    const h = hour.length == 1 ? `0${hour}` : hour;

    const dateTime = moment(
      `${moment(appointmentDate).format("YYYY-MM-DD")}, ${h}:${minute} ${amPm}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD HH:mm:ss");

    const data = {
      idEvaluator: user.idEvaluator,
      idLocation: location.idLocation,
      locationName: location.locationName,
      organizationName: location.organizationName,
      idWorker: worker.id,
      workerName: `${worker.nameFirst} ${worker.nameLast}`,
      appointmentDate: moment(dateTime).format("YYYY-MM-DD"),
      appointmentTime: moment(dateTime).format("HH:mm:ss"),
      deparment: worker.employeeDeskNumber,
      requestDate: moment().format("YYYY-MM-DD"),
      requestOrigin: "Eval App",
      type: evaluationType,
      locationEvaluatorDuringEvaluation: "OnSite",
      regionId: location.organizationRegionId
        ? location.organizationRegionId
        : ""
    };

    dispatch(
      insertResourceProcess(
        [data],
        [
          "idEvaluator",
          "idLocation",
          "idWorker",
          "appointmentDate",
          "appointmentTime",
          "department",
          "requestDate",
          "requestOrigin",
          "workerName",
          "locationName",
          "organizationName",
          "type",
          "regionId"
        ],
        {},
        "/evaluations"
      )
    )
      .then((rows: any) => {
        if (rows.length > 0) {
          present({
            message: "Evaluation Created!",
            position: "top",
            duration: 3000
          });

          let temp = [...currentEvaluations];

          for (const [, row] of rows.entries()) {
            temp.unshift(row);

            const data = {
              id: 0,
              type: "Office",
              spaceType: "Workstation",
              name: "Workstation"
            };

            let response = dispatch(saveEnvironment(row.id, data));

            if (response.status === 200) {
              let dataTemp = { ...data };
              dataTemp.id = response.data.successes[0].id;
              let temp = [...currentEnvironments];
              temp.push(dataTemp);

              dispatch({
                type: environmentsUpdated.type,
                payload: temp
              });
            }
          }

          let currentEvaluation = { ...rows[0] };
          currentEvaluation.idOrganization = location.idOrganization;

          dispatch({
            type: idUpdated.type,
            payload: currentEvaluation.id
          });

          dispatch({
            type: setEvaluation.type,
            payload: currentEvaluation
          });

          dispatch({
            type: evaluationsUpdated.type,
            payload: temp
          });

          dispatch({
            type: setEnvironmentTabIndex.type,
            payload: 0
          });

          setTimeout(function () {
            setShowLoader(false);
            closeModal(currentEvaluation.id);
          }, 1000);
        }
      })
      .catch((e: any) => {
        console.log(e);
        setShowLoader(false);
      });
  };

  return (
    <BaseModal
      showLoader={showLoader}
      isOpen={isOpen}
      title="Create new Appointment"
      closeModal={closeModal}
      cssClass="appointment-modal"
      titleSize="9"
      closeButtonSize="3"
      content={
        <div className="relative top-2 pl-2 pr-2">
          <IonRow>
            <IonCol>
              <IonLabel className="font-bold text-20 pl-24">
                {worker && worker.nameFirst} {worker && worker.nameLast}
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel className="text-left title-name text-18">
                <b>
                  {location && location.organizationName},{" "}
                  {location && location.locationName}
                </b>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <SelectForm
                label="Type"
                options={currentEvaluationTypes}
                name="type"
                value={evaluationType}
                onChange={handleOnChange}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className="appointment-datepicker">
                <DatePicker
                  selected={appointmentDate}
                  onChange={(date) => setAppointmentDate(date)}
                  inline
                  calendarClassName="w-full"
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">
              <SelectForm
                placeholder=""
                label="Hour"
                options={[
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                  "11",
                  "12"
                ]}
                name="hour"
                value={hour}
                onChange={handleTime}
              />
            </IonCol>
            <IonCol size="4">
              <SelectForm
                label="Minutes"
                placeholder=""
                options={appointmentMinutes}
                name="minute"
                value={minute}
                onChange={handleTime}
              />
            </IonCol>
            <IonCol size="4">
              <SelectForm
                placeholder=""
                label="AM/PM"
                options={["AM", "PM"]}
                name="amPm"
                value={amPm}
                onChange={(key: string, value: string) => {
                  if (amPm != value) {
                    handleTime(key, value);
                  }
                }}
              />
            </IonCol>
          </IonRow>
        </div>
      }
      footer={
        <IonRow className="px-24px">
          <IonCol className="flex ion-justify-content-between">
            <IonButton
              color="medium"
              className="btn-custom-modal font-bold btn-cancel2 w-120 h-32"
              onClick={() => closeModal(false)}
            >
              CANCEL
            </IonButton>
            <IonButton
              className="btn-custom-modal endfont-bold w-120 h-32"
              onClick={handleSave}
            >
              CREATE
            </IonButton>
          </IonCol>
        </IonRow>
      }
    />
  );
};

export default AddAppointmentModal;
