import React from "react";

const TouchFurnitureWrapper: React.FC<any> = (props) => {
  const { children, onStart, onEnd } = props;

  return (
    <div
      className="furniture-tab relative z-1"
      onTouchStart={onStart}
      onTouchEnd={onEnd}
    >
      {" "}
      {children}
    </div>
  );
};

export default TouchFurnitureWrapper;
