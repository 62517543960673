import React from "react";

const RiskItem: React.FC<any> = (props: any) => {
  const { item, groupedLeftList } = props;

  return (
    <div className={`h-80 w-210 flex flex-column ion-justify-content-between`}>
      <div className="h-auto pl-05">
        <p className="name-item">
          <span
            className={`name-value text-15 ${
              groupedLeftList &&
              groupedLeftList === true &&
              item.group &&
              item.group === true
                ? "font-italic"
                : ""
            }`}
          >
            {item.name}
          </span>
        </p>
      </div>
      <div className="h-auto pl-05">
        <p className="name-item mb-1 text-12 font-bold">
          {item.category}:&nbsp;
          <span className="name-value text-12">{item.subCategory}</span>
        </p>
      </div>
    </div>
  );
};

export default RiskItem;
