import React, { useEffect, useState } from "react";
import { IonCol, IonLabel, IonButton } from "@ionic/react";

const YesNoInput: React.FC<any> = (props: any) => {
  const { label, data, property, onChange } = props;
  const [yesNo, setYesNo] = useState<boolean | null>(null);

  const handleOnClick = (value: boolean | null) => {
    setYesNo(value);
    onChange(property, value);
  };

  useEffect(() => {
    setYesNo(data && data[property] && data[property]);
  }, [data && data.id]);

  return (
    <>
      <IonCol
        size-xxl="6"
        size-xl="6"
        size-lg="4"
        size-md="4"
        size-sm="4"
        size-sx="4"
        size="4"
      >
        <IonLabel class="text-left">{label}</IonLabel>
      </IonCol>
      <IonCol>
        <IonButton
          onClick={() => handleOnClick(yesNo ? null : true)}
          className={
            "btn " + (yesNo === true ? "positive" : "negative") + " font-bold"
          }
        >
          YES
        </IonButton>
        <IonButton
          onClick={() => handleOnClick(yesNo === false ? null : false)}
          className={
            "btn " + (yesNo === false ? "positive" : "negative") + " font-bold"
          }
        >
          NO
        </IonButton>
      </IonCol>
    </>
  );
};

export default YesNoInput;
