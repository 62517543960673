import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
  name: "appointments",
  initialState: {
    workers: [],
    search: "",
    organizationIndex: -1,
    locationIndex: -1,
    selectedLocation: null,
    sort: null
  },
  reducers: {
    workersUpdated(appointments: any, action: PayloadAction<any>) {
      appointments.workers = action.payload;
    },
    searchUpdated(appointments: any, action: PayloadAction<any>) {
      appointments.search = action.payload;
    },
    organizationIndexUpdated(appointments: any, action: PayloadAction<any>) {
      appointments.organizationIndex = action.payload;
    },
    locationIndexUpdated(appointments: any, action: PayloadAction<any>) {
      appointments.locationIndex = action.payload;
    },
    selectedLocationUpdated(appointments: any, action: PayloadAction<any>) {
      appointments.selectedLocation = action.payload;
    },
    sortUpdated(appointments: any, action: PayloadAction<any>) {
      appointments.sort = action.payload;
    }
  }
});

export const {
  workersUpdated,
  searchUpdated,
  organizationIndexUpdated,
  locationIndexUpdated,
  selectedLocationUpdated,
  sortUpdated
} = slice.actions;
export default slice.reducer;

/**
 * Gets current workers.
 */
export const getCurrentSort = createSelector(
  (state: any) => state.appointments,
  (appointments: any) => appointments.sort
);

/**
 * Gets current workers.
 */
export const getCurrentWorkers = createSelector(
  (state: any) => state.appointments,
  (appointments: any) => appointments.workers
);

/**
 * Gets current search.
 */
export const getCurrentSearch = createSelector(
  (state: any) => state.appointments,
  (appointments: any) => appointments.search
);

/**
 * Gets current organization index.
 */
export const getCurrentOrganizationIndex = createSelector(
  (state: any) => state.appointments,
  (appointments: any) => appointments.organizationIndex
);

/**
 * Gets current location index.
 */
export const getCurrentLocationIndex = createSelector(
  (state: any) => state.appointments,
  (appointments: any) => appointments.locationIndex
);

/**
 * Gets current location.
 */
export const getCurrentSelectedLocation = createSelector(
  (state: any) => state.appointments,
  (appointments: any) => appointments.selectedLocation
);
