import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { makeRequest } from "../helpers/global.helper";

const slice = createSlice({
  name: "evaluations",
  initialState: {
    list: [],
    originalList: [],
    lastFetch: 0,
    sortColumns: [],
    sortDirections: []
  },
  reducers: {
    setEvaluations: (evaluations, action: PayloadAction<any>) => {
      evaluations.list = action.payload;
      evaluations.lastFetch = Date.now();
    },
    setOriginalList: (evaluations: any) => {
      evaluations.originalList = evaluations.list;
    },
    setSortColumnDirection: (evaluations, action: PayloadAction<any>) => {
      evaluations.sortColumns = action.payload.sortColumns;
      evaluations.sortDirections = action.payload.sortDirections;
    },
    evaluationsUpdated: (evaluations, action: PayloadAction<any>) => {
      evaluations.list = action.payload;
    },
    evaluationsOriginalListUpdated: (
      evaluations,
      action: PayloadAction<any>
    ) => {
      evaluations.originalList = action.payload;
    }
  }
});

export const {
  setEvaluations,
  setSortColumnDirection,
  setOriginalList,
  evaluationsUpdated,
  evaluationsOriginalListUpdated
} = slice.actions;
export default slice.reducer;

/**
 * Loads evaluations
 * @returns
 */
export const loadEvaluations =
  (params: any) => async (dispatch: any, getState: any) => {
    // We fetch evaluations from server
    const { search, dateObject } = params;
    const currentUser = getState().user.current;
    const { idEvaluator } = currentUser;

    let parameters: any = {
      idEvaluator,
      limit: process.env.REACT_APP_BIDB_LIMIT,
      active: true
    };
    const url: string = process.env.REACT_APP_BIDB_API_EVALUATIONS
      ? process.env.REACT_APP_BIDB_API_EVALUATIONS
      : "";

    if (search !== "") {
      parameters = { ...parameters, search };
    }

    if (dateObject) {
      // appointmentDateGte
      if (dateObject.appointmentDateGte) {
        parameters = {
          ...parameters,
          appointmentDateGte: dateObject.appointmentDateGte
        };
      }

      // appointmentDateLte
      if (dateObject.appointmentDateLte) {
        parameters = {
          ...parameters,
          appointmentDateLte: dateObject.appointmentDateLte
        };
      }

      // appointmentDate
      if (dateObject.appointmentDate) {
        parameters = {
          ...parameters,
          appointmentDate: dateObject.appointmentDate
        };
      }
    }

    const response: any = await dispatch(
      makeRequest({
        endpoint: url,
        method: "GET",
        data: {},
        parameters
      })
    );

    return response && response.data && response.data.rows;
  };

/**
 * Gets evaluation by index
 */
export const getEvaluationByIndex = createSelector(
  (state: any) => state.entities.evaluations,
  (evaluations) => console.log(evaluations)
);

/**
 * Gets evaluations
 */
export const getCurrentEvaluations = createSelector(
  (state: any) => state.entities.evaluations,
  (evaluations) => evaluations.list
);

/**
 * Gets evaluations original list.
 */
export const getCurrentEvaluationsOriginalList = createSelector(
  (state: any) => state.entities.evaluations,
  (evaluations) => evaluations.originalList
);

/**
 * Gets sort columns
 */
export const getSortColumns = createSelector(
  (state: any) => state.entities.evaluations,
  (evaluations: any) => evaluations.sortColumns
);

/**
 * Gets sort directions
 */
export const getSortDirections = createSelector(
  (state: any) => state.entities.evaluations,
  (evaluations: any) => evaluations.sortDirections
);
