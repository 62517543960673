import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { checkmark, close } from "ionicons/icons";

import classNames from "classnames";

const SmallButton: React.FC<any> = (props: any) => {
  const { buttonLabel, check, onClick, className } = props;

  return (
    <IonButton
      size="small"
      fill="outline"
      className={classNames("text-10", {
        [className]: className ? true : false
      })}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick("chief", !check);
      }}
    >
      <IonIcon icon={check ? checkmark : close}></IonIcon>
      {buttonLabel}
    </IonButton>
  );
};

export default SmallButton;
