import React from "react";
import { IonCheckbox, IonLabel, IonItem } from "@ionic/react";

const Checkbox: React.FC<any> = (props: any) => {
  const { name, value, label, onChange, className, labelClassName } = props;

  return (
    <div className={`flex ion-align-items-center`}>
      <IonItem
        slot="end"
        className="ion-no-padding item-wrapper"
        lines="none"
        onKeyPress={(e: any) => {
          if (e.code === "Enter") {
            e.target.children[0].checked = !(
              e.target.children[0].value === "on"
            );
            e.target.children[0].click();
            e.stopPropagation();
          }
        }}
      >
        <IonCheckbox
          className={className}
          name={name}
          checked={value}
          onClick={(e: any) => {
            e.stopPropagation();
            onChange(e.target.name, e.target.checked);
          }}
          mode="md"
        ></IonCheckbox>
        <IonLabel className={labelClassName}>&nbsp;{label}</IonLabel>
      </IonItem>
    </div>
  );
};

export default Checkbox;
