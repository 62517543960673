import React from "react";
import {
  IonRow,
  IonCol,
  IonText,
  IonItem,
  IonContent,
  IonList
} from "@ionic/react";

import moment from "moment";

const TableBody: React.FC<any> = (props: any) => {
  const { data, onClick, selectedIndex } = props;

  return (
    <>
      <IonRow className="flex-grow">
        <IonCol>
          <IonList
            lines="full"
            className="rounded-4 h-full overflow-x-hidden border-solid ion-text-center"
          >
            {data &&
              data.map((row: any, index: number) => {
                return (
                  <IonItem
                    button
                    onClick={() => {
                      onClick(row, index);
                    }}
                    className={`row cursor-pointer ${
                      selectedIndex === index ? "item-active" : ""
                    }`}
                    key={index}
                  >
                    <IonCol size="3">
                      <IonText className="row-data-text">
                        {row.nameFirst} {row.nameLast}
                      </IonText>
                    </IonCol>

                    <IonCol size="4">
                      <IonText className="row-data-text">
                        {row.employeeFloorName}
                      </IonText>
                    </IonCol>

                    <IonCol size="4">
                      <IonText className="row-data-text">
                        {row.employeeDeskNumber}
                      </IonText>
                    </IonCol>
                  </IonItem>
                );
              })}
          </IonList>
        </IonCol>
      </IonRow>
    </>
  );
};

export default TableBody;
