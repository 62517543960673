import React from "react";
import { IonLabel, IonInput, IonImg } from "@ionic/react";

import { iconImages } from "../../services/tabs.service";

const BaseSearch: React.FC<any> = (props: any) => {
  const { onChange, value, debounce } = props;

  return (
    <div className="relative form-group custom-input matching-element w-full flex">
      <IonLabel className="form-group-input-text-title matching-element-label">
        {props.label}
      </IonLabel>
      <IonInput
        type="text"
        name="search"
        onIonInput={(e: any) => {
          //if (e.target.value !== undefined && e.target.value !== value) {
          onChange(e.target.value);
          //}
        }}
        className="form-control input-lg form-group-input-text text-left"
        placeholder={props.placeholder}
        value={value}
        debounce={debounce}
      ></IonInput>
      <IonImg
        className="mt-3 absolute right-3"
        src={iconImages["searchIcon"]}
        alt="search"
      ></IonImg>
    </div>
  );
};

export default BaseSearch;
