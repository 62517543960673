import React, { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

import Input from "../../../../../common/controls/Input";

import { filterList } from "../../../../../../services/tabs.service";

import { omit, debounce } from "lodash";

const Search: React.FC<{
  name: string;
  label: string;
  placeholder: string;
  list: any;
  filters: any;
  sort?: any;
  fields: any;
  updateFilterValue: any;
  updateList: any;
  isFilteredList: any;
  showClearButton?: any;
}> = ({
  name,
  label,
  placeholder,
  list,
  filters,
  sort,
  fields,
  updateFilterValue,
  updateList,
  isFilteredList,
  showClearButton
}) => {
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      setValue("");
    }
  }, [filters]);

  const filter = async (value: string): Promise<void> => {
    let filtersObject = { ...filters };
    let newFilteredList = [];

    if (value && value !== "") {
      for (const [index, key] of fields.entries()) {
        filtersObject[key] = {
          sign: "contains",
          value
        };
      }

      updateFilterValue(filtersObject);

      newFilteredList = await filterList(list, filtersObject, sort);

      updateList(newFilteredList);
    } else {
      for (const [, key] of fields.entries()) {
        filtersObject = omit(filtersObject, [key]);

        isFilteredList(key);
      }

      updateFilterValue(filtersObject);

      newFilteredList = await filterList(list, filtersObject, sort);
      updateList(newFilteredList, false);
    }
  };

  const handleOnInput = async (key: string, value: string): Promise<void> => {
    setValue(value);
    await filter(value);
  };

  const clearValue = () => {
    handleOnInput("search", "");
  };

  return (
    <div className="relative">
      <Input
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onInput={debounce(
          (key: string, value: string) => handleOnInput(key, value),
          200
        )}
      />
      {showClearButton === true && value && (
        <IonIcon
          className="cursor-pointer clear-button top-3  z-2"
          onClick={() => clearValue()}
          icon={closeCircleOutline}
        ></IonIcon>
      )}
    </div>
  );
};

export default Search;
