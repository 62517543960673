import { combineReducers } from "redux";
import evaluationsReducer from "./Evaluations";
import environmentsReducer from "./Environments";
import issuesReducer from "./Issues";
import activitiesReducer from "./Activities";
import equipmentsReducer from "./Equipments";
import furnituresReducer from "./Furnitures";
import recommendationsReducer from "./Recommendations";
import risksReducer from "./Risks";
import workersReducer from "./Workers";
import valueListReducer from "./ValueList";

export default combineReducers({
  evaluations: evaluationsReducer,
  environments: environmentsReducer,
  issues: issuesReducer,
  activities: activitiesReducer,
  equipments: equipmentsReducer,
  furnitures: furnituresReducer,
  recommendations: recommendationsReducer,
  risks: risksReducer,
  workers: workersReducer,
  valueList: valueListReducer
});
