import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "environments",
  initialState: {
    types: [],
    percentTimeHereWeekly: [],
    spaceTypes: [],
    officeSpaceTypes: [],
    assignmentTypes: [],
    roomTypes: [],
    showSkeleton: false
  },
  reducers: {
    environmentTypesReceived(environments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        environments.types = action.payload.rows[0].values;
      } else {
        environments.types = [];
      }
    },
    environmentTimeHereWeeklyReceived(
      environments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        environments.percentTimeHereWeekly = payload.rows[0].values;
      } else {
        environments.percentTimeHereWeekly = [];
      }
    },
    environmentSpaceTypesReceived(
      environments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        environments.spaceTypes = payload.rows[0].values;
      } else {
        environments.spaceTypes = [];
      }
    },
    environmentOfficeSpaceTypesReceived(
      environments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        environments.officeSpaceTypes = payload.rows[0].values;
      } else {
        environments.officeSpaceTypes = [];
      }
    },
    environmentAssignmentTypesReceived(
      environments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        environments.assignmentTypes = payload.rows[0].values;
      } else {
        environments.assignmentTypes = [];
      }
    },
    environmentRoomTypesReceived(
      environments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        environments.roomTypes = payload.rows[0].values;
      } else {
        environments.roomTypes = [];
      }
    },
    showSkeletonUpdated(environments: any, action: PayloadAction<any>) {
      environments.showSkeleton = action.payload;
    }
  }
});

export const {
  environmentTypesReceived,
  environmentTimeHereWeeklyReceived,
  environmentSpaceTypesReceived,
  environmentOfficeSpaceTypesReceived,
  environmentAssignmentTypesReceived,
  environmentRoomTypesReceived,
  showSkeletonUpdated
} = slice.actions;
export default slice.reducer;

export const getCurrentShowSkeleton = createSelector(
  (state: any) => state.entities.catalogs.environments,
  (environments: any) => environments.showSkeleton
);

/**
 * Gets environment types.
 */
export const getEnvironmentTypes = createSelector(
  (state: any) => state.entities.catalogs.environments,
  (environments: any) => environments.types
);

/**
 * Gets environment type here weekly.
 */
export const getEnvironmentTimeHereWeekly = createSelector(
  (state: any) => state.entities.catalogs.environments,
  (environments: any) => environments.percentTimeHereWeekly
);

/**
 * Gets environment space types.
 */
export const getEnvironmentSpaceTypes = createSelector(
  (state: any) => state.entities.catalogs.environments,
  (environments: any) => environments.spaceTypes
);

/**
 * Gets environment office space types.
 */
export const getEnvironmentOfficeSpaceTypes = createSelector(
  (state: any) => state.entities.catalogs.environments,
  (environments: any) => environments.officeSpaceTypes
);

/**
 * Gets environment assignment types.
 */
export const getEnvironmentAssignmentTypes = createSelector(
  (state: any) => state.entities.catalogs.environments,
  (environments: any) => environments.assignmentTypes
);

/**
 * Gets environment room types.
 */
export const getEnvironmentRoomTypeS = createSelector(
  (state: any) => state.entities.catalogs.environments,
  (environments: any) => environments.roomTypes
);
