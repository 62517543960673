import React from "react";
import { IonPopover, IonList, IonItem } from "@ionic/react";

const SortMenu: React.FC<any> = (props: any) => {
  const { popoverState, setShowPopover, onSortTable } = props;

  return (
    <>
      <IonPopover
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({
            showPopover: false,
            event: undefined,
            column: "",
            index: -1
          })
        }>
        <IonList>
          <IonItem
            className="cursor-pointer"
            onClick={() =>
              onSortTable(popoverState.column, "asc", popoverState.index)
            }
          >
            Ascending
          </IonItem>
          <IonItem
            className="cursor-pointer"
            onClick={() =>
              onSortTable(popoverState.column, "desc", popoverState.index)
            }
          >
            Descending
          </IonItem>
          <IonItem
            className="cursor-pointer"
            onClick={() => onSortTable(popoverState.column, "", -1)}
          >
            Clear
          </IonItem>
        </IonList>
      </IonPopover>
    </>
  );
};

export default SortMenu;
