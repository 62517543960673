import React from "react";
import { IonRow, IonCol, IonIcon, IonButton, useIonAlert } from "@ionic/react";
import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";

import { useAppSelector } from "../../store/Hooks";
import { getCurrentDevice, getCurrentOrientation } from "../../store/App";

const BaseBand: React.FC<any> = (props: any) => {
  const {
    isRowExpanded,
    onDelete,
    content,
    expandRowClick,
    message,
    hideLeftIcon,
    item,
    extraRightButtons,
    bandOnClick
  } = props;

  const currentOrientation = useAppSelector(getCurrentOrientation);
  const currentDevice = useAppSelector(getCurrentDevice);

  const [present] = useIonAlert();

  const getButtonSizeMd = () => {
    if (currentDevice === "desktop") {
      return "1";
    }

    if (currentOrientation === "portrait" && currentDevice === "tablet") {
      return "2.8";
    }

    if (currentOrientation === "landscape" && currentDevice === "tablet") {
      return "2.8";
    }
  };

  const getButtonSizeLg = () => {
    if (currentDevice === "desktop") {
      return "1.7";
    }

    if (currentOrientation === "portrait" && currentDevice === "tablet") {
      return "2";
    }

    if (currentOrientation === "landscape" && currentDevice === "tablet") {
      return "1.8";
    }
  };

  const getButtonSizeXl = () => {
    if (currentDevice === "desktop") {
      return "1.6";
    }
  };

  return (
    <IonRow className="flex flex-nowrap background-row-color mb-10 text-center cursor-pointer">
      <IonCol
        sizeMd=".7"
        sizeLg=".4"
        onClick={expandRowClick}
        className="cursor-pointer"
      >
        <div className="mt-10">
          {hideLeftIcon ? (
            <></>
          ) : (
            <IonIcon
              icon={isRowExpanded() ? chevronUpOutline : chevronDownOutline}
            ></IonIcon>
          )}
        </div>
      </IonCol>
      {content}
      <IonCol
        sizeMd={getButtonSizeMd()}
        sizeLg={getButtonSizeLg()}
        sizeXl={getButtonSizeXl()}
      >
        <div className="flex ion-align-items-center float-end">
          {extraRightButtons && extraRightButtons}
          <IonButton
            color="danger"
            size="small"
            className="font-bold text-12 w-75 h-32 mr-05"
            onClick={(e: any) => {
              e.stopPropagation();
              present({
                cssClass: "",
                message: message || "",
                buttons: [
                  "Cancel",
                  { text: "Ok", handler: () => onDelete(item.id) }
                ]
              });
            }}
          >
            {" "}
            DELETE
          </IonButton>
        </div>
      </IonCol>
    </IonRow>
  );
};

export default BaseBand;
