import React, { useEffect, useState } from "react";
import { IonCheckbox, IonLabel } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../../../store/Hooks";
import Loader from "../../../common/Loader";

const Checkbox: React.FC<any> = (props: any) => {
  const {
    index,
    label,
    onChange,
    environmentId,
    idEnvironmentBlocked,
    environmentsChecked,
    getCurrentShowCheckboxLoader,
    showCheckboxLoaderUpdated
  } = props;

  const dispatch = useAppDispatch();
  const showLoader = useAppSelector(getCurrentShowCheckboxLoader);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    setIsDisabled(environmentId === idEnvironmentBlocked);
  }, []);

  useEffect(() => {
    setIsChecked(environmentsChecked?.includes(environmentId));
  }, [
    environmentsChecked && environmentsChecked?.includes(environmentId) == true
  ]);

  const handleOnChange = (environmentId: number, value: boolean): void => {
    onChange(environmentId, value);
  };

  useEffect(() => {
    if (!showLoader) {
      setSelectedIndex(-1);
    }
  }, [!showLoader]);

  return (
    <div className={`flex ion-align-items-center ml-10 mr-10`}>
      <IonCheckbox
        name={`checkbox-${index + 1}`}
        onClick={(e: any) => {
          dispatch({
            type: showCheckboxLoaderUpdated.type,
            payload: true
          });
          setIsChecked(e.target.checked);
          setSelectedIndex(index);
          setTimeout(() => {
            handleOnChange(environmentId, e.target.checked);
          }, 300);
        }}
        checked={isChecked}
        mode="md"
        disabled={isDisabled}
      ></IonCheckbox>
      <IonLabel>&nbsp;{label}</IonLabel>
      <Loader
        visible={selectedIndex === index && showLoader}
        cssSpinnerClass={"absolute right-0"}
      />
    </div>
  );
};

export default Checkbox;
