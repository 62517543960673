import { useCallback, useRef } from "react";
import { LoadingOptions } from "@ionic/core";

import { getRequestOptions } from "../services/auth.service";

import axios from "axios";
import Cookie from "js-cookie";
import _, { find } from "lodash";
import { RequestSigner } from "aws4";
import moment from "moment";
import { getOAuth2Headers } from "../store/User";

const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;

export const getApiValuelistByName = (name: string) => {
  const url = `/valuelists?name=${name}`;
  return url;
};

export const getValuelistByName = async (name: string) => {
  const url = `${apiBaseUrl}${getApiValuelistByName(name)}`;

  const response: any = await axios.get(url);
  return response.data;
};

export const getValuelist = async () => {
  const url = `${apiBaseUrl}/valuelists`;
  const response: any = await axios.get(url);

  return response.data;
};

export const cleanObject = (obj: any, excludedProperties: string[]) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      excludedProperties.includes(propName)
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const getToken = () => {
  let userCookie = Cookie.getJSON("user");
  return userCookie.accessToken;
};

export const presetLoadingSettings = (message: any = "") => {
  return {
    message: message || "Working on it",
    spinner: "dots",
    translucent: true
  } as LoadingOptions;
};

export const capitalize = (str: any) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

/**
 * Capitalizes letter
 * @param str
 */
export const capitalizeLetter = (char: string) => {
  return char.toUpperCase();
};

/**
 * Makes api call
 * @param endpoint
 * @param method
 * @param data
 * @returns
 */
export const makeApiCall = async (
  endpoint: string,
  method: any = "POST",
  data: any = {},
  parameters: any = {},
  headers: any = {}
) => {
  const response = await axios.request({
    url: `${apiBaseUrl}${endpoint}`,
    method,
    data,
    params: parameters,
    headers
  });

  return response;
};

export const makeRequest =
  (params: any) => async (dispatch: any, getState: any) => {
    const { endpoint, method, data, parameters } = params;
    let { headers } = params;
    const oauth2Headers: any = await dispatch(getOAuth2Headers());
    headers = { ...headers, ...oauth2Headers };
    const response = await makeApiCall(
      endpoint,
      method,
      data,
      parameters,
      headers
    );

    return response;
  };
/**
 * Gets pach payload.
 * @param id
 * @param key
 * @param value
 * @returns
 */
export const getPatchPayload = async (
  id: number,
  key: string,
  value: string
) => {
  const payload: any = {
    id,
    [key]: value
  };

  return payload;
};

/**
 * Replaces object from list.
 * @param items
 * @param payload
 * @returns
 */
export const replaceObjectFromList = async (items: any, payload: any) => {
  const index = await getIndex(items, payload, "id");
  let object = { ...items[index] };
  items[index] = payload;

  return items;
};

/**
 * Updates object from list.
 */
export const updateObjectFromList = async (
  items: any,
  payload: any,
  property: string,
  key: any,
  value: any
) => {
  const index = await getIndex(items, payload, property);
  let object = { ...items[index] };
  object[key] = value;
  items[index] = object;

  return items;
};

/**
 * Gets index
 * @param items
 * @param payload
 * @param property
 * @returns
 */
export const getIndex = async (items: any, payload: any, property: any) => {
  return _.findIndex(items, { [property]: payload[property] });
};

/**
 * Builds base payload
 * @param array items
 * @param array payloadTemplate
 * @param object adjuster. This object add specific values that don't come with items in question.
 * Basic example.
 * [
 *  {
 *     idEvaluation: 1
 *  },
 *  {
 *     idEvaluation: 2
 *  }
 * ]
 */
export const buildPayload = async (
  items: any,
  payloadTemplate: any,
  adjuster: any = {}
) => {
  let payload: any = [];
  for (const [, item] of items.entries()) {
    let resource: any = {};
    for (const [, property] of payloadTemplate.entries()) {
      resource[property] = item[property];
    }

    if (Object.keys(adjuster).length > 0) {
      for (const [, value] of Object.entries(adjuster)) {
        let object: any = value;
        resource[object.key] = object.keyValue
          ? item[object.keyValue]
          : object.value;
      }
    }

    payload.push(resource);
  }

  return payload;
};

/**
 * Assigns ids to each inserted recommendation.
 * @param template
 * @param ids
 */
export const assignIds = async (payload: any, ids: any) => {
  let rows: any = [];

  for (const [index, row] of payload.entries()) {
    let r: any = { ...row };
    r.id = ids[index].id;

    rows.push(r);
  }

  return rows;
};

export const insertResource =
  (url: string, payload: any) => (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      try {
        const responsePromise = dispatch(
          makeRequest({
            endpoint: url,
            method: "POST",
            data: payload
          })
        );

        responsePromise
          .then((response: any) => {
            if (response.status == 200) {
              const insertedRowsPromise = assignIds(
                payload,
                response.data.successes
              );

              resolve(insertedRowsPromise);
            }
          })
          .catch((e: any) => {
            console.log(e);
            reject(e);
          });
      } catch (e) {
        reject(e);
      }
    });
  };

/**
 * Inserts resource proces
 */
export const insertResourceProcess =
  (
    items: any,
    payloadProperties: any,
    adjuster: any = {},
    endpointUrl: string,
    assignIdsToResponse: boolean = true
  ) =>
  (dispatch: any, getState: any) => {
    return new Promise((resolve, reject) => {
      try {
        // We get the payload base on recommendation template.
        const payloadPromise = buildPayload(items, payloadProperties, adjuster);
        payloadPromise
          .then((payload) => {
            const responsePromise = dispatch(
              makeRequest({
                endpoint: endpointUrl,
                method: "POST",
                data: payload
              })
            );
            responsePromise.then((response: any) => {
              if (response.status == 200 && assignIdsToResponse) {
                const insertedRowsPromise = assignIds(
                  payload,
                  response.data.successes
                );

                resolve(insertedRowsPromise);
              }
            });
          })
          .catch((e: any) => console.log(e));
      } catch (e) {
        reject(e);
      }
    });
  };

/**
 * Updates current list
 * @param newList
 * @param oldList
 * @param sort
 * @returns
 */
export const updateCurrentList = async (
  newList: any,
  oldList: any,
  sort: string = "asc"
) => {
  let temp = [...oldList];

  for (const [, row] of newList.entries()) {
    if (sort === "desc") {
      temp.unshift(row);
    } else {
      temp.push(row);
    }
  }

  return temp;
};

/**
 * Removes object from list.
 */
export const removeObjectFromList = (list: any, object: any) => {
  let temp = [...list];
  const index = _.findIndex(temp, { id: object.id });
  temp.splice(index, 1);

  return temp;
};

export const useLongPress = (
  // callback that is invoked at the specified duration or `onEndLongPress`
  callback: () => any,
  // long press duration in milliseconds
  ms = 300
) => {
  // used to persist the timer state
  // non zero values means the value has never been fired before

  const timerRef = useRef<number>(0);

  // clear timed callback
  const endTimer = () => {
    clearTimeout(timerRef.current || 0);
    timerRef.current = 0;
  };

  // init timer
  const onStartLongPress = useCallback(
    (e) => {
      // stop any previously set timers
      endTimer();
      // set new timeout
      timerRef.current = window.setTimeout(() => {
        callback();
        endTimer();
      }, ms);
    },
    [callback, ms]
  );

  // determine to end timer early and invoke the callback or do nothing
  const onEndLongPress = useCallback(() => {
    // run the callback fn the timer hasn't gone off yet (non zero)
    if (timerRef.current) {
      endTimer();
      callback();
    }
  }, [callback]);

  return [onStartLongPress, onEndLongPress, endTimer];
};

export const signRequest = (
  url: any,
  currentUserGoogleCredentials: any,
  method: any = "GET",
  data: any = {}
) => {
  let options: any = {};
  // const basePath = process.env.REACT_APP_BIDB_API_BASE_PATH;
  let requestOptions = getRequestOptions(`${url}`, "GET");

  const credentials: any = {
    accessKeyId: currentUserGoogleCredentials.Credentials.AccessKeyId,
    secretAccessKey: currentUserGoogleCredentials.Credentials.SecretKey,
    sessionToken: currentUserGoogleCredentials.Credentials.SessionToken
  };

  const signer = new RequestSigner(requestOptions, credentials);
  let signed: any = signer.sign();
  options.headers = signed.headers;
  delete options.headers.Host;

  options = signed.headers;
  console.log(options);
  return options;
};

/**
 * Avoids duplicate array value.
 * @param array
 * @param value
 * @returns
 */
export const avoidDuplicateArrayValue = (array: Array<any>, value: any) => {
  let temp: Array<any> = [...array];

  const index = _.indexOf(array, value);

  if (index === -1) {
    temp = [...temp, ...[value]];

    return temp;
  }

  return temp;
};

/**
 * Refresh google id token.
 * @param response
 * @returns
 */
export const refreshGoogleIdToken = (reloadAuthResponse: any) => {
  return new Promise((resolve, reject) => {
    reloadAuthResponse()
      .then((authResponse: any) => resolve(authResponse))
      .catch((error: any) => reject(error));
  });
};

/**
 * Gets temporary credentials.
 * @param tokenId
 */
export const getTemporaryCredentials = async (tokenId: string) => {
  const headers: any = {
    Authorization: tokenId
  };

  const endpoint = `/google/auth`;
  const response = await makeApiCall(endpoint, "GET", {}, {}, headers);

  if (response.status === 200) {
    let data: any = response.data;
    const { Expiration } = data.Credentials;
    const offset = moment(Expiration).utcOffset();

    // We convert expiration date to local time, google expiration is UTC.
    const expirationDate = moment
      .utc(Expiration)
      .utcOffset(offset)
      .format("YYYY-MM-DD HH:mm:ss");

    data.expirationDate = expirationDate;

    return data;
  }
};

/**
 * Gets sorted column class.
 * @param column
 * @param direction
 * @param sort
 * @returns
 */
export const getSortedColumnClass = (
  column: string,
  direction: string,
  sort: any
) => {
  let columnIndex;
  let directionIndex;

  if (sort && sort.columns.length > 1) {
    let sortColumn = [];
    sortColumn.push(sort.columns[0]);
    let tempColumn = [];
    tempColumn = column.split(",");

    columnIndex = _.indexOf(sortColumn, tempColumn[0]);
  } else {
    columnIndex = _.indexOf(sort && sort.columns ? sort.columns : [], column);
  }

  if (sort && sort.directions.length > 1) {
    let sortDirection = [];
    sortDirection.push(sort.directions[0]);

    directionIndex = _.indexOf(sortDirection, direction);
  } else {
    directionIndex = _.indexOf(
      sort && sort.directions ? sort.directions : [],
      direction
    );
  }

  if (columnIndex != -1 && directionIndex != -1) {
    return "icon-button-selected";
  }

  return "";
};

/**
 * Capitalizes first letter of a string
 * @param string separator
 * @param string separator
 */
export const capitalizeFirstLetter = (string: string) => {
  const separator = " ";
  const array: any = string.split(separator);
  const newArray: any[] = [];

  for (const [i, word] of array.entries()) {
    newArray.push(`${word.charAt(0).toUpperCase()}${word.slice(1)}`);
  }

  const newString = newArray.join(" ");

  return newString;
};
