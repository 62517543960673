import React from "react";

import BaseSideMenuList from "../../../common/BaseSideMenuList";

import _ from "lodash";

const SideMenuList: React.FC<any> = (props: any) => {
  const {
    openModal,
    list,
    showFormComponent,
    buttonLabel,
    deleteLabel,
    getSelectedIndex,
    setIndexSelected,
    workstationDeleteEndpoint,
    updateSortedList,
    setNoWorkstationSelected,
    setWorstationTypeSelected,
    resetList,
    idSelected,
    showSkeleton
  } = props;

  return (
    <BaseSideMenuList
      list={list}
      openModal={openModal}
      showTopButton={true}
      buttonLabel={buttonLabel}
      buttonClasses={
        "w-full h-32 rounded-4 text-14 letter-spacing-042 font-bold"
      }
      showFormComponent={showFormComponent}
      deleteLabel={deleteLabel}
      updateSortedList={updateSortedList}
      setNoWorkstationSelected={setNoWorkstationSelected}
      workstationDeleteEndpoint={workstationDeleteEndpoint}
      getSelectedIndex={getSelectedIndex}
      setIndexSelected={setIndexSelected}
      setWorstationTypeSelected={setWorstationTypeSelected}
      resetList={resetList}
      idSelected={idSelected}
      showSkeleton={showSkeleton}
    />
  );
};

export default SideMenuList;
