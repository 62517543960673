import React from "react";
import {
  IonModal,
  IonHeader,
  IonContent,
  IonRow,
  IonCol,
  IonImg,
  IonFooter,
  IonGrid
} from "@ionic/react";

import { iconImages } from "../../services/tabs.service";
import Loader from "./Loader";

const BaseModal: React.FC<any> = (props: any) => {
  const {
    title,
    isOpen,
    closeModal,
    cssClass,
    content,
    footer,
    titleSize,
    closeButtonSize,
    onDidDismiss,
    showLoader,
    contentRef
  } = props;

  return (
    <IonModal
      cssClass={cssClass}
      isOpen={isOpen}
      onDidDismiss={() => (onDidDismiss ? onDidDismiss() : null)}
      backdropDismiss={false}
    >
      <Loader visible={showLoader} />
      <IonHeader className="px-24px header-modal">
        <IonRow>
          <IonCol size={titleSize ? titleSize : "10"}>
            <p className="title-modal">{title}</p>
          </IonCol>
          <IonCol
            size={closeButtonSize ? closeButtonSize : "2"}
            className="flex align-center ion-justify-content-end"
          >
            <IonImg
              className="cursor-pointer object-fit"
              onClick={() => closeModal(false)}
              src={iconImages["closeIcon"]}
            ></IonImg>
          </IonCol>
        </IonRow>
      </IonHeader>
      <IonContent className="relative px-24px" ref={contentRef}>
        <IonGrid className="flex flex-column h-full">{content}</IonGrid>
      </IonContent>
      <IonFooter className="ion-no-border">{footer}</IonFooter>
    </IonModal>
  );
};

export default BaseModal;
