import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "environmentActivities",
  initialState: {
    list: [],
    total: 0,
    activityEnvironments: [],
    risks: [],
    showCheckboxLoader: false
  },
  reducers: {
    listReceived(environmentActivities: any, action: PayloadAction<any>) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        environmentActivities.list = payload.rows;
        environmentActivities.total = payload.rows.length;
      } else {
        environmentActivities.list = [];
        environmentActivities.total = 0;
      }
    },
    listUpdated(environmentActivities: any, action: PayloadAction<any>) {
      environmentActivities.list = action.payload;
      environmentActivities.total = action.payload.length;
    },
    activityEnvironmentsReceived(
      environmentActivities: any,
      action: PayloadAction<any>
    ) {
      environmentActivities.activityEnvironments = action.payload.rows;
    },
    activityEnvironmentsUpdated(
      environmentActivities: any,
      action: PayloadAction<any>
    ) {
      environmentActivities.activityEnvironments = action.payload;
    },
    activityRisksReceived(
      environmentActivities: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        environmentActivities.risks = payload.rows;
      } else {
        environmentActivities.risks = [];
      }
    },
    activityRisksUpdated(
      environmentActivities: any,
      action: PayloadAction<any>
    ) {
      environmentActivities.risks = action.payload;
    },
    totalEnvironmentActivitiesUpdated(
      environmentActivities: any,
      action: PayloadAction<any>
    ) {
      environmentActivities.total = action.payload;
    },
    showCheckboxLoaderUpdated(
      environmentActivities: any,
      action: PayloadAction<any>
    ) {
      environmentActivities.showCheckboxLoader = action.payload;
    }
  }
});

export const {
  listReceived,
  listUpdated,
  activityRisksReceived,
  activityRisksUpdated,
  totalEnvironmentActivitiesUpdated,
  activityEnvironmentsReceived,
  activityEnvironmentsUpdated,
  showCheckboxLoaderUpdated
} = slice.actions;
export default slice.reducer;

/**
 * Gets current showChekboxLoader.
 */
export const getCurrentShowCheckboxLoader = createSelector(
  (state: any) => {
    return state.evaluation.environment.activities;
  },
  (activities: any) => activities.showCheckboxLoader
);

/**
 * Gets current total environment activities.
 * @returns
 */
export const getCurrentTotalEnvironmentActivities =
  (
    currentEvaluationActivities: any = undefined,
    currentEnvironmentActivities: any = undefined
  ) =>
  (dispatch: any, getState: any) => {
    if (
      currentEvaluationActivities != undefined &&
      currentEnvironmentActivities != undefined
    ) {
      const total = currentEvaluationActivities.filter((activity: any) =>
        currentEnvironmentActivities
          ? currentEnvironmentActivities.filter(
              (env: any) => env.idActivity === activity.id
            ).length > 0 || activity.id === undefined
          : false
      ).length;

      return total;
    } else {
      return 0;
    }
  };

/**
 * Gets environment activities.
 */
export const getCurrentEnvironmentActivities = createSelector(
  (state: any) => {
    return state.evaluation.environment.activities;
  },
  (activities: any) => activities.list
);

/**
 * Gets activity environments.
 */
export const getCurrentActivityEnvironments = createSelector(
  (state: any) => {
    return state.evaluation.environment.activities;
  },
  (activities: any) => activities.activityEnvironments
);

/**
 * Gets current activity environments in runtime.
 * @returns
 */
export const getCurrentActivityEnvironmentsInRunTime =
  () => (dispatch: any, getState: any) => {
    const { activityEnvironments } =
      getState().evaluation.environment.activities;

    return activityEnvironments;
  };

/**
 * Gets activities risks.
 */
export const getCurrentActivitiesRisks = createSelector(
  (state: any) => {
    return state.evaluation.environment.activities;
  },
  (activities: any) => activities.risks
);

/**
 * Gets total environment activities.
 */
export const getTotalEnvironmentActivities = createSelector(
  (state: any) => {
    return state.evaluation.environment.activities;
  },
  (activities: any) => activities.total
);

/**
 * Updates total environment activities.
 * @returns
 */
export const updateTotalEnvironmentActivities =
  (currentEvaluationActivities: any) => (dispatch: any, getState: any) => {
    const currentEnvironmentActivities =
      getState().evaluation.environment.activities.list;
    const environmentActivities: any = currentEvaluationActivities.filter(
      (activity: any) =>
        currentEnvironmentActivities
          ? currentEnvironmentActivities.filter(
              (env: any) => env.idActivity === activity.id
            ).length > 0 || activity.id === undefined
          : false
    );

    dispatch({
      type: totalEnvironmentActivitiesUpdated.type,
      payload: environmentActivities.length
    });
  };
