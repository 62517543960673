import React, { useEffect, useRef, useState } from "react";
import { IonCol, IonLabel, IonRow } from "@ionic/react";

import LeftList from "./LeftList";
import RightList from "./RightList";
import { iconImages } from "../../../../../services/tabs.service";

const icons: any = {
  laptop: iconImages["laptopIcon"],
  display: iconImages["displayIcon"],
  "pointing device": iconImages["cursorIcon"],
  mouse: iconImages["cursorIcon"],
  keyboard: iconImages["keyboardIcon"],
  "tablet computer": iconImages["tabletComputerIcon"],
  "video camera": iconImages["videoCameraIcon"],
  accessories: iconImages["accessoryIcon"],
  accessory: iconImages["accessoryIcon"],
  hardware: iconImages["toolIcon"],
  seating: iconImages["chairIcon"],
  "work surface": iconImages["deskIcon"],
  storage: iconImages["cupboardIcon"],
  body: iconImages["body"]
};

const Content: React.FC<any> = (props: any) => {
  const {
    filters,
    selectedItems,
    selectItem,
    removeItem,
    isFilteredList,

    // picker
    isPicker,
    listUpdated,
    setSelectedItem,
    hasCustomItem,
    itemName,
    hideSmallIcon,
    pickOneElement,
    setPickedItems,
    itemClassName,
    category,
    removeItems,
    leftList,
    showIcon,
    listHeight,
    hideBoxIcon,
    groupedLeftList
  } = props;

  /**
   * Gets icon
   */
  const getIcon = (category: any) => {
    return icons[`${category.toLowerCase()}`];
  };

  return (
    <div className="px-24px">
      {filters}
      <IonRow className="relative mt-1 flex-1">
        <IonCol size={isPicker ? "12" : "6"}>
          <div className="relative flex flex-column h-full">
            <div className="mb-5 w-full">
              <IonLabel className="subtitle-modal">{`${
                isFilteredList ? "Filtered" : "Found"
              }  ${leftList && leftList.length} items`}</IonLabel>
            </div>
            <LeftList
              showIcon={showIcon}
              isPicker={isPicker}
              items={leftList}
              getIcon={getIcon}
              action={selectItem}
              listUpdated={listUpdated}
              setSelectedItem={setSelectedItem}
              hasCustomItem={hasCustomItem}
              itemName={itemName}
              hideSmallIcon={hideSmallIcon}
              pickOneElement={pickOneElement}
              setPickedItems={setPickedItems}
              itemClassName={itemClassName}
              category={category}
              removeItems={removeItems}
              listHeight={listHeight}
              hideBoxIcon={hideBoxIcon}
              groupedLeftList={groupedLeftList}
            />
          </div>
        </IonCol>
        {!isPicker && (
          <IonCol size="6">
            <div className="relative">
              <div className="mb-5 w-full">
                <IonLabel className="subtitle-modal">
                  Selected {selectedItems.length} items
                </IonLabel>
              </div>
              <RightList
                listHeight={listHeight}
                showIcon={showIcon}
                items={selectedItems}
                getIcon={getIcon}
                action={removeItem}
                hasCustomItem={hasCustomItem}
                itemName={itemName}
                hideSmallIcon={hideSmallIcon}
                itemClassName={itemClassName}
                category={category}
                hideBoxIcon={hideBoxIcon}
              />
            </div>
          </IonCol>
        )}
      </IonRow>
    </div>
  );
};

export default Content;
