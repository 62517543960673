import { useAppDispatch } from "../../store/Hooks";
import axios from "axios";
import Cookie from "js-cookie";
import { useHistory } from "react-router";
import { GoogleLogin } from "react-google-login";
import { userLogged, setGoogleData, getOAuth2Headers } from "../../store/User";
import "./loginWithGoogle.css";
import { setNoEquipmentSelected } from "../../store/evaluation/environment/workstation/Equipments";
import { getTemporaryCredentials } from "../../helpers/global.helper";

const googleClientId: any = process.env.REACT_APP_GOOGLE_CLIENT_ID?.toString();

const GoogleWithLogin = (props: any) => {
  const { showErrorMessage } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const responseGoogleSuccess = async (response: any) => {
    if (response.profileObj.email) {
      const { tokenId } = response;
      const data: any = await getTemporaryCredentials(tokenId);
      const { email } = response.profileObj;
      const user = await validateUser(email);

      if (user) {
        // Set valid user to a cookie and redirect to dashboard
        const userObject = {
          ...response.profileObj,
          idEvaluator: user.idEvaluator,
          role: user.role
        };

        dispatch(
          setGoogleData({
            data,
            tokenId,
            response,
            userObject
          })
        );
        dispatch({
          type: setNoEquipmentSelected.type,
          payload: true
        });
        dispatch(userLogged(true));
        Cookie.remove("user");
        Cookie.set("user", JSON.stringify(userObject));
        history.replace(`/dashboard`);
      }
    }
  };

  /**
   * Validates user
   * @param {email} userEmail
   * @returns
   */
  const validateUser = async (userEmail: string) => {
    const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;
    const apiUsers = process.env.REACT_APP_BIDB_API_USERS;
    const headers = await dispatch(getOAuth2Headers());

    const response = await axios.get(
      `${apiBaseUrl}${apiUsers}?accountName=${userEmail}`,
      {
        headers
      }
    );

    if (response.data.rows.length === 0) {
      showErrorMessage("Account not found");
      return false;
    }

    const user = response.data.rows[0];

    if (!user.active) {
      showErrorMessage("Account not active");
      return false;
    }

    if (
      user.role &&
      user.role.toLowerCase() !== process.env.REACT_APP_BIDB_ROLE
    ) {
      showErrorMessage("User not authorized");
      return false;
    }

    if (
      !user.idEvaluator ||
      user.idEvaluator === undefined ||
      user.idEvaluator === null
    ) {
      showErrorMessage("Evaluator record not found");
      return false;
    }

    return user;
  };

  const responseGoogleError = (response: string) => {
    console.log(response);
    console.log("Error login");
  };

  return (
    <GoogleLogin
      clientId={googleClientId}
      className="btn-google-login"
      buttonText="LOGIN WITH GOOGLE"
      onSuccess={responseGoogleSuccess}
      onFailure={responseGoogleError}
      cookiePolicy={"single_host_origin"}
      isSignedIn={true}
    />
  );
};
export default GoogleWithLogin;
