import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "risks",
  initialState: {
    list: [],
    originalList: [],
    categories: [],
    severities: [],
    subCategories: [],
    originalSubCategories: [],
    riskBodyPositions: [],
    riskCauseTypes: []
  },
  reducers: {
    riskVsReceived(risks: any, action: PayloadAction<any>) {
      const { payload } = action;
      // In this reducer we update 2 list, because of filter in add modal.
      // The second one is used to initialize main list.
      if (payload.rows && payload.rows.length > 0) {
        risks.list = payload.rows;
        risks.originalList = payload.rows;
      } else {
        risks.list = [];
        risks.originalList = [];
      }
    },
    riskVsUpdated(risks: any, action: PayloadAction<any>) {
      risks.list = action.payload;
    },
    riskCategoriesReceived(risks: any, action: PayloadAction<any>) {
      risks.categories = action.payload;
    },
    riskSubCategoriesReceived(risks: any, action: PayloadAction<any>) {
      risks.subCategories = action.payload;
      risks.originalSubCategories = action.payload;
    },
    riskSubCategoriesUpdated(risks: any, action: PayloadAction<any>) {
      risks.subCategories = action.payload;
    },
    riskSeveritiesReceived(risks: any, action: PayloadAction<any>) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        risks.severities = payload.rows[0].values;
      } else {
        risks.severities = [];
      }
    },
    riskBodyPositionsReceived(risks: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows)
        risks.riskBodyPositions = action.payload.rows[0].values;
      else risks.riskBodyPositions = [];
    },
    riskCauseTypesReceived(risks: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows)
        risks.riskCauseTypes = action.payload.rows[0].values;
      else risks.riskCauseTypes = [];
    }
  }
});

export const {
  riskVsReceived,
  riskVsUpdated,
  riskCategoriesReceived,
  riskSeveritiesReceived,
  riskSubCategoriesReceived,
  riskSubCategoriesUpdated,
  riskBodyPositionsReceived,
  riskCauseTypesReceived
} = slice.actions;
export default slice.reducer;

/**
 * Gets risk vs.
 */
export const getRiskVs = createSelector(
  (state: any) => state.entities.catalogs.risks,
  (risks: any) => risks.list
);

/**
 * Gets risk categories;
 */
export const getRiskCategories = createSelector(
  (state: any) => state.entities.catalogs.risks,
  (risks: any) => risks.categories
);

/**
 * Gets original riskvs list.
 */
export const getOriginalRiskVs = createSelector(
  (state: any) => state.entities.catalogs.risks,
  (risks: any) => risks.originalList
);

/**
 * Gets risk subcategories.
 */
export const getRiskSubCategories = createSelector(
  (state: any) => state.entities.catalogs.risks,
  (risks: any) => risks.subCategories
);

/**
 * Gets risk severities.
 */
export const getRiskSeverities = createSelector(
  (state: any) => state.entities.catalogs.risks,
  (risks: any) => risks.severities
);

/**
 * Gets  original risk subcategories.
 */
export const getOriginalRiskSubCategories = createSelector(
  (state: any) => state.entities.catalogs.risks,
  (risks: any) => risks.originalSubCategories
);

/**
 * Gets Risk Body Positions
 */
export const getRiskBodyPositions = createSelector(
  (state: any) => state.entities.catalogs.risks,
  (risks) => risks.riskBodyPositions
);

/**
 * Gets Risk Cause Types
 */
export const getRiskCauseTypes = createSelector(
  (state: any) => state.entities.catalogs.risks,
  (risks) => risks.riskCauseTypes
);
