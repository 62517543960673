import React from "react";
import { IonLabel, IonInput, IonImg } from "@ionic/react";
import SEARCH from "../../assets/search.png";
import _ from "lodash";

const Search: React.FC<any> = (props: any) => {
  const { fetchEvaluations, valueSearchText } = props;

  /**
   * Handles on change event
   * @param {*} e
   */
  const handleOnChange = _.debounce(async (e: any) => {
    fetchEvaluations(e.target.value);
  }, 2000);

  return (
    <div className="search form-group main-column">
      <IonLabel className="form-group-input-text-title">Search</IonLabel>
      <IonInput
        type="text"
        className="form-control input-lg form-group-input-text margin-remover"
        placeholder="Search worker name, company, location"
        value={valueSearchText}
        onIonChange={(e) => handleOnChange(e)}
      ></IonInput>
      <IonImg className="icon-image z-2" src={SEARCH} alt="search"></IonImg>
    </div>
  );
};

export default Search;
