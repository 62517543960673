import classNames from "classnames";
import React from "react";

const RecommendationItem: React.FC<any> = (props: any) => {
  const { item, isPicker, groupedLeftList, index } = props;

  return (
    <div
      className={`pl-05 pr-1 h-94px m-h-94px max-h-94px flex flex-column ${
        !isPicker ? "w-290" : ""
      } top-2 ${
        groupedLeftList && item.group && item.group === true
          ? "grouped-col"
          : ""
      }`}
      key={index}
    >
      <div className={`h-auto mb-4 cursor-pointer flex ion-align-items-center`}>
        <p
          className={`name-item mb-1 text-15 font-bold ${
            groupedLeftList &&
            groupedLeftList === true &&
            item.group &&
            item.group === true
              ? "font-italic"
              : ""
          }`}
        >
          {item.category}:&nbsp;{" "}
          <span
            className={`name-value text-15 ${
              groupedLeftList &&
              groupedLeftList === true &&
              item.group &&
              item.group === true
                ? "font-italic"
                : ""
            }`}
          >
            {item.subCategory}
          </span>
        </p>
      </div>
      <div className="cursor-pointer mt-1 h-15 m-h-15px max-h-15px">
        <p
          className={classNames("name-item mb-3, truncate-text", {
            "font-italic": groupedLeftList && item.group && item.group === true
          })}
        >
          <span
            className={classNames("base-text text-black-4F4F4F font-bold", {
              "font-italic":
                groupedLeftList && item.group && item.group === true
            })}
          >
            {item.name}
          </span>
        </p>
      </div>
      <div className="cursor-pointer h-44 m-h-44px max-h-44px">
        <div
          className={classNames(
            "name-item mb-3 name-value text-12 relative truncate-overflow  max-h-44px",
            {
              "font-italic":
                groupedLeftList && item.group && item.group === true
            }
          )}
        >
          {item.description}
        </div>
      </div>
    </div>
  );
};

export default RecommendationItem;
