import React, { useEffect, useState } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonText
} from "@ionic/react";
import { caretDownOutline, caretForwardOutline } from "ionicons/icons";

import classNames from "classnames";
import _ from "lodash";

const BaseSideMenu: React.FC<any> = (props: any) => {
  const {
    title,
    menuOptions,
    titleBarClasses,
    itemName,
    hideLeftIcon,
    subItems,
    subItemName,
    id,
    contentId,
    parentIndex,
    setParentIndex,
    onSubItemClick,
    currentOrganizationIndex,
    currentLocationIndex
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedSubItemIndex, setSelectedSubItemIndex] = useState(-1);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const handleSelectIndex = (index: number) => {
    let currentSelectedIndex = -1;
    let open = !openSubmenu;

    if (selectedIndex !== index) {
      currentSelectedIndex = index;
      open = true;
    }

    setOpenSubmenu(open);
    setSelectedIndex(currentSelectedIndex);
  };

  const handleSubItemClick = (subItem: any, index: number) => {
    setSelectedSubItemIndex(index);
    setParentIndex(selectedIndex);
    onSubItemClick(subItem);
  };

  const getSubItems = (id: any) => {
    const filtered = _.filter(subItems, { organizationName: id });

    return filtered;
  };

  useEffect(() => {
    if (currentOrganizationIndex > -1) {
      setOpenSubmenu(true);
    }

    setSelectedIndex(currentOrganizationIndex);
  }, [currentOrganizationIndex && currentOrganizationIndex]);

  useEffect(() => {
    setSelectedSubItemIndex(currentLocationIndex);
  }, [currentLocationIndex && currentLocationIndex]);

  return (
    <IonMenu
      side="start"
      contentId={contentId}
      className="y-side-menu"
      menuId={contentId}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle
            className={classNames("text-left", titleBarClasses)}
            slot="start"
          >
            {title}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {menuOptions.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <IonItem key={index}>
                <div className="flex ion-align-items-center">
                  <IonIcon
                    className="text-black cursor-pointer"
                    icon={
                      openSubmenu && selectedIndex === index
                        ? caretDownOutline
                        : caretForwardOutline
                    }
                    onClick={() => handleSelectIndex(index)}
                  />
                  <IonText
                    className="ml-1 cursor-pointer"
                    onClick={() => handleSelectIndex(index)}
                  >
                    {item[itemName]}
                  </IonText>
                  {!hideLeftIcon && <IonIcon icon={caretDownOutline} />}
                </div>
              </IonItem>
              {openSubmenu &&
                selectedIndex === index &&
                getSubItems(item[id]).map(
                  (subitem: any, subItemIndex: number) => (
                    <IonItem
                      className={classNames("cursor-pointer", {
                        "item-active":
                          parentIndex === selectedIndex &&
                          selectedSubItemIndex === subItemIndex
                      })}
                      key={index + subItemIndex + 1}
                      onClick={() => handleSubItemClick(subitem, subItemIndex)}
                    >
                      <IonText className="ml-2-5">
                        {subitem[subItemName]}
                      </IonText>
                    </IonItem>
                  )
                )}
            </React.Fragment>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default BaseSideMenu;
