import React from "react";
import { IonList } from "@ionic/react";

const CollapsedSideMenuContent: React.FC<any> = (props: any) => {
  return (
    <>
      <IonList>
        {/* <IonItem className="cursor-pointer"></IonItem> */}
      </IonList>
    </>
  );
};

export default CollapsedSideMenuContent;
