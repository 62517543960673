import React, { useEffect, useState } from "react";
import { IonCol, IonRow, IonItem } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getCurrentEquipment,
  getLaptopIds
} from "../../../../../store/evaluation/environment/workstation/Equipments";

import SelectForm from "../../../../common/controls/SelectForm";
import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";
import YesNoInput from "../../../../common/controls/YesNoInput";
import Emea from "../../../../common/controls/Emea";

import "../../../../../css/bidb.css";
import "../../../../../css/equipment.css";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import Checkbox from "../workstations/Checkbox";
import KeyboardTypeCheckbox from "./KeyboardTypeCheckbox";

const KeyboardForm: React.FC<any> = (props: any) => {
  const { updateValue, getSelectedText, hasLaptopValue } = props;

  const dispatch = useAppDispatch();
  const data = useAppSelector(getCurrentEquipment);
  const keyboardDeviceTrayType = dispatch(
    getListByName("Keyboard_Device_Tray_Type")
  );

  const [laptopIds] = useState<any>(dispatch(getLaptopIds()));
  const [label, setLabel] = useState<string | null>(null);
  const [laptopId, setLaptopId] = useState<number | null>();
  const namesTypes = ["Curved", "Split", "Tented"];
  const [currentTypes, setCurrentTypes] = useState<any>([]);
  const [trayType, setTrayType] = useState<string | null>(null);
  const [trayName, setTrayName] = useState<string | null>(null);

  useEffect(() => {
    setLaptopId(
      data &&
        data &&
        data.idLaptopEquipment &&
        data.idLaptopEquipment !== null &&
        parseInt(data.idLaptopEquipment)
    );
    setLabel(data && data.label);
    setCurrentTypes(data && data.types ? data.types : []);
    setTrayType(data && data.trayType);
    setTrayName(data && data.trayName);
  }, [data && data.id]);

  const saveKeyboardTypes = async (types: any) => {
    updateValue("types", types);
  };

  const updateKeyboardType = (key: any, value: any) => {
    let isExists = [...currentTypes].includes(key);

    if (value && !isExists) {
      setCurrentTypes([...currentTypes, key]);
      saveKeyboardTypes([...currentTypes, key]);
    } else {
      let temp = [...currentTypes];
      const index = temp.indexOf(key);
      temp.splice(index, 1);
      setCurrentTypes(temp);
      saveKeyboardTypes(temp);
    }
  };

  const renderKeyboardType = (type: string, index: number) => {
    return (
      <IonItem className="ion-no-padding comboBoxCss" key={index}>
        <KeyboardTypeCheckbox
          name={`${type}`}
          label={`${type}`}
          updateKeyboardType={updateKeyboardType}
          getCurrentWorkstation={getCurrentEquipment}
        />
      </IonItem>
    );
  };

  return (
    <>
      <IonRow>
        <Name nameLabel="keyboard Name" value={data && data.name} />
      </IonRow>
      <IonRow className="pt-3 top-bar">
        <IonCol size="6" className="pt-08 equipment-label">
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={updateValue}
            maxlength={16}
          />
        </IonCol>
        <IonCol size="6">
          <SelectForm
            label="Laptop"
            options={laptopIds}
            value={laptopId}
            name="idLaptopEquipment"
            selectedText={getSelectedText(laptopIds, laptopId)}
            onChange={(key: string, value: any) => {
              if (value && value !== null && value !== 0) {
                setLaptopId(parseInt(value));
                updateValue(key, parseInt(value));
              }
            }}
            keyValue={{
              key: "id",
              value: "label"
            }}
            clearValue={() => {
              setLaptopId(null);
              updateValue("idLaptopEquipment", null);
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <IonCol size="6" className="flex align-items-center">
          {namesTypes &&
            namesTypes &&
            namesTypes.map((type: any, index: number) => {
              return renderKeyboardType(type, index);
            })}
        </IonCol>
        <IonCol size="6"></IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesKeyboardNumeric"
          label="Uses Keyboard Numeric"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesSeparateNumeric"
          label="Uses Separate Numeric"
          onChange={updateValue}
        />
      </IonRow>
      <hr className="separator top-2" />
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesWristRest"
          label="Uses Wristrest"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="8"
          size-md="8"
          size-sm="8"
          size-sx="8"
          size="8"
        >
          <IonRow>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="wristrestAdhoc"
                label="Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="wristrestDesignated"
                label="Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <hr className="separator top-var" />
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesDeviceTray"
          label="Uses Device Tray"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="8"
          size-md="8"
          size-sm="8"
          size-sx="8"
          size="8"
        >
          <IonRow>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="trayAdhoc"
                label="Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="trayDesignated"
                label="Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow className="top-2 ion-justify-content-center ion-align-items-center">
        <IonCol size="6">
          <SelectForm
            label="Type"
            options={keyboardDeviceTrayType}
            value={trayType}
            name="trayType"
            onChange={(key: string, value: any) => {
              if (value) {
                setTrayType(value);
                updateValue(key, value);
              }
            }}
            clearValue={() => {
              setTrayType(null);
              updateValue("trayType", null);
            }}
          />
        </IonCol>
        <IonCol size="6" className="pt-08 equipment-label">
          <Input
            value={trayName}
            label="Name"
            name="trayName"
            onBlur={(key: string, value: any) => {
              setTrayName(value);
              updateValue(key, value);
            }}
          />
        </IonCol>
      </IonRow>
      <hr className="separator top-var" />
      <Emea></Emea>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          label="Positioned Easily"
          property="positionedEasily"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          label="Sufficient Space"
          property="hasSufficientSpace"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          label="Keys Readable"
          property="keysReadable"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          label="External"
          property="external"
          onChange={updateValue}
        />
      </IonRow>
    </>
  );
};

export default KeyboardForm;
