import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonButton,
  IonContent,
  IonHeader,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  useIonAlert,
  IonList,
  IonItem,
  IonCheckbox,
  IonFooter
} from "@ionic/react";
import { addSharp } from "ionicons/icons";

import { useAppDispatch } from "../../../../../store/Hooks";
import {
  getCurrentShowCheckboxLoader,
  showCheckboxLoaderUpdated
} from "../../../../../store/evaluation/environment/Risks";

import EnvironmentCheckboxes from "../../EnvironmentCheckboxes";

import { iconImages } from "../../../../../services/tabs.service";
import { getRiskName } from "../../../../../services/risk.service";

import classNames from "classnames";
import { indexOf, map } from "lodash";
import Loader from "../../../../common/Loader";

export const LinkRiskModal: React.FC<any> = (props) => {
  const { title, risks, filterEnvironments, saveSelectedRisks } = props;

  const [showModal, setShowModal] = useState(false);
  const [environmentsChecked, setEnvironmentsChecked] = useState<number[]>([]);
  const [filteredRisks, setFilteredRisks] = useState<any[]>([]);
  const [selectedRisks, setSelectedRisks] = useState<any>([]);
  const [presentAlert] = useIonAlert();
  const [allRiskEnvironment, setAllRiskEnvironment] = useState([]);
  const dispatch = useAppDispatch();

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const filterRisksByEnvironment = async (
    environmentId: number,
    isChecked: boolean
  ) => {
    if (isChecked) {
      setEnvironmentsChecked([...environmentsChecked, environmentId]);
      let environmentRisks: any = [];

      allRiskEnvironment.map((riskEnv: any) => {
        if (
          environmentId == riskEnv.idEnvironment &&
          indexOf(map(risks, "id"), riskEnv.idRisk) > -1
        ) {
          environmentRisks.push(riskEnv);
        }
      });

      const newFilteredRisks = risks.filter((risk: any) =>
        allRiskEnvironment
          ? allRiskEnvironment.filter(
              (riskEnv: any) =>
                riskEnv.idRisk == risk.id &&
                riskEnv.idEnvironment === environmentId
            ).length > 0
          : false
      );
      setFilteredRisks([...filteredRisks, ...newFilteredRisks]);
    } else {
      let newEnvironmentsChecked: any = environmentsChecked.filter(
        (env) => env !== environmentId
      );

      setEnvironmentsChecked(newEnvironmentsChecked);
      setFilteredRisks([]);

      if (newEnvironmentsChecked.length > 0) {
        let temp: any = [];

        for (const [, env] of newEnvironmentsChecked.entries()) {
          let newFilteredRisks = risks.filter((risk: any) =>
            allRiskEnvironment
              ? allRiskEnvironment.filter(
                  (envrisk: any) =>
                    envrisk.idRisk === risk.id && envrisk.idEnvironment === env
                ).length > 0
              : false
          );

          temp = [...temp, ...newFilteredRisks];
        }

        setFilteredRisks(temp);
      } else {
        setFilteredRisks([]);
      }
    }

    dispatch({
      type: showCheckboxLoaderUpdated.type,
      payload: false
    });
  };

  const isRiskChecked = (riskId: number) => {
    return selectedRisks.includes(riskId);
  };

  const onCheckedRisk = (riskId: number, isChecked: boolean) => {
    let newSelectedRisks = [];

    if (isChecked) {
      newSelectedRisks = [...selectedRisks, riskId];
    } else {
      newSelectedRisks = selectedRisks.filter((risk: any) => risk !== riskId);
    }
    setSelectedRisks(newSelectedRisks);
  };

  const getEnvironmentNameByRisk = (environmentId: number) => {
    let environmentName = "Not found";
    let environments = filterEnvironments?.filter(
      (env: any) => env.id === environmentId
    );
    if (environments.length > 0) environmentName = environments[0].name;
    return environmentName;
  };

  const saveChanges = () => {
    if (selectedRisks.length === 0) {
      presentAlert({
        cssClass: "my-css",
        message: "Select any risk",
        buttons: ["Ok"]
      });
      return;
    }

    setShowLoader(true);

    let risks: any = [];

    for (const [, id] of selectedRisks.entries()) {
      risks.push({
        id
      });
    }
    saveSelectedRisks(risks);
    setShowLoader(false);
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal == false) {
      setEnvironmentsChecked([]);
      setFilteredRisks([]);
      setSelectedRisks([]);
    } else {
      let riskEnvironment: any = [];
      for (const [, row] of risks.entries()) {
        for (const [, environment] of row.environments.entries()) {
          riskEnvironment.push(environment);
        }
      }

      setAllRiskEnvironment(riskEnvironment);
    }
  }, [showModal]);

  return (
    <div>
      <IonModal
        isOpen={showModal}
        cssClass="size-modal-y-90"
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
      >
        <Loader visible={showLoader} />
        <IonHeader className="px-24px header-modal">
          <IonRow>
            <IonCol size="11">
              <p className="text-white">{title}</p>
            </IonCol>
            <IonCol size="1" className="align-center">
              <IonImg
                className="vector-close"
                onClick={() => setShowModal(false)}
                src={iconImages["closeIcon"]}
              ></IonImg>
            </IonCol>
          </IonRow>
        </IonHeader>
        <IonContent>
          <IonRow className="px-24px">
            <IonCol size="12" className="mt-10">
              {risks.length}{" "}
              {`Risk${risks.length == 1 ? "" : "s"} for this Evaluation`}
            </IonCol>
          </IonRow>
          <IonRow className="px-24px">
            <IonCol size="12" className="mt-10">
              <p className="subtitle-modal3">Filter by Environments</p>
              <EnvironmentCheckboxes
                environments={filterEnvironments}
                environmentsChecked={environmentsChecked}
                onChangeEnvironment={filterRisksByEnvironment}
                showAddButton={false}
                getCurrentShowCheckboxLoader={getCurrentShowCheckboxLoader}
                showCheckboxLoaderUpdated={showCheckboxLoaderUpdated}
              />
            </IonCol>
          </IonRow>
          <IonRow className="px-24px">
            <IonCol size="12" className="mt-10">
              {filteredRisks?.length} Risks for the selected Environments
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonList>
                {filteredRisks.map((risk: any, index: number) => (
                  <IonItem
                    key={index}
                    className={classNames("px-24px", {
                      "item-checked": isRiskChecked(risk.id)
                    })}
                  >
                    <IonCol size="1.2" className="box-img">
                      <div className="img-border">
                        <IonImg
                          src={risk.imageUrl}
                          className="img-item"
                        ></IonImg>
                      </div>
                    </IonCol>
                    <IonCol size="7.8">
                      <div className="pl-05">
                        <p className="name-item mb-2">
                          <span className="name-value text-20">
                            {dispatch(getRiskName(risk.idRiskV))}
                          </span>
                        </p>
                      </div>
                      <div className="pl-05">
                        <p className="name-item mb-1">
                          <span className="name-value">{risk.description}</span>
                        </p>
                      </div>
                      <div className="pl-05">
                        <p className="name-item mb-1">
                          <span className="name-value">
                            {getEnvironmentNameByRisk(
                              risk.environments
                                ? risk.environments[0].idEnvironment
                                : 0
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="pl-05">
                        <p className="name-item mb-1">
                          {risk.category}:&nbsp;
                          <span className="name-value">{risk.subCategory}</span>
                        </p>
                      </div>
                    </IonCol>
                    <IonCol
                      size="2.5"
                      className="flex ion-justify-content-end ion-align-self-start"
                    >
                      <>
                        <p className="name-item text-20">
                          Severity:&nbsp;
                          <span className="name-value text-20">
                            {risk.severity}
                          </span>
                        </p>
                      </>
                    </IonCol>
                    <IonCol size=".5" className="box-icon">
                      <IonCheckbox
                        onIonChange={(e: any) =>
                          onCheckedRisk(risk.id, e.target.checked)
                        }
                        checked={isRiskChecked(risk.id)}
                      />
                    </IonCol>
                  </IonItem>
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonContent>
        <IonFooter>
          <IonRow className="px-24px">
            <IonCol className="display-flex ion-justify-content-end">
              <IonButton
                color="medium"
                className="btn-custom-modal btn-cancel ml-44"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </IonButton>
              <IonButton
                className="w-136 btn-add-selected ml-1"
                onClick={() => saveChanges()}
              >
                SAVE CHANGES
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonModal>
      <IonButton
        fill="clear"
        className="ion-float-right"
        onClick={() => setShowModal(true)}
      >
        <IonIcon src={addSharp}></IonIcon>&nbsp;ADD RISKS
      </IonButton>
    </div>
  );
};

export default LinkRiskModal;
