import React, { useEffect, useState } from "react";
import { IonGrid } from "@ionic/react";

import {
  getToken,
  insertResourceProcess,
  updateCurrentList
} from "../../../../../helpers/global.helper";
import { saveActivityEnvironment } from "../../../../../services/environment.service";
import ActivitiesRow from "./ActivitiesRow";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getCurrentEvaluation,
  getCurrentEvaluationEnvironment
} from "../../../../../store/evaluation/Current";
import {
  activitiesUpdated,
  getActivities
} from "../../../../../store/evaluation/Activities";
import {
  activityEnvironmentsUpdated,
  getCurrentActivityEnvironmentsInRunTime,
  getCurrentEnvironmentActivities,
  listUpdated,
  showCheckboxLoaderUpdated
} from "../../../../../store/evaluation/environment/Activities";
import TopBar from "./TopBar";

import {
  activityPayloadProperties,
  deleteActivity,
  environmentActivityPayloadProperties
} from "../../../../../services/activity.service";
import Loader from "../../../../common/Loader";

const ActivitiesTab: React.FC<any> = (props) => {
  const { updateEnvironmentsSideMenu, getRiskvsName } = props;
  const dispatch = useAppDispatch();
  const currentActivities = useAppSelector(getActivities);
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const currentEvaluation = useAppSelector(getCurrentEvaluation);
  const currentEnvironmentActivities = useAppSelector(
    getCurrentEnvironmentActivities
  );
  const activityDefaultExpanded: any = -1;
  const [activityIdExpanded, setActivityIdExpanded] = useState<any>(
    activityDefaultExpanded
  );
  const [allowActivityPersistence, setAllowActivityPersistence] =
    useState(false);
  const [currentActivity, setCurrentActivity] = useState<any>(undefined);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const setAllActivities = (activities: any) => {
    dispatch({
      type: activitiesUpdated.type,
      payload: activities
    });
  };

  const isValidActivityId = (activity: any, id: any) => activity["id"] === id;

  const setEnvironmentActivities = (environmentActivities: any) => {
    dispatch({
      type: listUpdated.type,
      payload: environmentActivities
    });
  };

  const onClickAdd = async () => {
    setShowLoader(true);
    dispatch(
      insertResourceProcess(
        [{ idPreviousActivity: null, workingPositions: ["Seated"] }],
        activityPayloadProperties,
        {},
        `/evaluations/${currentEvaluation && currentEvaluation.id}/activities`
      )
    )
      .then(async (rows: any) => {
        setActivityIdExpanded(rows[rows.length - 1].id);

        // Environment activities
        const relationRows = await dispatch(
          insertResourceProcess(
            rows,
            environmentActivityPayloadProperties,
            {
              idEnvironment: {
                key: "idEnvironment",
                value: currentEnvironment && currentEnvironment.id
              },
              idActivity: {
                key: "idActivity",
                keyValue: "id"
              }
            },
            `/activityenvironments`
          )
        );

        const updatedRows = await updateCurrentList(
          rows,
          currentActivities,
          "desc"
        );
        setAllActivities(updatedRows);

        const updatedRelationRows = await updateCurrentList(
          relationRows,
          currentEnvironmentActivities
        );
        setEnvironmentActivities(updatedRelationRows);
        setShowLoader(false);
      })
      .catch((e: any) => {
        console.log(e);
        setShowLoader(false);
      });
  };

  const onClickActivity = (activity: any) => {
    let newActivityIdExpanded = activityDefaultExpanded;
    if (activityIdExpanded !== activity.id) newActivityIdExpanded = activity.id;
    if (activity.id !== undefined) {
      let currentActivity = currentActivities.filter((item: any) =>
        isValidActivityId(item, activity.id)
      );
      if (currentActivity.length > 0) {
        setAllowActivityPersistence(false);
        setCurrentActivity(currentActivity[0]);
      }
    }
    setActivityIdExpanded(newActivityIdExpanded);
  };

  const updateRow = (
    activityId: any,
    updatedActivity: any,
    allowPersistence: boolean = true
  ) => {
    let updatedActivities = currentActivities.map((activity: any) =>
      isValidActivityId(activity, activityId)
        ? { ...activity, ...updatedActivity }
        : activity
    );

    setAllowActivityPersistence(allowPersistence);
    setAllActivities(updatedActivities);
  };

  const insertActivityEnvironment = async (payloadActivityEnvironment: any) => {
    dispatch(saveActivityEnvironment(payloadActivityEnvironment))
      .then(async (responseData: any) => {
        if (responseData["successes"]) {
          let newPayloadActivityEnvironment = {
            ...payloadActivityEnvironment,
            id: responseData.successes[0].id
          };
          const currentActivityEnvironmentsInRuntime = dispatch(
            getCurrentActivityEnvironmentsInRunTime()
          );
          const updatedRelationRows = await updateCurrentList(
            [newPayloadActivityEnvironment],
            currentActivityEnvironmentsInRuntime
          );
          dispatch({
            type: activityEnvironmentsUpdated.type,
            payload: updatedRelationRows
          });

          setTimeout(() => {
            dispatch({
              type: showCheckboxLoaderUpdated.type,
              payload: false
            });
          }, 500);
        }
      })
      .catch((error) => console.log("error-saveActivityEnviroments:", error));
  };

  const onAddEnvironment = (activityId: number, data: any) => {
    let payloadActivityEnvironment = {
      idActivity: activityId,
      idEnvironment: data.id,
      token: getToken()
    };
    insertActivityEnvironment(payloadActivityEnvironment);
  };

  const removeActivity = async (activityId: any) => {
    let updatedActivities = currentActivities.filter(
      (activity: any) => !isValidActivityId(activity, activityId)
    );
    setAllActivities(updatedActivities);
    updateTotalEnvironmentActivities(activityId);
    if (activityId !== undefined) {
      dispatch(deleteActivity(activityId));
    }
  };

  const updateTotalEnvironmentActivities = (activityId: any) => {
    let updatedActivities = currentEnvironmentActivities.filter(
      (env: any) => env.idActivity !== activityId
    );
    dispatch({
      type: listUpdated.type,
      payload: updatedActivities
    });
  };

  const fechActivitiesByEnvironment = () => {
    return currentActivities.filter((activity: any) =>
      currentEnvironmentActivities
        ? currentEnvironmentActivities.filter(
            (env: any) => env.idActivity === activity.id
          ).length > 0
        : false
    );
  };

  const setValueActivity = (property: string, value: any) => {
    let newActivity: any = { id: currentActivity.id };
    newActivity[property] = value;
    updateRow(currentActivity.id, newActivity);
    setCurrentActivity(newActivity);
  };

  useEffect(() => {
    if (currentEnvironment !== undefined) {
      setActivityIdExpanded(activityDefaultExpanded);
    }
  }, [currentEnvironment]);

  const handleAddActivity = () => {
    onClickAdd();
  };

  return (
    <div className="relative">
      <Loader visible={showLoader} />
      <IonGrid>
        <TopBar onAddActivity={handleAddActivity} />

        {currentActivities !== undefined &&
          fechActivitiesByEnvironment().map((activity: any, index: number) => (
            <ActivitiesRow
              key={index}
              activity={activity}
              setValueActivity={setValueActivity}
              onClickActivity={onClickActivity}
              onDeleteActivity={removeActivity}
              activityIdExpanded={activityIdExpanded}
              onClickOpenEnvironmentForm={async (env: any) => {
                onAddEnvironment(activity.id, env);
                updateEnvironmentsSideMenu();
              }}
              insertActivityEnvironment={insertActivityEnvironment}
              getRiskvsName={getRiskvsName}
            />
          ))}
      </IonGrid>
    </div>
  );
};

export default ActivitiesTab;
