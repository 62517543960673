import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getIsFilteredList,
  getSelectedType,
  setCurrentEquipment,
  setEquipmentIndexSelected,
  setNoEquipmentSelected,
  setShowAddWorkstationEquipmentModal,
  setEquipmentTypeSelected,
  getSortedEnvironmentEquipments,
  isFilteredEquipmentsSet,
  showEquipmentPickerSet,
  updateEnvironmentEquipments,
  idSelected,
  getLaptopIds
} from "../../../../../store/evaluation/environment/workstation/Equipments";
import {
  equipmentsUpdated,
  getEquipmentManufacturer,
  getEquipments,
  getEquipmentSubcategories,
  getOriginalEquipments,
  getSortedEquipmentCategories,
  loadEquipmentCategories,
  equipmentSubCategoriesReceived,
  equipmentSubCategoriesUpdated,
  loadCurrentEquipmentSubcategories
} from "../../../../../store/catalogs/Equipments";
import { getCurrentEvaluationEnvironment } from "../../../../../store/evaluation/Current";
import { getIfNewSession, userLogged } from "../../../../../store/User";

import BaseAddModal from "../add-modal/BaseAddModal";
import Filters from "../workstations/Filters";

import {
  insertWorkstationProcess,
  equipmentMapping
} from "../../../../../services/tabs.service";

import _, { filter, find } from "lodash";
import axios from "axios";

import {
  isFilteredOrganizationRegionEquipmentsSet,
  organizationRegionEquipmentsGrouped,
  filterCurrentOrganizationRegionEquipments
} from "../../../../../store/evaluation/OrganizationRegionEquipments";
import {
  getPatchPayload,
  makeRequest,
  updateObjectFromList
} from "../../../../../helpers/global.helper";
import {
  getRecommendations,
  recommendationsUpdated
} from "../../../../../store/evaluation/Recommendations";
import { getListByName } from "../../../../../store/catalogs/ValueList";

const endpointUrl = "/environments/<<>>/workstationequipments";

const AddEquipmentModal: React.FC<any> = (props: any) => {
  const {
    isOpen,
    isPicker,
    getOrganizationRegionEquipments,
    getOriginalGroupedOrganizationRegionEquipments,
    getIsFilteredOrganizationRegionEquipments,
    getOrganizationRegionEquipmentManufacturers,
    organizationRegionEquipmentsUpdated,
    recommendationIdExpanded,
    pickOneElement,
    resetList
  } = props;

  const dispatch = useAppDispatch();
  const recommendations = useAppSelector(getRecommendations);

  // This is catalog equipments.
  const equipments = useAppSelector(
    isPicker ? getOrganizationRegionEquipments : getEquipments
  );

  // Left side menu.
  const sortedEnvironmentEquipments = useAppSelector(
    getSortedEnvironmentEquipments
  );

  const originalEquipments: any = useAppSelector(
    isPicker
      ? getOriginalGroupedOrganizationRegionEquipments
      : getOriginalEquipments
  );

  const isFilteredList = useAppSelector(
    isPicker ? getIsFilteredOrganizationRegionEquipments : getIsFilteredList
  );
  // Selected type, this is used for looking specific form in equipments tab
  const selectedType = useAppSelector(getSelectedType);
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const isNewSession = useAppSelector(getIfNewSession);

  // Equipment categories is used for both.
  const categories = useAppSelector(getSortedEquipmentCategories);

  const subcategories = useAppSelector(getEquipmentSubcategories);

  const manufacturers = useAppSelector(
    isPicker
      ? getOrganizationRegionEquipmentManufacturers
      : getEquipmentManufacturer
  );

  const [filters, setFilters] = useState<any>({});
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const closeModal = async () => {
    dispatch({
      type: !isPicker
        ? setShowAddWorkstationEquipmentModal.type
        : showEquipmentPickerSet.type,
      payload: false
    });
  };

  useEffect(() => {
    if (isOpen) {
      const equipmentCategories: any = dispatch(
        getListByName("Equipment_Category")
      );
      dispatch(loadEquipmentCategories(equipmentCategories));

      const equipmentSubcategories: any = dispatch(
        loadCurrentEquipmentSubcategories()
      );
      dispatch({
        type: equipmentSubCategoriesReceived.type,
        payload: equipmentSubcategories
      });

      if (selectedType !== "" && isPicker) {
        let filterValue = selectedType;

        if (equipmentMapping[filterValue.toLowerCase()]) {
          filterValue = equipmentMapping[filterValue.toLowerCase()];
        }

        let filterObject: any;

        // We check if value is present in categories
        let index = equipmentCategories.indexOf(filterValue);

        if (index !== -1) {
          filterObject = {
            category: {
              sign: "=",
              value: filterValue
            }
          };

          loadEquipmentSubcategories(filterValue);
        } else {
          // if it doesn't, we check if it is present in subCategories
          index = equipmentSubcategories.indexOf(filterValue);

          if (index !== -1) {
            filterObject = {
              subCategory: {
                sign: "=",
                value: filterValue
              }
            };
          }
        }

        if (index !== -1) {
          setFilters(filterObject);
          if (
            (filterObject.category && filterObject.category.value !== "") ||
            (filterObject.subCategory && filterObject.subCategory.value !== "")
          ) {
            dispatch(filterCurrentOrganizationRegionEquipments(filterObject));
            dispatch({
              type: isFilteredOrganizationRegionEquipmentsSet.type,
              payload: true
            });
          }
        } else {
          dispatch(filterCurrentOrganizationRegionEquipments());
        }
      }
    }
  }, [isOpen]);

  const initializeFilter = () => {
    handleRemoveFilter();
    loadEquipmentSubcategories();
  };

  const attachEquipmentToRecommendation = async (item: any) => {
    setShowLoader(true);
    const payload = await getPatchPayload(
      recommendationIdExpanded,
      "idEquipment",
      item.idEquipment
    );

    try {
      await dispatch(
        makeRequest({
          endpoint: `/recommendations`,
          method: "PATCH",
          data: payload
        })
      );

      let temp = await updateObjectFromList(
        recommendations && [...recommendations],
        payload,
        "id",
        "idEquipment",
        item.idEquipment
      );

      // item name (equipment name)
      const list = originalEquipments;

      const object = _.find(list, {
        idEquipment: item.idEquipment
      });

      temp = await updateObjectFromList(
        recommendations && [...recommendations],
        payload,
        "id",
        "itemName",
        object ? object.name : ""
      );

      await dispatch({
        type: recommendationsUpdated.type,
        payload: temp
      });

      setShowLoader(false);
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSave = async (selectedItems: any) => {
    setShowLoader(true);
    const finalEndpointUrl = endpointUrl.replace("<<>>", currentEnvironment.id);

    const response: any = await dispatch(
      insertWorkstationProcess(
        selectedItems,
        false,
        "equipments",
        "idEquipment",
        finalEndpointUrl,
        sortedEnvironmentEquipments
      )
    );

    dispatch(updateEnvironmentEquipments(response.temp));

    dispatch({
      type: setEquipmentIndexSelected.type,
      payload: response.indexToBeSelected
    });

    dispatch({
      type: setCurrentEquipment.type,
      payload: response.currentWorkstation
    });

    dispatch({
      type: idSelected.type,
      payload: response.currentWorkstation.id
    });

    dispatch({
      type: setEquipmentTypeSelected.type,
      payload: response.toBeSelected.type
    });

    if (isNewSession) {
      dispatch(userLogged(false));
    }

    dispatch({
      type: setNoEquipmentSelected.type,
      payload: false
    });

    const laptopIds = dispatch(getLaptopIds());

    if (laptopIds.length === 1) {
      const rows: any = filter(response.temp, (o) => {
        return (
          o.type === "displays" ||
          o.type === "keyboards" ||
          o.type === "pointingDevices"
        );
      });

      if (rows.length > 0) {
        let payload = {} as any;

        for (const [, row] of rows.entries()) {
          payload = {
            [row.type]: {
              id: row.id,
              idLaptoptopEquipment: laptopIds[0].id
            }
          };
        }
        const r = await dispatch(
          makeRequest({
            endpoint: "/workstationequipments",
            method: "PATCH",
            data: payload
          })
        );

        if (r.status === 200) {
          let newRows = [];
          for (const [, row] of response.temp.entries()) {
            let r = { ...row };

            const object = find(rows, { id: r.id });

            if (!r.idLaptopEquipment) {
              if (object) {
                r.idLaptopEquipment = laptopIds[0].id;
              }
            }

            newRows.push(r);
          }

          if (newRows.length > 0) {
            dispatch(updateEnvironmentEquipments(newRows));
          }
        }
      }
    }

    setShowLoader(false);

    closeModal();
  };

  const handleSetFilter = (key: string, value: any, contains: boolean) => {
    let temp = { ...filters };

    if (value !== undefined && value !== "") {
      temp = {
        ...temp,
        ...{
          [key]: {
            sign: !contains ? "=" : "contains",
            value
          }
        }
      };

      setFilters(temp);

      if (key === "category") {
        if (temp.subCategory) {
          delete temp.subCategory;
        }

        setFilters(temp);
      }
    }
  };

  const handleRemoveFilter = (key: string = "") => {
    if (key !== "") {
      if (key == "category") {
        loadEquipmentSubcategories();
      }

      let temp = { ...filters };

      if (temp[key]) {
        temp = _.omit(temp, [key]);
      }

      setFilters(temp);
    } else {
      setFilters({});
    }
  };

  const equipmentOnDidDismiss = () => {
    // We set selectedType to empty value when equipment picker is closed.
    setTimeout(() => {
      if (isPicker) {
        dispatch({
          type: setEquipmentTypeSelected.type,
          payload: ""
        });
        dispatch({
          type: organizationRegionEquipmentsUpdated.type,
          payload: originalEquipments
        });
      } else {
        resetList(originalEquipments);
      }

      handleRemoveFilter();
    }, 300);
  };

  const loadEquipmentSubcategories = (category: string = "") => {
    dispatch({
      type: equipmentSubCategoriesUpdated.type,
      payload: dispatch(loadCurrentEquipmentSubcategories(category))
    });
  };

  const updateFilterValue = (filters: any) => {
    setFilters(filters);
  };

  return (
    <BaseAddModal
      showLoader={showLoader}
      title="Add Equipment"
      getItems={isPicker ? getOrganizationRegionEquipments : getEquipments}
      isOpen={isOpen}
      originalList={originalEquipments}
      closeModal={closeModal}
      saveButtonLabel={
        isPicker ? `SAVE CHANGES` : `ADD <<>> SELECTED EQUIPMENTS`
      }
      leftList={equipments}
      listUpdated={
        isPicker ? organizationRegionEquipmentsUpdated : equipmentsUpdated
      }
      setIsFilteredList={
        isPicker
          ? isFilteredOrganizationRegionEquipmentsSet
          : isFilteredEquipmentsSet
      }
      category="equipments"
      isFilteredList={isFilteredList}
      onDidDismiss={equipmentOnDidDismiss}
      organizationRegionWorkstationsGrouped={
        organizationRegionEquipmentsGrouped
      }
      filters={
        <Filters
          list={equipments}
          originalList={originalEquipments}
          categories={categories}
          subcategories={subcategories}
          manufacturers={manufacturers}
          isFilteredWorkstationsSet={
            isPicker
              ? isFilteredOrganizationRegionEquipmentsSet
              : isFilteredEquipmentsSet
          }
          loadWorkstationCategories={loadEquipmentCategories}
          listUpdated={
            isPicker ? organizationRegionEquipmentsUpdated : equipmentsUpdated
          }
          filters={filters}
          setFilter={handleSetFilter}
          removeFilter={handleRemoveFilter}
          loadWorkstationSubcategories={loadEquipmentSubcategories}
          updateFilterValue={updateFilterValue}
          sort={{
            columns: ["sortOrder"],
            directions: ["asc"]
          }}
        />
      }
      initializeFilter={initializeFilter}
      onSave={isPicker ? attachEquipmentToRecommendation : handleSave}
      // for picker modal
      isPicker={isPicker}
      pickOneElement={pickOneElement}
      itemClassName={!isPicker ? "w-208" : ""}
      hideSmallIcon={false}
    />
  );
};

export default AddEquipmentModal;
