import React from "react";
import { IonCol } from "@ionic/react";

const BandContent: React.FC<any> = (props: any) => {
  const { type, bandOnClick } = props;

  return (
    <>
      <IonCol>
        <div
          className="flex band-content w-full h-full"
          onClick={bandOnClick ? bandOnClick : null}
        >
          <div className="flex">
            <div className="flex-colum">
              <div className="flex">
                <p
                  className="mt-6 mb-0 w-auto"
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  {type}
                </p>
              </div>
            </div>
          </div>
        </div>
      </IonCol>
    </>
  );
};

export default BandContent;
