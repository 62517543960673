import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { find } from "lodash";

const slice = createSlice({
  name: "valueList",
  initialState: {
    list: [],
    reasonFilters: []
  },
  reducers: {
    listReceived(valueList: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        valueList.list = payload.rows;
      } else {
        valueList.list = [];
      }
    }
  }
});

export const { listReceived } = slice.actions;
export default slice.reducer;

/**
 * Gets list by nae.
 */
export const getListByName =
  (name: string = "") =>
  (dispatch: any, getState: any) => {
    const { list } = getState().entities.catalogs.valueList;

    if (name === "") {
      return [];
    }

    const object = find(list, function (o: any) {
      return o.name.toLowerCase() === name.toLowerCase();
    });

    if (object) {
      return object.values;
    }

    return [];
  };
