import React from "react";
import AddButton from "../../../../common/controls/AddButton";

import AddRow from "../../../../common/controls/AddRow";

const IssueBar: React.FC<any> = (props: any) => {
  const { handleOpenIssuePicker } = props;

  return (
    <AddRow
      label="Issues"
      buttons={
        <AddButton label="ATTACH ISSUE" onClick={handleOpenIssuePicker} />
      }
    />
  );
};

export default IssueBar;
