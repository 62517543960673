import React from "react";

import List from "./List";

import { iconImages } from "../../../../../services/tabs.service";
const addIcon = iconImages["addIcon"];

const LeftList: React.FC<any> = (props) => {
  const {
    items,
    action,
    getIcon,
    isPicker,
    listUpdated,
    setSelectedItem,
    hasCustomItem,
    itemName,
    hideSmallIcon,
    setPickedItems,
    pickOneElement,
    itemClassName,
    category,
    removeItems,
    showIcon,
    listHeight,
    hideBoxIcon,
    groupedLeftList
  } = props;

  return (
    <List
      listHeight={listHeight}
      listUpdated={listUpdated}
      items={items}
      action={action}
      getIcon={getIcon}
      icon={addIcon}
      isPicker={isPicker}
      setSelectedItem={setSelectedItem}
      hasCustomItem={hasCustomItem}
      itemName={itemName}
      hideSmallIcon={hideSmallIcon}
      setPickedItems={setPickedItems}
      pickOneElement={pickOneElement}
      itemClassName={itemClassName}
      category={category}
      removeItems={removeItems}
      clickOnDescription={true}
      showIcon={showIcon}
      hideBoxIcon={hideBoxIcon}
      groupedLeftList={groupedLeftList}
    />
  );
};

export default LeftList;
