import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "issues",
  initialState: {
    requestTypes: [],
    issueBodyLocations: [],
    issueSeverities: [],
    issuePersistences: [],
    issueFrequencies: [],
    issueDurations: []
  },
  reducers: {
    requestTypesReceived(issues: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows) {
        issues.requestTypes = action.payload.rows[0].values;
      } else {
        issues.requestTypes = [];
      }
    },
    issueBodyLocationsReceived(catalogs: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows)
        catalogs.issueBodyLocations = action.payload.rows[0].values;
      else catalogs.issueBodyLocations = [];
    },
    issueSeveritiesReceived(catalogs: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows)
        catalogs.issueSeverities = action.payload.rows[0].values;
      else catalogs.issueSeverities = [];
    },
    issuePersistencesReceived(catalogs: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows)
        catalogs.issuePersistences = action.payload.rows[0].values;
      else catalogs.issuePersistences = [];
    },
    issueFrequenciesReceived(catalogs: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows)
        catalogs.issueFrequencies = action.payload.rows[0].values;
      else catalogs.issueFrequencies = [];
    },
    issueDurationsReceived(catalogs: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows)
        catalogs.issueDurations = action.payload.rows[0].values;
      else catalogs.issueDurations = [];
    }
  }
});

export const {
  requestTypesReceived,
  issueBodyLocationsReceived,
  issueDurationsReceived,
  issueFrequenciesReceived,
  issuePersistencesReceived,
  issueSeveritiesReceived
} = slice.actions;
export default slice.reducer;

export const getRequestTypes = createSelector(
  (state: any) => state.entities.catalogs.issues,
  (issues: any) => issues.requestTypes
);

/**
 * Get Issue Body Locations
 */
export const getIssueBodyLocations = createSelector(
  (state: any) => state.entities.catalogs.issues,
  (issues) => issues.issueBodyLocations
);

/**
 * Get Issue Severities
 */
export const getIssueSeverities = createSelector(
  (state: any) => state.entities.catalogs.issues,
  (issues) => issues.issueSeverities
);

/**
 * Get Issue Persistences
 */
export const getIssuePersistences = createSelector(
  (state: any) => state.entities.catalogs.issues,
  (issues) => issues.issuePersistences
);

/**
 * Get Issue Frequencies
 */
export const getIssueFrequencies = createSelector(
  (state: any) => state.entities.catalogs.issues,
  (issues) => issues.issueFrequencies
);

/**
 * Get Issue Durations
 */
export const getIssueDurations = createSelector(
  (state: any) => state.entities.catalogs.issues,
  (issues) => issues.issueDurations
);
