import { combineReducers } from "redux";
import appReducer from "./App";
import entitiesReducer from "./Entities";
import evaluationReducer from "./evaluation/Index";
import userReducer from "./User";
import evaluatorReducer from "./Evaluator";
import appointmentsReducer from "./appointments/Index";

export default combineReducers({
  app: appReducer,
  user: userReducer,
  entities: entitiesReducer,
  evaluation: evaluationReducer,
  evaluator: evaluatorReducer,
  appointments: appointmentsReducer
});
