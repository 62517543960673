import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "risks",
  initialState: {
    list: [],
    rows: []
  },
  reducers: {
    risksReceived(risks: any, action: PayloadAction<any>) {
      const { payload } = action;
      // The second one is used to initialize main list.
      if (payload.rows && payload.rows.length > 0) {
        risks.list = payload.rows;
      } else {
        risks.list = [];
      }
    },
    risksUpdated(risks: any, action: PayloadAction<any>) {
      risks.list = action.payload;
    },
    rowsUpdated(risks: any, action: PayloadAction<any>) {
      risks.rows = action.payload;
    }
  }
});

export const { risksReceived, risksUpdated, rowsUpdated } = slice.actions;
export default slice.reducer;

/**
 * Gets evaluation risks.
 */
export const getEvaluationRisks = createSelector(
  (state: any) => state.evaluation.risks,
  (risks: any) => risks.list
);

/**
 * Gets current evaluation risks in runtime.
 * @returns
 */
export const getCurrentEvaluationRisksInRuntime =
  () => (dispatch: any, getState: any) => {
    const { list } = getState().evaluation.risks;

    return list;
  };

/**
 * Gets current environment risk rows.
 */
export const getCurrentEnvironmentRiskRows = createSelector(
  (state: any) => state.evaluation.risks,
  (risks) => risks.rows
);
