import { combineReducers } from "redux";
import currentReducer from "./Current";
import environmentReducer from "./environment/Index";
import issuesReducer from "./Issues";
import activitiesReducer from "./Activities";
import risksReducer from "./Risks";
import recommendationsReducer from "./Recommendations";
import organizationRegionEquipmentsReducer from "./OrganizationRegionEquipments";
import organizationRegionFurnituresReducer from "./OrganizationRegionFurnitures";

export default combineReducers({
  current: currentReducer,
  environment: environmentReducer,
  issues: issuesReducer,
  activities: activitiesReducer,
  risks: risksReducer,
  recommendations: recommendationsReducer,
  organizationRegionEquipments: organizationRegionEquipmentsReducer,
  organizationRegionFurnitures: organizationRegionFurnituresReducer
});
