import React from "react";
import { IonLabel, IonInput } from "@ionic/react";

import classNames from "classnames";

/**
 * This comment supports onBlur, onInput events, if any of them are not passed in,
 *  undefined is assigned to them.
 * @param props
 * @returns
 */

const Input: React.FC<any> = (props: any) => {
  const {
    name,
    label,
    onBlur,
    onInput,
    value,
    maxlength,
    className,
    style,
    disabled,
    placeholder
  } = props;

  return (
    <div
      className={classNames(`form-group custom-input`, {
        [className]: className ? true : false
      })}
      style={style}
    >
      <IonLabel className="form-group-input-text-title">{label}</IonLabel>
      <IonInput
        disabled={disabled}
        type="text"
        className="form-control input-lg form-group-input-text text-left"
        maxlength={maxlength ? maxlength : undefined}
        name={name}
        onInput={
          onInput
            ? (e: any) => onInput(e.target.name, e.target.value)
            : undefined
        }
        onIonBlur={
          onBlur ? (e: any) => onBlur(e.target.name, e.target.value) : undefined
        }
        value={value}
        placeholder={placeholder}
      ></IonInput>
    </div>
  );
};

export default Input;
