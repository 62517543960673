import React from "react";
import AddButton from "../../../../common/controls/AddButton";

import AddRow from "../../../../common/controls/AddRow";

const OptionBar: React.FC<any> = (props: any) => {
  const { handleOpenOptionForm } = props;

  return (
    <AddRow
      label="Alternative Make/Model"
      buttons={<AddButton label="ADD OPTION" onClick={handleOpenOptionForm} />}
    />
  );
};

export default OptionBar;
