import React, { useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import { getCurrentEquipment } from "../../../../../store/evaluation/environment/workstation/Equipments";

import SelectForm from "../../../../common/controls/SelectForm";
import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";
import LengthLabel from "../../../../common/controls/LengthLabel";
import YesNoInput from "../../../../common/controls/YesNoInput";

import "../../../../../css/equipment.css";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import Checkbox from "../workstations/Checkbox";

const LaptopForm: React.FC<any> = (props) => {
  const { updateValue } = props;
  const dispatch = useAppDispatch();
  const data = useAppSelector(getCurrentEquipment);

  const laptopDisplayPercentUsed = dispatch(
    getListByName("Laptop_Display_Percent_Used")
  );
  const laptopPositionHorizontal = dispatch(
    getListByName("Laptop_Position_Horizontal")
  );

  const laptopDisplaySize = dispatch(getListByName("Laptop_Display_Size"));
  const laptopPositionVertical = dispatch(
    getListByName("Laptop_Position_Vertical")
  );

  // States' variables.
  const [label, setLabel] = useState<string | null>(null);
  const [displayPercentUsed, setDisplayPercentUsed] = useState<string | null>(
    null
  );
  const [positionHorizontal, setPositionHorizontal] = useState<string | null>(
    null
  );
  const [displaySize, setDisplaySize] = useState<string | null>(null);
  const [positionVertical, setPositionVertical] = useState<string | null>(null);

  useEffect(() => {
    setLabel(data && data.label);
    setDisplayPercentUsed(
      data &&
        data.displayPercentUsed &&
        (~~(data.displayPercentUsed * 100)).toString()
    );
    setDisplaySize(data && data.displaySize);
    setPositionHorizontal(data && data.positionHorizontal);
    setPositionVertical(data && data.positionVertical);
  }, [data && data.id]);

  return (
    <>
      <IonRow>
        <Name nameLabel="Laptop Name" value={data && data.name} />
      </IonRow>
      <IonRow className="top-var">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="5"
          size-md="5"
          size-sm="5"
          size-sx="5"
          size="5"
        >
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={(key: string, value: any) => {
              setLabel(value);
              updateValue(key, value, data.id);
            }}
            maxlength={16}
          />
        </IonCol>
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="7"
          size-md="7"
          size-sm="7"
          size-sx="7"
          size="7"
        >
          <LengthLabel />
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesAsDisplay"
          label="Uses Laptop As Display"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow>
        <IonCol size="6" className="top-1">
          <SelectForm
            label="Laptop Display % Used"
            options={laptopDisplayPercentUsed}
            value={displayPercentUsed}
            name="displayPercentUsed"
            onChange={(key: string, value: any) => {
              if (value && value !== null && value !== 0) {
                setDisplayPercentUsed(value);
                updateValue(key, value / 100);
              }
            }}
            clearValue={() => {
              setDisplayPercentUsed(null);
              updateValue("displayPercentUsed", null);
            }}
          />
        </IonCol>
        <IonCol size="6" className="top-1">
          <SelectForm
            label="Laptop Position (Right/Left)"
            options={laptopPositionHorizontal}
            value={positionHorizontal}
            name="positionHorizontal"
            onChange={(key: string, value: any) => {
              setPositionHorizontal(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setPositionHorizontal(null);
              updateValue("positionHorizontal", null);
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6" className="top-1">
          <SelectForm
            label="Display Size"
            options={laptopDisplaySize}
            value={displaySize}
            name="displaySize"
            onChange={(key: string, value: any) => {
              setDisplaySize(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setDisplaySize(null);
              updateValue("displaySize", null);
            }}
          />
        </IonCol>
        <IonCol size="6" className="top-1">
          <SelectForm
            label="Laptop Position (Above/Below)"
            options={laptopPositionVertical}
            value={positionVertical}
            name="positionVertical"
            onChange={(key: string, value: any) => {
              setPositionVertical(value);
              updateValue(key, value);
            }}
            clearValue={() => {
              setPositionVertical(null);
              updateValue("positionVertical", null);
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesSeparatePointingDevice"
          label="Uses Separate Pointing Device"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesSeparateKeyboard"
          label="Uses Separate Keyboard"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesRiser"
          label="Uses Riser"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="8"
          size-md="8"
          size-sm="8"
          size-sx="8"
          size="8"
        >
          <IonRow>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="riserAdhoc"
                label="Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="riserDesignated"
                label="Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default LaptopForm;
