import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonCol,
  IonIcon,
  IonImg,
  IonLabel,
  IonRow,
  useIonAlert
} from "@ionic/react";
import { addSharp, chevronDownOutline, unlink } from "ionicons/icons";
import SelectForm from "../../../../common/controls/SelectForm";

import deleteVector from "../../../../../assets/delete.png";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import Input from "../../../../common/controls/Input";
import {
  insertResourceProcess,
  updateObjectFromList,
  removeObjectFromList,
  capitalizeFirstLetter,
  updateCurrentList
} from "../../../../../helpers/global.helper";
import Checkbox from "../../../../common/controls/Checkbox";
import AddForm from "../../../../common/controls/AddForm";
import {
  getRecommendations,
  recommendationsUpdated
} from "../../../../../store/evaluation/Recommendations";
import { iconImages } from "../../../../../services/tabs.service";
import {
  setEquipmentTypeSelected,
  showEquipmentPickerSet
} from "../../../../../store/evaluation/environment/workstation/Equipments";
import Badge from "../../../../common/controls/Badge";

import { filter, find, findIndex, indexOf, sortBy } from "lodash";
import OptionBar from "./OptionBar";
import IssueBar from "./IssueBar";
import { apiCall } from "../../../../../store/api";
import {
  getRecommendationEnvironmentIds,
  getRecommendationsRisks,
  getRecommendationsIssues,
  recommendationsEnvironmentsReceived,
  recommendationsEnvironmentsUpdated,
  recommendationsIssuesReceived,
  recommendationsRisksReceived,
  recommendationsRisksUpdated,
  acceptDeleteRiskUpdated,
  getCurrentAcceptDeleteRisk,
  getCurrentShowCheckboxLoader,
  showCheckboxLoaderUpdated,
  getCurrentRecommendationEnvironmentsInRuntime,
  linkedRisksUpdated,
  getCurrentLinkedRisks
} from "../../../../../store/evaluation/environment/Recommendations";
import EnvironmentCheckboxes from "../../EnvironmentCheckboxes";
import { getEvaluationIssues } from "../../../../../store/evaluation/Issues";
import { getEvaluationRisks } from "../../../../../store/evaluation/Risks";
import { getCurrentEvaluationEnvironment } from "../../../../../store/evaluation/Current";
import {
  deleteRecommendationEnvironment,
  patchRecommendation
} from "../../../../../services/recommendations.service";
import {
  setFurnitureTypeSelected,
  showFurniturePickerSet
} from "../../../../../store/evaluation/environment/workstation/Furnitures";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import {
  getEnvironmentRisks,
  environmentRisksUpdated
} from "../../../../../store/evaluation/environment/Risks";
import { risksUpdated } from "../../../../../store/evaluation/Risks";
import {
  deleteRiskRecommendation,
  deleteRisk
} from "../../../../../services/risk.service";
import Textarea from "../../../../common/Textarea";

const Recommendation: React.FC<any> = (props: any) => {
  const {
    data,
    recommendationIdExpanded,
    handleOpenIssuePicker,
    environments,
    handleDeleteRecommendationIssues,
    setShowEnvironmentModal,
    getRiskvsName,
    handleShowRiskModal,
    setRecommendationIcon
  } = props;

  const [presentAlert] = useIonAlert();

  const [openOption, setOpenOption] = useState(false);
  const [option, setOption] = useState("");
  const [isEquipmentFurniture] = useState<boolean>(
    data &&
      (data.category.toLowerCase() === "equipment" ||
      data.category.toLowerCase() === "furniture"
        ? true
        : false)
  );
  const [originalReasonFilters, setOriginalReasonFilters] =
    useState<Array<string>>();
  const [reasonsNoSelected, setReasonsNoSelected] = useState<Array<string>>([]);

  const [present] = useIonAlert();
  const dispatch = useAppDispatch();
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const recommendationPriorities = dispatch(
    getListByName("Recommendation_Priority")
  );
  const recommendationActionItems = dispatch(
    getListByName("Recommendation_Action_Item")
  );
  const recommendationResponsibleParties = dispatch(
    getListByName("Recommendation_Responsible_Party")
  );
  const recommendations = useAppSelector(getRecommendations);
  const evaluationIssues = useAppSelector(getEvaluationIssues);
  const evaluationRisks = useAppSelector(getEvaluationRisks);
  const recommendationsIssues = useAppSelector(getRecommendationsIssues);
  const recommendationsRisks = useAppSelector(getRecommendationsRisks);
  const recommendationEnvironmentIds = useAppSelector(
    getRecommendationEnvironmentIds
  );
  const currentEnvironmentRisks = useAppSelector(getEnvironmentRisks);
  const [responsibleParty, setResponsibleParty] = useState<string | null>();
  const [priority, setPriority] = useState<string | null>();
  const [workerCategories] = useState([
    "furniture",
    "equipment",
    "adjustment",
    "behavior",
    "resource"
  ]);

  const [category, setCategory] = useState<string | null>(null);
  const [adminComment, setAdminComment] = useState<string | null>(null);
  const [measurements, setMeasurements] = useState<string | null>(null);
  const [reasons, setReasons] = useState<Array<string>>([]);
  const [recommendationRisks, setRecommendationRisks] = useState([]);
  const [recommendationIssuesSorted, setRecommendationIssuesSorted] = useState(
    []
  );

  const currentLinkedRisks = useAppSelector(getCurrentLinkedRisks);

  // hidden
  const [isHiddenAdjustmentBehavior, setIsHiddenAdjustmentBehavior] = useState<
    boolean | null
  >(null);
  const [isHiddenResource, setIsHiddenResource] = useState<boolean>(true);

  const updateRecommendationRisks = (data: any) => {
    dispatch({
      type: recommendationsRisksReceived.type,
      payload: data
    });
  };

  useEffect(() => {
    fetchRecommendationsRisks();
  }, [recommendationsRisks.length]);

  const updateRecommendationIssues = (data: any) => {
    dispatch({
      type: recommendationsIssuesReceived.type,
      payload: data
    });
  };

  useEffect(() => {
    setRecommendationIssuesSorted(
      sortRecommendationIssues(recommendationsIssues)
    );
  }, [recommendationsIssues.length]);

  useEffect(() => {
    dispatch({
      type: recommendationsIssuesReceived.type,
      payload: []
    });

    dispatch({
      type: recommendationsRisksReceived.type,
      payload: []
    });

    setIsHiddenResource(hideResource(data?.category ?? ""));

    if (recommendationIdExpanded > 0) {
      dispatch(
        apiCall({
          url: `/recommendations/${
            recommendationIdExpanded && recommendationIdExpanded
          }/environments`,
          onSuccess: recommendationsEnvironmentsReceived.type
        })
      );

      dispatch(
        apiCall({
          url: `/recommendations/${
            recommendationIdExpanded && recommendationIdExpanded
          }/issues`,
          onSuccess: updateRecommendationIssues
        })
      );

      dispatch(
        apiCall({
          url: `/recommendations/${
            recommendationIdExpanded && recommendationIdExpanded
          }/risks`,
          onSuccess: updateRecommendationRisks
        })
      );
    }

    const list = dispatch(
      getListByName(
        `${data?.category}_${data?.type}${
          !isEquipmentFurniture ? "" : `_${data?.subCategory}`
        }_Reason`
      )
    );

    setOriginalReasonFilters(list);
    setReasonsNoSelected(list);

    // If validation is true, set responsibleParty to 'Worker'
    if (data && data.responsibleParty) {
      setResponsibleParty(data.responsibleParty);
    } else {
      if (
        workerCategories.includes(
          data && data.category && data.category.toLowerCase()
        )
      ) {
        setResponsibleParty("Worker");
        handlePatch("responsibleParty", "Worker");
      }
    }

    if (data && data.priority) {
      setPriority(data.priority);
    } else {
      setPriority("Standard");
      handlePatch("priority", "Standard");
    }

    if (
      (data && data.category.toLowerCase() == "adjustment") ||
      (data && data.category.toLowerCase() == "behavior")
    ) {
      handlePatch("actionItem", "Implement");
    }

    setCategory(data && data.category);
    setAdminComment(data && data.adminComment && data.adminComment);
    setMeasurements(data && data.measurements && data.measurements);
    setReasons(data && data.reasons ? data.reasons : []);

    fetchRecommendationsRisks();
    setRecommendationIssuesSorted(
      sortRecommendationIssues(recommendationsIssues)
    );

    // hide elements
    setIsHiddenAdjustmentBehavior(hideAdjustmentBehavior(data?.category ?? ""));
  }, [recommendationIdExpanded && recommendationIdExpanded]);

  const handleOpenOptionForm = async () => {
    setOpenOption(!openOption);
  };

  const handleInputOption = async (key: string, value: string) => {
    setOption(value);
  };

  const handleOptionConfirm = async (
    value: string = "",
    remove: boolean = false
  ) => {
    if (option === "" && !remove) {
      return;
    }

    const options = data && data.options ? data.options : [];
    let temp: any = [...options];

    if (!remove) {
      // if text have ',' with replace it for other character so that endpoint does not explode it and save it like a separated rows
      let tempOption: string = option;
      tempOption = tempOption.replace(/,/g, "\\");
      temp.push(tempOption);

      await handleInputOption("option", "");
    } else {
      const index = indexOf(temp, value);
      temp.splice(index, 1);
    }

    await handlePatch("options", temp);
  };

  // We set selectedType with current row subCategory in order to preset the category field when equipment picker is opened.
  const handleOpenEquipmentPicker = async () => {
    dispatch({
      type: setEquipmentTypeSelected.type,
      payload: data.subCategory
    });

    dispatch({
      type: showEquipmentPickerSet.type,
      payload: true
    });
  };

  const handleOpenFurniturePicker = async () => {
    dispatch({
      type: setFurnitureTypeSelected.type,
      payload: data.subCategory
    });

    dispatch({
      type: showFurniturePickerSet.type,
      payload: true
    });
  };

  const handlePatch = async (key: string, value: any) => {
    if (value !== undefined && data[key] !== value) {
      const payload = {
        id: data && data.id,
        [key]: value
      };

      if (data.id > 0) {
        dispatch(patchRecommendation(payload));
      }

      const temp = await updateObjectFromList(
        recommendations && [...recommendations],
        payload,
        "id",
        key,
        value
      );

      dispatch({
        type: recommendationsUpdated.type,
        payload: temp
      });
    }
  };

  const filterStoredReasons = async (rows: any, reasons: any = []) => {
    setReasons(reasons);
    setReasonsNoSelected(rows);
  };

  const handleReasonClick = async (
    key: string,
    value: string,
    remove: boolean = false
  ) => {
    let temp: any;

    if (key === "selected") {
      return "";
    }

    if (data[key]) {
      temp = [...data[key]];
    } else {
      temp = [];
    }

    if (!remove) {
      temp.push(value);
    } else {
      const index = indexOf(temp, value);
      temp.splice(index, 1);
    }

    await handlePatch(key, temp);
    setRecommendationIcon(temp);
    await filterStoredReasons(originalReasonFilters, temp);
  };

  const getRecommendationIssueData = (issue: any) => {
    const row = find(evaluationIssues, { id: issue.idIssue });

    if (row === undefined) {
      return "";
    }

    let description: string = "";

    let foundEmpty: string = "";
    if (row.requestTypes && row.requestTypes.length > 0) {
      foundEmpty = row.requestTypes.find((el: any) => el === "empty");
    }

    switch (row.type.toLowerCase()) {
      case "request":
        description =
          row.requestTypes && row.requestTypes.length > 0
            ? foundEmpty
              ? ""
              : row.requestTypes.join(",")
            : "";
        break;

      case "discomfort":
        description = row.bodyLocation ? row.bodyLocation : "";
        break;
    }

    if (row) {
      return `${capitalizeFirstLetter(row.type)}: ${description}`;
    }

    return "";
  };

  const getRecommendationRiskData = (risk: any) => {
    const row = find(evaluationRisks, { id: risk.idRisk });

    if (row) {
      return `${row.subCategory ? row.subCategory : ""}: ${
        row.description ? row.description : ""
      } ${getRiskvsName(row.idRiskV)}`;
    }

    return "";
  };

  const updateEnvironmentRecommendations = (rows: any) => {
    dispatch({
      type: recommendationsEnvironmentsUpdated.type,
      payload: rows
    });
  };

  const handleRecommendationEnvironment = async (
    idEnvironment: number,
    checked: boolean
  ) => {
    if (checked) {
      dispatch(
        insertResourceProcess(
          [{ idRecommendation: data && data.id, idEnvironment }],
          ["idRecommendation", "idEnvironment"],
          {},
          `/recommendationenvironments`
        )
      )
        .then(async (rows: any) => {
          if (rows.length > 0) {
            const currentRecommendationEnvironmentsInRuntime = dispatch(
              getCurrentRecommendationEnvironmentsInRuntime()
            );
            const updatedRelationRows = await updateCurrentList(
              rows,
              currentRecommendationEnvironmentsInRuntime
            );

            updateEnvironmentRecommendations(updatedRelationRows);

            setTimeout(() => {
              dispatch({
                type: showCheckboxLoaderUpdated.type,
                payload: false
              });
            }, 500);
          }
        })
        .catch((e: any) => console.log(e));
    } else {
      const currentRecommendationEnvironmentsInRuntime = dispatch(
        getCurrentRecommendationEnvironmentsInRuntime()
      );
      const index = findIndex(currentRecommendationEnvironmentsInRuntime, {
        idEnvironment
      });
      const resource = { ...currentRecommendationEnvironmentsInRuntime[index] };
      const updatedRelationRows =
        currentRecommendationEnvironmentsInRuntime.filter(
          (recommendationEnvironment: any) =>
            recommendationEnvironment.id !== resource.id
        );

      updateEnvironmentRecommendations(updatedRelationRows);

      dispatch(deleteRecommendationEnvironment(resource.id));

      dispatch({
        type: showCheckboxLoaderUpdated.type,
        payload: false
      });
    }
  };

  const getRecommendationTypes = (recommendationTypes: any, reasons: any) => {
    const filtered = filter(recommendationTypes, function (o) {
      return indexOf(reasons, o) === -1;
    });

    return filtered;
  };

  const removeRisk = async (riskRecommId: any, riskId: any) => {
    const acceptDeleteRisk = dispatch(getCurrentAcceptDeleteRisk());

    if (riskRecommId !== undefined) {
      dispatch(deleteRiskRecommendation(riskRecommId));

      const updatedRiskRecommendations = recommendationsRisks.filter(
        (risk: any) => risk.id !== riskRecommId
      );

      dispatch({
        type: recommendationsRisksUpdated.type,
        payload: updatedRiskRecommendations
      });

      if (acceptDeleteRisk) {
        // deletes from table and from global store (evaluation recommendations, environment recommendations)
        dispatch(deleteRisk(riskId));

        let temp = await removeObjectFromList(evaluationRisks, {
          id: riskId
        });

        dispatch({
          type: risksUpdated.type,
          payload: temp
        });

        temp = [];
        temp = [...currentEnvironmentRisks];
        temp = filter(temp, (o: any) => {
          return o.idRisk !== riskId && currentEnvironment.id;
        });

        dispatch({
          type: environmentRisksUpdated.type,
          payload: temp
        });
      }
    }
  };

  const handleSetAcceptDeleteRisk = async (value: boolean) => {
    dispatch({
      type: acceptDeleteRiskUpdated.type,
      payload: value
    });
  };

  const fetchRecommendationsRisks = () => {
    const linkedRisks = recommendationsRisks.filter(
      (recrisk: any) =>
        evaluationRisks.filter(
          (evalRisk: any) => recrisk.idRisk === evalRisk.id
        ).length > 0 && recrisk.idRecommendation === recommendationIdExpanded
    );

    dispatch({
      type: linkedRisksUpdated.type,
      payload: linkedRisks
    });

    // setRecommendationRisks(rows);
  };

  const sortRecommendationIssues = (recommendationIssues: any) => {
    let temp: any = [];

    recommendationIssues.map((recommendationIssue: any) => {
      let ri = { ...recommendationIssue };
      let found = find(evaluationIssues, { id: recommendationIssue.idIssue });
      let type = found ? found.type.toLowerCase() : "";

      switch (type) {
        case "request":
          ri.sort = 1;
          break;
        case "discomfort":
          ri.sort = 2;
          break;
        default:
          ri.sort = 3;
      }

      temp.push(ri);
    });

    temp = sortBy(temp, ["sort"], ["asc"]);

    return temp;
  };

  const formatOptions = (options: any) => {
    let temp: any = [];

    for (const [, row] of options.entries()) {
      let r: any = row.replace(/\\/g, ",");

      temp.push(r);
    }

    return temp;
  };

  const hideResource = (category: string) => {
    if (category.toLowerCase() === "resource") {
      return true;
    }

    return false;
  };

  const hideAdjustmentBehavior = (category: string) => {
    if (
      category.toLowerCase() == "adjustment" ||
      category.toLowerCase() == "behavior"
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol size="8">
          <IonRow>
            <IonCol size="auto">
              <IonLabel className="text-left">{data?.description}</IonLabel>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size="auto">
          <Checkbox
            name="implementedRealtime"
            label="Implemented real-time"
            value={data && data.implementedRealtime}
            onChange={handlePatch}
            labelClassName="font-lato text-14"
          />
        </IonCol>
      </IonRow>
      {!isHiddenResource && (
        <IonRow>
          <IonCol>
            <div className="form-group custom-input-f-h matching-element-f-h">
              <IonLabel className="form-group-input-text-title matching-element-label">
                Reasons
              </IonLabel>
              <IonRow className="reasons flex mx-1">
                {reasonsNoSelected.map((type: any, index: number) => {
                  let selected =
                    reasons &&
                    reasons.filter((reasonType: any) => reasonType === type)
                      .length > 0;

                  let badgeStyle = selected ? "669c36" : "0632ff";
                  return (
                    <React.Fragment key={index}>
                      <Badge
                        color={`bg-color-${badgeStyle}`}
                        className="cursor-pointer mr-05 mb-2 min-w-190"
                        name="reasons"
                        label={type}
                        onClick={handleReasonClick}
                        selected={selected}
                      />
                    </React.Fragment>
                  );
                })}
              </IonRow>
            </div>
          </IonCol>
        </IonRow>
      )}
      {!isHiddenAdjustmentBehavior && (
        <>
          {category && category.toLowerCase() !== "resource" && (
            <IonRow>
              {data && isEquipmentFurniture && (
                <IonCol size="6" className="mt-2 relative">
                  <div className="display-inline">
                    <Input
                      name="itemName"
                      label="Item"
                      onBlur={handlePatch}
                      disabled={true}
                      value={data?.itemName}
                    />
                    <IonImg
                      className="cursor-pointer absolute top-5 right-4 z-100"
                      src={iconImages["editIcon"]}
                      onClick={() =>
                        data?.category.toLowerCase() === "equipment"
                          ? handleOpenEquipmentPicker()
                          : handleOpenFurniturePicker()
                      }
                    ></IonImg>
                  </div>
                </IonCol>
              )}
              <IonCol
                size={isEquipmentFurniture && isEquipmentFurniture ? "6" : "12"}
              >
                <SelectForm
                  label="Priority"
                  options={recommendationPriorities}
                  value={priority}
                  name="priority"
                  onChange={(key: string, value: any) => {
                    if (value) {
                      setPriority(value);
                      handlePatch(key, value);
                    }
                  }}
                  clearValue={() => {
                    setPriority(null);
                    handlePatch("priority", null);
                  }}
                />
              </IonCol>
            </IonRow>
          )}

          {category && category.toLowerCase() !== "resource" && (
            <IonRow>
              <IonCol size="6">
                <SelectForm
                  label="Action Item"
                  options={recommendationActionItems}
                  value={data && data.actionItem}
                  name="actionItem"
                  onChange={handlePatch}
                  clearValue={() => handlePatch("actionItem", null)}
                />
              </IonCol>
              <IonCol size="6">
                <SelectForm
                  label="Responsible Party"
                  options={recommendationResponsibleParties}
                  value={responsibleParty}
                  name="responsibleParty"
                  onChange={(key: string, value: any) => {
                    setResponsibleParty(value);
                    handlePatch(key, value);
                  }}
                  clearValue={() => {
                    setResponsibleParty(null);
                    handlePatch("responsibleParty", null);
                  }}
                />
              </IonCol>
            </IonRow>
          )}
        </>
      )}

      <IonRow className="pt-3">
        <IonCol>
          <Textarea
            label={"Comment"}
            value={data && data.comment}
            onBlur={(e: any) => {
              handlePatch("comment", e.target.innerText);
            }}
          />
        </IonCol>
      </IonRow>

      {category &&
        category.toLowerCase() !== "resource" &&
        category.toLowerCase() !== "adjustment" &&
        category.toLowerCase() !== "behavior" && (
          <IonRow className="pt-3">
            <IonCol>
              <Textarea
                label={"Admin Comment"}
                value={adminComment}
                onBlur={(e: any) => {
                  handlePatch("adminComment", e.target.innerText);
                  setAdminComment(e.target.innerText);
                }}
              />
            </IonCol>
          </IonRow>
        )}

      {category &&
        category.toLowerCase() !== "resource" &&
        category.toLowerCase() !== "behavior" && (
          <IonRow className="pt-3">
            <IonCol>
              <Textarea
                label={"Measurements"}
                value={measurements}
                onBlur={(e: any) => {
                  handlePatch("measurements", e.target.innerText);
                  setMeasurements(e.target.innerText);
                }}
              />
            </IonCol>
          </IonRow>
        )}

      {category &&
        category.toLowerCase() !== "resource" &&
        category.toLowerCase() !== "adjustment" &&
        category.toLowerCase() !== "behavior" && (
          <>
            <hr className="separator top-2" />
            <OptionBar handleOpenOptionForm={handleOpenOptionForm} />
            {openOption && openOption && (
              <AddForm
                label="Options"
                leftButtonLabel="Cancel"
                rightButtonLabel="Save"
                onInput={handleInputOption}
                value={option}
                onCancel={handleOpenOptionForm}
                onConfirm={() => handleOptionConfirm()}
              />
            )}
            {data &&
              data.options &&
              data?.options.length > 0 &&
              formatOptions(data.options).map((option: any, index: number) => (
                <IonRow key={index}>
                  <IonCol className="background-row-color">
                    <IonRow className="ion-justify-content-between ion-text-left relative ion-align-items-center">
                      <IonCol size="11">
                        <Textarea value={option} contentEditable={false} />
                      </IonCol>
                      <IonCol>
                        <div className="float-end">
                          <IonImg
                            src={deleteVector}
                            className="cursor-pointer"
                            onClick={() =>
                              present({
                                cssClass: "my-css",
                                message:
                                  "Are you sure that you want to delete this option?" ||
                                  "",
                                buttons: [
                                  "Cancel",
                                  {
                                    text: "Ok",
                                    handler: () =>
                                      handleOptionConfirm(option, true)
                                  }
                                ]
                              })
                            }
                          ></IonImg>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              ))}
          </>
        )}

      {category && category.toLowerCase() !== "resource" && (
        <>
          <hr className="separator top-2" />
          <IssueBar handleOpenIssuePicker={handleOpenIssuePicker} />
          {recommendationsIssues.length > 0 &&
            recommendationIssuesSorted.map((issue: any, index: number) => (
              <IonRow key={index}>
                <IonCol className="background-row-color">
                  <IonRow className="ion-justify-content-between ion-text-left">
                    <IonCol size="11">
                      <IonLabel className="ml-1 ion-float-left">
                        {issue && getRecommendationIssueData(issue)}
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <div className="float-end">
                        <IonImg
                          src={deleteVector}
                          className="cursor-pointer"
                          onClick={() =>
                            present({
                              cssClass: "my-css",
                              message:
                                "Are you sure that you remove this issue from this recommendation?" ||
                                "",
                              buttons: [
                                "Cancel",
                                {
                                  text: "Ok",
                                  handler: () =>
                                    handleDeleteRecommendationIssues(issue)
                                }
                              ]
                            })
                          }
                        ></IonImg>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            ))}
        </>
      )}

      {category && category.toLowerCase() !== "resource" && (
        <>
          <hr className="separator top-2" />
          <IonRow className="ion-align-items-baseline">
            <IonCol size="6" className="bold to-left">
              Risks
            </IonCol>
            <IonCol size="6" className="to-right text-regular-size bold">
              <IonImg className="cursor-pointer"></IonImg>
              <IonButton fill="clear" onClick={() => handleShowRiskModal()}>
                <IonIcon src={addSharp}></IonIcon>&nbsp;ADD RISKS
              </IonButton>
              <IonButton size="small" className="circle-btn-icon" fill="clear">
                <IonIcon icon={chevronDownOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
        </>
      )}

      {currentLinkedRisks &&
        currentLinkedRisks.map((risk: any, index: number) => {
          return (
            <IonRow className="mb-16" key={index}>
              <IonCol className="background-row-color">
                <IonRow className="ion-justify-content-between ion-text-left">
                  <IonCol size="11">
                    <IonLabel className="ml-1 ion-float-left">
                      {getRecommendationRiskData(risk)}
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <div className="ion-text-center">
                      <IonIcon
                        icon={unlink}
                        mode="md"
                        className="cursor-pointer"
                        onClick={() => {
                          dispatch({
                            type: acceptDeleteRiskUpdated.type,
                            payload: false
                          });
                          presentAlert({
                            cssClass: "my-css",
                            message:
                              "Are you sure that you want to unlink this Risk?",
                            inputs: [
                              {
                                type: "checkbox",
                                label: "Delete Risk?",
                                handler: (item: any) => {
                                  handleSetAcceptDeleteRisk(item.checked);
                                },
                                checked: false
                              }
                            ],
                            buttons: [
                              "Cancel",
                              {
                                text: "Ok",
                                handler: () => removeRisk(risk.id, risk.idRisk)
                              }
                            ]
                          });
                        }}
                      ></IonIcon>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          );
        })}
      <>
        <IonRow className="top-1">
          <IonCol size="6" className="bold to-left top-1">
            Environment
          </IonCol>
          <IonCol>
            <IonImg className="cursor-pointer"></IonImg>
            <IonButton
              fill="clear"
              className="ion-float-right"
              onClick={() => setShowEnvironmentModal()}
            >
              <IonIcon src={addSharp}></IonIcon>&nbsp;ADD ENVIRONMENT
            </IonButton>
          </IonCol>
        </IonRow>
        <EnvironmentCheckboxes
          environments={environments}
          environmentsChecked={recommendationEnvironmentIds}
          idEnvironmentBlocked={currentEnvironment && currentEnvironment.id}
          onChangeEnvironment={handleRecommendationEnvironment}
          getCurrentShowCheckboxLoader={getCurrentShowCheckboxLoader}
          showCheckboxLoaderUpdated={showCheckboxLoaderUpdated}
        />
      </>
    </>
  );
};

export default Recommendation;
