import React, { useState } from "react";
import { IonRow, IonCol, IonButton } from "@ionic/react";
import Input from "./Input";
import Textarea from "../Textarea";

const AddForm: React.FC<any> = (props: any) => {
  const {
    label,
    leftButtonLabel,
    rightButtonLabel,
    onInput,
    onCancel,
    onConfirm,
    value
  } = props;

  return (
    <IonRow className="ion-justify-content-between ion-align-items-center">
      <IonCol sizeMd="6" sizeLg="8">
        <Textarea
          label={label}
          value={value}
          onBlur={(e: any) => {
            onInput("option", e.target.innerText);
          }}
        />
      </IonCol>
      <IonCol sizeMd="3" sizeLg="2">
        <IonButton
          onClick={() => onCancel(false)}
          className="btn-2 cancel font-bold"
        >
          {leftButtonLabel}
        </IonButton>
      </IonCol>
      <IonCol sizeMd="3" sizeLg="2">
        <IonButton onClick={onConfirm} className="btn-2 positive font-bold">
          {rightButtonLabel}
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export default AddForm;
