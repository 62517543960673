import React, { useEffect, useState } from "react";
import { IonRow, IonCol } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import { getCurrentFurniture } from "../../../../../store/evaluation/environment/workstation/Furnitures";

import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";
import LengthLabel from "../../../../common/controls/LengthLabel";
import YesNoInput from "../../../../common/controls/YesNoInput";
import SelectForm from "../../../../common/controls/SelectForm";
import Emea from "../../../../common/controls/Emea";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import Checkbox from "../workstations/Checkbox";

const WorkSurfaceForm: React.FC<any> = (props: any) => {
  const { updateValue } = props;

  const dispatch = useAppDispatch();
  const data = useAppSelector(getCurrentFurniture);
  const worksurfaceTypes = dispatch(getListByName("Worksurface_Type"));

  const [label, setLabel] = useState<string | null>(null);
  const [worksurfaceType, setWorksurfaceType] = useState<string | null>(null);

  useEffect(() => {
    setLabel(data && data.label);
    setWorksurfaceType(data && data.worksurfaceType);
  }, [data && data.id]);

  return (
    <>
      <IonRow>
        <Name nameLabel="Worksurface name" value={data && data.name} />
      </IonRow>
      <IonRow className="top-var">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="5"
          size-md="5"
          size-sm="5"
          size-sx="5"
          size="5"
        >
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={(key: string, value: any) => {
              setLabel(value);
              updateValue(key, value);
            }}
            maxlength={16}
          />
        </IonCol>
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="7"
          size-md="7"
          size-sm="7"
          size-sx="7"
          size="7"
        >
          <LengthLabel />
        </IonCol>
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="shared"
          label="Shared"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="hasStandingMat"
          label="Standing Mat"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="12"
          size-md="12"
          size-sm="12"
          size-sx="12"
          size="12"
        >
          <IonRow>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="standingMatAdhoc"
                label="Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentFurniture}
              />
            </IonCol>
            <IonCol
              size-xxl="6"
              size-xl="6"
              size-lg="6"
              size-md="6"
              size-sm="6"
              size-sx="6"
              size="6"
              className="aligned-middle"
            >
              <Checkbox
                name="standingMatDesignated"
                label="Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentFurniture}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <hr className="separator" />
      <Emea></Emea>
      <IonRow className="top-2">
        <YesNoInput
          label="Adequate size"
          property="adequateSize"
          data={data}
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          label="Surface free of glare"
          property="freeOfGlare"
          data={data}
          onChange={updateValue}
        />
      </IonRow>
    </>
  );
};

export default WorkSurfaceForm;
