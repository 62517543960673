import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getRecommendationVs,
  setIsFilteredRecommendationVsList,
  getIsFilteredRecommendationVsList,
  getOriginalRecommendationVs,
  getRecommendationOriginalSubCategories,
  recommendationSubCategoriesReset,
  recommendationvsUpdated,
  recommendationSubCategoriesReceived,
  recommendationCategoryReceived,
  resetRecommendationVs
} from "../../../../../store/catalogs/Recommendations";
import {
  getCurrentEvaluation,
  getCurrentEvaluationEnvironment
} from "../../../../../store/evaluation/Current";
import {
  getRecommendations,
  recommendationsUpdated
} from "../../../../../store/evaluation/Recommendations";
import {
  environmentRecommendationsUpdated,
  getCurrentEnvironmentRecommendations,
  showAddRecommendationModalSet
} from "../../../../../store/evaluation/environment/Recommendations";

import BaseAddModal from "../add-modal/BaseAddModal";
import RecommendationFilters from "./RecommendationFilters";

import {
  insertResourceProcess,
  updateCurrentList
} from "../../../../../helpers/global.helper";

import _, { find, orderBy } from "lodash";

import { getListByName } from "../../../../../store/catalogs/ValueList";
import {
  getCurrentLinkedRecommendations,
  getCurrentRiskRecommendations
} from "../../../../../store/evaluation/environment/Risks";

const AddRecommendationModal: React.FC<any> = (props: any) => {
  const {
    onClickRecommendation,
    isPicker,
    closePicker,
    isOpen,
    currentRisk,
    updateRiskRecommendations,
    loadItems,
    pickedItemsUpdated,
    currentSelectedPickedItems,
    removeItems,
    isAttach,
    groupedLeftList
  } = props;

  const dispatch = useAppDispatch();
  const currentEvaluation = useAppSelector(getCurrentEvaluation);
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const recommendationVs = useAppSelector(getRecommendationVs);

  const originalRecommendationVs = useAppSelector(getOriginalRecommendationVs);
  const isFilteredRecommendationVsList = useAppSelector(
    getIsFilteredRecommendationVsList
  );
  const recommendations = useAppSelector(getRecommendations);
  const currentEnvironmentRecommendations = useAppSelector(
    getCurrentEnvironmentRecommendations
  );
  const originalSubCategories = useAppSelector(
    getRecommendationOriginalSubCategories
  );
  const currentRiskRecommendations = useAppSelector(
    getCurrentRiskRecommendations
  );
  const currentLinkedRecommendations = useAppSelector(
    getCurrentLinkedRecommendations
  );

  const [filters, setFilters] = useState<any>({});
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const setAllCategories = () => {
    dispatch({
      type: recommendationSubCategoriesReceived.type,
      payload: dispatch(getListByName("Recommendation_All_Subcategory"))
    });
  };

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: recommendationCategoryReceived.type,
        payload: dispatch(getListByName("Recommendation_Category"))
      });

      setAllCategories();
      handleResetList();
    }

    initializeFilter();

    if (isOpen) {
      dispatch({
        type: recommendationvsUpdated.type,
        payload: originalRecommendationVs
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (groupedLeftList) {
        grouped();
      }
    }
  }, [isAttach && isOpen === true && recommendationVs.length]);

  const closeModal = () => {
    if (closePicker) {
      closePicker(false);
    } else {
      dispatch({
        type: showAddRecommendationModalSet.type,
        payload: false
      });
    }
  };

  const initializeFilter = () => {
    handleRemoveFilter();
  };

  const initializeModal = () => {
    initializeFilter();

    dispatch({
      type: recommendationSubCategoriesReset.type,
      payload: originalSubCategories
    });
  };

  const removeItemFromLeftSide = (item: any) => {
    let temp = [...recommendationVs];
    const index = _.findIndex(temp, { id: item.id });

    temp.splice(index, 1);

    dispatch({
      type: recommendationvsUpdated.type,
      payload: temp
    });
  };

  const reOrderRecommendationVs = (items: any) => {
    const temp = [...recommendationVs];

    for (const [, item] of items.entries()) {
      temp.push(item);
    }

    const ordered = _.orderBy(temp, ["id", "asc"]);

    dispatch({
      type: recommendationvsUpdated.type,
      payload: ordered
    });
  };

  /**
   * Handles on save
   */
  const handleOnSave = async (
    selectedItems: any,
    attachToRisks: boolean = false
  ) => {
    // Filter duplicates from recommendations and environment recommendations.
    setShowLoader(true);
    let filtered: any = [];
    let alreadyInserted: any = [];

    if (attachToRisks) {
      selectedItems.map((selectedItem: any) => {
        let recommendation = existsRecommendationByRecommendationVs(
          selectedItem.id
        );

        if (recommendation == null) filtered.push(selectedItem);
        else alreadyInserted.push(recommendation);
      });
    } else {
      filtered = selectedItems;
    }

    if (filtered.length > 0) {
      let adjuster: any = {
        idRecommendationv: {
          key: "idRecommendationv",
          keyValue: "id"
        }
      };

      dispatch(
        insertResourceProcess(
          filtered,
          [
            "idRecommendationv",
            "category",
            "subCategory",
            "type",
            "name",
            "description"
          ],
          adjuster,
          `/evaluations/${currentEvaluation.id}/recommendations`
        )
      )
        .then(async (recommendationRows: any) => {
          if (recommendationRows.length > 0) {
            // We need to attach recommendationV property to each recommendation inserted to be sorted
            let temp: any = [...recommendationRows];
            let recommendationRowsWithRecommendationV: any = [];

            for (let [, row] of temp.entries()) {
              let recommendationV = find(filtered, {
                id: row.idRecommendationv
              });

              row = { ...row, recommendationV };

              recommendationRowsWithRecommendationV.push(row);
            }

            recommendationRows = recommendationRowsWithRecommendationV;

            // Inserting into recommendation environment
            const adjuster = {
              idRecommendation: {
                key: "idRecommendation",
                keyValue: "id"
              },
              idEnvironment: {
                key: "idEnvironment",
                value: currentEnvironment.id
              }
            };

            dispatch(
              insertResourceProcess(
                recommendationRows,
                ["idRecommendation", "idEnvironment"],
                adjuster,
                `/recommendationenvironments`
              )
            )
              .then(async (environmentRecommendations: any) => {
                if (environmentRecommendations.length > 0) {
                  const temp = await updateCurrentList(
                    recommendationRows,
                    recommendations,
                    "desc"
                  );

                  dispatch({
                    type: recommendationsUpdated.type,
                    payload: temp
                  });

                  if (onClickRecommendation) {
                    onClickRecommendation(temp[0]);
                  }

                  dispatch({
                    type: environmentRecommendationsUpdated.type,
                    payload: [
                      ...currentEnvironmentRecommendations,
                      ...environmentRecommendations
                    ]
                  });

                  if (!attachToRisks) {
                    setShowLoader(false);
                    closeModal();
                  } else {
                    await attachRecommendations([
                      ...recommendationRows,
                      ...alreadyInserted
                    ]);
                  }
                }
              })
              .catch((e: any) => console.log(e));
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      if (!attachToRisks) {
        setShowLoader(false);
        closeModal();
      } else {
        await attachRecommendations(alreadyInserted);
      }
    }
  };

  const handleSetFilter = (key: string, value: any, contains: boolean) => {
    let temp = { ...filters };

    if (value !== undefined && value !== "") {
      temp[key] = {
        sign: !contains ? "=" : "contains",
        value
      };

      setFilters(temp);
    }

    if (key === "category") {
      if (temp.subCategory) {
        delete temp.subCategory;
      }

      setFilters(temp);
    }
  };

  const handleRemoveFilter = (key: string = "") => {
    if (key !== "") {
      if (key == "category") {
        setAllCategories();
      }

      let temp = { ...filters };

      if (temp[key]) {
        temp = _.omit(temp, [key]);
      }

      setFilters(temp);
    } else {
      setFilters({});
    }
  };

  const attachRecommendations = async (insertedRecommendations: any) => {
    if (insertedRecommendations && insertedRecommendations.length > 0) {
      const adjuster = {
        idRecommendation: {
          key: "idRecommendation",
          keyValue: "id"
        },
        idRisk: {
          key: "idRisk",
          value: currentRisk.id
        }
      };

      let insertedRecommendationsFiltered = insertedRecommendations.filter(
        (rec: any) =>
          currentRiskRecommendations?.filter(
            (riskRec: any) => riskRec.idRecommendation == rec.id
          ).length == 0
      );

      dispatch(
        insertResourceProcess(
          insertedRecommendationsFiltered,
          ["idRecommendation", "idRisk"],
          adjuster,
          `/riskrecommendations`
        )
      )
        .then(async (riskRecommendations: any) => {
          await updateRiskRecommendations(riskRecommendations);
          setShowLoader(false);
          closeModal();
        })
        .catch((e: any) => console.log(e));
    } else {
      setShowLoader(false);
      closeModal();
    }
  };

  const handleAttachRecommendations = async (pickedItems: any) => {
    await handleOnSave([...pickedItems], isAttach);
  };

  const handleResetList = () => {
    if (!isOpen) {
      dispatch(resetRecommendationVs());

      if (pickedItemsUpdated) {
        dispatch({
          type: pickedItemsUpdated.type,
          payload: []
        });
      }
    }
  };

  const existsRecommendationByRecommendationVs = (idRecommendationv: any) => {
    let recommendation = recommendations.filter(
      (rec: any) =>
        rec.idRecommendationv == idRecommendationv &&
        currentEnvironmentRecommendations?.filter(
          (envrec: any) => envrec.idRecommendation == rec.id
        ).length > 0
    );
    return recommendation.length > 0 ? recommendation[0] : null;
  };

  const updateFilterValue = (filters: any) => {
    setFilters(filters);
  };

  //
  const grouped = () => {
    let currentRecommendationVs = [...recommendationVs]; //all recommendations

    currentRecommendationVs = currentRecommendationVs.map(
      (recommendationVs: any) => {
        let rvc = { ...recommendationVs };
        if (rvc.group) {
          delete rvc.group;
        }

        if (rvc.linked) {
          delete rvc.linked;
        }

        return rvc;
      }
    );

    let temp = currentRecommendationVs.map((recommendationVs: any) => {
      let currentRecommendationRow: any = find(recommendations, (o: any) => {
        return o.idRecommendationv === recommendationVs.id;
      });

      if (currentRecommendationRow) {
        let linkedRow = find(currentLinkedRecommendations, (o) => {
          return o.recommendationV.id === recommendationVs.id;
        });

        if (linkedRow) {
          recommendationVs = Object.assign({}, recommendationVs, {
            linked: true
          });
        }

        return Object.assign({}, recommendationVs, { group: true });
      }

      return recommendationVs;
    });

    temp = orderBy(temp, ["group", "sortOrder"], ["asc", "asc"]);

    dispatch({
      type: recommendationvsUpdated.type,
      payload: temp
    });
  };

  const updateList = (filtered: any, isFiltered: boolean = true) => {
    dispatch({
      type: recommendationvsUpdated.type,
      payload: filtered
    });

    if (isFiltered) {
      dispatch({
        type: setIsFilteredRecommendationVsList.type,
        payload: isFiltered
      });
    }

    if (isAttach) {
      grouped();
    }
  };

  //

  return (
    <BaseAddModal
      showIcon={false}
      showLoader={showLoader}
      onDidDismiss={handleResetList}
      title="Add Recommendation"
      getItems={getRecommendationVs}
      isOpen={isOpen}
      originalList={originalRecommendationVs}
      closeModal={closeModal}
      // Filters
      setIsFilteredList={setIsFilteredRecommendationVsList}
      filters={
        <RecommendationFilters
          filters={filters}
          setFilter={handleSetFilter}
          removeFilter={handleRemoveFilter}
          loadItems={loadItems}
          showClearButton={true}
          updateFilterValue={updateFilterValue}
          sort={{
            columns: ["sortOrder"],
            directions: ["asc"]
          }}
          updateList={updateList}
        />
      }
      removeItems={removeItems}
      initializeFilter={initializeModal}
      // Content
      leftList={recommendationVs}
      isFilteredList={isFilteredRecommendationVsList}
      removeItemFromLeftSide={removeItemFromLeftSide}
      reOrganizeLeftList={reOrderRecommendationVs}
      // Footer
      saveButtonLabel={
        isPicker ? `SAVE CHANGES` : `ADD <<>> SELECTED RECOMMENDATIONS`
      }
      onSave={isPicker ? handleOnSave : handleAttachRecommendations}
      //
      listUpdated={recommendationvsUpdated}
      isPicker={isPicker}
      hasCustomItem={true}
      itemName="recommendationItem"
      hideSmallIcon={true}
      pickedItemsUpdated={pickedItemsUpdated}
      currentSelectedPickedItems={currentSelectedPickedItems}
      hideBoxIcon={true}
      groupedLeftList={groupedLeftList}
    />
  );
};

export default AddRecommendationModal;
