import React from "react";

import List from "./List";

import { iconImages } from "../../../../../services/tabs.service";

const RightList: React.FC<any> = (props) => {
  const {
    items,
    getIcon,
    action,
    hasCustomItem,
    itemName,
    hideSmallIcon,
    itemClassName,
    category,
    showIcon,
    listHeight,
    hideBoxIcon
  } = props;

  return (
    <List
      listHeight={listHeight}
      showIcon={showIcon}
      items={items}
      getIcon={getIcon}
      action={action}
      icon={iconImages["unselectIcon"]}
      hasCustomItem={hasCustomItem}
      itemName={itemName}
      hideSmallIcon={hideSmallIcon}
      itemClassName={itemClassName}
      category={category}
      rightList={true}
      hideBoxIcon={hideBoxIcon}
    />
  );
};

export default RightList;
