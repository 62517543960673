import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "activities",
  initialState: {
    list: []
  },
  reducers: {
    activitiesReceived(activities: any, action: PayloadAction<any>) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        activities.list = payload.rows;
      } else {
        activities.list = [];
      }
    },
    activitiesUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.list = action.payload;
    }
  }
});

export const { activitiesReceived, activitiesUpdated } = slice.actions;
export default slice.reducer;

/**
 * Gets activities.
 */
export const getActivities = createSelector(
  (state: any) => state.evaluation.activities,
  (activities: any) => activities.list
);
