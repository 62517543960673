import React, { useState, useEffect, useRef } from "react";
import { useIonAlert } from "@ionic/react";
import { useAppDispatch } from "../../../../../store/Hooks";

import BaseModal from "../../../../common/BaseModal";
import Content from "../add-modal/Content";
import Footer from "../add-modal/Footer";

import {
  groupList,
  setPickerPropertyToList
} from "../../../../../services/tabs.service";

import { find, findIndex } from "lodash";

import "../../../../../css/bidb.css";
import "../../../../../css/modal-equipment.css";

const BaseAddModal: React.FC<any> = (props: any) => {
  const {
    isOpen,
    buttonLabel,
    category,
    onSave,

    // Content
    groupSelectedItems, // This paremeter is used to know if we need to group selected items (equipments, furnitures)
    leftList,
    getItems,
    originalList,
    setIsFilteredList,
    title,
    closeModal,
    saveButtonLabel,
    buttonSaveClasses,
    listUpdated,
    isFilteredList,
    filters,
    initializeFilter,

    // workstation picker
    isPicker,
    onDidDismiss,
    hasCustomItem,
    itemName,
    hideSmallIcon,
    pickOneElement,
    itemClassName,
    pickedItemsUpdated,
    currentSelectedPickedItems,
    removeItems,
    showLoader,
    showIcon,
    hideBoxIcon,
    groupedLeftList
  } = props;

  const dispatch = useAppDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [present] = useIonAlert();
  const [footerSaveButtonLabel, setFooterSaveButtonLabel] = useState("");
  const [selectedItem, setSelectedItem] = useState(undefined);

  const contentRef: any = useRef(null);
  const [listHeight, setListHeight] = useState();

  useEffect(() => {
    setFooterSaveButtonLabel(getFooterButtonLabel(selectedItems));
  }, [selectedItems && selectedItems.length]);

  useEffect(() => {
    setFooterSaveButtonLabel(
      getFooterButtonLabel(
        currentSelectedPickedItems ? currentSelectedPickedItems : []
      )
    );
  }, [currentSelectedPickedItems && currentSelectedPickedItems.length]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setListHeight(
          contentRef && contentRef.current ? contentRef.current.offsetHeight : 0
        );
      }, 500);

      window.addEventListener("resize", () => {
        setListHeight(
          contentRef && contentRef.current ? contentRef.current.offsetHeight : 0
        );
      });
      setSelectedItems([]);

      if (pickedItemsUpdated) {
        dispatch({
          type: pickedItemsUpdated.type,
          payload: []
        });
      }
    }
  }, [isOpen]);

  const getFooterButtonLabel = (items: any[]) => {
    return saveButtonLabel
      ? saveButtonLabel.replace("<<>>", items.length.toString())
      : "";
  };

  /**
   * Handles save
   */
  const handleSave = async () => {
    if (!isPicker || isPicker === undefined) {
      await onSave(selectedItems);
    } else {
      if (pickOneElement) {
        await onSave(selectedItem);
      } else {
        await onSave(currentSelectedPickedItems);
      }
    }
  };

  const handleCloseModal = async () => {
    setTimeout(() => {
      dispatch({
        type: setIsFilteredList.type,
        payload: false
      });
    }, 100);

    closeModal();
  };

  const clearFilter = async () => {
    let list = [...originalList];

    if (isPicker) {
      list = await setPickerPropertyToList(list, currentSelectedPickedItems);
    }

    dispatch({
      type: listUpdated.type,
      payload: list
    });

    dispatch({
      type: setIsFilteredList.type,
      payload: false
    });

    initializeFilter();
  };

  /**
   * Clears selection alert
   */
  const showClearSelectionAlert = () => {
    if (selectedItems.length > 0) {
      present({
        cssClass: "my-css",
        message: "Are you sure that you want to remove all items?",
        buttons: ["Cancel", { text: "Ok", handler: () => clearSelection() }]
      });
    }
  };

  /**
   * Clears selection
   */
  const clearSelection = async () => {
    // await reOrganizeLeftList(selectedItems);
    setSelectedItems([]);
  };

  /**
   * Select items and insert them into selectedItems array
   */
  const handleSelectItem = async (object: any) => {
    let temp: any = [...selectedItems];

    // Check that the object isn't been added.
    const found = find(temp, { id: object.id });

    temp.unshift(object);

    if (groupSelectedItems) {
      temp = await groupList(
        temp,
        {
          columns: ["label"],
          directions: ["asc"]
        },
        category
      );
    }

    setSelectedItems(temp);
  };

  /**
   * Removes items
   */
  const removeItem = async (object: any) => {
    let temp: any = [...selectedItems];
    const index = findIndex(temp, { id: object.id });
    temp.splice(index, 1);
    setSelectedItems(temp);

    let found = find([...leftList], { id: object.id });

    if (found === undefined) {
      const items: any = [];
      items.push(object);

      // await reOrganizeLeftList(items);
    }
  };

  const handlePickedItems = (selectedPickedItems: any) => {
    const temp = selectedPickedItems.filter((selectedItem: any) => {
      if (currentSelectedPickedItems.length == 0) {
        return selectedItem;
      }

      let filtered = currentSelectedPickedItems.filter(
        (currentSelectedPickedItem: any) => {
          return currentSelectedPickedItem.id == selectedItem.id;
        }
      );

      if (filtered.length == 0) {
        return selectedItem;
      }
    });

    dispatch({
      type: pickedItemsUpdated.type,
      payload: [...currentSelectedPickedItems, ...temp]
    });
  };

  return (
    <BaseModal
      showLoader={showLoader}
      isOpen={isOpen}
      title={title}
      closeModal={handleCloseModal}
      cssClass={"size-modal-y"}
      onDidDismiss={onDidDismiss}
      contentRef={contentRef}
      content={
        <Content
          listHeight={listHeight}
          showIcon={showIcon}
          leftList={leftList}
          filters={filters}
          getItems={getItems}
          selectedItems={selectedItems}
          selectItem={handleSelectItem}
          removeItem={removeItem}
          isFilteredList={isFilteredList}
          isPicker={isPicker}
          listUpdated={listUpdated}
          setSelectedItem={setSelectedItem}
          hasCustomItem={hasCustomItem}
          itemName={itemName}
          hideSmallIcon={hideSmallIcon}
          pickOneElement={pickOneElement}
          setPickedItems={handlePickedItems}
          itemClassName={itemClassName}
          category={category}
          removeItems={removeItems}
          hideBoxIcon={hideBoxIcon}
          groupedLeftList={groupedLeftList}
        />
      }
      footer={
        <Footer
          isPicker={isPicker}
          buttonLabel={buttonLabel}
          closeModal={handleCloseModal}
          clearFilter={clearFilter}
          showClearSelectionAlert={showClearSelectionAlert}
          save={handleSave}
          disabledButton={
            !isPicker
              ? selectedItems.length === 0
                ? true
                : false
              : pickOneElement
              ? selectedItem
                ? false
                : true
              : currentSelectedPickedItems &&
                currentSelectedPickedItems.length === 0
              ? true
              : false
          }
          saveButtonLabel={footerSaveButtonLabel}
          buttonClasses="mr-1"
          btnSaveClasses={buttonSaveClasses ?? "w-136 h-32 ml-1 font-black"}
        />
      }
      currentSelectedPickedItems={currentSelectedPickedItems}
    />
  );
};

export default BaseAddModal;
