import React from "react";
import { IonCol, IonButton } from "@ionic/react";

const BaseSelectMultiButton: React.FC<any> = (props: any) => {
  const {
    source,
    onClickButton,
    isChecked,
    ionColSize,
    ionColClass,
    ionButtonClass
  } = props;
  return (
    <>
      {source &&
        source.map((value: any, index: number) => (
          <IonCol
            key={index}
            size={ionColSize ?? 4}
            className={ionColClass ?? "no-padding to-left"}
          >
            <IonButton
              onClick={() => onClickButton(value)}
              className={
                (ionButtonClass ??
                  "font-12px no-maring btn btn-group-1 font-bold") +
                " " +
                (isChecked(value) ? "positive" : "negative")
              }
            >
              <div className="truncate-text">{value}</div>
            </IonButton>
          </IonCol>
        ))}
    </>
  );
};

export default BaseSelectMultiButton;
