import React from "react";
import { IonRow, IonCol } from "@ionic/react";

import SideMenuList from "../SideMenuList";
import FormsContainer from "./FormsContainer";
import AddFurnitureModal from "./AddFurnitureModal";

const Wrapper: React.FC<any> = (props) => {
  const {
    openWorkstationFurnitureModal,
    environmentFurnitures,
    openModal,
    showFormComponent,
    getSelectedFurnitureIndex,
    setFurnitureIndexSelected,
    updateSortedList,
    setNoFurnitureSelected,
    setFurnitureTypeSelected,
    isNewSession,
    noSelectedFurniture,
    type,
    showSkeleton,
    resetList,
    idSelected
  } = props;

  return (
    <>
      <AddFurnitureModal
        isOpen={openWorkstationFurnitureModal}
        resetList={resetList}
      />
      <IonRow>
        <IonCol
          size-xxl="3"
          size-xl="3"
          size-lg="4"
          size-md="4"
          size-sm="4"
          size-sx="4"
          size="4"
          className="border-right y-height-50vh y-custom-child"
        >
          <SideMenuList
            list={environmentFurnitures}
            openModal={openModal}
            showFormComponent={showFormComponent}
            buttonLabel={"ADD FURNITURE"}
            deleteLabel="furniture"
            getSelectedIndex={getSelectedFurnitureIndex}
            setIndexSelected={setFurnitureIndexSelected}
            workstationDeleteEndpoint="/workstationfurnitures"
            updateSortedList={updateSortedList}
            setNoWorkstationSelected={setNoFurnitureSelected}
            setWorstationTypeSelected={setFurnitureTypeSelected}
            idSelected={idSelected}
            showSkeleton={showSkeleton}
          />
        </IonCol>
        <IonCol className="y-height-100">
          {environmentFurnitures && environmentFurnitures.length === 0 ? (
            <p>No Furniture Added for this Environment.</p>
          ) : (isNewSession && isNewSession) ||
            (noSelectedFurniture && environmentFurnitures.length > 0) ? (
            <p>No Furniture Selected</p>
          ) : (
            <FormsContainer type={type} />
          )}
        </IonCol>
      </IonRow>
    </>
  );
};

export default Wrapper;
