import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _, { find, findIndex } from "lodash";
import { groupList } from "../../../../services/tabs.service";
import { categoryTypes } from "../../../../services/tabs.service";

const slice = createSlice({
  name: "workstationEquipments",
  initialState: {
    list: {},
    environmentEquipments: {},
    showAddWorkstationEquipmentModal: false,
    selectedEquipmentIndex: -1,
    currentEquipment: undefined,
    selectedType: "",
    noEquipmentSelected: false,
    sortedList: [],
    isFilteredList: false,
    filters: {
      category: "",
      subCategory: "",
      manufacturer: ""
    },
    showEquipmentPicker: false,
    selectedId: -1,
    total: 0,
    showEquipmentsSkeleton: false,
    selectedIdEnvironment: -1
  },
  reducers: {
    equipmentsReceived(workstationEquipments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        workstationEquipments.list = payload.rows[0];
      } else {
        workstationEquipments.list = {};
      }
    },
    setShowAddWorkstationEquipmentModal(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.showAddWorkstationEquipmentModal = action.payload;
    },
    setEquipmentIndexSelected(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.selectedEquipmentIndex = action.payload;
    },
    setEquipmentTypeSelected(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.selectedType = action.payload;
    },
    setCurrentEquipment(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.currentEquipment = action.payload;
    },
    currentEquipmentUpdated(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.currentEquipment[`${action.payload.key}`] =
        action.payload.value;
    },
    updateList(workstationEquipments: any, action: PayloadAction<any>) {
      workstationEquipments.list[`${action.payload.type}`] =
        action.payload.list;
    },
    sortedListUpdated(workstationEquipments: any, action: PayloadAction<any>) {
      workstationEquipments.sortedList = action.payload;
      workstationEquipments.total = action.payload.length;
    },
    setNoEquipmentSelected(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.noEquipmentSelected = action.payload;
    },
    equipmentsSorted(workstationEquipments: any, action: PayloadAction<any>) {
      workstationEquipments.sortedList = action.payload;
      workstationEquipments.total = action.payload.length;
    },
    isFilteredEquipmentsSet(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.isFilteredList = action.payload;
    },
    filtersUpdated(workstationEquipments: any, action: PayloadAction<any>) {
      workstationEquipments.filters[action.payload.key] = action.payload.value;
    },
    showEquipmentPickerSet(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.showEquipmentPicker = action.payload;
    },
    idSelected(workstationEquipments: any, action: PayloadAction<any>) {
      workstationEquipments.selectedId = action.payload;
    },
    showEquipmentsSkeletonUpdated(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.showEquipmentsSkeleton = action.payload;
    },
    environmentEquipmentsReceived(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.environmentEquipments = action.payload;
    },
    selectedIdEnvironmentUpdated(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.selectedIdEnvironment = action.payload;
    },
    environmentEquipmentsUpdated(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.environmentEquipments = action.payload;
    }
  }
});

export const {
  equipmentsReceived,
  setShowAddWorkstationEquipmentModal,
  setEquipmentIndexSelected,
  setCurrentEquipment,
  currentEquipmentUpdated,
  updateList,
  sortedListUpdated,
  setEquipmentTypeSelected,
  setNoEquipmentSelected,
  equipmentsSorted,
  isFilteredEquipmentsSet,
  filtersUpdated,
  showEquipmentPickerSet,
  idSelected,
  showEquipmentsSkeletonUpdated,
  environmentEquipmentsReceived,
  selectedIdEnvironmentUpdated,
  environmentEquipmentsUpdated
} = slice.actions;

export default slice.reducer;

/**
 * Gets show equipments skeleton.
 */
export const getCurrentShowEquipmentsSkeleton = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) => workstationEquipments.showEquipmentsSkeleton
);

/**
 * Gets current total environment equipments.
 * @returns
 */
export const getCurrentTotalEnvironmentEquipments =
  (currentEnvironmentEquipments: any = undefined) =>
  (dispatch: any, getState: any) => {
    const { selectedIdEnvironment, environmentEquipments } =
      getState().evaluation.environment.workstation.workstationEquipments;

    const currentEnvironmentEquipments: any = environmentEquipments[
      selectedIdEnvironment
    ]
      ? environmentEquipments[selectedIdEnvironment]
      : {};

    return currentEnvironmentEquipments.sorted
      ? currentEnvironmentEquipments.sorted.length
      : 0;
  };

/**
 * Gets show add modal.
 */
export const getShowAddWorkstationEquipmentModal = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) =>
    workstationEquipments.showAddWorkstationEquipmentModal
);

/**
 * Gets selected type.
 */
export const getSelectedType = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) => workstationEquipments.selectedType
);

/**
 * Gets no equipment selected.
 */
export const getNoEquipmentSelected = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) => workstationEquipments.noEquipmentSelected
);

/**
 * Gets sorted list.
 */
export const getSortedEnvironmentEquipments = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) => {
    return workstationEquipments.environmentEquipments[
      workstationEquipments.selectedIdEnvironment
    ]
      ? workstationEquipments.environmentEquipments[
          workstationEquipments.selectedIdEnvironment
        ].sorted
      : [];
  }
);
/**
 * Gets is filtered list.
 */
export const getIsFilteredList = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) => workstationEquipments.isFilteredList
);

/**
 * Gets current equipment.
 */
export const getCurrentEquipment = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) => workstationEquipments.currentEquipment
);

/**
 * Gets selected equiment.
 * @returns
 */
export const getSelectedEquipment = () => (dispatch: any, getState: any) => {
  return getState().evaluation.environment.workstation.workstationEquipments
    .currentEquipment;
};

/**
 * Gets current selected equipment.
 * @returns
 */
export const getCurrentSelectedEquipment =
  () => (dispatch: any, getState: any) => {
    const { environmentEquipments, selectedId, selectedIdEnvironment } =
      getState().evaluation.environment.workstation.workstationEquipments;
    const { sorted } = environmentEquipments[selectedIdEnvironment];

    const equipment = find(sorted, { id: selectedId });

    return equipment;
  };

/**
 * Updates workstation equipments with corresponding current environment
 * @returns
 */
export const updateEquipmentsList =
  (currentEquipment: any = undefined) =>
  (dispatch: any, getState: any) => {
    const { environmentEquipments, selectedId, selectedIdEnvironment } =
      getState().evaluation.environment.workstation.workstationEquipments;
    const currentEnvironmentEquipments: any = {
      ...environmentEquipments[selectedIdEnvironment]
    };
    const { list, sorted } = currentEnvironmentEquipments;

    if (currentEquipment === undefined) {
      currentEquipment = find(sorted, { id: selectedId });
    }

    if (list) {
      let equipments = list[`${currentEquipment.type}`]
        ? list[`${currentEquipment.type}`]
        : [];

      let index = findIndex(equipments, {
        id: currentEquipment.id
      });

      if (index === -1) {
        index = 0;
      }

      let temp = [...equipments];
      temp[index] = currentEquipment;

      // Updating the unsorted list
      let tempList = { ...list };
      tempList[`${currentEquipment.type}`] = temp;

      index = findIndex(sorted, {
        id: currentEquipment.id
      });

      if (index === -1) {
        index = 0;
      }

      let tempSortedList = [];
      tempSortedList = [...sorted];
      tempSortedList[index] = currentEquipment;

      let tempEnvironmentEquipments = { ...environmentEquipments };

      tempEnvironmentEquipments[selectedIdEnvironment] = {
        list: tempList,
        sorted: tempSortedList
      };

      dispatch({
        type: environmentEquipmentsUpdated.type,
        payload: tempEnvironmentEquipments
      });
      dispatch({
        type: setCurrentEquipment.type,
        payload: currentEquipment
      });
    }
  };

/**
 * Gets selected equipment index.
 */
export const getSelectedEquipmentIndex = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) => workstationEquipments.selectedEquipmentIndex
);

/**
 * Gets laptop ids.
 */
export const getLaptopIds = () => (dispatch: any, getState: any) => {
  const { selectedIdEnvironment, environmentEquipments } =
    getState().evaluation.environment.workstation.workstationEquipments;

  const currentEnvironmentEquipments =
    environmentEquipments[selectedIdEnvironment];
  const sortedList = currentEnvironmentEquipments.sorted
    ? currentEnvironmentEquipments.sorted
    : [];

  return _.filter(sortedList, function (o: any) {
    return o.type.toLowerCase() === "laptops";
  });
};

/**
 * Gets category.
 * @returns
 */
export const getCategory =
  (equipmentCategories: any) => (dispatch: any, getState: any) => {
    const { selectedType } =
      getState().evaluation.environment.workstation.workstationEquipments;
    const categories = categoryTypes["equipments"];

    let category = _.findKey(categories, function (o) {
      return o.toLowerCase() === selectedType.toLowerCase();
    });

    const index = _.findIndex(equipmentCategories, function (e: any) {
      return e.toLowerCase() === category;
    });

    category = equipmentCategories[index];

    return category;
  };

/**
 * Loads grouped equipments
 * - laptop
 * - display
 * - pointing device (mouse)
 * - keyboard
 * - tablet computer
 * - video camera
 * - accessories
 * - hardware
 * @returns
 */
export const loadGroupedEquipments =
  () => async (dispatch: any, getState: any) => {
    const { environmentEquipments, selectedIdEnvironment } =
      getState().evaluation.environment.workstation.workstationEquipments;

    const sort = {
      columns: ["subCategory"],
      directions: ["asc"]
    };

    // const sorted = await groupList(list, sort, "equipments");
    const currentEquipments = await groupList(
      environmentEquipments[selectedIdEnvironment]?.list,
      sort,
      "equipments"
    );

    dispatch({
      type: equipmentsReceived.type,
      payload: {
        rows: currentEquipments
      }
    });
  };

/**
 * Loads environment equiments grouped and sorted
 * @returns
 */
export const sortEnvironmentEquipments =
  (data: any) => async (dispatch: any, getState: any) => {
    const { selectedIdEnvironment, environmentEquipments } =
      getState().evaluation.environment.workstation.workstationEquipments;

    let currentEnvironmentEquipments: any = { ...environmentEquipments };
    currentEnvironmentEquipments[selectedIdEnvironment] = {
      list: data.rows.length > 0 ? data.rows[0] : {}
    };

    const groupedAndSorted: any = await groupList(
      data.rows[0],
      {
        columns: ["name"],
        directions: ["asc"]
      },
      "equipments"
    );

    currentEnvironmentEquipments[selectedIdEnvironment] = {
      ...currentEnvironmentEquipments[selectedIdEnvironment],
      sorted: groupedAndSorted
    };

    dispatch({
      type: equipmentsSorted.type,
      payload: groupedAndSorted
    });

    // Storing all evaluation => environment => equipments
    dispatch({
      type: environmentEquipmentsReceived.type,
      payload: currentEnvironmentEquipments
    });
  };

/**
 * Gets shows equipment picker.
 */
export const getShowEquipmentPicker = createSelector(
  (state: any) =>
    state.evaluation.environment.workstation.workstationEquipments,
  (workstationEquipments: any) => workstationEquipments.showEquipmentPicker
);

/**
 * Gets current selected id.
 */
export const getCurrentSelectedId = () => (dispatch: any, getState: any) => {
  return getState().evaluation.environment.workstation.workstationEquipments
    .selectedId;
};

/**
 * Gets total environment equipments.
 */
export const getTotalEnvironmentEquipments = createSelector(
  (state: any) => {
    return state.evaluation.environment.workstation.workstationEquipments;
  },
  (workstationEquipments: any) => workstationEquipments.total
);

/**
 * Updates selected id.
 * @param selectedId
 * @returns
 */
export const selectedIdUpdated =
  (selectedId: number) => (dispatch: any, getState: any) => {
    dispatch({
      type: idSelected.type,
      payload: selectedId
    });
  };

export const updateEnvironmentEquipments =
  (data: any) => (dispatch: any, getState: any) => {
    const { selectedIdEnvironment, environmentEquipments } =
      getState().evaluation.environment.workstation.workstationEquipments;

    let currentEnvironmentEquipments: any = environmentEquipments[
      selectedIdEnvironment
    ]
      ? { ...environmentEquipments[selectedIdEnvironment] }
      : {};

    currentEnvironmentEquipments.sort = data;

    let tempEnvironmentEquipments = { ...environmentEquipments };
    tempEnvironmentEquipments[selectedIdEnvironment] = {
      list: currentEnvironmentEquipments.list
        ? currentEnvironmentEquipments.list
        : {},
      sorted: data
    };

    dispatch({
      type: environmentEquipmentsUpdated.type,
      payload: tempEnvironmentEquipments
    });
  };
