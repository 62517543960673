import React from "react";
import { IonRow } from "@ionic/react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table: React.FC<any> = React.forwardRef((props: any, ref: any) => {
  const {
    sortRows,
    evaluations,
    sortColumns,
    selectedIndex,
    goToEvaluation,
    sortDirections,
    setSelectedIndex,
    disableBaseMethods,
    setShowEvaluationsTable,
    setTopNavigationEvaluation,
    setEvaluation
  } = props;

  const handleSortTable = (column: string, direction: string) => {
    sortRows(evaluations, column, direction);
  };

  return (
    <>
      <TableHeader
        sortTable={handleSortTable}
        sortColumns={sortColumns}
        sortDirections={sortDirections}
        disableBaseMethods={disableBaseMethods}
      />
      <IonRow className="separate-line"></IonRow>
      <TableBody
        ref={ref}
        evaluations={evaluations}
        setEvaluation={setEvaluation}
        selectedIndex={selectedIndex}
        disableBaseMethods={disableBaseMethods}
        setTopNavigationEvaluation={setTopNavigationEvaluation}
        setSelectedIndex={setSelectedIndex}
        setShowEvaluationsTable={setShowEvaluationsTable}
        goToEvaluation={goToEvaluation}
      />
    </>
  );
});

export default Table;
