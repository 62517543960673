import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { IonHeader, IonPage, IonToolbar, IonSplitPane } from "@ionic/react";
import Toolbar from "../components/layout/Toolbar";
import { useAppDispatch, useAppSelector } from "../store/Hooks";
import { getCurrentUser } from "../store/User";
import { apiCall } from "../store/api";
import {
  getCurrentOrganizationLocations,
  getCurrentOrganizations,
  updateOrganizationLocations
} from "../store/Evaluator";
import Menu from "../components/appointment/leftmenu/Menu";
import Workers from "../components/appointment/employees/Workers";
import { getWorkerById, getWorkers } from "../services/worker.service";

import "../css/appointments.css";
import {
  getApiValuelistByName,
  insertResourceProcess
} from "../helpers/global.helper";
import {
  appointmentMinutesReceived,
  evaluationTypesReceived
} from "../store/catalogs/Evaluations";
import _, { findIndex } from "lodash";
import { getEvaluatorLocations } from "../services/evaluator.service";
import {
  getCurrentSearch,
  getCurrentSelectedLocation,
  getCurrentSort,
  getCurrentWorkers,
  locationIndexUpdated,
  searchUpdated,
  selectedLocationUpdated,
  sortUpdated,
  workersUpdated
} from "../store/appointments/Index";

const Appointments: React.FC<any> = () => {
  const [isOpenWorkerModal, setIsOpenWorkerModal] = useState<boolean>(false);
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] =
    useState<boolean>(false);
  const [isOpenRewiewDuplicatesModal, setIsOpenRewiewDuplicatesModal] =
    useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<any>();

  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(getCurrentUser);
  const currentOrganizations = useAppSelector(getCurrentOrganizations);
  const currentOrganizationLocations = useAppSelector(
    getCurrentOrganizationLocations
  );
  const [workers, setWorkers] = useState<any>([]);
  const [worker, setWorker] = useState<any>();
  const [selectedTableIndex, setSelectedTableIndex] = useState<number>(-1);
  const [isLocationSelected, setIsLocationSelected] = useState<boolean>(false);
  const [sort, setSort] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>(undefined);
  const [workerData, setWorkerData] = useState<any>();
  const [duplicatedRows, setDuplicatedRows] = useState<any>([]);
  const [sortDuplicates, setSortDuplicates] = useState<any>(undefined);

  const currentSearch = useAppSelector(getCurrentSearch);
  const currentWorkers = useAppSelector(getCurrentWorkers);
  const currentSelectedLocation = useAppSelector(getCurrentSelectedLocation);
  const currentSort = useAppSelector(getCurrentSort);

  const goBack = (route: string) => {
    history.push(route);
  };

  const getData = async () => {
    const response = await dispatch(
      getEvaluatorLocations(currentUser && currentUser.idEvaluator)
    );

    dispatch({
      type: updateOrganizationLocations,
      payload: response.data.rows
    });
  };

  useEffect(() => {
    setSelectedLocation(currentSelectedLocation);
    setIsLocationSelected(true);
    setWorkers(currentWorkers);

    if (currentSearch) {
      setSearchValue(currentSearch);
    }
    setSort(currentSort);
  }, [currentSelectedLocation && currentSelectedLocation]);

  useEffect(() => {
    getData();

    dispatch(
      apiCall({
        url: getApiValuelistByName("Evaluation_Type"),
        onSuccess: evaluationTypesReceived.type
      })
    );

    dispatch(
      apiCall({
        url: getApiValuelistByName("Appointment_Minutes"),
        onSuccess: appointmentMinutesReceived.type
      })
    );
  }, [currentUser && currentUser.idEvaluator]);

  const handleLocationClick = async (location: any) => {
    setWorkers([]);
    setSelectedLocation(location);
    setIsLocationSelected(true);

    const parameters = {
      idLocation: location.idLocation
    };

    const locationIndex = findIndex(currentOrganizationLocations, {
      idLocation: location.idLocation
    });

    dispatch({
      type: locationIndexUpdated.type,
      payload: locationIndex
    });

    dispatch({
      type: selectedLocationUpdated.type,
      payload: location
    });

    const response: any = await dispatch(getWorkers(parameters));

    if (response.status === 200) {
      setWorkers(response.data.rows);
      dispatch({
        type: workersUpdated.type,
        payload: response.data.rows
      });
    }
  };

  const handleSaveWorker = async (data: any) => {
    data.idLocation = selectedLocation.idLocation;
    data.idOrganization = selectedLocation.idOrganization;

    dispatch(
      insertResourceProcess(
        [data],
        ["idLocation", "idOrganization", "nameFirst", "nameLast"],
        {},
        `/workers`
      )
    )
      .then(async (rows: any) => {
        setIsOpenWorkerModal(false);
        const response: any = await dispatch(getWorkerById(rows[0].id));
        setWorker(response.data);
        setWorkers([response.data]);
        dispatch({
          type: workersUpdated.type,
          payload: [response.data]
        });
        setSearchValue(`${data.nameFirst}`);
        setSelectedTableIndex(0);

        setDuplicatedRows([]);
        setSortDuplicates(undefined);
        setIsOpenRewiewDuplicatesModal(false);
      })
      .catch((e: any) => console.log(e));
  };

  const handleOnClickTableBody = (row: any, index: number) => {
    setWorker(row);
    setSelectedTableIndex(index);
  };

  const handleInputSearch = async (name: string) => {
    let location = selectedLocation
      ? selectedLocation
      : currentSelectedLocation;

    let parameters: any = {
      idLocation: location.idLocation
    };

    let response: any;

    if (name !== "" && name.length >= 3) {
      dispatch({
        type: searchUpdated.type,
        payload: name
      });

      parameters = { ...parameters, name };
      response = await dispatch(getWorkers(parameters));

      if (response.status === 200) {
        setWorkers(response.data.rows);
        dispatch({
          type: workersUpdated.type,
          payload: response.data.rows
        });
      }
    }

    if (name == "") {
      dispatch({
        type: searchUpdated.type,
        payload: name
      });

      response = await dispatch(getWorkers(parameters));

      if (response.status === 200) {
        setWorkers(response.data.rows);
        dispatch({
          type: workersUpdated.type,
          payload: response.data.rows
        });
      }
    }

    setSelectedTableIndex(-1);

    if (name !== searchValue) {
      if (name.length == 0) {
        setSearchValue(undefined);
      }
    }
  };

  const handleSort = async (column: string, direction: string) => {
    if (column != "") {
      let temp = [...workers];
      let columns: any = [];
      columns = column.split(",");
      let directions: any = [];

      if (column.length > 1) {
        for (const [, d] of columns.entries()) {
          directions.push(direction);
        }
      }

      const sorted = _.orderBy(temp, columns, directions);

      setWorkers(sorted);
      dispatch({
        type: workersUpdated.type,
        payload: sorted
      });

      setSort({
        columns,
        directions
      });

      dispatch({
        type: sortUpdated.type,
        payload: {
          columns,
          directions
        }
      });
    }
  };

  const openReviewDuplicates = (data: any, rows: any) => {
    setWorkerData(data);
    setDuplicatedRows(rows);

    setIsOpenWorkerModal(false);
    setIsOpenRewiewDuplicatesModal(true);
  };

  const selectWorker = (data: any) => {
    setWorker(data);
    setWorkers([data]);
    setIsOpenRewiewDuplicatesModal(false);
    setSelectedTableIndex(0);
  };

  const handleSortDuplicates = async (column: string, direction: string) => {
    if (column != "") {
      let temp = [...duplicatedRows];
      let columns: any = [];
      columns = column.split(",");
      let directions: any = [];

      if (column.length > 1) {
        for (const [, d] of columns.entries()) {
          directions.push(direction);
        }
      }

      const sorted = _.orderBy(temp, columns, directions);

      setDuplicatedRows(sorted);

      setSortDuplicates({
        columns,
        directions
      });
    }
  };

  const onDidDismissDuplicates = () => {
    if (!isOpenRewiewDuplicatesModal) {
      setSortDuplicates(undefined);
    }
  };

  const handleAddWorkerModal = () => {
    setIsOpenWorkerModal(!isOpenWorkerModal);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar className="toolbar-header">
          <Toolbar
            backButtonIsPresent={true}
            title="Add Appointment"
            withNavigation={true}
            goBack={goBack}
          />
        </IonToolbar>
      </IonHeader>
      <IonSplitPane className="pt-16" contentId="appointmentMenu" when="md">
        <Menu
          contentId="appointmentMenu"
          items={currentOrganizations}
          subItems={currentOrganizationLocations}
          onLocationClick={handleLocationClick}
        />
        <IonPage id="appointmentMenu">
          <Workers
            isOpenWorkerModal={isOpenWorkerModal}
            setIsOpenWorkerModal={handleAddWorkerModal}
            list={workers}
            onSaveWorker={handleSaveWorker}
            onClickTableBody={handleOnClickTableBody}
            selectedIndex={selectedTableIndex}
            isLocationSelected={isLocationSelected}
            isOpenAppointmentModal={isOpenAppointmentModal}
            setIsOpenAppointmentModal={setIsOpenAppointmentModal}
            isOpenRewiewDuplicatesModal={isOpenRewiewDuplicatesModal}
            setIsOpenRewiewDuplicatesModal={setIsOpenRewiewDuplicatesModal}
            worker={worker}
            location={selectedLocation}
            handleInputSearch={handleInputSearch}
            handleSort={handleSort}
            sort={sort}
            value={searchValue}
            openReviewDuplicates={openReviewDuplicates}
            workerData={workerData}
            duplicatedRows={duplicatedRows}
            selectWorker={selectWorker}
            handleSortDuplicates={handleSortDuplicates}
            sortDuplicates={sortDuplicates}
            onDidDismissDuplicates={onDidDismissDuplicates}
          />
        </IonPage>
      </IonSplitPane>
    </>
  );
};

export default withRouter(Appointments);
