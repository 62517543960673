import React, { useState, useEffect } from "react";
import _, { findIndex } from "lodash";
import crypto from "crypto";
import {
  IonRow,
  IonCol,
  IonText,
  IonPage,
  IonGrid,
  IonBadge,
  IonLabel,
  IonInput,
  IonButton,
  IonContent,
  IonCheckbox,
  IonIcon,
  IonItem,
  IonImg,
  useIonAlert,
  IonSkeletonText
} from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { addSharp } from "ionicons/icons";
import moment from "moment";
import { getWorkerById, patchWorker } from "../../services/worker.service";
import { getEvaluatorById } from "../../services/evaluator.service";
import {
  saveEvaluationComment,
  patchEvaluation,
  isHighPriority,
  deleteComment
} from "../../services/evaluation.service";
import "../../css/bidb.css";
import "../../css/basicInfo.css";
import "../../css/equipment.css";
import "react-image-lightbox/style.css";
import DatePicker from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import SelectForm from "../common/controls/SelectForm";
import "react-datepicker/dist/react-datepicker.css";
import greenCheck from "../../assets/green_check.png";
import CALENDAR from "../../assets/calendar_today.png";
import { getOrganizationById } from "../../services/organization.service";
import {
  commentUpdated,
  evaluationUpdated,
  evaluatorUpdated,
  getCurrentEvaluationComments,
  getCurrentEvaluationEvaluator,
  getCurrentEvaluationInRuntime,
  getCurrentEvaluationOrganization,
  getCurrentEvaluationWorker,
  getCurrentEvaluationWorkerInRuntime,
  organizationUpdated,
  updateCurrentEvaluationComments,
  workerUpdated,
  showEnvironmentLoaderUpdated
} from "../../store/evaluation/Current";
import { getListByName } from "../../store/catalogs/ValueList";
import Checkbox from "../common/controls/Checkbox";
import RequestPriority from "../common/RequestPriority";
import Textarea from "../common/Textarea";
import {
  evaluationsOriginalListUpdated,
  evaluationsUpdated,
  getCurrentEvaluations,
  getCurrentEvaluationsOriginalList
} from "../../store/Evaluations";
import { getToken } from "../../helpers/global.helper";

const BasicInformation: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const statuses = dispatch(getListByName("Evaluation_Status"));
  const workerLocations = dispatch(
    getListByName("Worker_Location_During_Evaluation")
  );
  const workerHeightUnits = dispatch(getListByName("Height_Unit"));
  const workerHandDominance = dispatch(getListByName("Hand_Dominance"));
  const recheckTimeFrames = dispatch(
    getListByName("Evalution_Recheck_Time_Frame")
  );
  const commentTypes = dispatch(getListByName("Evaluation_Comment_Type"));
  const [showCommentInputs, setShowCommentInputs] = useState(false);
  const [evaluationCommentType, setEvaluationCommentType] = useState("");
  const [evaluationCommentInput, setEvaluationCommentInput] = useState("");
  const [requestOrigin, setRequestOrigin] = useState([]);
  const [requestReferred, setRequestReferred] = useState([]);
  const [employeeNewHire, setEmployeeNewHire] = useState<boolean | null>(null);
  const [recheckNotes, setRecheckNotes] = useState<string | null>(null);
  const [present] = useIonAlert();

  const currentEvaluation = dispatch(getCurrentEvaluationInRuntime());
  const currentEvaluationComments = useAppSelector(
    getCurrentEvaluationComments
  );

  const currentWorker = useAppSelector(getCurrentEvaluationWorker);
  const currentEvaluator = useAppSelector(getCurrentEvaluationEvaluator);
  const currentOrganization = useAppSelector(getCurrentEvaluationOrganization);
  const currentEvaluations = useAppSelector(getCurrentEvaluations);
  const currentEvaluationsOriginalList = useAppSelector(
    getCurrentEvaluationsOriginalList
  );
  const [heightUnit, setHeightUnit] = useState<string | null>(null);

  /**
   * Updates evaluation status
   * @param value
   */
  const updateEvaluationValue = async (key: string, value: any) => {
    const currentEvaluation = dispatch(getCurrentEvaluationInRuntime());

    if (currentEvaluation && value !== undefined) {
      value = value !== currentEvaluation[key] ? value : undefined;
      if (value !== undefined) {
        console.log("Final Value :>> ", value);

        let data: any = {
          id: currentEvaluation.id,
          [key]: value
        };

        dispatch(patchEvaluation(data));

        dispatch({
          type: evaluationUpdated.type,
          payload: {
            [key]: value
          }
        });

        // Updating status dashboard
        if (key === "status") {
          let temp = [...currentEvaluations];
          let originalListTemp = [...currentEvaluationsOriginalList];

          let index = findIndex(temp, { id: currentEvaluation.id });
          let object = { ...temp[index] };
          object.evalStatus = value;
          temp[index] = object;

          dispatch({
            type: evaluationsUpdated.type,
            payload: temp
          });

          index = findIndex(originalListTemp, { id: currentEvaluation.id });
          object = { ...originalListTemp[index] };
          object.evalStatus = value;
          originalListTemp[index] = object;

          dispatch({
            type: evaluationsOriginalListUpdated.type,
            payload: originalListTemp
          });
        }
      }
    }

    if (
      key === "visionDateOfLastExam" &&
      (currentEvaluation[key] !== value) === true
    ) {
      let data: any = {
        id: currentEvaluation.id,
        [key]: value
      };

      patchEvaluation(data);

      dispatch({
        type: evaluationUpdated.type,
        payload: {
          [key]: value
        }
      });
    }

    if (key === "commentType") {
      setEvaluationCommentType("");
    }
  };

  const getWeekLabel = (totalWeeks: number) => {
    if (totalWeeks) {
      let weeks = "";

      if (totalWeeks > 1) {
        weeks = "Weeks";
      } else {
        weeks = "Week";
      }
      return `${totalWeeks} ${weeks}`;
    }

    return "";
  };

  const setShowLoader = (value: boolean) => {
    dispatch({
      type: showEnvironmentLoaderUpdated.type,
      payload: value
    });
  };

  useEffect(() => {
    if (currentEvaluation) {
      dispatch({
        type: workerUpdated.type,
        payload: undefined
      });
      dispatch({
        type: organizationUpdated.type,
        payload: undefined
      });
      dispatch({
        type: evaluatorUpdated.type,
        payload: undefined
      });
      dispatch(getWorkerById(currentEvaluation.idWorker))
        .then((response: any) => {
          const { data } = response;
          dispatch({
            type: workerUpdated.type,
            payload: data
          });

          setHeightUnit(data.heightUnit && data.heightUnit);

          dispatch(getOrganizationById(data.idOrganization))
            .then((response: any) => {
              dispatch({
                type: organizationUpdated.type,
                payload: response.data
              });
            })
            .catch((e) => console.log(e));
        })
        .catch((e: any) => console.log(e));

      dispatch(
        getEvaluatorById(currentEvaluation && currentEvaluation.idEvaluator)
      )
        .then((response: any) => {
          dispatch({
            type: evaluatorUpdated.type,
            payload: response.data
          });
        })
        .catch((e) => console.log(e));
    }

    setEmployeeNewHire(currentEvaluation && currentEvaluation.employeeNewHire);

    setRecheckNotes(
      currentEvaluation && currentEvaluation.recheckNotes
        ? currentEvaluation.recheckNotes
        : ""
    );
  }, [currentEvaluation && currentEvaluation.id]);

  useEffect(() => {
    setRequestOrigin(dispatch(getListByName("Request_Origin")));

    setRequestReferred(dispatch(getListByName("Request_Referred_By")));
  }, []);

  const sortedEvaluationComments = () => {
    const sortedComments: any = _.orderBy(
      currentEvaluationComments,
      ["timestampInserted"],
      ["desc"]
    );

    return sortedComments;
  };

  /**
   * Updates worker value
   * @param key
   * @param value
   */
  const updateWorkerValue = async (key: string, value: any) => {
    if (dispatch(getCurrentEvaluationInRuntime())) {
      if (value !== undefined) {
        if (currentWorker && currentWorker[key] !== value) {
          let data: any = {
            id: currentWorker.id,
            [key]: value
          };

          dispatch(patchWorker(data));

          dispatch({
            type: workerUpdated.type,
            payload: { ...currentWorker, ...data }
          });
        }
      }
    }
  };

  /**
   * Updates vision date
   * @param date
   */
  const updateVisionDate: any = async (date: any) => {
    if (date !== null) {
      date = moment(date).format("yyyy-MM-DD");

      if (currentEvaluation["visionDateOfLastExam"] === date) {
        return;
      }
    }

    updateEvaluationValue("visionDateOfLastExam", date);
  };

  /**
   * Saves comment
   */
  const saveComment = async () => {
    let token = crypto.randomBytes(64).toString("hex");

    if (evaluationCommentType !== "" && evaluationCommentInput !== "") {
      setShowLoader(true);
      const data = {
        idEvaluation: currentEvaluation.id,
        idEvaluator: currentEvaluator.id,
        comment: evaluationCommentInput,
        confidential: true,
        type: evaluationCommentType,
        token
      };

      let temp: any = [...currentEvaluationComments];
      const comment: any = {
        type: evaluationCommentType,
        comment: evaluationCommentInput,
        timestampInserted: new Date(),
        token
      };

      temp.unshift(comment);

      dispatch({
        type: updateCurrentEvaluationComments.type,
        payload: _.orderBy(temp, ["timestampInserted"], ["desc"])
      });

      dispatch(saveEvaluationComment({ id: currentEvaluation.id, data }))
        .then((response: any) => {
          temp = _.map(temp, (item) => {
            return item.token === token
              ? { ...item, id: response.data.successes[0].id }
              : { ...item };
          });
          dispatch({
            type: updateCurrentEvaluationComments.type,
            payload: _.orderBy(temp, ["timestampInserted"], ["desc"])
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: updateCurrentEvaluationComments.type,
            payload: _.orderBy(
              currentEvaluationComments,
              ["timestampInserted"],
              ["desc"]
            )
          });
        });

      setEvaluationCommentType("");
      setEvaluationCommentInput("");

      setTimeout(() => {
        setShowLoader(false);
      }, 500);
    }
  };

  /**
   * Updates recheck time frame
   */
  const updateRecheckTimeFrame = (name: string, value: any) => {
    if (value) {
      const newValue = value.split(" ");
      updateEvaluationValue(name, newValue[0]);
    }
  };

  const refDatePicker = React.createRef<ReactDatePicker>();
  const onClickDatePickerWrapper = () => {
    if (!refDatePicker.current?.isCalendarOpen())
      refDatePicker.current?.setOpen(true);
  };

  // eslint-disable-next-line no-restricted-globals
  const width = screen.width;
  // eslint-disable-next-line no-restricted-globals
  const height = screen.height;

  const handleDeleteComment = async (id: number) => {
    setShowLoader(true);
    if (id) {
      await dispatch(deleteComment(id));

      let temp = [...currentEvaluationComments];
      const filtered = _.filter(temp, (comment: any) => comment.id !== id);

      dispatch({
        type: commentUpdated.type,
        payload: filtered
      });
    }
    setShowLoader(false);
  };

  const onKeyPressCheckbox = (e: any) => {
    if (e.code === "Enter") {
      e.target.children[0].checked = !(e.target.children[0].value === "on");
      e.target.children[0].click();
      e.stopPropagation();
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid className="content-grid">
          <IonRow className="flex ion-align-items-center">
            <IonCol>
              <div className="header-title text-left">
                <IonText
                  color="dark"
                  className="text-24 font-bold font-lato font-stretch-normal 
                  font-style-normal line-height-normal letter-spacing-normal"
                >
                  Basic information
                </IonText>
                <IonBadge className="title-badge ml-1 text-12 px-1 py-020 bg-e5e5e5 ion-text-uppercase">
                  {currentEvaluation && currentEvaluation.type}
                </IonBadge>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="to-left">
            <IonCol>
              <IonRow>
                <IonCol className="bold">Worker</IonCol>
              </IonRow>
              <IonRow className="top-2 text-regular-size">
                <IonCol size="6" className="title-name">
                  First Name
                </IonCol>
                <IonCol size="6" className="title-name">
                  Last Name
                </IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol size="6">
                  {currentWorker == undefined ? (
                    <IonSkeletonText animated />
                  ) : (
                    <IonLabel className="bold">
                      {currentWorker && currentWorker.nameFirst}
                    </IonLabel>
                  )}
                </IonCol>
                <IonCol size="6">
                  {currentWorker == undefined ? (
                    <IonSkeletonText animated />
                  ) : (
                    <IonLabel className="bold">
                      {currentWorker && currentWorker.nameLast}
                    </IonLabel>
                  )}
                </IonCol>
              </IonRow>
            </IonCol>
            <div className="vertical-separator info-separator-vertical"></div>
            <IonCol>
              <IonRow>
                <IonCol className="bold">Evaluator</IonCol>
              </IonRow>
              <IonRow className="top-2 text-regular-size">
                <IonCol size="6" className="title-name">
                  First Name
                </IonCol>
                <IonCol size="6" className="title-name">
                  Last Name
                </IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol size="6">
                  {currentEvaluator == undefined ? (
                    <IonSkeletonText animated />
                  ) : (
                    <IonLabel className="bold">
                      {currentEvaluator && currentEvaluator.nameFirst}
                    </IonLabel>
                  )}
                </IonCol>
                <IonCol size="6">
                  {currentEvaluator == undefined ? (
                    <IonSkeletonText animated />
                  ) : (
                    <IonLabel className="bold">
                      {currentEvaluator && currentEvaluator.nameLast}
                    </IonLabel>
                  )}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <hr className="separator top-2" />
          <IonRow>
            <IonCol className="bold to-left top-1">Consult</IonCol>
          </IonRow>
          <IonRow className="">
            <IonCol>
              <IonRow className="top-2">
                <IonCol size="auto" className="">
                  <RequestPriority evaluation={currentEvaluation} />
                </IonCol>
                <IonCol
                  className="ion-justify-content-start ion-no-padding"
                  size="auto"
                >
                  <IonRow className="ion-text-left text-regular-size">
                    <IonCol className="title-name">Type</IonCol>
                  </IonRow>
                  <IonRow className="text-regular-size">
                    <IonCol className="label-eval-type">
                      <IonLabel className="bold text-regular-size">
                        {currentEvaluation && currentEvaluation.type}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>

            <IonCol size="6">
              <SelectForm
                label="Status"
                isDanger={true}
                options={statuses}
                value={currentEvaluation && currentEvaluation.status}
                name="status"
                onChange={(key: string, value: any) => {
                  if (value) {
                    updateEvaluationValue(key, value);
                  }
                }}
                clearValue={() =>
                  currentEvaluation && updateEvaluationValue("status", null)
                }
              />
            </IonCol>
          </IonRow>
          <IonRow className="to-left">
            <IonCol size="6">
              <SelectForm
                label="Worker location"
                isDanger={true}
                options={workerLocations}
                value={
                  currentEvaluation &&
                  currentEvaluation.locationWorkerDuringEvaluation
                }
                name="locationWorkerDuringEvaluation"
                onChange={(key: string, value: any) => {
                  if (value) {
                    updateEvaluationValue(key, value);
                  }
                }}
                clearValue={() =>
                  updateEvaluationValue("locationWorkerDuringEvaluation", null)
                }
              />
            </IonCol>
            <IonCol>
              <IonRow>
                <IonCol>
                  <IonRow className="top-1 text-regular-size">
                    <IonCol size="12" className="title-name">
                      Evaluator location
                    </IonCol>
                  </IonRow>
                  <IonRow className="text-regular-size">
                    <IonCol size="12">
                      <IonLabel className="bold text-regular-size">
                        {currentEvaluation &&
                          currentEvaluation.locationEvaluatorDuringEvaluation}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol>
                  <Checkbox
                    name="selfAssessmentTaken"
                    label="Self Assessment Taken"
                    value={
                      currentEvaluation && currentEvaluation.selfAssessmentTaken
                    }
                    onChange={updateEvaluationValue}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="top-2">
            {currentEvaluation && currentEvaluation.imageUrl && (
              <>
                <div className="title-equipment">
                  <a
                    target="popup"
                    onClick={() => {
                      window.open(
                        currentEvaluation.imageUrl,
                        "images",
                        "width=" + width + ",height=" + height
                      );
                    }}
                    className="cursor-pointer"
                  >
                    Click here to open image directory
                  </a>
                </div>
              </>
            )}
          </IonRow>
          <IonRow className="top-2">
            <IonCol>
              <IonRow>
                <IonLabel className="form-group-input-text-title">
                  {" "}
                  Recheck Needed{" "}
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonCol size="6" className="to-left">
                  <IonButton
                    onClick={() => {
                      let value: boolean | null = true;

                      if (
                        currentEvaluation &&
                        currentEvaluation.resolutionRecheckNeeded &&
                        currentEvaluation.resolutionRecheckNeeded == true
                      ) {
                        value = null;
                      }

                      updateEvaluationValue("resolutionRecheckNeeded", value);
                    }}
                    className={
                      "no-maring btn btn-group-2 " +
                      (currentEvaluation &&
                      currentEvaluation.resolutionRecheckNeeded === true
                        ? "positive"
                        : "negative") +
                      " font-bold"
                    }
                  >
                    YES
                  </IonButton>
                </IonCol>
                <IonCol size="6" className="to-right">
                  <IonButton
                    onClick={() => {
                      let value: boolean | null = false;

                      if (
                        currentEvaluation &&
                        !currentEvaluation.resolutionRecheckNeeded &&
                        currentEvaluation.resolutionRecheckNeeded == false
                      ) {
                        value = null;
                      }

                      updateEvaluationValue("resolutionRecheckNeeded", value);
                    }}
                    className={
                      "no-maring btn btn-group-2 " +
                      (currentEvaluation &&
                      currentEvaluation.resolutionRecheckNeeded === false
                        ? "positive"
                        : "negative") +
                      " font-bold"
                    }
                  >
                    NO
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol>
              <SelectForm
                label="Recheck time frame"
                options={recheckTimeFrames}
                name="recheckTimeFrame"
                value={
                  currentEvaluation &&
                  getWeekLabel(currentEvaluation.recheckTimeFrame)
                }
                onChange={(key: string, value: any) => {
                  const array = value ? value.split(" ") : [""];
                  let v = array[0];
                  v = v === "" ? 0 : parseInt(v);
                  const evaluation = dispatch(getCurrentEvaluationInRuntime());

                  if (value && evaluation.recheckTimeFrame !== v) {
                    updateRecheckTimeFrame(key, value);
                  }
                }}
                clearValue={() =>
                  updateEvaluationValue("recheckTimeFrame", null)
                }
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="bold to-left top-1">Recheck Focus</IonCol>
          </IonRow>
          <IonRow className="top-1">
            <IonCol
              size-xxl="3"
              size-xl="3"
              size-lg="3"
              size-md="3"
              size-sm="3"
              size-sx="3"
              size="3"
              className="aligned-middle to-left"
            >
              <IonItem
                className="ion-no-padding comboBoxCss"
                onKeyPress={onKeyPressCheckbox}
              >
                <IonCheckbox
                  className="chk"
                  mode="md"
                  checked={
                    currentEvaluation && currentEvaluation.recheckFocusEquipment
                  }
                  onIonChange={(e: any) => {
                    const evaluation = dispatch(
                      getCurrentEvaluationInRuntime()
                    );
                    const evaluationValue =
                      (evaluation && evaluation.recheckFocusEquipment) ?? false;

                    if (evaluationValue !== e.detail.checked) {
                      updateEvaluationValue(
                        "recheckFocusEquipment",
                        e.detail.checked
                      );
                    }
                  }}
                ></IonCheckbox>
                <IonLabel className="chk-label">&nbsp;Equipment</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol
              size-xxl="3"
              size-xl="3"
              size-lg="3"
              size-md="3"
              size-sm="3"
              size-sx="3"
              size="3"
              className="aligned-middle to-left"
            >
              <IonItem
                className="ion-no-padding comboBoxCss"
                onKeyPress={onKeyPressCheckbox}
              >
                <IonCheckbox
                  className="chk"
                  mode="md"
                  checked={
                    currentEvaluation && currentEvaluation.recheckFocusFurniture
                  }
                  onIonChange={(e: any) => {
                    const evaluation = dispatch(
                      getCurrentEvaluationInRuntime()
                    );
                    const evaluationValue =
                      (evaluation && evaluation.recheckFocusFurniture) ?? false;

                    if (evaluationValue !== e.detail.checked) {
                      updateEvaluationValue(
                        "recheckFocusFurniture",
                        e.detail.checked
                      );
                    }
                  }}
                ></IonCheckbox>
                <IonLabel className="chk-label">&nbsp;Furniture</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol
              size-xxl="3"
              size-xl="3"
              size-lg="3"
              size-md="3"
              size-sm="3"
              size-sx="3"
              size="3"
              className="aligned-middle to-left"
            >
              <IonItem
                className="ion-no-padding comboBoxCss"
                onKeyPress={onKeyPressCheckbox}
              >
                <IonCheckbox
                  className="chk"
                  mode="md"
                  checked={
                    currentEvaluation &&
                    currentEvaluation.recheckFocusDiscomfort
                  }
                  onIonChange={(e: any) => {
                    const evaluation = dispatch(
                      getCurrentEvaluationInRuntime()
                    );
                    const evaluationValue =
                      (evaluation && evaluation.recheckFocusDiscomfort) ??
                      false;

                    if (evaluationValue !== e.detail.checked) {
                      updateEvaluationValue(
                        "recheckFocusDiscomfort",
                        e.detail.checked
                      );
                    }
                  }}
                ></IonCheckbox>
                <IonLabel className="chk-label">&nbsp;Discomfort</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol
              size-xxl="3"
              size-xl="3"
              size-lg="3"
              size-md="3"
              size-sm="3"
              size-sx="3"
              size="3"
              className="aligned-middle to-left"
            >
              <IonItem
                className="ion-no-padding comboBoxCss"
                onKeyPress={onKeyPressCheckbox}
              >
                <IonCheckbox
                  className="chk"
                  mode="md"
                  checked={
                    currentEvaluation && currentEvaluation.recheckHighPriority
                  }
                  onIonChange={(e: any) => {
                    const evaluation = dispatch(
                      getCurrentEvaluationInRuntime()
                    );
                    const evaluationValue =
                      (evaluation && evaluation.recheckHighPriority) ?? false;

                    if (evaluationValue !== e.detail.checked) {
                      updateEvaluationValue(
                        "recheckHighPriority",
                        e.detail.checked
                      );
                    }
                  }}
                ></IonCheckbox>
                <IonLabel className="chk-label">&nbsp;High Priority</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className="top-2">
            <IonCol>
              <Textarea
                label={"Recheck notes"}
                value={recheckNotes}
                onBlur={(e: any) => {
                  updateEvaluationValue("recheckNotes", e.target.innerText);
                  setRecheckNotes(e.target.innerText);
                }}
              />
            </IonCol>
          </IonRow>
          <hr className="separator top-2" />
          <IonRow>
            <IonCol className="bold to-left top-1">Request</IonCol>
          </IonRow>
          <IonRow className="top-2">
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Date</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {moment(
                      currentEvaluation && currentEvaluation.requestDate
                    ).format("MM/DD/YYYY")}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <SelectForm
                    label="Origin"
                    options={requestOrigin}
                    value={currentEvaluation && currentEvaluation.requestOrigin}
                    name="requestOrigin"
                    onChange={(key: string, value: any) => {
                      if (value) {
                        updateEvaluationValue(key, value);
                      }
                    }}
                    clearValue={() =>
                      currentEvaluation &&
                      updateEvaluationValue("requestOrigin", null)
                    }
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <SelectForm
                    label="Referred By"
                    options={requestReferred}
                    value={
                      currentEvaluation && currentEvaluation.requestReferredBy
                    }
                    name="requestReferredBy"
                    onChange={(key: string, value: any) => {
                      if (value) {
                        updateEvaluationValue(key, value);
                      }
                    }}
                    clearValue={() =>
                      currentEvaluation &&
                      updateEvaluationValue("requestReferredBy", null)
                    }
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Priority</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <span
                    className={`bold text-regular-size ${
                      isHighPriority(
                        currentEvaluation &&
                          currentEvaluation.requestPriority &&
                          currentEvaluation.requestPriority.toLowerCase()
                      )
                        ? "high-priority"
                        : ""
                    }`}
                  >
                    {currentEvaluation && currentEvaluation.requestPriority}
                  </span>
                  &nbsp;
                  {currentEvaluation &&
                    currentEvaluation.requestPriority &&
                    isHighPriority(
                      currentEvaluation.requestPriority.toLowerCase()
                    ) && <span className="warning">!</span>}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="bold to-left top-1">Purposes</IonCol>
          </IonRow>
          <IonRow className="top-2">
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <Checkbox
                    name="purposePreventative"
                    label="Preventative"
                    value={
                      currentEvaluation && currentEvaluation.purposePreventative
                    }
                    onChange={updateEvaluationValue}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <Checkbox
                    name="purposeDiscomfort"
                    label="Discomfort"
                    value={
                      currentEvaluation && currentEvaluation.purposeDiscomfort
                    }
                    onChange={updateEvaluationValue}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <Checkbox
                    name="purposePregnancy"
                    label="Expectant"
                    value={
                      currentEvaluation && currentEvaluation.purposePregnancy
                    }
                    onChange={updateEvaluationValue}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
          <IonRow className="top-2">
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <Checkbox
                    name="purposeChairRequest"
                    label="Chair Request"
                    value={
                      currentEvaluation && currentEvaluation.purposeChairRequest
                    }
                    onChange={updateEvaluationValue}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <Checkbox
                    name="purposeDeskRequest"
                    label="Desk Request"
                    value={
                      currentEvaluation && currentEvaluation.purposeDeskRequest
                    }
                    onChange={updateEvaluationValue}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <Checkbox
                    name="purposeEquipmentRequest"
                    label="Equipment Request"
                    value={
                      currentEvaluation &&
                      currentEvaluation.purposeEquipmentRequest
                    }
                    onChange={updateEvaluationValue}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol>
                  <Checkbox
                    name="purposeOther"
                    label="Other"
                    value={currentEvaluation && currentEvaluation.purposeOther}
                    onChange={updateEvaluationValue}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="top-2">
            <IonCol>
              <Textarea
                label={"Admin notes"}
                value={currentEvaluation && currentEvaluation.adminNotes}
                onBlur={(e: any) => {
                  updateEvaluationValue("adminNotes", e.target.innerText);
                }}
              />
            </IonCol>
          </IonRow>
          <hr className="separator top-2" />
          <IonRow>
            <IonCol className="bold to-left top-1">Worker</IonCol>
          </IonRow>
          <IonRow className="top-1">
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Department</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentWorker && currentWorker.employeeDepartment}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className=" title-name">Start Date</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentWorker && currentWorker.dateStarted}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Shift Schedule</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentWorker && currentWorker.schedulingShiftSchedule}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="3">
              <div className="flex">
                <div className="form-group custom-input matching-element w-50pct">
                  <IonLabel className="form-group-input-text-title matching-element-label">
                    Height (inches)
                  </IonLabel>
                  <IonInput
                    type="number"
                    maxlength={1}
                    className="form-control input-lg form-group-input-text text-left"
                    value={currentWorker && currentWorker.height}
                    onIonBlur={(e: any) => {
                      let isDifferent =
                        (currentWorker && currentWorker.height) !==
                        e.target.value;
                      if (isDifferent)
                        updateWorkerValue("height", e.target.value);
                    }}
                  ></IonInput>
                </div>
                <SelectForm
                  className="w-50pct"
                  label="Units"
                  options={workerHeightUnits}
                  value={heightUnit}
                  name="heightUnit"
                  onChange={(key: string, value: any) => {
                    const worker = dispatch(
                      getCurrentEvaluationWorkerInRuntime()
                    );

                    if (value && worker && worker.heightUnit !== undefined) {
                      if (worker.heightUnit !== value) {
                        setHeightUnit(value);
                        updateWorkerValue(key, value);
                      }
                    }
                  }}
                  clearValue={() => {
                    setHeightUnit(null);
                    updateWorkerValue("heightUnit", null);
                  }}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="top-1">
            <IonCol size="3" className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Type</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentWorker && currentWorker.employeeType}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="3" className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">SubType</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentWorker && currentWorker.employeeSubtype}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="3" className="to-left">
              <Checkbox
                name="employeeNewHire"
                label="New hire"
                value={employeeNewHire}
                onChange={(key: string, value: any) => {
                  setEmployeeNewHire(value);
                  updateEvaluationValue(key, value);
                }}
              />
            </IonCol>
            <IonCol size="3">
              <SelectForm
                label="Hand Dominance"
                options={workerHandDominance}
                value={currentWorker && currentWorker.handDominance}
                name="handDominance"
                onChange={(key: string, value: any) => {
                  const worker = dispatch(
                    getCurrentEvaluationWorkerInRuntime()
                  );

                  if (value && worker && worker.handDominance !== undefined) {
                    if (worker.handDominance !== value) {
                      updateWorkerValue(key, value);
                    }
                  }
                }}
                clearValue={() => updateWorkerValue("handDominance", null)}
              />
            </IonCol>
          </IonRow>
          <hr className="separator top-1" />
          <div className="title-equipment top-var">
            <IonRow>
              <IonLabel className="text-left bold">EMEA Only</IonLabel>
            </IonRow>
          </div>
          <IonRow className="pt-1">
            <IonCol>
              <IonRow>
                <IonLabel className="text-left">
                  User free from vision problems
                </IonLabel>
              </IonRow>
              <IonRow>
                <IonLabel class="text-left title-name text-regular-size">
                  (i.e. difficulty focusing, eye discomfort, headaches)
                </IonLabel>
              </IonRow>
            </IonCol>
            <IonCol>
              <IonButton
                onClick={() => {
                  let value: boolean | null = true;

                  if (
                    currentEvaluation &&
                    currentEvaluation.visionProblems &&
                    currentEvaluation.visionProblems == true
                  ) {
                    value = null;
                  }

                  updateEvaluationValue("visionProblems", value);
                }}
                className={
                  "btn font-bold " +
                  (currentEvaluation && currentEvaluation.visionProblems == true
                    ? "positive"
                    : "negative")
                }
              >
                YES
              </IonButton>
              <IonButton
                onClick={() => {
                  let value: boolean | null = false;

                  if (
                    currentEvaluation &&
                    !currentEvaluation.visionProblems &&
                    currentEvaluation.visionProblems == false
                  ) {
                    value = null;
                  }

                  updateEvaluationValue("visionProblems", value);
                }}
                className={
                  "btn font-bold " +
                  (currentEvaluation &&
                  currentEvaluation.visionProblems == false
                    ? "positive"
                    : "negative")
                }
              >
                NO
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="pt-1">
            <IonCol>
              <IonLabel class="text-left">
                User aware of how to obtain an eye and eyesight test within
                company
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonButton
                onClick={() => {
                  let value: boolean | null = true;

                  if (
                    currentEvaluation &&
                    currentEvaluation.visionAwareOfTestOpportunities &&
                    currentEvaluation.visionAwareOfTestOpportunities == true
                  ) {
                    value = null;
                  }

                  updateEvaluationValue(
                    "visionAwareOfTestOpportunities",
                    value
                  );
                }}
                className={
                  "btn font-bold " +
                  (currentEvaluation &&
                  currentEvaluation.visionAwareOfTestOpportunities == true
                    ? "positive"
                    : "negative")
                }
              >
                YES
              </IonButton>
              <IonButton
                onClick={() => {
                  let value: boolean | null = false;

                  if (
                    currentEvaluation &&
                    !currentEvaluation.visionAwareOfTestOpportunities &&
                    currentEvaluation.visionAwareOfTestOpportunities == false
                  ) {
                    value = null;
                  }
                  console.log(value);
                  updateEvaluationValue(
                    "visionAwareOfTestOpportunities",
                    value
                  );
                }}
                className={
                  "btn font-bold " +
                  (currentEvaluation &&
                  currentEvaluation.visionAwareOfTestOpportunities == false
                    ? "positive"
                    : "negative")
                }
              >
                NO
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="pt-1 ion-align-items-center">
            <IonCol>
              <IonLabel class="text-left">Date of last eye exam</IonLabel>
            </IonCol>
            <IonCol className="ion-align-items-center">
              <div className="datetime-picker to-left h-50">
                <div
                  className="date-wrapper"
                  onClick={onClickDatePickerWrapper}
                >
                  <DatePicker
                    todayButton="Today"
                    className="search-date form-control input-lg form-group-input-text margin-remover"
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    ref={refDatePicker}
                    selected={
                      currentEvaluation &&
                      currentEvaluation.visionDateOfLastExam &&
                      moment(
                        currentEvaluation &&
                          currentEvaluation.visionDateOfLastExam
                      ).toDate()
                    }
                    onChange={(date: any) => updateVisionDate(date)}
                  />
                </div>
                <IonImg
                  className="bi icon-image calendar"
                  src={CALENDAR}
                  alt="calendar"
                ></IonImg>
              </div>
            </IonCol>
          </IonRow>
          <hr className="separator top-2" />
          <IonRow>
            <IonCol className="bold to-left top-1">Company</IonCol>
          </IonRow>
          <IonRow className="top-1">
            <IonCol size="3" className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Name</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentOrganization && currentOrganization.name}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="3" className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Desk number</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentWorker && currentWorker.employeeDeskNumber}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="4" className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Location</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentEvaluation &&
                      currentEvaluation.location &&
                      currentEvaluation.location.name}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
          <hr className="separator top-2" />
          <IonRow>
            <IonCol className="bold to-left top-1">Appointment</IonCol>
          </IonRow>
          <IonRow className="top-1">
            <IonCol size="3" className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Confirmed</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel
                    className={
                      "bold text-regular-size " +
                      (currentEvaluation &&
                      currentEvaluation.appointmentConfirmed
                        ? "confirm"
                        : "high-priority")
                    }
                  >
                    {currentEvaluation && currentEvaluation.appointmentConfirmed
                      ? "Yes"
                      : "No"}
                  </IonLabel>
                  &nbsp;
                  <span className="green-check">
                    <IonText>
                      {currentEvaluation &&
                      currentEvaluation.appointmentConfirmed ? (
                        <img src={greenCheck} />
                      ) : (
                        <span className="warning">!</span>
                      )}
                    </IonText>
                  </span>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="3" className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Date</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentEvaluation &&
                      moment(currentEvaluation.appointmentDate).format(
                        "MM/DD/YYYY"
                      )}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="4" className="to-left">
              <IonRow className="text-regular-size">
                <IonCol className="title-name">Time</IonCol>
              </IonRow>
              <IonRow className="text-regular-size">
                <IonCol>
                  <IonLabel className="bold text-regular-size">
                    {currentEvaluation &&
                      currentEvaluation.appointmentDate &&
                      currentEvaluation.appointmentTime &&
                      moment(
                        `${currentEvaluation.appointmentDate} ${currentEvaluation.appointmentTime}`
                      ).format("hh:mm a")}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
          <hr className="separator top-2" />
          <IonRow>
            <IonCol size="6" className="bold to-left top-1">
              Comments
            </IonCol>
            <IonCol size="6" className="to-right top-1 text-regular-size bold">
              <IonButton
                fill="clear"
                className="ion-float-right"
                onClick={() => setShowCommentInputs(true)}
              >
                <IonIcon src={addSharp}></IonIcon>&nbsp;Add Comment
              </IonButton>
            </IonCol>
          </IonRow>
          {showCommentInputs && (
            <IonRow className="top-2">
              <IonCol size="3">
                <SelectForm
                  label="Type"
                  name="commentType"
                  options={commentTypes}
                  value={evaluationCommentType}
                  onChange={(key: string, value: string) =>
                    setEvaluationCommentType(value)
                  }
                  clearValue={() => updateEvaluationValue("commentType", null)}
                />
              </IonCol>
              <IonCol size="5">
                <div className="form-group custom-input matching-element">
                  <IonLabel className="form-group-input-text-title matching-element-label">
                    Description
                  </IonLabel>
                  <IonInput
                    type="text"
                    className="form-control input-lg form-group-input-text text-left text-color-293A3E"
                    onIonChange={(e: any) =>
                      setEvaluationCommentInput(e.target.value)
                    }
                    value={evaluationCommentInput}
                  ></IonInput>
                </div>
              </IonCol>
              <IonCol size="2">
                <IonButton
                  onClick={() => setShowCommentInputs(false)}
                  className="btn-2 comment-btn-group cancel font-bold"
                >
                  Cancel
                </IonButton>
              </IonCol>
              <IonCol size="2">
                <IonButton
                  onClick={saveComment}
                  className="btn-2 comment-btn-group positive font-bold"
                >
                  Save
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          <table className="tbl-comments top-2 text-regular-size">
            <tbody>
              <tr className="comment">
                <td className="to-left type-comment">Comment Type</td>
                <td className="to-left descr-comment">Comment description</td>
                <td className="to-right date-comment">Date</td>
                <td className="to-center time-comment">Time</td>
                <td className="to-center"></td>
              </tr>
              {currentEvaluationComments &&
                sortedEvaluationComments().map((item: any, index: number) => {
                  return (
                    <tr className="comment" key={index}>
                      <td className="to-left type-comment">
                        {item.type === "" ? "---" : item.type}
                      </td>
                      <td className="to-left descr-comment">{item.comment}</td>
                      <td className="to-right date-comment">
                        {moment(item.timestampInserted).format("MM/DD/YYYY")}
                      </td>
                      <td className="to-center time-comment">
                        {moment(item.timestampInserted).format("hh:mm a")}
                      </td>
                      <td>
                        <IonButton
                          disabled={item.id ? false : true}
                          onClick={(e) => {
                            e.stopPropagation();
                            present({
                              cssClass: "my-css",
                              message: `Are you sure that you want to delete this comment?`,
                              buttons: [
                                "Cancel",
                                {
                                  text: "Ok",
                                  handler: () => {
                                    handleDeleteComment(
                                      item && item.id && item.id
                                    );
                                  }
                                }
                              ]
                            });
                          }}
                          color="danger"
                        >
                          Delete
                        </IonButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default BasicInformation;
