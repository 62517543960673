import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _, { find, findIndex } from "lodash";
import { makeRequest } from "../../../helpers/global.helper";
import moment from "moment";

const slice = createSlice({
  name: "environmentRecommendations",
  initialState: {
    list: [],
    showAddRecommendationModal: false,
    currentEnvironmentRecommendation: undefined,
    issues: [],
    environments: [],
    risks: [],
    total: 0,
    acceptDeleteRisk: false,
    tempItemsForPatching: [],
    lastFetch: "",
    tempItemsForDeleting: [],
    showCheckboxLoader: false,
    linkedRisks: []
  },
  reducers: {
    environmentRecommendationsReceived(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;
      if (payload.rows && payload.rows.length > 0) {
        environmentRecommendations.list = payload.rows;
      } else {
        environmentRecommendations.list = [];
      }
    },
    environmentRecommendationsUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.list = action.payload;
      environmentRecommendations.total = action.payload.length;
    },
    recommendationsEnvironmentsReceived(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;
      if (payload.rows && payload.rows.length > 0) {
        environmentRecommendations.environments = payload.rows;
      } else {
        environmentRecommendations.environments = [];
      }
    },
    recommendationsEnvironmentsUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.environments = action.payload;
    },
    showAddRecommendationModalSet(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.showAddRecommendationModal = action.payload;
    },
    currentEnvironmentRecommendationSet(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.currentEnvironmentRecommendation =
        action.payload;
    },
    recommendationsRisksReceived(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        environmentRecommendations.risks = payload.rows;
      } else {
        environmentRecommendations.risks = [];
      }
    },
    recommendationsRisksUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.risks = action.payload;
    },
    recommendationsIssuesReceived(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;

      if (payload.rows && payload.rows.length > 0) {
        environmentRecommendations.issues = payload.rows;
      } else {
        environmentRecommendations.issues = [];
      }
    },
    recommendationsIssuesUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.issues = action.payload;
    },
    totalEnvironmentRecommendationsUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.total = action.payload;
    },
    acceptDeleteRiskUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.acceptDeleteRisk = action.payload;
    },
    tempItemsForPatchingUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.tempItemsForPatching = action.payload;
    },
    lastFetchUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.lastFetch = action.payload;
    },
    tempItemsForDeletingUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.tempItemsForDeleting = action.payload;
    },
    showCheckboxLoaderUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.showCheckboxLoader = action.payload;
    },
    linkedRisksUpdated(
      environmentRecommendations: any,
      action: PayloadAction<any>
    ) {
      environmentRecommendations.linkedRisks = action.payload;
    }
  }
});

export const {
  environmentRecommendationsReceived,
  environmentRecommendationsUpdated,
  showAddRecommendationModalSet,
  currentEnvironmentRecommendationSet,
  recommendationsIssuesReceived,
  recommendationsIssuesUpdated,
  recommendationsRisksReceived,
  recommendationsRisksUpdated,
  recommendationsEnvironmentsReceived,
  recommendationsEnvironmentsUpdated,
  totalEnvironmentRecommendationsUpdated,
  acceptDeleteRiskUpdated,
  tempItemsForPatchingUpdated,
  lastFetchUpdated,
  tempItemsForDeletingUpdated,
  showCheckboxLoaderUpdated,
  linkedRisksUpdated
} = slice.actions;
export default slice.reducer;

/**
 * Gets current linked risks.
 */
export const getCurrentLinkedRisks = createSelector(
  (state: any) => {
    return state.evaluation.environment.recommendations;
  },
  (recommendations: any) => recommendations.linkedRisks
);

/**
 * Gets current showChekboxLoader.
 */
export const getCurrentShowCheckboxLoader = createSelector(
  (state: any) => {
    return state.evaluation.environment.recommendations;
  },
  (recommendations: any) => recommendations.showCheckboxLoader
);

/**
 * Gets current total environment recommendations.
 * @returns
 */
export const getCurrentTotalEnvironmentRecommendations =
  (
    currentEvaluationRecommendations: any = undefined,
    currentEnvironmentRecommendations: any = undefined
  ) =>
  (dispatch: any, getState: any) => {
    if (
      currentEvaluationRecommendations != undefined &&
      currentEnvironmentRecommendations != undefined
    ) {
      const total = currentEvaluationRecommendations.filter(
        (recommendation: any) =>
          currentEnvironmentRecommendations
            ? currentEnvironmentRecommendations.filter(
                (env: any) => env.idRecommendation === recommendation.id
              ).length > 0
            : false
      ).length;

      return total;
    } else {
      return 0;
    }
  };

export const getCurrentAcceptDeleteRisk =
  () => (dispatch: any, getState: any) => {
    return getState().evaluation.environment.recommendations.acceptDeleteRisk;
  };

/**
 * Gets environment recommendations.
 */
export const getCurrentEnvironmentRecommendations = createSelector(
  (state: any) => state.evaluation.environment.recommendations,
  (recommendations: any) => recommendations.list
);

/**
 * Gets add recommendation modal.
 */
export const getShowAddRecommendationModal = createSelector(
  (state: any) => state.evaluation.environment.recommendations,
  (recommendations: any) => recommendations.showAddRecommendationModal
);

/**
 * Gets current environment recommendation.
 */
export const getCurrentEnvironmentRecommendation = createSelector(
  (state: any) => {
    return state.evaluation.environment.recommendations;
  },
  (recommendations: any) => recommendations.currentEnvironmentRecommendation
);

/**
 * Gets recommendations issues.
 */
export const getRecommendationsIssues = createSelector(
  (state: any) => {
    return state.evaluation.environment.recommendations;
  },
  (recommendations: any) => recommendations.issues
);

/**
 * Gets recommendation risks.
 */
export const getRecommendationsRisks = createSelector(
  (state: any) => {
    return state.evaluation.environment.recommendations;
  },
  (recommendations: any) => recommendations.risks
);

/**
 * Gets recommendation environments.
 */
export const getRecommendationEnvironments = createSelector(
  (state: any) => {
    return state.evaluation.environment.recommendations;
  },
  (recommendations: any) => recommendations.environments
);

/**
 * Gets current recommendation environments in real time.
 */
export const getCurrentRecommendationEnvironmentsInRuntime =
  () => (dispatch: any, getState: any) => {
    return getState().evaluation.environment.recommendations.environments;
  };

/**
 * Gets recommendation environment ids..
 */
export const getRecommendationEnvironmentIds = createSelector(
  (state: any) => {
    return state.evaluation.environment.recommendations;
  },
  (recommendations: any) =>
    _.map(
      _.uniqBy(recommendations.environments, "idEnvironment"),
      "idEnvironment"
    )
);

/**
 * Gets total environment recommendations.
 */
export const getTotalEnvironmentRecommendations = createSelector(
  (state: any) => {
    return state.evaluation.environment.recommendations;
  },
  (recommendations: any) => recommendations.total
);

/**
 * Updates total environment recommendations.
 * @returns
 */
export const updateTotalEnvironmentRecommendations =
  (currentEvaluationRecommendations: any) => (dispatch: any, getState: any) => {
    const currentEnvironmentRecommendations =
      getState().evaluation.environment.recommendations.list;
    const environmentRecommendations: any =
      currentEvaluationRecommendations.filter((recommendation: any) =>
        currentEnvironmentRecommendations
          ? currentEnvironmentRecommendations.filter(
              (env: any) => env.idRecommendation === recommendation.id
            ).length > 0 || recommendation.id === undefined
          : false
      );

    dispatch({
      type: totalEnvironmentRecommendationsUpdated.type,
      payload: environmentRecommendations.length
    });
  };

/**
 * Stores recommendation into temporaly array.
 * @param object
 * @returns
 */
export const storeTempRecommendations =
  (object: any) => (dispatch: any, getState: any) => {
    const { itemsForPatching } =
      getState().evaluation.environment.recommendations;
    let temp = [...itemsForPatching];
    const found = find(temp, { id: object.id });

    if (found == undefined) {
      temp.push(object);
    } else {
      const index = findIndex(temp, { id: object.id });
      temp[index] = { ...found, ...object };
    }

    dispatch({
      type: tempItemsForPatchingUpdated.type,
      payload: temp
    });
  };

/**
 * Gets current items for patching.
 */
export const getCurrentItemsForPatching = createSelector(
  (state: any) => state.evaluation.environment.recommendations,
  (recommendations: any) => recommendations.tempItemsForPatching
);

/**
 * Loads environment recommendations.
 * - Validates lastFetch
 * - If now if greater than lastFech
 *    - Fetch rows from server
 *    - Change negative ids with correct ones
 * @returns
 */
export const loadEnvironmentRecommendations =
  (id: number) => async (dispatch: any, getState: any) => {
    const currentRows = getState().evaluation.environment.recommendations.list;
    let { lastFetch } = getState().evaluation.environment.recommendations;
    const rowsWithNegativeIds = currentRows.filter(
      (row: any) => row.idRecommendation < 0
    );
    const cacheLimit = process.env.REACT_APP_BIDB_CACHE_LIMIT
      ? process.env.REACT_APP_BIDB_CACHE_LIMIT
      : "5";
    let rows: any = [];

    if (lastFetch != "") {
      lastFetch = moment(lastFetch).add(cacheLimit, "minutes");
    } else {
      lastFetch = moment();
    }

    const now = moment();
    const isNowGreaterThan: boolean =
      process.env.REACT_APP_ENV == "DEV" ? true : now > lastFetch;

    const response: any = await dispatch(
      makeRequest({
        endpoint: `/environments/${id}/recommendations`,
        method: "GET"
      })
    );

    if (response.status == 200) {
      rows = response.data.rows;
      /*
      const filtered = rows.filter((row: any) => {
        return row.id > 0 && parseInt(row.token) < 0;
      });

      // Update negative ids.
      if (filtered.length > 0) {
        for (const [, row] of filtered.entries()) {
          let index = findIndex(rowsWithNegativeIds, {
            idTemp: parseInt(row.token)
          });

          if (index >= 0) {
            rowsWithNegativeIds.splice(index, 1);
          }
        }
        rows = [...rowsWithNegativeIds, ...rows];
      }
      */
      dispatch({
        type: lastFetchUpdated.type,
        payload: moment().format("YYYY-MM-DD HH:mm:ss")
      });

      dispatch({
        type: environmentRecommendationsUpdated.type,
        payload: rows
      });
    }
  };

/**
 * Gets current environment recommendations.
 * @returns
 */
export const getCurrentEnvironmentRecommendationRows =
  () => (dispatch: any, getState: any) => {
    return getState().evaluation.environment.recommendations.list;
  };
