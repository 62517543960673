import React, { useEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonBadge,
  IonButton,
  IonGrid,
  useIonAlert,
  IonImg
} from "@ionic/react";
import {
  unlink,
  addSharp,
  chevronDownOutline,
  chevronUpOutline
} from "ionicons/icons";
import SelectForm from "../../../../common/controls/SelectForm";

import {
  getCurrentEvaluationEnvironment,
  getEnvironments
} from "../../../../../store/evaluation/Current";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import EnvironmentCheckboxes from "../../EnvironmentCheckboxes";
import {
  getToken,
  removeObjectFromList,
  updateCurrentList
} from "../../../../../helpers/global.helper";
import { deleteRecommendation } from "../../../../../services/recommendations.service";
import { isArray, orderBy } from "lodash";
import BaseSelectMultiButton from "../../../../common/BaseSelectMultiButton";
import BaseSelectMultiCheckbox from "../../../../common/BaseSelectMultiCheckbox";
import AddRiskModal from "./AddRiskModal";
import RecommendationPicker from "../recommendations/AddRecommendationModal";

import _ from "lodash";
import {
  getCurrentEnvironmentRiskRows,
  getCurrentEvaluationRisksInRuntime,
  getEvaluationRisks,
  risksUpdated,
  rowsUpdated
} from "../../../../../store/evaluation/Risks";
import TopBar from "./TopBar";
import {
  getRecommendations,
  recommendationsUpdated
} from "../../../../../store/evaluation/Recommendations";
import {
  acceptDeleteRecommendationUpdated,
  getCurrentAcceptDeleteRecommendation,
  getCurrentLinkedRecommendations,
  getCurrentRiskEnvironments,
  getCurrentRiskEnvironmentsInRuntime,
  getCurrentRiskRecommendations,
  getCurrentShowCheckboxLoader,
  getEnvironmentRisks,
  getShowAddRiskModal,
  linkedRecommendationsUpdated,
  riskEnvironmentsReceived,
  riskEnvironmentsUpdated,
  riskRecommendationsReceived,
  riskRecommendationsUpdated,
  setShowAddRiskModal,
  showCheckboxLoaderUpdated
} from "../../../../../store/evaluation/environment/Risks";
import {
  deleteRisk,
  deleteRiskEnvironment,
  deleteRiskRecommendation,
  patchRisk,
  saveRiskEnvironment
} from "../../../../../services/risk.service";
import { apiCall } from "../../../../../store/api";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import {
  getCurrentSelectedPickedItems,
  loadItems,
  pickedItemsUpdated,
  removeItems
} from "../../../../../store/catalogs/Recommendations";
import {
  environmentRecommendationsUpdated,
  getCurrentEnvironmentRecommendations
} from "../../../../../store/evaluation/environment/Recommendations";
import Textarea from "../../../../common/Textarea";

const RisksTab: React.FC<any> = (props) => {
  const { getRiskvsName, setShowEnvironmentModal } = props;
  const dispatch = useAppDispatch();

  const risks = useAppSelector(getEvaluationRisks);

  const currentEvaluationRecommendations = useAppSelector(getRecommendations);
  const currentEnvironmentRecommendations = useAppSelector(
    getCurrentEnvironmentRecommendations
  );
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const environments = useAppSelector(getEnvironments);
  const currentEnvironmentRisks = useAppSelector(getEnvironmentRisks);
  const severityList = dispatch(getListByName("Risk_Severity"));
  const riskBodyPositions = dispatch(getListByName("Risk_Body_Position"));
  const riskCauseTypes = dispatch(getListByName("Risk_Cause_Type"));
  const currentRiskEnvironments = useAppSelector(getCurrentRiskEnvironments);
  const currentRiskRecommendations = useAppSelector(
    getCurrentRiskRecommendations
  );
  const currentEnvironmentRiskRows = useAppSelector(
    getCurrentEnvironmentRiskRows
  );

  const [currentRisk, setCurrentRisk] = useState<any>();
  const [updatedDataRisk, setUpdatedDataRisk] = useState<any>(undefined);
  const [environmentsChecked, setEnvironmentsChecked] = useState<any>([]);
  const riskDefaultExpanded: any = -1;
  const [riskIdExpanded, setRiskIdExpanded] =
    useState<any>(riskDefaultExpanded);
  const [allowRiskPersistence, setAllowRiskPersistence] = useState(false);
  const [presentAlert] = useIonAlert();
  const [openRecommendationPicker, setOpenRecommendationPicker] =
    useState(false);
  const showAddRiskModal = useAppSelector(getShowAddRiskModal);
  const currentSelectedPickedItems = useAppSelector(
    getCurrentSelectedPickedItems
  );
  const currentLinkedRecommendations = useAppSelector(
    getCurrentLinkedRecommendations
  );

  // For now, v1 will be sorted with this list
  const riskBodySubcategories = dispatch(
    getListByName("Risk_Body_Subcategory")
  );

  const setAllRisks = (risks: any) => {
    dispatch({
      type: risksUpdated.type,
      payload: risks
    });
  };

  const isValidRiskId = (risk: any, id: any) => risk["id"] === id;

  const isRiskExpanded = (riskId: any) => riskId == riskIdExpanded;

  const onClickRisk = (risk: any) => {
    let newRiskIdExpanded = riskDefaultExpanded;
    if (riskIdExpanded !== risk.id) newRiskIdExpanded = risk.id;

    if (risk.id !== undefined) {
      const currentEvaluationRisks = dispatch(
        getCurrentEvaluationRisksInRuntime()
      );
      let currentRisk = currentEvaluationRisks.filter((item: any) =>
        isValidRiskId(item, risk.id)
      );
      if (currentRisk.length > 0) {
        setAllowRiskPersistence(false);
        setCurrentRisk(undefined);
        setCurrentRisk(currentRisk[0]);
      }
    }
    setRiskIdExpanded(newRiskIdExpanded);
  };

  const fechRisksByEnvironment = () => {
    let sortedBodySubcategory: any = {};

    riskBodySubcategories?.map((riskBodySubcategory: any, index: number) => {
      sortedBodySubcategory = {
        ...sortedBodySubcategory,
        [riskBodySubcategory]: index
      };
    });

    // We get risks that match current environment risks and risks where id is negative
    let filteredRisks = risks.filter(
      (risk: any) =>
        currentEnvironmentRisks &&
        currentEnvironmentRisks.filter((env: any) => env.idRisk === risk.id)
          .length > 0
    );

    filteredRisks = filteredRisks.map((risk: any) => {
      return { ...risk, order: sortedBodySubcategory[risk.subCategory] || 0 };
    });

    return orderBy(filteredRisks, ["order"]);
  };

  const fetchRecommendations = () => {
    const linkedRecommendations = currentEvaluationRecommendations?.filter(
      (recomm: any) =>
        currentRiskRecommendations
          ? currentRiskRecommendations.filter(
              (riskRecomm: any) => riskRecomm.idRecommendation === recomm.id
            ).length > 0
          : false
    );

    dispatch({
      type: linkedRecommendationsUpdated.type,
      payload: linkedRecommendations
    });
  };

  const getRiskRecommendationId = (recommId: number) => {
    let riskRecommendations = currentRiskRecommendations?.filter(
      (riskRecomm: any) => riskRecomm.idRecommendation === recommId
    );
    return riskRecommendations.length > 0 ? riskRecommendations[0].id : 0;
  };

  const getRiskEnvironmentId = (idEnvironment: number) => {
    const currentRiskEnvironmentsInRuntime = dispatch(
      getCurrentRiskEnvironmentsInRuntime()
    );

    let riskEnvironment = currentRiskEnvironmentsInRuntime.filter(
      (env: any) =>
        env.idEnvironment === idEnvironment && env.idRisk === currentRisk.id
    );
    return riskEnvironment.length > 0 ? riskEnvironment[0].id : 0;
  };

  const insertRiskEnvironment = async (payloadRiskEnvironment: any) => {
    return dispatch(saveRiskEnvironment(payloadRiskEnvironment))
      .then(async (responseData: any) => {
        if (responseData["successes"]) {
          let newPayloadRiskEnvironment = {
            ...payloadRiskEnvironment,
            id: responseData.successes[0].id
          };
          const currentRiskEnvironmentsInRuntime = dispatch(
            getCurrentRiskEnvironmentsInRuntime()
          );
          const updatedRelationRows = await updateCurrentList(
            [newPayloadRiskEnvironment],
            currentRiskEnvironmentsInRuntime
          );
          dispatch({
            type: riskEnvironmentsUpdated.type,
            payload: updatedRelationRows
          });

          setTimeout(() => {
            dispatch({
              type: showCheckboxLoaderUpdated.type,
              payload: false
            });
          }, 500);
        }
      })
      .catch((error) => console.log("error-savRiskEnviroments:", error));
  };

  const updateRiskEnvironments = (riskEnvironments: any) => {
    dispatch({
      type: riskEnvironmentsUpdated.type,
      payload: riskEnvironments
    });
  };

  const updateEnvironmentRisk = async (
    environmentId: number,
    isChecked: boolean
  ) => {
    if (isChecked) {
      let payloadRiskEnvironment = {
        idRisk: currentRisk.id,
        idEnvironment: environmentId,
        token: getToken()
      };
      insertRiskEnvironment(payloadRiskEnvironment);
    } else {
      let idRiskEnvironment = getRiskEnvironmentId(environmentId);
      const currentRiskEnvironmentsInRuntime = dispatch(
        getCurrentRiskEnvironmentsInRuntime()
      );
      const updatedRiskEnvironments = currentRiskEnvironmentsInRuntime.filter(
        (env: any) => env.id !== idRiskEnvironment
      );

      updateRiskEnvironments(updatedRiskEnvironments);

      dispatch(deleteRiskEnvironment(idRiskEnvironment));

      dispatch({
        type: showCheckboxLoaderUpdated.type,
        payload: false
      });
    }
  };

  const removeRisk = async (riskId: any) => {
    if (riskId !== undefined) {
      dispatch(deleteRisk(riskId));

      const updatedRisks = risks.filter(
        (risk: any) => !isValidRiskId(risk, riskId)
      );

      setAllRisks(updatedRisks);
    }
  };

  const removeRecommendation = async (riskRecommId: any, recommId: any) => {
    if (riskRecommId !== undefined) {
      const acceptDeleteRecommendation = dispatch(
        getCurrentAcceptDeleteRecommendation()
      );
      dispatch(deleteRiskRecommendation(riskRecommId));

      const updatedrRiskRecommendations = currentRiskRecommendations.filter(
        (recomm: any) => recomm.id !== riskRecommId
      );

      dispatch({
        type: riskRecommendationsUpdated.type,
        payload: updatedrRiskRecommendations
      });

      if (acceptDeleteRecommendation) {
        // deletes from table and from global store (evaluation recommendations, environment recommendations)
        dispatch(deleteRecommendation(recommId));

        let temp = await removeObjectFromList(
          currentEvaluationRecommendations,
          { id: recommId }
        );

        dispatch({
          type: recommendationsUpdated.type,
          payload: temp
        });

        temp = [...currentEnvironmentRecommendations];
        temp = _.filter(temp, (o: any) => {
          return o.idRecommendation !== recommId && currentEnvironment.id;
        });

        dispatch({
          type: environmentRecommendationsUpdated.type,
          payload: temp
        });
      }
    }
  };

  const onAddEnvironment = (riskId: number, data: any) => {
    let payloadRiskEnvironment = {
      idRisk: riskId,
      idEnvironment: data.id,
      token: getToken()
    };
    insertRiskEnvironment(payloadRiskEnvironment);
  };

  const isItemChecked = (fieldName: string, key: string) =>
    currentRisk ? currentRisk[fieldName]?.includes(key) : false;

  const updateFieldList = (name: string, value: string, checked: boolean) => {
    let list = [];
    if (currentRisk[name] !== undefined && isArray(currentRisk[name]))
      list = currentRisk[name];

    let updatedList = list.filter((item: string) => item != value);
    if (checked) updatedList = [...updatedList, value];
    return updatedList;
  };

  const setValueRisk = (property: string, value: any) => {
    let newRisk: any = { id: currentRisk.id };
    newRisk[property] = value;
    let newCurrentRisk: any = { ...currentRisk, [property]: value };
    setCurrentRisk(newCurrentRisk);
    updateRow(currentRisk.id, newRisk);
    setUpdatedDataRisk(newRisk);
  };

  const updateRow = (
    riskId: any,
    updatedRisk: any,
    allowPersistence: boolean = true
  ) => {
    let updatedRisks = risks.map((risk: any) =>
      isValidRiskId(risk, riskId) ? { ...risk, ...updatedRisk } : risk
    );

    setAllowRiskPersistence(allowPersistence);
    setAllRisks(updatedRisks);
  };

  useEffect(() => {
    const updateRisk = async () => {
      let updatedData = { ...updatedDataRisk, token: getToken() };
      dispatch(patchRisk(updatedData));
    };

    if (allowRiskPersistence && updatedDataRisk) {
      updateRisk();
    }
  }, [updatedDataRisk]);

  const updateRiskRecommendations = (data: any) => {
    dispatch({
      type: riskRecommendationsReceived.type,
      payload: data
    });
  };

  useEffect(() => {
    fetchRecommendations();
  }, [currentRiskRecommendations.length]);

  useEffect(() => {
    dispatch({
      type: riskRecommendationsReceived.type,
      payload: []
    });

    if (riskIdExpanded && riskIdExpanded !== -1) {
      dispatch(
        apiCall({
          url: `/risks/${riskIdExpanded && riskIdExpanded}/environments`,
          onSuccess: riskEnvironmentsReceived.type
        })
      );

      dispatch(
        apiCall({
          url: `/risks/${riskIdExpanded && riskIdExpanded}/recommendations`,
          onSuccess: updateRiskRecommendations
        })
      );
    }
  }, [riskIdExpanded]);

  useEffect(() => {
    if (currentRiskEnvironments) {
      let environmentsChecked = currentRiskEnvironments.map(
        (env: any) => env.idEnvironment
      );

      setEnvironmentsChecked(environmentsChecked);
    }
  }, [currentRiskEnvironments]);

  useEffect(() => {
    dispatch({
      type: rowsUpdated.type,
      payload: fechRisksByEnvironment()
    });
  }, [
    currentEnvironment && currentEnvironment.id,
    currentEnvironmentRisks.length,
    risks
  ]);

  const handleShowModal = () => {
    dispatch({
      type: setShowAddRiskModal.type,
      payload: true
    });
  };

  const handleOpenRecommendationPicker = (value: boolean) => {
    setOpenRecommendationPicker(value);
  };

  const handleUpdateRiskRecommendations = async (riskRecommendations: any) => {
    let temp = [...currentRiskRecommendations, ...riskRecommendations];

    dispatch({
      type: riskRecommendationsUpdated.type,
      payload: temp
    });
  };

  const getColorPill = (category: string) => {
    let bgColor: any = "";
    switch (category.toLowerCase()) {
      case "body":
        bgColor = "bg-color-56CCF2";
        break;
      case "equipment":
        bgColor = "bg-color-87CC9B";
        break;
      case "furniture":
        bgColor = "bg-color-AA6ECC";
        break;
      default:
        bgColor = "";
        break;
    }

    return bgColor;
  };

  const closeModal = () => {
    dispatch({
      type: setShowAddRiskModal.type,
      payload: false
    });
  };

  const handleSetAcceptDeleteRecommendation = async (value: boolean) => {
    dispatch({
      type: acceptDeleteRecommendationUpdated.type,
      payload: value
    });
  };

  const getSeverityAbbreviation = (severity: string) => {
    const severities: any = {
      potential: "PTL",
      min: "MIN",
      mod: "MOD",
      max: "MAX"
    };

    return severities[severity.toLocaleLowerCase()]
      ? severities[severity.toLocaleLowerCase()]
      : "";
  };

  const getSeverityColor = (severity: string) => {
    const severities: any = {
      potential: "bg-00b4d8",
      min: "bg-ffb627",
      mod: "bg-e2711d",
      max: "bg-bf3100"
    };

    return severities[severity.toLocaleLowerCase()]
      ? severities[severity.toLocaleLowerCase()]
      : "bg-color-9c9c9c";
  };

  return (
    <>
      <IonGrid>
        <RecommendationPicker
          isOpen={openRecommendationPicker}
          isPicker={false}
          closePicker={handleOpenRecommendationPicker}
          currentRisk={currentRisk}
          updateRiskRecommendations={handleUpdateRiskRecommendations}
          pickedItemsUpdated={pickedItemsUpdated}
          currentSelectedPickedItems={currentSelectedPickedItems}
          loadItems={loadItems}
          removeItems={removeItems}
          isAttach={true}
          groupedLeftList={true}
        />
        <AddRiskModal
          onClickRisk={onClickRisk}
          isOpen={showAddRiskModal}
          isPicker={false}
          closeModal={closeModal}
          setCurrentRisk={setCurrentRisk}
          setRiskIdExpanded={setRiskIdExpanded}
        />
        <TopBar onClickAddResource={handleShowModal} />
        {risks !== undefined &&
          currentEnvironmentRiskRows &&
          currentEnvironmentRiskRows.map((risk: any, index: number) => (
            <div key={index}>
              <IonRow
                className="flex flex-nowrap background-row-color mb-10 text-center ion-align-items-center cursor-pointer"
                key={index}
              >
                <IonCol sizeMd=".7" sizeLg=".3">
                  <IonIcon
                    className="py-03 cursor-pointer"
                    icon={
                      isRiskExpanded(risk.id)
                        ? chevronUpOutline
                        : chevronDownOutline
                    }
                    onClick={() => onClickRisk(risk)}
                  ></IonIcon>
                </IonCol>
                <IonCol
                  className="flex ion-align-items-center p-0 m-0 h-50"
                  onClick={() => onClickRisk(risk)}
                >
                  <div className="flex band-content w-full">
                    <div className="flex">
                      <IonBadge
                        className={`cursor-default text-uppercase rounded-27 text-9 text-white py-05 px-18 mr-1 md hydrated ${getColorPill(
                          risk.category
                        )}`}
                      >
                        {risk.category}
                      </IonBadge>
                      <IonBadge className="cursor-default text-uppercase rounded-27 text-9 text-white py-05 px-18 mr-1  md hydrated bg-color-9c9c9c">
                        {risk.subCategory}
                      </IonBadge>
                    </div>
                    <div
                      className="flex w-full justify-between"
                      onClick={() => onClickRisk(risk)}
                    >
                      <div
                        className="mwrap-text mr-1 w-full text-left"
                        onClick={() => onClickRisk(risk)}
                      >
                        <IonLabel
                          className="text-16 wrap-text"
                          onClick={(e: any) => {
                            e.stopPropagation();
                          }}
                        >
                          &nbsp;&nbsp;{getRiskvsName(risk.idRiskV)}
                        </IonLabel>
                      </div>
                      {risk && risk.severity && (
                        <IonBadge
                          className={`cursor-default font-bold ml-05 font-size12px py-03 text-uppercase rounded-27 w-60 text-9 text-white py-05 px-18 mr-1  md hydrated ${getSeverityColor(
                            risk && risk.severity ? risk.severity : ""
                          )}`}
                        >
                          {getSeverityAbbreviation(
                            risk && risk.severity ? risk.severity : ""
                          )}
                        </IonBadge>
                      )}
                    </div>
                  </div>
                </IonCol>
                <IonCol sizeLg="1">
                  <div className="flex ion-align-items-center float-end">
                    <IonButton
                      color="danger"
                      size="small"
                      className="float-end font-bold text-12 w-75 h-32"
                      onClick={(e) => {
                        e.stopPropagation();
                        presentAlert({
                          cssClass: "my-css",
                          message:
                            "Are you sure that you want to delete this risk?",
                          buttons: [
                            "Cancel",
                            {
                              text: "Ok",
                              handler: () => removeRisk(risk.id)
                            }
                          ]
                        });
                      }}
                    >
                      DELETE
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
              {isRiskExpanded(risk.id) && (
                <>
                  <IonRow className="">
                    <IonCol
                      size-xxl="6"
                      size-xl="6"
                      size-lg="6"
                      size-md="12"
                      size-sm="12"
                      size-sx="12"
                      size="12"
                    >
                      <IonRow>
                        <IonLabel className="form-group-input-text-title single-label line-height-2">
                          Body position
                        </IonLabel>
                      </IonRow>
                      <IonRow className="no-padding">
                        <BaseSelectMultiButton
                          source={riskBodyPositions}
                          onClickButton={(value: any) =>
                            setValueRisk(
                              "bodyPositions",
                              updateFieldList(
                                "bodyPositions",
                                value,
                                !isItemChecked("bodyPositions", value)
                              )
                            )
                          }
                          isChecked={(value: string) =>
                            isItemChecked("bodyPositions", value)
                          }
                        />
                      </IonRow>
                    </IonCol>
                    <IonCol className="ion-float-left" size="6">
                      <SelectForm
                        label="Severity"
                        name="severity"
                        options={severityList}
                        value={currentRisk && currentRisk.severity}
                        onChange={setValueRisk}
                        clearValue={() => setValueRisk("severity", null)}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow className="">
                    <IonCol size="6" className="bold to-left top-1">
                      Cause Types
                    </IonCol>
                  </IonRow>
                  <IonRow className="">
                    <BaseSelectMultiCheckbox
                      source={riskCauseTypes}
                      onClickButton={(name: any, value: boolean) =>
                        setValueRisk(
                          "causeTypes",
                          updateFieldList("causeTypes", name, value)
                        )
                      }
                      isChecked={(value: string) =>
                        isItemChecked("causeTypes", value)
                      }
                    />
                  </IonRow>
                  <IonRow className="top-2">
                    <IonCol>
                      <Textarea
                        label={"Risk Comment"}
                        value={currentRisk && currentRisk.comment}
                        onBlur={(e: any) => {
                          let risk: any = { ...currentRisk };

                          if (risk && !risk.comment) {
                            risk.comment = "";
                          }

                          let isDifferent =
                            risk && risk.comment != e.target.value;

                          if (isDifferent)
                            setValueRisk("comment", e.target.innerText);
                        }}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow className="top-1">
                    <IonCol size="6" className="bold to-left top-1">
                      Environment
                    </IonCol>
                    <IonCol>
                      <IonImg className="cursor-pointer"></IonImg>
                      <IonButton
                        fill="clear"
                        className="ion-float-right"
                        onClick={() => setShowEnvironmentModal()}
                      >
                        <IonIcon src={addSharp}></IonIcon>&nbsp;ADD ENVIRONMENT
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <EnvironmentCheckboxes
                    environments={environments}
                    environmentsChecked={environmentsChecked}
                    idEnvironmentBlocked={currentEnvironment.id}
                    onChangeEnvironment={updateEnvironmentRisk}
                    getCurrentShowCheckboxLoader={getCurrentShowCheckboxLoader}
                    showCheckboxLoaderUpdated={showCheckboxLoaderUpdated}
                  />

                  <IonRow className="ion-align-items-baseline">
                    <IonCol size="6" className="bold to-left">
                      Recommendations
                    </IonCol>
                    <IonCol
                      size="6"
                      className="to-right text-regular-size bold"
                    >
                      <IonButton
                        fill="clear"
                        onClick={() => handleOpenRecommendationPicker(true)}
                      >
                        <IonIcon src={addSharp}></IonIcon>&nbsp;ADD
                        RECOMMENDATIONS
                      </IonButton>
                      <IonButton
                        size="small"
                        className="circle-btn-icon"
                        fill="clear"
                      >
                        <IonIcon icon={chevronDownOutline} />
                      </IonButton>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="12">
                      <table className="iontbl-comments top-1 text-regular-size w-full">
                        <tbody>
                          {currentLinkedRecommendations?.map(
                            (recomm: any, index: number) => (
                              <tr className="comment" key={index}>
                                <td className="to-left td-width-auto">
                                  &nbsp;&nbsp;{recomm.category}
                                </td>
                                <td className="to-left td-width-auto">
                                  {recomm.subCategory}
                                </td>
                                <td className="to-left td-width-auto">
                                  {recomm.type}
                                </td>
                                {
                                  <td className="to-left descr-comment">
                                    {recomm.description}
                                  </td>
                                }
                                <td className="to-left descr-comment">
                                  {recomm.name}
                                </td>
                                <td className="to-center time-comment">
                                  <IonIcon
                                    icon={unlink}
                                    mode="md"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      dispatch({
                                        type: acceptDeleteRecommendationUpdated.type,
                                        payload: false
                                      });
                                      presentAlert({
                                        cssClass: "my-css",
                                        message:
                                          "Are you sure that you want to unlink this Recommendation?",
                                        inputs: [
                                          {
                                            type: "checkbox",
                                            label: "Delete Recommendation?",
                                            handler: (item: any) => {
                                              handleSetAcceptDeleteRecommendation(
                                                item.checked
                                              );
                                            },
                                            checked: false
                                          }
                                        ],
                                        buttons: [
                                          "Cancel",
                                          {
                                            text: "Ok",
                                            handler: () =>
                                              removeRecommendation(
                                                getRiskRecommendationId(
                                                  recomm.id
                                                ),
                                                recomm.id
                                              )
                                          }
                                        ]
                                      });
                                    }}
                                  ></IonIcon>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </div>
          ))}
      </IonGrid>
    </>
  );
};

export default RisksTab;
