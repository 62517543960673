import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { createSelector } from "reselect";
import { makeRequest } from "../../helpers/global.helper";

const slice = createSlice({
  name: "recommendations",
  initialState: {
    list: [],
    lastFetch: "",
    idTemp: -1,
    rowsToDelete: [],
    rows: []
  },
  reducers: {
    recommendationsReceived(recommendations: any, action: PayloadAction<any>) {
      const { payload } = action;
      if (payload.rows && payload.rows.length > 0) {
        recommendations.list = payload.rows;
      } else {
        recommendations.list = [];
      }
    },
    recommendationsUpdated(recommendations: any, action: PayloadAction<any>) {
      recommendations.list = action.payload;
    },
    lastFetchUpdated(recommendations: any, action: PayloadAction<any>) {
      recommendations.lastFetch = action.payload;
    },
    idTempUpdated(recommendations: any, action: PayloadAction<any>) {
      recommendations.idTemp = action.payload;
    },
    rowsToDeleteUpdated(recommendations: any, action: PayloadAction<any>) {
      recommendations.rowsToDelete = action.payload;
    },
    rowsUpdated(recommendations: any, action: PayloadAction<any>) {
      recommendations.rows = action.payload;
    }
  }
});

export const {
  recommendationsReceived,
  recommendationsUpdated,
  lastFetchUpdated,
  idTempUpdated,
  rowsToDeleteUpdated,
  rowsUpdated
} = slice.actions;
export default slice.reducer;

// Gets current recommendations filtered by environment
export const getCurrentRows = createSelector(
  (state: any) => state.evaluation.recommendations,
  (recommendations) => recommendations.rows
);

/**
 * Gets recommendations.
 */
export const getRecommendations = createSelector(
  (state: any) => state.evaluation.recommendations,
  (recommendations: any) => recommendations.list
);

/**
 * Gets current recommendations.
 * @returns
 */
export const getCurrentRecommendations =
  () => (dispatch: any, getState: any) => {
    return getState().evaluation.recommendations.list;
  };

/**
 * Loads environment recommendations.
 * - Gets current rows
 * - Filters rows with negative id
 * - Merge rows from server and filtered rows
 * @returns
 */
export const loadEvaluationRecommendations =
  (id: any) => async (dispatch: any, getState: any) => {
    const currentRows = getState().evaluation.recommendations.list;
    let { lastFetch, rowsToDelete } = getState().evaluation.recommendations;
    const currentEvaluation = getState().evaluation.current;
    const rowsWithNegativeIds = currentRows.filter((row: any) => row.id < 0);
    const cacheLimit = process.env.REACT_APP_BIDB_CACHE_LIMIT
      ? process.env.REACT_APP_BIDB_CACHE_LIMIT
      : "5";

    let evaluationsRecommendations =
      process.env.REACT_APP_BIDB_API_EVALUATIONS_RECOMMENDATIONS;
    evaluationsRecommendations = evaluationsRecommendations?.replace(
      "<<idEvaluation>>",
      id
    );
    let rows: any = [];

    if (lastFetch != "") {
      lastFetch = moment(lastFetch).add(cacheLimit, "minutes");
    } else {
      lastFetch = moment();
    }

    const now = moment();
    const isNowGreaterThan: boolean =
      process.env.REACT_APP_ENV == "DEV" ? true : now > lastFetch;

    const response: any = await dispatch(
      makeRequest({
        endpoint: evaluationsRecommendations,
        method: "GET"
      })
    );

    if (response.status == 200 && response.data.rows.length > 0) {
      rows = response.data.rows;
      /*
        const filtered = rows.filter((row: any) => {
          return (
            row.idEvaluation == currentEvaluation.id &&
            row.id > 0 &&
            parseInt(row.token) < 0
          );
        });
        let rowsToBePatched: any = [];
        let rowsToBeDeleted: any = [];

        // Update negative ids.
        if (filtered.length > 0) {
          for (const [, row] of filtered.entries()) {
            // rows to be patched
            let index = findIndex(rowsWithNegativeIds, {
              idTemp: parseInt(row.token)
            });

            if (index >= 0) {
              let temp: any = { ...rowsWithNegativeIds[index] };
              temp.id = row.id;
              if (temp.idTemp) {
                delete temp.idTemp;
              }
              temp.token = "";

              rowsToBePatched.push(temp);

              rowsWithNegativeIds.splice(index, 1);
            }

            // rows to be deleted
            let found = find(rowsToDelete, { idTemp: parseInt(row.token) });

            if (found) {
              rowsToBeDeleted.push(row);
            }
          }

          if (rowsToBePatched.length > 0) {
            dispatch(patchRecommendation(rowsToBePatched));
          }

          if (rowsToBeDeleted.length > 0) {
            for (const [, row] of rowsToBeDeleted.entries()) {
              dispatch(deleteRecommendation(row.id));
              rows = rows.filter((o: any) => {
                return o.id != row.id;
              });
            }

            dispatch({
              type: rowsToDeleteUpdated.type,
              payload: []
            });
          }

          rows = [...rowsWithNegativeIds, ...rows];
        }
        */

      dispatch({
        type: lastFetchUpdated.type,
        payload: moment().format("YYYY-MM-DD HH:mm:ss")
      });

      dispatch({
        type: recommendationsUpdated.type,
        payload: rows
      });
    }
  };

/**
 * Get last id temp.
 * @returns
 */
export const getLastIdTemp = () => async (dispatch: any, getState: any) => {
  const { idTemp } = getState().evaluation.recommendations;

  return idTemp;
};

/**
 * Gets rows to delete.
 * @returns
 */
export const getCurrentRowsToDelete =
  () => async (dispatch: any, getState: any) => {
    const { rowsToDelete } = getState().evaluation.recommendations;

    return rowsToDelete;
  };
