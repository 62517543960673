import _, { result } from "lodash";
import axios from "axios";
import Cookie from "js-cookie";
import { useState } from "react";
import { arrowBack } from "ionicons/icons";
import { makeApiCall, makeRequest } from "../helpers/global.helper";

const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;
const apiEvaluations = process.env.REACT_APP_BIDB_API_EVALUATIONS;
const apiIssues = process.env.REACT_APP_BIDB_API_ISSUES;
const apiActivities = process.env.REACT_APP_BIDB_API_ACTIVITIES;

export interface RecommendationPayload {
  idEvaluation?: number;
  idRecommendationv?: number;
  category?: string;
  description?: string;
  name?: string;
  subCategory?: string;
}

export const payloadTemplate: any = [
  "idRecommendationv",
  "category",
  "description",
  "name",
  "subCategory"
];

export const payloadRecommendationEnvironment: any = [
  "idRecommendation",
  "idEnvironment"
];

export const recommendationEnvironmentTemplate: any = {
  idRecommendation: null,
  idEnvironment: null
};

export const recommendationTemplate = {
  idEvaluation: null,
  idEquipment: null,
  idFurniture: null,
  idPrevious: null,
  idRecommendationv: null,
  actionItem: null,
  availability: null,
  bodyPosition: null,
  category: null,
  comment: null,
  description: null,
  implemented: null,
  implementedComment: null,
  implementedRealtime: null,
  measurements: null,
  name: null,
  notes: null,
  notesAdmin: null,
  options: null,
  orgTaskNumber: null,
  priority: null,
  reason: null,
  responsibleParty: null,
  size: null,
  status: null,
  subCategory: null,
  type: null
};

/**
 * Deletes recommendation
 * @param id
 * @returns
 */
export const deleteRecommendation =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/recommendations/${id}`,
        method: "DELETE"
      })
    );

    console.log(response.status);
  };

/**
 * Deletes recommendation issues
 * @param id
 * @returns
 */
export const deleteRecommendationIssues = async (id: number) => {
  return await makeApiCall(`/issuerecommendations/${id}`, "DELETE");
};

/**
 * Deletes recommendations environments
 * @param id
 * @returns
 */
export const deleteRecommendationsEnvironments =
  (id: number) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({
        endpoint: `/recommendations/${id}/environments`,
        method: "DELETE"
      })
    );

    console.log(response.status);
  };

/**
 * Deletes recommendations issues
 * @param id
 * @returns
 */
export const deleteRecommendationsIssues = async (id: number) => {
  return await makeApiCall(`/recommendations/${id}/issues`, "DELETE");
};

/**
 * Deletes recommendations risks
 * @param id
 * @returns
 */
export const deleteRecommendationsRisks = async (id: number) => {
  return await makeApiCall(`/recommendations/${id}/risks`, "DELETE");
};

export const postRecommendations = async (recommendations: any, rec: any) => {
  let response: any = [];
  console.warn(recommendations);
  for (let i = 0; recommendations && i < recommendations.length; i++) {
    let data = {
      idRecommendationv: recommendations[i]?.id,
      token: await getUuidToken(),
      category: recommendations[i]?.category,
      comment: rec.comment == undefined ? "" : rec.comment,
      measurements: rec.measurements == undefined ? "" : rec.measurements,
      type: recommendations[i]?.type,
      options: rec.options == undefined ? [] : rec.options,
      subCategory: recommendations[i]?.subCategory,
      actionItem: rec.actionItem == undefined ? "" : rec.actionItem
    };

    let res = await axios
      .post(
        `${apiBaseUrl}/evaluations/${rec.idEvaluation}/recommendations`,
        data
      )
      .then(async (responseData: any) => {
        if (responseData?.data["successes"]) {
          let envRes = await postEnvironments(
            rec.currentEnvironment,
            responseData?.data["successes"]
          );
        }
      })
      .catch((error) => {
        console.log("error-postRecommendation:", error);
      });
  }
};

export const postEnvironments = async (
  environment: any,
  recommendation: any
) => {
  let data = {
    idEnvironment: environment?.id,
    idRecommendation: recommendation[0]?.id,
    token: await getUuidToken()
  };

  let res = await axios.post(`${apiBaseUrl}/recommendationenvironments`, data);
  let response = {
    token: data.token,
    type: recommendation?.type,
    description: recommendation?.description,
    idEnvironment: environment?.id,
    genRecommendationId: res.data.successes[0].id
  };

  return response;
};

export const postIssues = async (issues: any, rec: any) => {
  let response: any = [];

  for (let i = 0; issues && i < issues.length; i++) {
    let data = {
      idIssue: issues[0].id,
      idRecommendation: rec.id,
      token: getUuidToken()
    };

    let res = await axios.post(`${apiBaseUrl}/issuerecommendations`, data);
    response.push({
      token: data?.token,
      type: rec?.type,
      description: rec?.description,
      idIssue: issues[0].id,
      genIssueId: res?.data.successes[0].id
    });
  }

  return response;
};

export const getUuidToken = async () => {
  // let uuid=await axios.get("https://www.uuidtools.com/api/generate/v1");
  // return uuid?.data[0]
  // let uuid=await axios.get("https://www.uuidtools.com/api/generate/v1");
  return "00000000-0000-0000-0000-000000000000";
};

export const getRecommendationEnvironments = async (id: number) => {
  const response = await axios.get(
    `${apiBaseUrl}/evaluations/${id}/environments`
  );

  return response.data.rows;
};

export const getRecommendationRisks = async (id: number) => {
  const response = await axios.get(`${apiBaseUrl}/evaluations/${id}/risks`);

  return response.data.rows;
};

export const getRecommendationIssues = async (id: number) => {
  const response = await axios.get(`${apiBaseUrl}/evaluations/${id}/issues`);

  return response.data.rows;
};

export const getPossibleParams = (params: any) => {
  let orgParams = ["category", "subcategory", "name", "description"];
  let tempArr: any = {};

  for (var i = 0; i < params.length; i++)
    if (params[i] != "") tempArr[orgParams[i]] = params[i];

  return tempArr;
};

export const getRecommendationvs = async (
  _category: string,
  _subcategory: string,
  _name: string,
  _description: string
) => {
  let _params = getPossibleParams([
    _category,
    _subcategory,
    _name,
    _description
  ]);

  if (_params != "") {
    const response = await axios.get(`${apiBaseUrl}/recommendationvs`, {
      params: _params
    });

    return response.data.rows;
  } else {
    const response = await axios.get(`${apiBaseUrl}/recommendationvs`);

    return response.data.rows;
  }
};

export const getRiskvs = async () => {
  console.warn("riskvs");
  const response = await axios.get(`${apiBaseUrl}/riskvs`);

  return response.data.rows;
};

export const getRisksRecommendation = async (id: number) => {
  const response = await axios.get(`${apiBaseUrl}/risks/${id}/recommendations`);

  return response.data.rows;
};

export const getRisksRecommendations = async (id: number) => {
  const response = await axios.get(`${apiBaseUrl}/riskrecommendations/${id}`);

  return response.data.rows;
};

export const getRecommendationsRisks = async (id: number) => {
  const response = await axios.get(`${apiBaseUrl}/recommendations/${id}/risks`);

  return response.data.rows;
};

export const getEnvironmentsRecommendations = async (id: number) => {
  const response = await axios.get(
    `${apiBaseUrl}/environments/${id}/recommendations`
  );

  return response.data.rows;
};

export const getEvaluationsRecommendations = async (
  id: number,
  idEnvironment: number
) => {
  const response = await axios.get(
    `${apiBaseUrl}/evaluations/${id}/recommendations`
  );

  let arr: any = [];
  const enviromentRecommendations = await getEnvironmentsRecommendations(
    idEnvironment
  );

  if (enviromentRecommendations?.length > 0)
    for (var i = 0; i < response?.data?.rows?.length; i++) {
      for (var j = 0; j < enviromentRecommendations?.length; j++) {
        if (
          response?.data?.rows[i].id ==
          enviromentRecommendations[j].idRecommendation
        ) {
          arr.push(response?.data?.rows[i]);
          break;
        }
      }
    }

  return arr;
};

export const getValueListByName = async (name: string) => {
  const response = await axios.get(`${apiBaseUrl}$/recommendationvs`);

  return response.data.rows[0].values;
};

/**
 * Patches recommendation.
 * @param payload
 */
export const patchRecommendation =
  (payload: any) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({
        endpoint: `/recommendations`,
        method: "PATCH",
        data: payload
      })
    );

    console.log(response.status);
  };

/**
 * Deletes recommendation environment.
 * @param id
 * @returns
 */
export const deleteRecommendationEnvironment =
  (id: number) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({
        endpoint: `/recommendationenvironments/${id}`,
        method: "DELETE"
      })
    );

    console.log(response.status);
  };
