import React, { useState } from "react";
import { IonCol, IonButton, IonIcon } from "@ionic/react";
import { addSharp } from "ionicons/icons";

import moment from "moment";
import Search from "./Search";
import format from "date-fns/format";
import SearchDate from "./SearchDate";

const SearchBox: React.FC<any> = (props: any) => {
  const { fetchEvaluations } = props;
  const [appointmentDateGte, setAppointmentDateGte] = useState<any>(null);
  const [appointmentDateLte, setAppointmentDateLte] = useState<any>(null);
  const [appointmentSearchText, setAppointmentSearchText] = useState<any>(null);

  /**
   * Handles appointment date gte
   */
  const handleAppointmentDateGteLte = (dateGte: any, dateLte: any) => {
    let dateObject: any = {};

    if (dateGte || dateLte) {
      if (dateGte && !dateLte) {
        dateGte = format(dateGte, "yyyy-MM-dd");
        dateLte = null;
        dateObject.appointmentDate = dateGte;
      }

      if (!dateGte && dateLte) {
        dateGte = null;
        dateLte = format(dateLte, "yyyy-MM-dd");
        dateObject.appointmentDate = dateLte;
      }

      if (dateGte && dateLte) {
        dateGte = format(dateGte, "yyyy-MM-dd");
        dateLte = format(dateLte, "yyyy-MM-dd");
        if (dateGte > dateLte) {
          setAppointmentDateGte(null);
          dateGte = null;
        }

        dateObject.appointmentDateGte = dateGte;
        dateObject.appointmentDateLte = dateLte;
      }

      /* const dateObject = {
        appointmentDateGte: dateGte,
        appointmentDateLte: dateLte,
        appointmentDate: null
      }; */

      fetchEvaluations(appointmentSearchText, dateObject);
    } else {
      fetchEvaluations();
    }
  };

  const handleToday = () => {
    setAppointmentDateGte(new Date());
    setAppointmentDateLte(null);
    setAppointmentSearchText(null);
    const date = moment().format("YYYY-MM-DD");

    if (date) {
      const dateObject = {
        appointmentDateGte: null,
        appointmentDateLte: null,
        appointmentDate: date
      };

      fetchEvaluations("", dateObject);
    } else {
      fetchEvaluations();
    }
  };

  const handleClick = () => {
    window.location.href = "/appointments";
  };

  const handleAppointmentSearchText = (searchText: any) => {
    if (appointmentSearchText !== searchText)
      setAppointmentSearchText(searchText);
    const dateObject = {
      appointmentDateGte: appointmentDateGte,
      appointmentDateLte: appointmentDateLte,
      appointment: null
    };
    fetchEvaluations(searchText, dateObject);
  };

  return (
    <React.Fragment>
      <IonCol className="input-fields-col">
        <Search
          fetchEvaluations={handleAppointmentSearchText}
          valueSearchText={appointmentSearchText}
        />
      </IonCol>
      <IonCol className="input-fields-col">
        <SearchDate
          fetchEvaluations={fetchEvaluations}
          onChangeAppointmentDateGteLte={handleAppointmentDateGteLte}
          appointmentDateGte={appointmentDateGte}
          setAppointmentDateGte={setAppointmentDateGte}
          appointmentDateLte={appointmentDateLte}
          setAppointmentDateLte={setAppointmentDateLte}
        />
      </IonCol>
      <IonCol size="auto" className="input-fields-col remove-paddin">
        <IonButton className="search-button" onClick={handleToday}>
          TODAY’S SCHEDULE
        </IonButton>
        &ensp;
        <IonButton className="search-button" onClick={handleClick}>
          <IonIcon src={addSharp}></IonIcon>&nbsp;ADD APPOINTMENT
        </IonButton>
      </IonCol>
    </React.Fragment>
  );
};

export default SearchBox;
