import React from "react";
import { IonLabel } from "@ionic/react";

import {
  checkIfEvaluationCompleted,
  getHighPriorityLetter,
  getIconType,
  isHighPriority
} from "../../services/evaluation.service";

const RequestPriority: React.FC<any> = (props: any) => {
  const { evaluation } = props;

  return (
    <div
      className={`type-item-t1 type-item-t1-${
        evaluation && evaluation.type && evaluation.type.toLowerCase()
      }`}
    >
      <div className="flex flex-column">
        <IonLabel className="type-item-inner-text-color1">
          {getIconType(evaluation && evaluation.type && evaluation.type)}
        </IonLabel>
        <IonLabel className="type-item-inner-text-color1">
          {getHighPriorityLetter(
            evaluation &&
              evaluation.requestPriority &&
              evaluation.requestPriority.toLowerCase()
          )}
        </IonLabel>
      </div>
      {evaluation && checkIfEvaluationCompleted(evaluation) && (
        <IonLabel className="icon-completed">
          <span>&#10003;</span>
        </IonLabel>
      )}
      {evaluation &&
        evaluation.requestPriority &&
        isHighPriority(evaluation.requestPriority.toLowerCase()) && (
          <IonLabel className="type-item-innor-icon">!</IonLabel>
        )}
    </div>
  );
};

export default RequestPriority;
