import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _, { filter, map, uniqBy } from "lodash";

const slice = createSlice({
  name: "furnitures",
  initialState: {
    list: [],
    originalList: [],
    filteredList: [],
    categories: [],
    sortedCategories: [],
    worksurfaceTypes: [],
    storageTypes: [],
    storageLocations: [],
    subCategories: [],
    originalSubCategories: [],
    furnitureFilters: {
      category: {
        sign: "=",
        value: ""
      },
      subCategory: {
        sign: "=",
        value: ""
      },
      name: {
        sign: "contains",
        value: ""
      },
      manufacturer: {
        sign: "=",
        value: ""
      }
    }
  },
  reducers: {
    furnituresReceived(furnitures: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows) {
        furnitures.list = payload.rows;
        furnitures.originalList = payload.rows;
      } else {
        furnitures.list = [];
        furnitures.originalList = [];
      }
    },
    furnituresUpdated(furnitures: any, action: PayloadAction<any>) {
      furnitures.list = action.payload;
    },
    furnituresFiltered(furnitures: any, action: PayloadAction<any>) {
      furnitures.filteredList = action.payload;
    },
    furnitureCategoriesReceived(furnitures: any, action: PayloadAction<any>) {
      furnitures.categories = action.payload;
    },
    furnitureCategoriesSorted(furnitures: any, action: PayloadAction<any>) {
      furnitures.sortedCategories = action.payload;
    },
    workSurfaceTypesReceived(furnitures: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows) {
        furnitures.worksurfaceTypes = action.payload.rows[0].values;
      }
    },
    storageTypesReceived(furnitures: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows) {
        furnitures.storageTypes = action.payload.rows[0].values;
      }
    },
    storageLocationsReceived(furnitures: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows) {
        furnitures.storageLocations = action.payload.rows[0].values;
      }
    },
    furnitureFilterUpdated(furnitures: any, action: PayloadAction<any>) {
      furnitures.furnitureFilters[action.payload.key].value =
        action.payload.value;
    },
    furnitureSubCategoriesReceived(
      furnitures: any,
      action: PayloadAction<any>
    ) {
      furnitures.subCategories = action.payload;
      furnitures.originalSubCategories = action.payload;
    },
    filterFurnitureUpdate(furnitures: any, action: PayloadAction<any>) {
      furnitures.furnitureFilters[action.payload.key].value =
        action.payload.value;
    },
    furnitureSubCategoriesUpdated(furnitures: any, action: PayloadAction<any>) {
      furnitures.subCategories = action.payload;
    },
    furnitureSubCategoriesReset(furnitures: any, action: PayloadAction<any>) {
      furnitures.subCategories = action.payload;
    }
  }
});

export const {
  furnituresReceived,
  furnituresUpdated,
  furnituresFiltered,
  furnitureCategoriesReceived,
  furnitureCategoriesSorted,
  workSurfaceTypesReceived,
  storageTypesReceived,
  storageLocationsReceived,
  furnitureFilterUpdated,
  furnitureSubCategoriesReceived,
  filterFurnitureUpdate,
  furnitureSubCategoriesUpdated,
  furnitureSubCategoriesReset
} = slice.actions;
export default slice.reducer;

/**
 * Gets equipments.
 */
export const getFurnitures = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.list
);

/**
 * Loads equipment categories.
 * @returns
 */
export const loadFurnitureCategories =
  (categories: any = []) =>
  (dispatch: any, getState: any) => {
    const furnitureCategories: Array<any> =
      categories.length === 0
        ? [...getState().entities.catalogs.furnitures.categories]
        : categories;

    if (categories.length > 0) {
      dispatch({
        type: furnitureCategoriesReceived.type,
        payload: categories
      });
    }

    dispatch({
      type: furnitureCategoriesSorted.type,
      payload: [...furnitureCategories].sort((a, b) => a.localeCompare(b))
    });
  };

/**
 * Gets furniture categories.
 */
export const getFurnitureCategories = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.categories
);

/**
 * Gets sorted furniture categories.
 */
export const getSortedFurnitureCategories = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.sortedCategories
);

/**
 * Loads equipment subcategories.
 */
export const loadCurrentFurnitureSubcategories =
  (category: string = "") =>
  (dispatch: any, getState: any) => {
    const { originalList } = getState().entities.catalogs.furnitures;
    let filtered: any = [];

    if (category === "") {
      filtered = map(uniqBy(originalList, "subCategory"), "subCategory");
    } else {
      filtered = filter(originalList, { category });
      filtered = map(uniqBy(filtered, "subCategory"), "subCategory");
    }

    return filtered.sort((a: any, b: any) => a.localeCompare(b));
  };

export const getFurnitureSubcategories = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.subCategories
);

export const getFurnitureOriginalSubCategories = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.originalSubCategories
);

/**
 * Gets equipment manufacturer.
 */
export const getFurnitureManufacturer = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => {
    const { originalList } = furnitures;

    let filtered = _.map(
      _.uniqBy(originalList, "manufacturer"),
      "manufacturer"
    );

    filtered = _.filter(filtered, function (o) {
      return o !== "";
    });

    return filtered.sort((a, b) => a.localeCompare(b));
  }
);

/**
 * Gets original furnitures.
 */
export const getOriginalFurnitures = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.originalList
);

/**
 * Loads original furnitures.
 * @returns
 */
export const loadOriginalFurnitures =
  () => async (dispatch: any, getState: any) => {
    const { originalList } = getState().entities.catalogs.furnitures;

    return originalList;
  };

/**
 * Gets filtered furnitures.
 */
export const getFilteredFurnitures =
  () => async (dispatch: any, getState: any) => {
    const { filteredList } = getState().entities.catalogs.furnitures;

    return filteredList;
  };

/**
 * Sorts list
 * @returns
 */
export const filterCurrentFurnitures =
  (furnitureFilters: any = undefined) =>
  async (dispatch: any, getState: any) => {
    const { originalList } = getState().entities.catalogs.furnitures;
    let list: any = originalList;

    if (furnitureFilters && furnitureFilters && furnitureFilters.category) {
      list = _.filter(originalList, {
        category: furnitureFilters.category.value
      });
    }

    if (furnitureFilters != undefined && list.length == 0) {
      list = originalList;
    }

    dispatch({
      type: furnituresUpdated.type,
      payload: list
    });
  };

/**
 * Reset furnitures.
 * @returns
 */
export const resetFurnitures = () => (dispatch: any, getState: any) => {
  const { originalList } = getState().entities.catalogs.furnitures;

  dispatch({
    type: furnituresUpdated.type,
    payload: originalList
  });
};

/**
 * Gets worksurface types.
 */
export const getWorksurfaceTypes = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.worksurfaceTypes
);

/**
 * Gets storage types.
 */
export const getStorageTypes = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.storageTypes
);

/**
 * Gets storage locations.
 */
export const getStorageLocations = createSelector(
  (state: any) => state.entities.catalogs.furnitures,
  (furnitures: any) => furnitures.storageLocations
);
