import React, { useState, useEffect } from "react";
import {
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonSkeletonText
} from "@ionic/react";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import {
  caretDownOutline,
  caretUpOutline,
  pencilOutline
} from "ionicons/icons";
import Table from "./Table";
import moment from "moment";
import {
  getLocationEvaluatorIcon,
  getStatusIcon
} from "../../services/evaluation.service";
import {
  getCurrentEvaluations,
  getSortColumns,
  getSortDirections
} from "../../store/Evaluations";
import {
  commentsReceived,
  environmentsReceived,
  environmentSubmenuOpenedClosed,
  getCurrentEvaluation,
  isOpenScratchPadModalUpdated,
  scratchPadHeaderUpdated,
  setEvaluation
} from "../../store/evaluation/Current";
import "../layout/InfoBar/InfoBar.css";
import { apiCall } from "../../store/api";
import { issuesReceived } from "../../store/evaluation/Issues";
import { activitiesReceived } from "../../store/evaluation/Activities";
import { risksReceived } from "../../store/evaluation/Risks";
import { recommendationsReceived } from "../../store/evaluation/Recommendations";
import { organizationRegionEquipmentsReceived } from "../../store/evaluation/OrganizationRegionEquipments";
import { organizationRegionFurnituresReceived } from "../../store/evaluation/OrganizationRegionFurnitures";
import RequestPriority from "../common/RequestPriority";
import { showSkeletonUpdated } from "../../store/catalogs/Environments";

import {
  equipmentsReceived,
  setCurrentEquipment,
  setEquipmentIndexSelected,
  setEquipmentTypeSelected,
  setNoEquipmentSelected,
  sortedListUpdated
} from "../../store/evaluation/environment/workstation/Equipments";
import {
  furnituresListReceived,
  setCurrentFurniture,
  setFurnitureIndexSelected,
  setFurnitureTypeSelected,
  setNoFurnitureSelected,
  sortedListUpdated as furnitureSortedListUpdated
} from "../../store/evaluation/environment/workstation/Furnitures";

const TopNavigation: React.FC<any> = React.forwardRef(
  (props: any, ref: any) => {
    const dispatch = useAppDispatch();
    const evaluations = useAppSelector(getCurrentEvaluations);
    const evaluation = useAppSelector(getCurrentEvaluation);
    const sortColumns = useAppSelector(getSortColumns);
    const sortDirections = useAppSelector(getSortDirections);
    const { selectedIndex, setSelectedIndex, setIsOpenTopNavigation } = props;
    const [showEvaluationsTable, setShowEvaluationsTable] =
      useState<boolean>(false);
    const [disableBaseMethods, setDisableBaseMethods] = useState<boolean>(true);

    const handleShowEvaluationsTable = async () => {
      setShowEvaluationsTable(!showEvaluationsTable);
      setIsOpenTopNavigation(!showEvaluationsTable);
    };

    useEffect(() => {
      setDisableBaseMethods(true);
    }, []);

    const setTopNavigationEvaluation = async (id: number) => {
      if (evaluation && evaluation.id !== id) {
        dispatch({
          type: showSkeletonUpdated.type,
          payload: true
        });

        dispatch({
          type: environmentsReceived.type,
          payload: []
        });

        dispatch({
          type: commentsReceived.type,
          payload: []
        });

        dispatch({
          type: issuesReceived.type,
          payload: []
        });

        dispatch({
          type: activitiesReceived.type,
          payload: []
        });

        dispatch({
          type: equipmentsReceived.type,
          payload: []
        });

        dispatch({
          type: sortedListUpdated.type,
          payload: []
        });

        dispatch({
          type: furnituresListReceived.type,
          payload: []
        });

        dispatch({
          type: furnitureSortedListUpdated.type,
          payload: []
        });

        dispatch({
          type: risksReceived.type,
          payload: []
        });

        dispatch({
          type: recommendationsReceived.type,
          payload: []
        });

        dispatch({
          type: organizationRegionEquipmentsReceived.type,
          payload: []
        });

        dispatch({
          type: organizationRegionFurnituresReceived.type,
          payload: []
        });

        dispatch({
          type: environmentSubmenuOpenedClosed.type,
          payload: false
        });

        dispatch({
          type: setEquipmentIndexSelected.type,
          payload: -1
        });

        dispatch({
          type: setCurrentEquipment.type,
          payload: undefined
        });

        dispatch({
          type: setEquipmentTypeSelected.type,
          payload: ""
        });

        dispatch({
          type: setNoEquipmentSelected.type,
          payload: true
        });

        dispatch({
          type: setFurnitureIndexSelected.type,
          payload: -1
        });

        dispatch({
          type: setCurrentFurniture.type,
          payload: undefined
        });

        dispatch({
          type: setFurnitureTypeSelected.type,
          payload: ""
        });

        dispatch({
          type: setNoFurnitureSelected.type,
          payload: true
        });

        dispatch({
          type: isOpenScratchPadModalUpdated.type,
          payload: false
        });

        dispatch(
          apiCall({
            url: `/evaluations/${id}`,
            onSuccess: setEvaluation.type
          })
        );
      }
    };

    const handleOpenScratchPad = (e: any, value: boolean, evaluation: any) => {
      if (e) {
        e.stopPropagation();
      }

      dispatch({
        type: scratchPadHeaderUpdated.type,
        payload: {
          workerName: evaluation.workerName,
          company: evaluation.organizationName
        }
      });

      dispatch({
        type: isOpenScratchPadModalUpdated.type,
        payload: value
      });
    };

    return (
      <div className="overflow-auto pl-1 pr-1 bg-white z-2 cursor-pointer">
        <IonRow
          className="ion-text-center row sticky top-0 z-1 bg-white cursor-pointer ion-justify-content-center"
          onClick={() => handleShowEvaluationsTable()}
        >
          <IonCol size=".75">
            {evaluation == undefined ? (
              <IonSkeletonText animated />
            ) : (
              <RequestPriority evaluation={evaluation} />
            )}
          </IonCol>
          <IonCol size=".75">
            {evaluation == undefined ? (
              <IonSkeletonText animated />
            ) : (
              <div className="row-data-text main-filter-alignment type-item-center-icon">
                {evaluation && (
                  <img
                    className="row-data-icons"
                    src={
                      evaluation &&
                      getLocationEvaluatorIcon(
                        evaluation.getLocationEvaluatorIcon
                          ? evaluation.locationEvaluatorDuringEvaluation
                          : ""
                      )
                    }
                    alt="logo"
                  />
                )}
              </div>
            )}
          </IonCol>
          <IonCol size="1.1">
            {evaluation == undefined ? (
              <IonSkeletonText animated />
            ) : (
              <>
                <IonRow>
                  <IonCol className="p-0 text-left">
                    <div className="filter-text-wrapper">
                      <IonText className="row-data-text">
                        {evaluation &&
                          evaluation.appointmentDate &&
                          moment(evaluation.appointmentDate).format(
                            "MM/DD/YYYY"
                          )}
                      </IonText>
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className="p-0 text-left">
                    <div className="filter-text-wrapper">
                      <IonText className="row-data-text">
                        {evaluation &&
                          evaluation.appointmentDate &&
                          evaluation.appointmentTime &&
                          moment(
                            `${evaluation.appointmentDate} ${evaluation.appointmentTime}`
                          ).format("hh:mm a")}
                      </IonText>
                    </div>
                  </IonCol>
                </IonRow>
              </>
            )}
          </IonCol>
          <IonCol size=".9">
            {evaluation == undefined ? (
              <IonSkeletonText animated />
            ) : (
              <div className="filter-text-wrapper justify-center">
                <img
                  src={
                    evaluation &&
                    getStatusIcon(
                      evaluation.appointmentConfirmed,
                      evaluation.appointmentCanceled,
                      evaluation.status && evaluation.status
                    )
                  }
                  alt=""
                />
              </div>
            )}
          </IonCol>
          <IonCol size="1.5">
            <IonRow className="p-0 m-0">
              <IonCol className="p-0 m-0 text-left">Worker Name</IonCol>
            </IonRow>
            {evaluation == undefined ? (
              <IonSkeletonText animated />
            ) : (
              <IonRow className="p-0">
                <IonCol className="p-0">
                  <div className="filter-text-wrapper">
                    <IonText className="row-data-text text-left p-0 m-0">
                      {evaluation && evaluation.workerName}
                    </IonText>
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonCol>
          <IonCol size="3">
            <IonRow className="p-0">
              <IonCol className="p-0 text-left">Company</IonCol>
            </IonRow>
            {evaluation == undefined ? (
              <IonSkeletonText animated />
            ) : (
              <IonRow className="p-0 m-0 text-left">
                <IonCol className="p-0">
                  <div className="filter-text-wrapper">
                    <IonText className="row-data-text">
                      {evaluation && evaluation.organizationName}
                    </IonText>
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonCol>
          <IonCol size="3">
            <IonRow className="p-0">
              <IonCol className="p-0 text-left">Location</IonCol>
            </IonRow>
            {evaluation == undefined ? (
              <IonSkeletonText animated />
            ) : (
              <IonRow>
                <IonCol className="p-0 text-left">
                  <div className="filter-text-wrapper ion-text-left">
                    <IonText className="row-data-text">
                      {evaluation && evaluation.locationName}
                    </IonText>
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonCol>
          <IonCol className="relative z-100" size=".5">
            <IonIcon
              className="text-26 z-1"
              icon={pencilOutline}
              onClick={(e: any) =>
                handleOpenScratchPad(e, true, evaluation && evaluation)
              }
            ></IonIcon>
          </IonCol>
          <IonCol size=".5" className="p-0 text-right">
            <IonIcon
              icon={showEvaluationsTable ? caretUpOutline : caretDownOutline}
            ></IonIcon>
          </IonCol>
        </IonRow>
        {showEvaluationsTable && <div className="separate-table-rows"></div>}
        {showEvaluationsTable && (
          <div className="table-evaluations relative h-75vh">
            <div className="pt-4"></div>
            <Table
              ref={ref}
              evaluations={evaluations}
              selectedIndex={selectedIndex}
              disableBaseMethods={disableBaseMethods}
              setTopNavigationEvaluation={setTopNavigationEvaluation}
              setSelectedIndex={setSelectedIndex}
              setShowEvaluationsTable={handleShowEvaluationsTable}
              sortColumns={sortColumns}
              sortDirections={sortDirections}
            />
          </div>
        )}
      </div>
    );
  }
);

export default TopNavigation;
