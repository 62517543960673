import React, { useEffect, useState } from "react";

import {
  setShowAddWorkstationFurnitureModal,
  setCurrentFurniture,
  setFurnitureTypeSelected,
  setFurnitureIndexSelected,
  setNoFurnitureSelected,
  getIsFilteredList,
  getSortedEnvironmentFurnitures,
  getSelectedType,
  isFilteredFurnituresSet,
  showFurniturePickerSet,
  idSelected,
  updateEnvironmentFurnitures
} from "../../../../../store/evaluation/environment/workstation/Furnitures";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import { insertWorkstationProcess } from "../../../../../services/tabs.service";
import {
  furnitureSubCategoriesReceived,
  furnitureSubCategoriesUpdated,
  furnituresUpdated,
  getFurnitureManufacturer,
  getFurnitures,
  getFurnitureSubcategories,
  getOriginalFurnitures,
  getSortedFurnitureCategories,
  loadCurrentFurnitureSubcategories,
  loadFurnitureCategories,
  resetFurnitures
} from "../../../../../store/catalogs/Furnitures";
import { getCurrentEvaluationEnvironment } from "../../../../../store/evaluation/Current";
import { getIfNewSession, userLogged } from "../../../../../store/User";

import BaseAddModal from "../add-modal/BaseAddModal";
import Filters from "../workstations/Filters";
import {
  getRecommendations,
  recommendationsUpdated
} from "../../../../../store/evaluation/Recommendations";
import {
  filterCurrentOrganizationRegionFurnitures,
  isFilteredOrganizationRegionFurnituresSet,
  organizationRegionFurnituresGrouped
} from "../../../../../store/evaluation/OrganizationRegionFurnitures";
import {
  getPatchPayload,
  makeRequest,
  updateObjectFromList
} from "../../../../../helpers/global.helper";
import { find, omit } from "lodash";
import { getListByName } from "../../../../../store/catalogs/ValueList";

const endpointUrl = "/environments/<<>>/workstationfurnitures";

const AddFurnitureModal: React.FC<any> = (props: any) => {
  const {
    isOpen,
    isPicker,
    getOrganizationRegionFurnitures,
    getOriginalGroupedOrganizationRegionFurnitures,
    getIsFilteredOrganizationRegionFurnitures,
    getOrganizationRegionFurnitureManufacturers,
    organizationRegionFurnituresUpdated,
    recommendationIdExpanded,
    pickOneElement,
    resetList
  } = props;

  const dispatch = useAppDispatch();
  const recommendations = useAppSelector(getRecommendations);
  const furnitures: any = useAppSelector(
    isPicker ? getOrganizationRegionFurnitures : getFurnitures
  );
  const sortedEnvironmentFurnitures = useAppSelector(
    getSortedEnvironmentFurnitures
  );
  const originalFurnitures: any = useAppSelector(
    isPicker
      ? getOriginalGroupedOrganizationRegionFurnitures
      : getOriginalFurnitures
  );
  const isFilteredList = useAppSelector(
    isPicker ? getIsFilteredOrganizationRegionFurnitures : getIsFilteredList
  );
  const selectedType = useAppSelector(getSelectedType);
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const isNewSession = useAppSelector(getIfNewSession);
  const categories = useAppSelector(getSortedFurnitureCategories);
  const subcategories = useAppSelector(getFurnitureSubcategories);
  const manufacturers = useAppSelector(
    isPicker
      ? getOrganizationRegionFurnitureManufacturers
      : getFurnitureManufacturer
  );

  const [filters, setFilters] = useState<any>({});
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const closeModal = () => {
    dispatch({
      type: isPicker
        ? showFurniturePickerSet.type
        : setShowAddWorkstationFurnitureModal.type,
      payload: false
    });
  };

  useEffect(() => {
    if (isOpen) {
      const furnitureCategories: any = dispatch(
        getListByName("Furniture_Category")
      );
      dispatch(loadFurnitureCategories(furnitureCategories));

      const furnitureSubcategories: any = dispatch(
        loadCurrentFurnitureSubcategories()
      );
      dispatch({
        type: furnitureSubCategoriesReceived.type,
        payload: furnitureSubcategories
      });

      // IsPicker = true, we use selectedType variable as category.
      if (selectedType !== "" && isPicker) {
        const filterValue = selectedType;
        let filterObject: any;

        // We check if value is present in categories
        let index = furnitureCategories.indexOf(filterValue);

        if (index !== -1) {
          filterObject = {
            category: {
              sign: "=",
              value: filterValue
            }
          };

          loadFurnitureSubcategories(filterValue);
        } else {
          // if it doesn't, we check if it is present in subCategories
          index = furnitureSubcategories.indexOf(filterValue);

          if (index !== -1) {
            filterObject = {
              subCategory: {
                sign: "=",
                value: filterValue
              }
            };
          }
        }

        if (index !== -1) {
          setFilters(filterObject);

          if (
            (filterObject.category && filterObject.category.value !== "") ||
            (filterObject.subCategory && filterObject.subCategory.value !== "")
          ) {
            dispatch(filterCurrentOrganizationRegionFurnitures(filterObject));
            dispatch({
              type: isFilteredOrganizationRegionFurnituresSet.type,
              payload: true
            });
          }
        } else {
          dispatch(filterCurrentOrganizationRegionFurnitures());
        }
      }
    }
  }, [isOpen]);

  const initializeFilter = () => {
    handleRemoveFilter();
    loadFurnitureSubcategories();
  };

  const attachEquipmentToRecommendation = async (item: any) => {
    setShowLoader(true);
    const payload = await getPatchPayload(
      recommendationIdExpanded,
      "idFurniture",
      item.idFurniture
    );
    const response = await dispatch(
      makeRequest({
        endpoint: `/recommendations`,
        method: "PATCH",
        data: payload
      })
    );

    if (response.status === 200) {
      let temp = await updateObjectFromList(
        recommendations && [...recommendations],
        payload,
        "id",
        "idFurniture",
        item.idFurniture
      );

      // item name (furniture name)
      const list = originalFurnitures;

      const object = find(list, {
        idFurniture: item.idFurniture
      });

      temp = await updateObjectFromList(
        recommendations && [...recommendations],
        payload,
        "id",
        "itemName",
        object ? object.name : ""
      );

      await dispatch({
        type: recommendationsUpdated.type,
        payload: temp
      });

      setShowLoader(false);
      closeModal();
    }
  };

  const handleSave = async (selectedItems: any) => {
    setShowLoader(true);
    const finalEndpointUrl = endpointUrl.replace("<<>>", currentEnvironment.id);

    const response: any = await dispatch(
      insertWorkstationProcess(
        selectedItems,
        false,
        "furnitures",
        "idFurniture",
        finalEndpointUrl,
        sortedEnvironmentFurnitures
      )
    );

    dispatch({
      type: setFurnitureIndexSelected.type,
      payload: response.indexToBeSelected
    });

    dispatch({
      type: setCurrentFurniture.type,
      payload: response.currentWorkstation
    });

    dispatch({
      type: idSelected.type,
      payload: response.currentWorkstation.id
    });

    await dispatch({
      type: setFurnitureTypeSelected.type,
      payload: response.toBeSelected.type
    });

    dispatch(updateEnvironmentFurnitures(response.temp));

    if (isNewSession) {
      dispatch(userLogged(false));
    }

    dispatch({
      type: setNoFurnitureSelected.type,
      payload: false
    });

    setShowLoader(false);

    closeModal();
  };

  const handleSetFilter = (key: string, value: any, contains: boolean) => {
    let temp = { ...filters };

    if (value !== undefined && value !== "") {
      temp[key] = {
        sign: !contains ? "=" : "contains",
        value
      };

      setFilters(temp);
    }

    if (key === "category") {
      if (temp.subCategory) {
        delete temp.subCategory;
      }

      setFilters(temp);
    }
  };

  const handleRemoveFilter = (key: string = "") => {
    if (key !== "") {
      if (key == "category") {
        loadFurnitureSubcategories();
      }

      let temp = { ...filters };

      if (temp[key]) {
        temp = omit(temp, [key]);
      }

      setFilters(temp);
    } else {
      setFilters({});
    }
  };

  const furnitureOnDidDismiss = () => {
    // We set selectedType to empty value when equipment picker is closed.
    setTimeout(() => {
      if (isPicker) {
        dispatch({
          type: setFurnitureTypeSelected.type,
          payload: ""
        });
        dispatch({
          type: organizationRegionFurnituresUpdated.type,
          payload: originalFurnitures
        });
      } else {
        resetList(originalFurnitures);
      }

      handleRemoveFilter();
    }, 300);
  };

  const loadFurnitureSubcategories = (category: string = "") => {
    dispatch({
      type: furnitureSubCategoriesUpdated.type,
      payload: dispatch(loadCurrentFurnitureSubcategories(category))
    });
  };

  const updateFilterValue = (filters: any) => {
    setFilters(filters);
  };

  return (
    <BaseAddModal
      showLoader={showLoader}
      title="Add Furniture"
      getItems={isPicker ? getOrganizationRegionFurnitures : getFurnitures}
      isOpen={isOpen}
      originalList={originalFurnitures}
      closeModal={closeModal}
      saveButtonLabel={
        isPicker ? `SAVE CHANGES` : `ADD <<>> SELECTED FURNITURES`
      }
      leftList={furnitures}
      listUpdated={
        isPicker ? organizationRegionFurnituresUpdated : furnituresUpdated
      }
      setIsFilteredList={
        isPicker
          ? isFilteredOrganizationRegionFurnituresSet
          : isFilteredFurnituresSet
      }
      category="furnitures"
      isFilteredList={isFilteredList}
      resetList={resetFurnitures}
      onDidDismiss={furnitureOnDidDismiss}
      organizationRegionWorkstationsGrouped={
        organizationRegionFurnituresGrouped
      }
      filters={
        <Filters
          list={furnitures}
          originalList={originalFurnitures}
          categories={categories}
          subcategories={subcategories}
          manufacturers={manufacturers}
          isFilteredWorkstationsSet={
            isPicker
              ? isFilteredOrganizationRegionFurnituresSet
              : isFilteredFurnituresSet
          }
          loadWorkstationCategories={loadFurnitureCategories}
          listUpdated={
            isPicker ? organizationRegionFurnituresUpdated : furnituresUpdated
          }
          filters={filters}
          setFilter={handleSetFilter}
          removeFilter={handleRemoveFilter}
          loadWorkstationSubcategories={loadFurnitureSubcategories}
          updateFilterValue={updateFilterValue}
          sort={{
            columns: ["sortOrder"],
            directions: ["asc"]
          }}
        />
      }
      initializeFilter={initializeFilter}
      onSave={isPicker ? attachEquipmentToRecommendation : handleSave}
      isPicker={isPicker}
      pickOneElement={pickOneElement}
      itemClassName={!isPicker ? "w-208" : ""}
    />
  );
};

export default AddFurnitureModal;
