import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
  name: "evaluations",
  initialState: {
    statuses: [],
    recheckTimeFramesReceived: [],
    commentTypes: [],
    type: [],
    appointmentMinutes: []
  },
  reducers: {
    evaluationStatusesReceived(evaluations: any, action: PayloadAction<any>) {
      if (action.payload && action.payload.rows) {
        evaluations.statuses = action.payload.rows[0].values;
      } else {
        evaluations.statuses = [];
      }
    },
    evaluationRecheckTimeFramesReceived(
      evaluations: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluations.recheckTimeFrames = payload.rows[0].values;
      } else {
        evaluations.recheckTimeFrames = [];
      }
    },
    evaluationCommentTypesReceived(
      evaluations: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluations.commentTypes = payload.rows[0].values;
      } else {
        evaluations.commentTypes = [];
      }
    },
    evaluationTypesReceived(evaluations: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluations.types = payload.rows[0].values;
      } else {
        evaluations.commentTypes = [];
      }
    },
    appointmentMinutesReceived(evaluations: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluations.appointmentMinutes = payload.rows[0].values;
      } else {
        evaluations.appointmentMinutes = [];
      }
    }
  }
});

export const {
  evaluationStatusesReceived,
  evaluationRecheckTimeFramesReceived,
  evaluationCommentTypesReceived,
  evaluationTypesReceived,
  appointmentMinutesReceived
} = slice.actions;
export default slice.reducer;

/**
 * Gets evaluation statuses.
 */
export const getEvaluationStatuses = createSelector(
  (state: any) => state.entities.catalogs.evaluations,
  (evaluations: any) => evaluations.statuses
);

/**
 * Gets evaluation recheck time frames.
 */
export const getEvaluationRecheckTimeFrames = createSelector(
  (state: any) => state.entities.catalogs.evaluations,
  (evaluations: any) => evaluations.recheckTimeFrames
);

/**
 * Gets evaluation comment types.
 */
export const getEvaluationCommentTypes = createSelector(
  (state: any) => state.entities.catalogs.evaluations,
  (evaluations: any) => evaluations.commentTypes
);

/**
 * Gets evaluation types.
 */
export const getCurrentEvaluationTypes = createSelector(
  (state: any) => state.entities.catalogs.evaluations,
  (evaluations: any) => evaluations.types
);

/**
 * Gets appointment minutes.
 */
export const getAppointmentMinutes = createSelector(
  (state: any) => state.entities.catalogs.evaluations,
  (evaluations: any) => evaluations.appointmentMinutes
);
