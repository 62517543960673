import React from "react";
import { IonRow, IonCol } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import { getIfNewSession, userLogged } from "../../../../../store/User";
import {
  getNoEquipmentSelected,
  getSelectedEquipmentIndex,
  getSelectedType,
  getSortedEnvironmentEquipments,
  setCurrentEquipment,
  setShowAddWorkstationEquipmentModal,
  setEquipmentIndexSelected,
  setEquipmentTypeSelected,
  setNoEquipmentSelected,
  getShowAddWorkstationEquipmentModal,
  idSelected,
  getCurrentShowEquipmentsSkeleton,
  updateEnvironmentEquipments,
  getCurrentSelectedEquipment
} from "../../../../../store/evaluation/environment/workstation/Equipments";

import SideMenuList from "../SideMenuList";
import FormsContainer from "./FormsContainer";
import AddEquipmentModal from "./AddEquipmentModal";
import { equipmentsUpdated } from "../../../../../store/catalogs/Equipments";

const EquipmentTab: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const environmentEquipments: any = useAppSelector(
    getSortedEnvironmentEquipments
  );
  const type: string = useAppSelector(getSelectedType);
  const isNewSession = useAppSelector(getIfNewSession);
  const noEquipmentSelected = useAppSelector(getNoEquipmentSelected);
  const openWorkstationEquipmentModal: any = useAppSelector(
    getShowAddWorkstationEquipmentModal
  );
  const currentShowEquipmentSkeleton = useAppSelector(
    getCurrentShowEquipmentsSkeleton
  );

  const openModal = () => {
    dispatch(setShowAddWorkstationEquipmentModal(true));
  };

  /**
   * Shows form component
   */
  const showFormComponent = async (equipment: any) => {
    if (isNewSession) {
      dispatch({
        type: userLogged.type,
        payload: false
      });
    }

    const currentEquipment = dispatch(getCurrentSelectedEquipment());

    dispatch({
      type: setCurrentEquipment.type,
      payload: currentEquipment
    });

    dispatch({
      type: setEquipmentTypeSelected.type,
      payload: equipment.type
    });

    dispatch({
      type: setNoEquipmentSelected.type,
      payload: false
    });
  };

  const resetList = (list: any) => {
    setTimeout(() => {
      dispatch({
        type: equipmentsUpdated.type,
        payload: list
      });
    }, 350);
  };

  return (
    <>
      <AddEquipmentModal
        isOpen={openWorkstationEquipmentModal}
        resetList={resetList}
      />
      <IonRow>
        <IonCol
          size-xxl="3"
          size-xl="3"
          size-lg="4"
          size-md="4"
          size-sm="4"
          size-sx="4"
          size="4"
          className="border-right y-height-50vh y-custom-child pl-00"
        >
          <SideMenuList
            list={environmentEquipments}
            openModal={openModal}
            showFormComponent={showFormComponent}
            buttonLabel={"ADD EQUIPMENT"}
            deleteLabel="equipment"
            getSelectedIndex={getSelectedEquipmentIndex}
            setIndexSelected={setEquipmentIndexSelected}
            workstationDeleteEndpoint="/workstationequipments"
            updateSortedList={updateEnvironmentEquipments}
            setNoWorkstationSelected={setNoEquipmentSelected}
            setWorstationTypeSelected={setEquipmentTypeSelected}
            idSelected={idSelected}
            showSkeleton={currentShowEquipmentSkeleton}
          />
        </IonCol>
        <IonCol className="y-height-100 pr-0">
          {environmentEquipments.length === 0 ? (
            <p>No Equipment Added for this Environment.</p>
          ) : (isNewSession && isNewSession) ||
            (noEquipmentSelected && environmentEquipments.length > 0) ? (
            <p>No Equipment Selected</p>
          ) : (
            <FormsContainer type={type} />
          )}
        </IonCol>
      </IonRow>
    </>
  );
};

export default EquipmentTab;
