import React, { useEffect, useState } from "react";
import { IonCheckbox, IonLabel } from "@ionic/react";

import { useAppSelector } from "../../../../../store/Hooks";
import { includes, without } from "lodash";

const KeyboardTypeCheckbox: React.FC<any> = (props: any) => {
  const { name, label, getCurrentWorkstation, updateKeyboardType } = props;
  const currentWorstation: any = useAppSelector(getCurrentWorkstation);
  const [value, setValue] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    let isChecked: any = includes(currentWorstation.types, name);
    setValue(isChecked);
  }, [currentWorstation && currentWorstation.id]);

  return (
    <div className={`flex ion-align-items-center ml-10 mr-10`}>
      <IonCheckbox
        name={name}
        checked={value}
        onClick={(e: any) => {
          setValue(e.target.checked);
          updateKeyboardType(e.target.name, e.target.checked);
        }}
        mode="md"
      ></IonCheckbox>
      <IonLabel>&nbsp;{label}</IonLabel>
    </div>
  );
};

export default KeyboardTypeCheckbox;
