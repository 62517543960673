import React from "react";

import AddRow from "../../../../common/controls/AddRow";
import AddButton from "../../../../common/controls/AddButton";

const TopBar: React.FC<any> = (props: any) => {
  const { onAddActivity } = props;

  return (
    <AddRow
      label="Activities"
      buttons={<AddButton label="ADD ACTIVITY" onClick={onAddActivity} />}
    />
  );
};

export default TopBar;
