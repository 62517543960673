import axios from "axios";
import { makeApiCall, makeRequest } from "../helpers/global.helper";
const apiBaseUrl = process.env.REACT_APP_BIDB_API_BASE_URL;
const workersApi = process.env.REACT_APP_BIDB_API_WORKERS;

// Gets worker by Id
export const getWorkerById =
  (id: number) => async (dispatch: any, getState: any) => {
    return await dispatch(
      makeRequest({
        endpoint: `/workers/${id}`,
        method: "GET"
      })
    );
  };

// Gets worker by Id
export const getWorkers =
  (params: any = {}) =>
  async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/workers`,
        method: "GET",
        data: {},
        parameters: params
      })
    );

    return response;
  };

/**
 * Patches worker
 * @param data
 * @returns
 */
export const patchWorker =
  (data: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `${workersApi}`,
        method: "PATCH",
        data: data
      })
    );

    console.log(response.status);
  };

/**
 * Post worker
 * @param data
 * @returns
 */
export const postWorker = async (data: any) => {
  const response = await axios.post(`${apiBaseUrl}${workersApi}`, data);

  return response.data;
};
