import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";

const slice = createSlice({
  name: "environmentRisks",
  initialState: {
    environmentRisks: [],
    showAddRiskModal: false,
    selectedRiskIndex: -1,
    currentRisk: undefined,
    sortedList: [],
    isFilteredList: false,
    filters: {
      category: "",
      subCategory: ""
    },
    total: 0,
    recommendations: [],
    environments: [],
    list: [],
    acceptDeleteRecommendation: false,
    showCheckboxLoader: false,
    linkedRecommendations: []
  },
  reducers: {
    environmentRisksReceived(
      environmentRisks: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;
      // The second one is used to initialize main list.
      if (payload.rows && payload.rows.length > 0) {
        environmentRisks.environmentRisks = payload.rows;
      } else {
        environmentRisks.environmentRisks = [];
      }
    },
    setShowAddRiskModal(environmentRisks: any, action: PayloadAction<any>) {
      environmentRisks.showAddRiskModal = action.payload;
    },
    currentRiskSet(environmentRisks: any, action: PayloadAction<any>) {
      environmentRisks.currentRisk = action.payload;
    },
    currentRiskUpdated(environmentRisks: any, action: PayloadAction<any>) {
      environmentRisks.currentRisk[`${action.payload.key}`] =
        action.payload.value;
    },
    environmentRisksUpdated(environmentRisks: any, action: PayloadAction<any>) {
      environmentRisks.environmentRisks = action.payload;
      environmentRisks.list = action.payload;
      environmentRisks.total = action.payload.length;
    },
    setNoEquipmentSelected(
      workstationEquipments: any,
      action: PayloadAction<any>
    ) {
      workstationEquipments.noEquipmentSelected = action.payload;
    },
    filtersUpdated(environmentRisks: any, action: PayloadAction<any>) {
      environmentRisks.filters[action.payload.key] = action.payload.value;
    },
    isFilteredRisksSet(workstationEquipments: any, action: PayloadAction<any>) {
      workstationEquipments.isFilteredList = action.payload;
    },
    riskRecommendationsReceived(
      environmentRisks: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;
      if (payload.rows && payload.rows.length > 0) {
        environmentRisks.recommendations = payload.rows;
      } else {
        environmentRisks.recommendations = [];
      }
    },
    riskRecommendationsUpdated(
      environmentRisks: any,
      action: PayloadAction<any>
    ) {
      environmentRisks.recommendations = action.payload;
    },
    riskEnvironmentsReceived(
      environmentRisks: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;
      if (payload.rows && payload.rows.length > 0) {
        environmentRisks.environments = payload.rows;
      } else {
        environmentRisks.environments = [];
      }
    },
    riskEnvironmentsUpdated(environmentRisks: any, action: PayloadAction<any>) {
      environmentRisks.environments = action.payload;
    },
    totalEnvironmentRisksUpdated(
      environmentRisks: any,
      action: PayloadAction<any>
    ) {
      environmentRisks.total = action.payload;
    },
    acceptDeleteRecommendationUpdated(
      environmentRisks: any,
      action: PayloadAction<any>
    ) {
      environmentRisks.acceptDeleteRecommendation = action.payload;
    },
    showCheckboxLoaderUpdated(
      environmentRisks: any,
      action: PayloadAction<any>
    ) {
      environmentRisks.showCheckboxLoader = action.payload;
    },
    linkedRecommendationsUpdated(
      environmentRisks: any,
      action: PayloadAction<any>
    ) {
      environmentRisks.linkedRecommendations = action.payload;
    }
  }
});

export const {
  environmentRisksReceived,
  environmentRisksUpdated,
  filtersUpdated,
  isFilteredRisksSet,
  setShowAddRiskModal,
  riskRecommendationsReceived,
  riskRecommendationsUpdated,
  riskEnvironmentsReceived,
  riskEnvironmentsUpdated,
  totalEnvironmentRisksUpdated,
  acceptDeleteRecommendationUpdated,
  showCheckboxLoaderUpdated,
  linkedRecommendationsUpdated
} = slice.actions;
export default slice.reducer;

/**
 * Gets current linked recommendations.
 */
export const getCurrentLinkedRecommendations = createSelector(
  (state: any) => state.evaluation.environment.risks,
  (risks: any) => risks.linkedRecommendations
);

/**
 * Gets current showChekboxLoader.
 */
export const getCurrentShowCheckboxLoader = createSelector(
  (state: any) => {
    return state.evaluation.environment.risks;
  },
  (risks: any) => risks.showCheckboxLoader
);

/**
 * Gets current accept delere recommendation.
 * @returns
 */
export const getCurrentAcceptDeleteRecommendation =
  () => (dispatch: any, getState: any) => {
    return getState().evaluation.environment.risks.acceptDeleteRecommendation;
  };

/**
 * Gets current total environment risks.
 * @returns
 */
export const getCurrentTotalEnvironmentRisks =
  (
    currentEvaluationRisks: any = undefined,
    currentEnvironmentRisks: any = undefined
  ) =>
  (dispatch: any, getState: any) => {
    if (
      currentEvaluationRisks != undefined &&
      currentEnvironmentRisks != undefined
    ) {
      const total = currentEvaluationRisks.filter((risk: any) =>
        currentEnvironmentRisks
          ? currentEnvironmentRisks.filter((env: any) => env.idRisk === risk.id)
              .length > 0
          : false
      ).length;

      return total;
    } else {
      return 0;
    }
  };

/**
 * Gets environment risks.
 */
export const getEnvironmentRisks = createSelector(
  (state: any) => {
    return state.evaluation.environment.risks;
  },
  (risks: any) => risks.environmentRisks
);

/**
 * Gets risk filters.
 */
export const getRiskFilters = createSelector(
  (state: any) => state.evaluation.environment.risks,
  (risks: any) => risks.filters
);

/**
 * Gets show add risk modal.
 */
export const getShowAddRiskModal = createSelector(
  (state: any) => state.evaluation.environment.risks,
  (risks: any) => risks.showAddRiskModal
);

export const getIsFilteredRiskVs = createSelector(
  (state: any) => state.evaluation.environment.risks,
  (risks: any) => risks.isFilteredList
);

/**
 * Gets total environment risks.
 */
export const getTotalEnvironmentRisks = createSelector(
  (state: any) => {
    return state.evaluation.environment.risks;
  },
  (risks: any) => risks.total
);

/**
 * Gets current risk recommendations.
 */
export const getCurrentRiskRecommendations = createSelector(
  (state: any) => {
    return state.evaluation.environment.risks;
  },
  (risks: any) => risks.recommendations
);

/**
 * Gets current risk environments.
 */
export const getCurrentRiskEnvironments = createSelector(
  (state: any) => {
    return state.evaluation.environment.risks;
  },
  (risks: any) => risks.environments
);

/**
 * Gets current risk environments.
 */
export const getCurrentRiskEnvironmentsInRuntime =
  () => (dispatch: any, getState: any) => {
    return getState().evaluation.environment.risks.environments;
  };

/**
 * Updates total environment risks.
 * @returns
 */
export const updateTotalEnvironmentRisks =
  (currentEvaluationRisks: any) => (dispatch: any, getState: any) => {
    const currentEnvironmentRisks =
      getState().evaluation.environment.risks.environmentRisks;
    const environmentRisks: any = currentEvaluationRisks.filter((risk: any) =>
      currentEnvironmentRisks
        ? currentEnvironmentRisks.filter((env: any) => env.idRisk === risk.id)
            .length > 0 || risk.id === undefined
        : false
    );

    dispatch({
      type: totalEnvironmentRisksUpdated.type,
      payload: environmentRisks.length
    });
  };
