import React, { useEffect, useState } from "react";
import { IonCol, IonRow, IonLabel, IonButton } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";
import {
  getCurrentEquipment,
  getLaptopIds
} from "../../../../../store/evaluation/environment/workstation/Equipments";

import SelectForm from "../../../../common/controls/SelectForm";
import Name from "../../../../common/controls/Name";
import Input from "../../../../common/controls/Input";
import YesNoInput from "../../../../common/controls/YesNoInput";
import Emea from "../../../../common/controls/Emea";

import "../../../../../css/equipment.css";
import { getListByName } from "../../../../../store/catalogs/ValueList";
import Checkbox from "../workstations/Checkbox";

const PointingDeviceForm: React.FC<any> = (props) => {
  const { updateValue, getSelectedText, hasLaptopValue } = props;

  const dispatch = useAppDispatch();
  const data = useAppSelector(getCurrentEquipment);
  const pointingDeviceUsageRanking = dispatch(
    getListByName("Pointing_Device_Usage_Ranking")
  );

  const [laptopIds] = useState<any>(dispatch(getLaptopIds()));
  const [label, setLabel] = useState<string | null>(null);
  const [laptopId, setLaptopId] = useState<number | null>();
  const [handUsed, setHandUsed] = useState<string | null>();
  const [usageRanking, setUsageRanking] = useState<string | null>();

  useEffect(() => {
    setLaptopId(
      data &&
        data &&
        data.idLaptopEquipment &&
        data.idLaptopEquipment !== null &&
        parseInt(data.idLaptopEquipment)
    );
    setLabel(data && data.label);
    setHandUsed(data && data.whichHand);
    setUsageRanking(data && data.usageRanking);
  }, [data && data.id]);

  return (
    <>
      <IonRow>
        <Name nameLabel="Pointing Device Name" value={data && data.name} />
      </IonRow>
      <IonRow className="pt-3 top-bar">
        <IonCol size="6" className="pt-09 equipment-label">
          <Input
            name="label"
            value={label}
            label="Label"
            onBlur={(key: string, value: any) => {
              setLabel(value);
              updateValue(key, value);
            }}
            maxlength={16}
          />
        </IonCol>
        <IonCol size="6">
          <SelectForm
            label="Laptop"
            options={laptopIds}
            value={laptopId}
            name="idLaptopEquipment"
            selectedText={getSelectedText(laptopIds, laptopId)}
            onChange={(key: string, value: any) => {
              if (value && value !== null && value !== 0) {
                setLaptopId(value);
                updateValue(key, parseInt(value));
              }
            }}
            keyValue={{
              key: "id",
              value: "label"
            }}
            clearValue={() => {
              setLaptopId(null);
              updateValue("idLaptopEquipment", null);
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="4"
          size-md="4"
          size-sm="4"
          size-sx="4"
          size="4"
        >
          <IonRow>
            <IonLabel className="form-group-input-text-title single-label line-height-2">
              Hand Used
            </IonLabel>
          </IonRow>
          <IonRow className="no-padding">
            <IonCol size="4" className="no-padding to-left">
              <IonButton
                onClick={() => {
                  let value: string | null = "Left";

                  if (handUsed === "Left") {
                    value = null;
                  }
                  setHandUsed(value);
                  updateValue("whichHand", value);
                }}
                className={
                  "btn no-maring btn-group-1 " +
                  (handUsed?.toLowerCase() === "left"
                    ? "positive"
                    : "negative") +
                  " font-bold"
                }
              >
                LEFT
              </IonButton>
            </IonCol>
            <IonCol size="4" className="no-padding to-center">
              <IonButton
                onClick={() => {
                  let value: string | null = "Right";

                  if (handUsed === "Right") {
                    value = null;
                  }

                  setHandUsed(value);
                  updateValue("whichHand", value);
                }}
                className={
                  "btn no-maring btn-group-1 " +
                  (handUsed?.toLowerCase() === "right"
                    ? "positive"
                    : "negative") +
                  " font-bold"
                }
              >
                RIGHT
              </IonButton>
            </IonCol>
            <IonCol size="4" className="no-padding to-right">
              <IonButton
                onClick={() => {
                  let value: string | null = "Both";

                  if (handUsed === "Both") {
                    value = null;
                  }

                  setHandUsed(value);
                  updateValue("whichHand", value);
                }}
                className={
                  "btn no-maring btn-group-1 " +
                  (handUsed?.toLowerCase() === "both"
                    ? "positive"
                    : "negative") +
                  " font-bold"
                }
              >
                BOTH
              </IonButton>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="8"
          size-md="8"
          size-sm="8"
          size-sx="8"
          size="8"
          className="ion-align-items-end"
        >
          <SelectForm
            label="Usage Ranking"
            options={pointingDeviceUsageRanking}
            value={usageRanking}
            name="usageRanking"
            onChange={(key: string, value: any) => {
              if (value) {
                setUsageRanking(value);
                updateValue(key, value);
              }
            }}
            clearValue={() => {
              setUsageRanking(null);
              updateValue("usageRanking", null);
            }}
          />
        </IonCol>
      </IonRow>

      <hr className="separator top-var" />
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesWristRest"
          label="Uses Wristrest"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonCol
          size-xxl="6"
          size-xl="6"
          size-lg="8"
          size-md="8"
          size-sm="8"
          size-sx="8"
          size="8"
        >
          <IonRow>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="wristrestAdhoc"
                label="Adhoc"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
            <IonCol className="aligned-middle to-left">
              <Checkbox
                name="wristrestDesignated"
                label="Designated"
                onChange={updateValue}
                getCurrentWorkstation={getCurrentEquipment}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <hr className="separator" />
      <Emea></Emea>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="appropriatelyPositioned"
          label="Appropriately Positioned"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="movesSmoothly"
          label="Moves Smoothly"
          onChange={updateValue}
        />
      </IonRow>
      <IonRow className="top-2">
        <YesNoInput
          data={data}
          property="usesSeparatePointingDevice"
          label="Uses Separate Pointing Device"
          onChange={updateValue}
        />
      </IonRow>
    </>
  );
};

export default PointingDeviceForm;
