import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
  name: "workers",
  initialState: {
    locationDuringEvaluations: [],
    handDominance: []
  },
  reducers: {
    workerLocationDuringEvaluationsReceived(
      workers: any,
      action: PayloadAction<any>
    ) {
      if (action.payload && action.payload.rows) {
        workers.locationDuringEvaluations = action.payload.rows[0].values;
      } else {
        workers.locationDuringEvaluations = [];
      }
    },
    workerHandDominanceReceived(workers: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        workers.handDominance = payload.rows[0].values;
      } else {
        workers.handDominance = []
      }
    },
  }
});

export const { workerLocationDuringEvaluationsReceived, workerHandDominanceReceived } = slice.actions;
export default slice.reducer;

export const getWorkerLocationDuringEvaluations = createSelector(
  (state: any) => state.entities.catalogs.workers,
  (workers: any) => workers.locationDuringEvaluations
);

/**
 * Gets hand dominance
 */
 export const getWorkerHandDominance = createSelector(
  (state: any) => state.entities.catalogs.workers,
  (workers: any) => workers.handDominance
);
