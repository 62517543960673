import React, { useState, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import {
  getCurrentEnvironmentIssues,
  getCurrentTotalEnvironmentIssues
} from "../../../store/evaluation/environment/Issues";
import {
  getCurrentEnvironmentActivities,
  getCurrentTotalEnvironmentActivities
} from "../../../store/evaluation/environment/Activities";
import {
  getCurrentTotalEnvironmentEquipments,
  getSortedEnvironmentEquipments
} from "../../../store/evaluation/environment/workstation/Equipments";
import { getCurrentTotalEnvironmentFurnitures } from "../../../store/evaluation/environment/workstation/Furnitures";
import {
  getCurrentTotalEnvironmentRisks,
  getEnvironmentRisks
} from "../../../store/evaluation/environment/Risks";
import {
  getCurrentEnvironmentRecommendations,
  getCurrentTotalEnvironmentRecommendations
} from "../../../store/evaluation/environment/Recommendations";

import SubItem from "./SubItem";
import { getEvaluationIssues } from "../../../store/evaluation/Issues";
import { getActivities } from "../../../store/evaluation/Activities";
import { getEvaluationRisks } from "../../../store/evaluation/Risks";
import { getRecommendations } from "../../../store/evaluation/Recommendations";

const SubMenu: React.FC<any> = (props: any) => {
  const { onClick } = props;

  const dispatch = useAppDispatch();

  const currentEvaluationIssues = useAppSelector(getEvaluationIssues);
  const currentEnvironmentIssues = useAppSelector(getCurrentEnvironmentIssues);
  const currentTotalIssues = dispatch(
    getCurrentTotalEnvironmentIssues(
      currentEvaluationIssues,
      currentEnvironmentIssues
    )
  );

  const currentEvaluationActivities = useAppSelector(getActivities);
  const currentEnvironmentActivities = useAppSelector(
    getCurrentEnvironmentActivities
  );
  const currentTotalActivities = dispatch(
    getCurrentTotalEnvironmentActivities(
      currentEvaluationActivities,
      currentEnvironmentActivities
    )
  );

  const currentEnvironmentEquipments = useAppSelector(
    getSortedEnvironmentEquipments
  );
  const currentTotalEquipments = dispatch(
    getCurrentTotalEnvironmentEquipments(currentEnvironmentEquipments)
  );
  const currentTotalFurnitures = dispatch(
    getCurrentTotalEnvironmentFurnitures()
  );

  const currentEvaluationRisks = useAppSelector(getEvaluationRisks);
  const currentEnvironmentRisks = useAppSelector(getEnvironmentRisks);
  const currentTotalRisks = dispatch(
    getCurrentTotalEnvironmentRisks(
      currentEvaluationRisks,
      currentEnvironmentRisks
    )
  );

  const currentEvaluationRecommendations = useAppSelector(getRecommendations);
  const currentEnvironmentRecommendations = useAppSelector(
    getCurrentEnvironmentRecommendations
  );
  const currentTotalRecommendations = dispatch(
    getCurrentTotalEnvironmentRecommendations(
      currentEvaluationRecommendations,
      currentEnvironmentRecommendations
    )
  );
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    setOptions([
      {
        label: "Issues",
        total: currentTotalIssues
      },
      {
        label: "Activities",
        total: currentTotalActivities
      },
      {
        label: "Equipment",
        total: currentTotalEquipments
      },
      {
        label: "Furniture",
        total: currentTotalFurnitures
      },
      {
        label: "Risks",
        total: currentTotalRisks
      },
      {
        label: "Recommendations",
        total: currentTotalRecommendations
      }
    ]);
  }, [
    currentTotalIssues,
    currentTotalActivities,
    currentTotalEquipments,
    currentTotalFurnitures,
    currentTotalRisks,
    currentTotalRecommendations
  ]);

  return (
    options &&
    options.map((option: any, index: number) => (
      <SubItem
        key={index}
        label={option.label}
        total={option.total}
        onClick={() => onClick(index)}
      />
    ))
  );
};

export default SubMenu;
