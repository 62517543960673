import React from "react";
import {
  IonButton,
  IonList,
  IonItem,
  IonImg,
  IonLabel,
  useIonAlert,
  IonSkeletonText
} from "@ionic/react";

import { useAppDispatch, useAppSelector } from "../../store/Hooks";

import {
  deleteWorkstation,
  iconImages,
  icons
} from "../../services/tabs.service";

import classNames from "classnames";
import { findIndex } from "lodash";

const BaseSideMenuList: React.FC<any> = (props: any) => {
  const {
    list,
    showFormComponent,
    showTopButton,
    buttonLabel,
    buttonClasses,
    openModal,
    deleteLabel,
    workstationDeleteEndpoint,
    updateSortedList,
    setNoWorkstationSelected,
    getSelectedIndex,
    setIndexSelected,
    setWorstationTypeSelected,
    resetList,
    idSelected,
    showSkeleton
  } = props;

  const dispatch = useAppDispatch();
  const currentSelectedIndex = useAppSelector(
    getSelectedIndex && getSelectedIndex
  );

  const [present] = useIonAlert();

  const handleOnClick = (item: any, index: number) => {
    if (idSelected) {
      dispatch({
        type: idSelected.type,
        payload: item.id
      });
    }

    dispatch({
      type: setIndexSelected.type,
      payload: index
    });
    showFormComponent(item);
  };

  const deleteItem = async (item: any) => {
    dispatch(deleteWorkstation(`${workstationDeleteEndpoint}/${item.id}`));

    const temp = [...list];
    const index = findIndex(list, { id: item.id });

    if (index !== -1) {
      temp.splice(index, 1);
      dispatch(updateSortedList(temp));

      dispatch({
        type: setNoWorkstationSelected.type,
        payload: true
      });

      dispatch({
        type: setWorstationTypeSelected.type,
        payload: ""
      });

      dispatch({
        type: setIndexSelected.type,
        payload: -1
      });
    }

    if (resetList) {
      resetList();
    }
  };

  return (
    <>
      {showTopButton && (
        <IonButton onClick={() => openModal()} className={buttonClasses}>
          {buttonLabel}
        </IonButton>
      )}
      <IonList>
        {showSkeleton && (
          <>
            <IonItem>
              <IonSkeletonText animated />
            </IonItem>
            <IonItem>
              <IonSkeletonText animated style={{ width: "75%" }} />
            </IonItem>
            <IonItem>
              <IonSkeletonText animated style={{ width: "50%" }} />
            </IonItem>
            <IonItem>
              <IonSkeletonText animated style={{ width: "25%" }} />
            </IonItem>
          </>
        )}
        {!showSkeleton &&
          list &&
          list.map((item: any, index: number) => {
            return (
              <IonItem
                key={index}
                button
                className={classNames("justify-content", {
                  "item-active": currentSelectedIndex === index
                })}
                onClick={() => handleOnClick(item, index)}
                detail={false}
              >
                <IonImg src={icons[item.type.toLowerCase()]}></IonImg>
                <IonLabel className="text-list-item ml-12">
                  {item.label}
                </IonLabel>
                <IonImg
                  src={iconImages["deleteIcon"]}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    present({
                      cssClass: "my-css",
                      message: `Are you sure that you want to delete this ${deleteLabel}?`,
                      buttons: [
                        "Cancel",
                        {
                          text: "Ok",
                          handler: () => {
                            deleteItem(item);
                          }
                        }
                      ]
                    });
                  }}
                ></IonImg>
              </IonItem>
            );
          })}
      </IonList>
    </>
  );
};

export default BaseSideMenuList;
