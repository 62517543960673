import React from "react";
import { IonRow, IonCol } from "@ionic/react";

import { useAppDispatch } from "../../../../../store/Hooks";

import Category from "../add-modal/filters/Category";
import SubCategory from "../add-modal/filters/SubCategory";
import Manufacturer from "../add-modal/filters/Manufacturer";
import Search from "../add-modal/filters/Search";

const Filters: React.FC<any> = (props: any) => {
  const {
    originalList,
    categories,
    subcategories,
    manufacturers,
    isFilteredWorkstationsSet,
    listUpdated,
    filters,
    setFilter,
    removeFilter,
    sort,
    loadItems, // This is used for pickers.
    loadWorkstationSubcategories,
    updateFilterValue
  } = props;
  const dispatch = useAppDispatch();

  const setFilterValue = async (
    key: string,
    value: string,
    contains: boolean = false
  ) => {
    if (value && value !== "") {
      setFilter(key, value, contains);

      if (key === "category") {
        loadWorkstationSubcategories(value);
      }
    }
  };

  const handleIsFilterdList = (currentKey: string) => {
    let isFilteredList = false;

    for (const [key, value] of Object.entries(filters)) {
      let val: any = value;

      if (key !== currentKey) {
        if (val.value !== "") {
          isFilteredList = true;
        } else {
          isFilteredList = false;
        }
      }
    }

    if (!isFilteredList) {
      dispatch({
        type: isFilteredWorkstationsSet.type,
        payload: false
      });
    }
  };

  const updateList = (filtered: any, isFiltered: boolean = true) => {
    dispatch({
      type: listUpdated.type,
      payload: filtered
    });

    if (isFiltered) {
      dispatch({
        type: isFilteredWorkstationsSet.type,
        payload: isFiltered
      });
    }
  };

  return (
    <>
      <IonRow className="pt-4">
        <IonCol size="4">
          <Category
            categories={categories}
            originalList={originalList}
            listFiltered={listUpdated}
            setIsFilteredList={isFilteredWorkstationsSet}
            category={filters && filters.category && filters?.category.value}
            setFilterValue={setFilterValue}
            filters={filters}
            sort={sort}
            loadItems={loadItems}
            placeholder="Select category"
            removeFilter={removeFilter}
          />
        </IonCol>
        <IonCol size="4">
          <SubCategory
            subcategories={subcategories}
            originalList={originalList}
            listFiltered={listUpdated}
            setIsFilteredList={isFilteredWorkstationsSet}
            subCategory={
              filters && filters.subCategory && filters?.subCategory.value
            }
            setFilterValue={setFilterValue}
            filters={filters}
            sort={sort}
            placeholder="Select subcategory"
            removeFilter={removeFilter}
          />
        </IonCol>
        <IonCol size="4">
          <Manufacturer
            manufacturers={manufacturers}
            originalList={originalList}
            listFiltered={listUpdated}
            setIsFilteredList={isFilteredWorkstationsSet}
            manufacturer={
              filters && filters.manufacturer && filters?.manufacturer.value
            }
            setFilterValue={setFilterValue}
            filters={filters}
            sort={sort}
            placeholder="Select manufacturer"
            removeFilter={removeFilter}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Search
            name="search"
            label="Search"
            placeholder="Name, Description"
            list={originalList}
            filters={filters}
            sort={sort}
            fields={["name", "description"]}
            updateFilterValue={updateFilterValue}
            updateList={updateList}
            isFilteredList={handleIsFilterdList}
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default Filters;
