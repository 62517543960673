import _ from "lodash";
import React, { useEffect, useState } from "react";
import { removeObjectFromList } from "../../../../../helpers/global.helper";
import { deleteIssueRecommendation } from "../../../../../services/issue.service";
import { deleteRecommendation } from "../../../../../services/recommendations.service";

import { getCurrentIssueEnvironments } from "../../../../../store/Evaluation";
import { getCurrentEvaluationEnvironment } from "../../../../../store/evaluation/Current";
import {
  getRecommendationsIssues,
  recommendationsIssuesUpdated
} from "../../../../../store/evaluation/environment/Recommendations";
import { getRecommendations } from "../../../../../store/evaluation/Recommendations";
import { useAppDispatch, useAppSelector } from "../../../../../store/Hooks";

import Band from "./Band";
import Recommendation from "./Recommendation";

const RecommendationRow: React.FC<any> = (props) => {
  const {
    data,
    onClickRecommendation,
    recommendationIdExpanded,
    recommendationEnvironment,
    environments,
    // issues
    handleOpenIssuePicker,
    recommendations,
    updateRecommendationsList,
    setShowEnvironmentModal,
    index,
    getRiskvsName,
    handleShowRiskModal
  } = props;

  const dispatch = useAppDispatch();

  const currentIssueEnvironments = useAppSelector(getCurrentIssueEnvironments);
  const [environmentsChecked, setEnvironmentsChecked] = useState<any>([]);
  const currentRecommendationsIssues = useAppSelector(getRecommendationsIssues);

  const [reasons, setReasons] = useState([]);
  const currentEnvironment = useAppSelector(getCurrentEvaluationEnvironment);
  const currentRecommendations = useAppSelector(getRecommendations);

  useEffect(() => {
    if (currentIssueEnvironments) {
      let environmentsChecked = currentIssueEnvironments.map(
        (env: any) => env.idEnvironment
      );
      setEnvironmentsChecked(environmentsChecked);
    }
  }, [currentIssueEnvironments]);

  useEffect(() => {
    setReasons(data && data.reasons ? data.reasons : []);
  }, [
    data && data.reasons,
    currentEnvironment && currentEnvironment.id,
    currentRecommendations.length,
    recommendationIdExpanded
  ]);

  const handleDeleteRecommendation = async (recommendationId: any) => {
    dispatch(deleteRecommendation(recommendationId));

    let newList: any;
    newList = await removeObjectFromList(recommendations, {
      id: recommendationId
    });

    updateRecommendationsList(newList);
  };

  const handleDeleteIssueRecommendations = async (issue: any) => {
    let response: any;

    try {
      dispatch(deleteIssueRecommendation(issue.id));
    } catch (e: any) {
      response = e.response;
    }

    let temp = [...currentRecommendationsIssues];
    const index = _.findIndex(temp, { id: issue.id });
    temp.splice(index, 1);

    dispatch({
      type: recommendationsIssuesUpdated.type,
      payload: temp
    });
  };

  const isRecommendationIdExpanded = () => data.id === recommendationIdExpanded;

  const handleRecommendationClick = () => onClickRecommendation(data);

  const setRecommendationIcon = (reasons: any) => {
    setReasons(reasons);
  };

  return (
    <>
      <Band
        isRowExpanded={isRecommendationIdExpanded}
        onClick={handleRecommendationClick}
        item={data}
        deleteRow={handleDeleteRecommendation}
        index={index}
        reasons={reasons}
      />
      {isRecommendationIdExpanded() && (
        <Recommendation
          data={data}
          environmentsChecked={environmentsChecked}
          recommendationEnvironment={recommendationEnvironment}
          handleOpenIssuePicker={handleOpenIssuePicker}
          recommendationIdExpanded={recommendationIdExpanded}
          environments={environments}
          handleDeleteRecommendationIssues={handleDeleteIssueRecommendations}
          setShowEnvironmentModal={setShowEnvironmentModal}
          getRiskvsName={getRiskvsName}
          handleShowRiskModal={handleShowRiskModal}
          setRecommendationIcon={setRecommendationIcon}
        />
      )}
    </>
  );
};

export default RecommendationRow;
