import React from "react";

const FurnitureWrapper: React.FC<any> = (props) => {
  const { children, onStart, onEnd } = props;

  return (
    <div
      className="furniture-wrapper relative -z-1"
      onMouseDown={onStart}
      onMouseLeave={onEnd}
    >
      {children}
    </div>
  );
};

export default FurnitureWrapper;
