import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _, { filter, map, uniqBy } from "lodash";

const slice = createSlice({
  name: "equipments",
  initialState: {
    laptopDisplayPercentUsed: [],
    laptopPositionHorizontal: [],
    laptopDisplaySizes: [],
    laptopPositionVertical: [],
    categories: [],
    sortedCategories: [],
    pointingDeviceTypes: [],
    displayPositions: [],
    displaySizes: [],
    displayPercentUsed: [],
    displayTechnologies: [],
    list: [],
    originalList: [],
    filteredList: [],
    pointingDeviceUsageRanking: [],
    keyboardDeviceTrayType: [],
    subCategories: [],
    originalSubCategories: [],
    equipmentFilters: {
      category: {
        sign: "=",
        value: ""
      },
      subCategory: {
        sign: "=",
        value: ""
      },
      name: {
        sign: "contains",
        value: ""
      },
      manufacturer: {
        sign: "=",
        value: ""
      }
    }
  },
  reducers: {
    laptopDisplayPercentUsedReceived(
      equipments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.laptopDisplayPercentUsed = payload.rows[0].values;
      } else {
        equipments.laptopDisplayPercentUsed = [];
      }
    },
    laptopPositionHorizontalReceived(
      equipments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.laptopPositionHorizontal = payload.rows[0].values;
      } else {
        equipments.laptopPositionHorizontal = [];
      }
    },
    laptopDisplaySizesReceived(equipments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.laptopDisplaySizes = payload.rows[0].values;
      } else {
        equipments.laptopDisplaySizes = [];
      }
    },
    laptopPositionVerticalReceived(
      equipments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.laptopPositionVertical = payload.rows[0].values;
      } else {
        equipments.laptopPositionVertical = [];
      }
    },
    equipmentCategoriesReceived(equipments: any, action: PayloadAction<any>) {
      equipments.categories = action.payload;
    },
    equipmentCategoriesSorted(catalogs: any, action: PayloadAction<any>) {
      catalogs.sortedCategories = action.payload;
    },
    pointingDeviceTypesReceived(equipments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.pointingDeviceTypes = payload.rows[0].values;
      } else {
        equipments.pointingDeviceTypes = [];
      }
    },
    displayPositionsReceived(equipments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.displayPositions = payload.rows[0].values;
      } else {
        equipments.displayPositions = [];
      }
    },
    displaySizesReceived(equipments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.displaySizes = payload.rows[0].values;
      } else {
        equipments.displaySizes = [];
      }
    },
    displayPercentUsedReceived(equipments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.displayPercentUsed = payload.rows[0].values;
      } else {
        equipments.displayPercentUsed = [];
      }
    },
    displayTechnologiesReceived(equipments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.displayTechnologies = payload.rows[0].values;
      } else {
        equipments.displayTechnologies = [];
      }
    },
    equipmentsReceived(equipments: any, action: PayloadAction<any>) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.list = payload.rows;
        equipments.originalList = payload.rows;
      } else {
        equipments.list = [];
        equipments.originalList = [];
      }
    },
    equipmentsUpdated(equipments: any, action: PayloadAction<any>) {
      equipments.list = action.payload;
    },
    equipmentsFiltered(equipments: any, action: PayloadAction<any>) {
      equipments.filteredList = action.payload;
    },
    pointingDeviceUsageRankingReceived(
      equipments: any,
      action: PayloadAction<any>
    ) {
      if (action.payload.rows && action.payload.rows.length > 0) {
        equipments.pointingDeviceUsageRanking = action.payload.rows[0].values;
      } else {
        equipments.pointingDeviceUsageRanking = [];
      }
    },
    keyboardDeviceTrayTypeReceived(
      equipments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.keyboardDeviceTrayType = payload.rows[0].values;
      } else {
        equipments.keyboardDeviceTrayType = [];
      }
    },
    tabletComputerPositionsReceived(
      equipments: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        equipments.tabletComputerPositions = payload.rows[0].values;
      } else {
        equipments.tabletComputerPositions = [];
      }
    },
    equipmentFilterUpdated(equipments: any, action: PayloadAction<any>) {
      equipments.equipmentFilters[action.payload.key].value =
        action.payload.value;
    },
    equipmentSubCategoriesReceived(
      equipments: any,
      action: PayloadAction<any>
    ) {
      equipments.subCategories = action.payload;
      equipments.originalSubCategories = action.payload;
    },
    filterUpdate(equipments: any, action: PayloadAction<any>) {
      equipments.equipmentFilters[action.payload.key].value =
        action.payload.value;
    },
    equipmentSubCategoriesUpdated(equipments: any, action: PayloadAction<any>) {
      equipments.subCategories = action.payload;
    },
    equipmentSubCategoriesReset(equipments: any, action: PayloadAction<any>) {
      equipments.subCategories = action.payload;
    }
  }
});

export const {
  laptopDisplayPercentUsedReceived,
  laptopPositionHorizontalReceived,
  laptopDisplaySizesReceived,
  laptopPositionVerticalReceived,
  equipmentCategoriesReceived,
  equipmentCategoriesSorted,
  pointingDeviceTypesReceived,
  displayPositionsReceived,
  displaySizesReceived,
  displayPercentUsedReceived,
  displayTechnologiesReceived,
  equipmentsReceived,
  equipmentsUpdated,
  equipmentsFiltered,
  pointingDeviceUsageRankingReceived,
  keyboardDeviceTrayTypeReceived,
  tabletComputerPositionsReceived,
  equipmentFilterUpdated,
  filterUpdate,
  equipmentSubCategoriesReceived,
  equipmentSubCategoriesUpdated,
  equipmentSubCategoriesReset
} = slice.actions;
export default slice.reducer;

/**
 * Gets equipments.
 */
export const getEquipments = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => {
    return equipments.list;
  }
);

/**
 * Gets laptop display percent used.
 */
export const getLaptopDisplayPercentUsed = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.laptopDisplayPercentUsed
);

/**
 * Gets laptop position horizontal.
 */
export const getLaptopPositionHorizontal = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.laptopPositionHorizontal
);

/**
 * Gets laptop display sizes.
 */
export const getLaptopDisplaySizes = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.laptopDisplaySizes
);

/**
 * Gets laptop position vertical.
 */
export const getLaptopPositionVertical = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.laptopPositionVertical
);

/**
 * Gets equipment categories.
 */
export const getEquipmentCategories = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.categories
);

/**
 * Gets pointing device types.
 */
export const getPointingDeviceTypes = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.pointingDeviceTypes
);

/**
 * Gets display position.
 */
export const getDisplayPositions = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.displayPositions
);

/**
 * Gets display sizes.
 */
export const getDisplaySizes = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.displaySizes
);

/**
 * Gets display percen used.
 */
export const getDisplayPercentUsed = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.displayPercentUsed
);

/**
 * Gets display technologies.
 */
export const getDisplayTechnologies = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.displayTechnologies
);

/**
 * Loads equipment categories.
 * @returns
 */
export const loadEquipmentCategories =
  (categories: any = []) =>
  (dispatch: any, getState: any) => {
    const equipmentCategories: Array<any> =
      categories.length === 0
        ? [...getState().entities.catalogs.equipments.categories]
        : categories;

    dispatch({
      type: equipmentCategoriesReceived.type,
      payload: equipmentCategories
    });

    dispatch({
      type: equipmentCategoriesSorted.type,
      payload: [...equipmentCategories].sort((a, b) => a.localeCompare(b))
    });
  };

/**
 * Gets sorted equipment categories.
 */
export const getSortedEquipmentCategories = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.sortedCategories
);

/**
 * Gets equipment subcategories.
 */
export const getEquipmentSubcategories = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => {
    const subcategories =
      equipments.subCategories && Array.isArray(equipments.subCategories)
        ? [...equipments.subCategories]
        : [];

    return subcategories.sort((a: any, b: any) => a.localeCompare(b));
  }
);

/**
 * Loads equipment subcategories.
 */
export const loadCurrentEquipmentSubcategories =
  (category: string = "") =>
  (dispatch: any, getState: any) => {
    const { originalList } = getState().entities.catalogs.equipments;
    let filtered: any = [];

    if (category === "") {
      filtered = map(uniqBy(originalList, "subCategory"), "subCategory");
    } else {
      filtered = filter(originalList, { category });
      filtered = map(uniqBy(filtered, "subCategory"), "subCategory");
    }

    return filtered.sort((a: any, b: any) => a.localeCompare(b));
  };

/**

/**
 * Gets original equipment subcategories.
 */
export const getOriginalEquipmentSubCategories = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) =>
    equipments.originalSubCategories.sort((a: any, b: any) =>
      a.localeCompare(b)
    )
);

/**
 * Gets equipment manufacturer.
 */
export const getEquipmentManufacturer = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => {
    const { originalList } = equipments;

    let filtered = _.map(
      _.uniqBy(originalList, "manufacturer"),
      "manufacturer"
    );

    filtered = _.filter(filtered, function (o) {
      return o !== "";
    });

    return filtered.sort((a, b) => a.localeCompare(b));
  }
);

/**
 * Gets original equipments.
 */
export const getOriginalEquipments = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.originalList
);

/**
 * Loads original equipments.
 * @returns
 */
export const loadOriginalEquipments =
  () => async (dispatch: any, getState: any) => {
    const { originalList } = getState().entities.catalogs.equipments;

    return originalList;
  };

/**
 * Gets filtered equipments.
 */
export const getFilteredEquipments =
  () => async (dispatch: any, getState: any) => {
    const { list } = getState().entities.catalogs.equipments;
    console.log(list);
    return list;
  };

/**
 * Sorts list
 * @returns
 */
export const filterCurrentEquipments =
  (equipmentFilters: any = undefined) =>
  async (dispatch: any, getState: any) => {
    const { originalList } = getState().entities.catalogs.equipments;
    let list: any = originalList;

    if (
      equipmentFilters &&
      equipmentFilters.category &&
      equipmentFilters.category.value
    ) {
      list = _.filter(originalList, {
        category: equipmentFilters.category.value
      });
    }

    if (equipmentFilters != undefined && list.length == 0) {
      list = originalList;
    }

    dispatch({
      type: equipmentsUpdated.type,
      payload: list
    });
  };

/**
 *
 */
export const loadEquipmentSubCategories =
  (equipments: any) => (dispatch: any, getState: any) => {
    // Here we remove duplicates
    let filtered = _.map(_.uniqBy(equipments, "subCategory"), "subCategory");
    filtered = _.filter(filtered, function (o) {
      return o !== "";
    });

    dispatch({
      type: equipmentSubCategoriesReceived.type,
      payload: filtered
    });
  };

export const setEquipmentSubCategories =
  (equipments: any) => (dispatch: any, getState: any) => {
    // Here we remove duplicates
    let filtered = _.map(_.uniqBy(equipments, "subCategory"), "subCategory");
    filtered = _.filter(filtered, function (o) {
      return o !== "";
    });

    dispatch({
      type: equipmentSubCategoriesUpdated.type,
      payload: filtered
    });
  };

/**
 * Gets pointing device usage ranking.
 */
export const getPointingDeviceUsageRanking = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.pointingDeviceUsageRanking
);

/**
 * Gets keyboard device tray type.
 */
export const getKeyboardDeviceTrayType = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.keyboardDeviceTrayType
);

/**
 * Gets table computer positions.
 */
export const getTabletComputerPositions = createSelector(
  (state: any) => state.entities.catalogs.equipments,
  (equipments: any) => equipments.tabletComputerPositions
);
