import React from "react";
import { IonCheckbox, IonLabel } from "@ionic/react";
import "../../../css/equipment.css";

export const CheckBoxForm: React.FC<any> = (props) => {
  return (
    <div className="flex ion-align-items-center">
      <IonCheckbox
        name={props.name}
        checked={props.checked}
        onIonChange={props.onChange}
        className={props.csscheckbox}
        mode={props.mode}
      />
      <IonLabel className={props.csstitle}>{props.title}</IonLabel>
    </div>
  );
};

export default CheckBoxForm;
