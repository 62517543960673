import React from "react";
import { IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

import { useAppDispatch } from "../../../../../../store/Hooks";

import Input from "../../../../../common/controls/Input";

import { filterList } from "../../../../../../services/tabs.service";
import _, { debounce } from "lodash";

const Name: React.FC<any> = (props: any) => {
  const {
    list,
    listFiltered,
    setIsFilteredList,
    onInput,
    onIsFilteredList,
    value,
    filters,
    removeFilter,
    sort,
    isAttach,
    grouped
  } = props;

  const dispatch = useAppDispatch();

  const handleOnInput = async (key: string, value: string) => {
    if (value && value !== "") {
      onInput(key, value, true);

      let filtersObject = { ...filters };

      filtersObject[key] = {
        sign: "contains",
        value
      };

      const filtered = await filterList(list, filtersObject, sort);

      dispatch({
        type: listFiltered.type,
        payload: filtered
      });

      if (isAttach) {
        grouped();
      }

      dispatch({
        type: setIsFilteredList.type,
        payload: true
      });
    } else {
      removeFilter("name");
      let filtersObject = { ...filters };
      filtersObject = _.omit(filters, ["name"]);

      const filtered = await filterList(list, filtersObject);

      dispatch({
        type: listFiltered.type,
        payload: filtered
      });

      onIsFilteredList("name");
    }
  };

  const clearValue = () => {
    handleOnInput("name", "");
  };

  return (
    <div className="relative">
      <Input
        name="name"
        label="Name"
        placeholder={"Name"}
        value={value}
        onInput={debounce(
          (key: string, value: any) => handleOnInput(key, value),
          150
        )}
      />
      {value && (
        <IonIcon
          className="cursor-pointer clear-button top-3  z-2"
          onClick={clearValue}
          icon={closeCircleOutline}
        ></IonIcon>
      )}
    </div>
  );
};

export default Name;
