import React from "react";
import { IonRow, IonLabel } from "@ionic/react";

const Name: React.FC<any> = (props: any) => {
  const { value, nameLabel } = props;

  return (
    <div className="ml-05">
      <IonRow>
        <IonLabel className="text-left">{nameLabel}</IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel className="text-left bold top-1">{value}</IonLabel>
      </IonRow>
    </div>
  );
};

export default Name;
