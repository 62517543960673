import React from "react";

import { useAppDispatch } from "../../../../../store/Hooks";
import {
  getCurrentEquipment,
  getCurrentSelectedEquipment,
  updateEquipmentsList
} from "../../../../../store/evaluation/environment/workstation/Equipments";

import LaptopForm from "./LaptopForm";
import DisplayForm from "./DisplayForm";
import PointingDeviceForm from "./PointingDeviceForm";
import KeyboardForm from "./KeyboardForm";
import TabletComputerForm from "./TabletComputerForm";
import VideoCameraForm from "./VideoCameraForm";
import AccessoryForm from "../workstations/AccessoryForm";
import HardwareForm from "./HardwareForm";

import { patchWorkstationEquipment } from "../../../../../services/workstationequipment.service";
import { find } from "lodash";

let forms: any = {};

const FormsContainer: React.FC<any> = (props: any) => {
  const { type } = props;
  const dispatch = useAppDispatch();
  /**
   * Updates equipment value
   */
  const updateValue = async (key: string, value: any) => {
    if (value !== undefined) {
      const currentSelectedEquipment = dispatch(getCurrentSelectedEquipment());
      value =
        currentSelectedEquipment && value !== currentSelectedEquipment[key]
          ? value
          : undefined;

      if (value !== undefined) {
        console.log(key, value);
        const { id, type } = currentSelectedEquipment;

        const data = {
          [`${type}`]: [
            {
              id,
              [key]: value
            }
          ]
        };

        dispatch(patchWorkstationEquipment(data));

        let temp = { ...currentSelectedEquipment };
        temp[key] = value;

        dispatch(updateEquipmentsList(temp));
      }
    }
  };

  const getSelectedText = (laptopIds: any, idLaptopEquipment: number) => {
    const object = find(laptopIds, {
      id: idLaptopEquipment
    });

    if (object) {
      return object.label;
    }

    return "";
  };

  const hasLaptopValue = (idLaptopEquipment: any) => {
    if (!idLaptopEquipment) {
      return false;
    }

    return true;
  };

  const renderForm = (formType: string) => {
    if (forms[`${formType.toLowerCase()}`] === undefined) {
      forms = {
        laptops: <LaptopForm updateValue={updateValue} />,
        displays: (
          <DisplayForm
            updateValue={updateValue}
            getSelectedText={getSelectedText}
            hasLaptopValue={hasLaptopValue}
          />
        ),
        pointingdevices: (
          <PointingDeviceForm
            updateValue={updateValue}
            getSelectedText={getSelectedText}
            hasLaptopValue={hasLaptopValue}
          />
        ),
        keyboards: (
          <KeyboardForm
            updateValue={updateValue}
            getSelectedText={getSelectedText}
            hasLaptopValue={hasLaptopValue}
          />
        ),
        tabletcomputers: <TabletComputerForm updateValue={updateValue} />,
        videocameras: <VideoCameraForm updateValue={updateValue} />,
        accessories: (
          <AccessoryForm
            updateValue={updateValue}
            getCurrentWorkstation={getCurrentEquipment}
          />
        ),
        hardware: <HardwareForm updateValue={updateValue} />
      };
    }

    return forms[`${formType.toLowerCase()}`];
  };

  return <>{type && renderForm(type) ? renderForm(type) : null}</>;
};

export default FormsContainer;
