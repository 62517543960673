import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import axios from "axios";
import qs from "qs";
import moment from "moment";

const slice = createSlice({
  name: "user",
  initialState: {
    current: {},
    isNewSession: true,
    googleCredentials: {},
    googleTokenId: "",
    googleResponse: {},
    oauth2: undefined
  },
  reducers: {
    setCurrentUser: (user: any, action: PayloadAction<any>) => {
      user.current = action.payload;
    },
    userLogged: (user: any, action: PayloadAction<any>) => {
      user.isNewSession = action.payload;
    },
    googleCredentialsReceived: (user: any, action: PayloadAction<any>) => {
      user.googleCredentials = action.payload;
    },
    googleTokenIdSet: (user: any, action: PayloadAction<any>) => {
      user.googleTokenId = action.payload;
    },
    googleResponseSet: (user: any, action: PayloadAction<any>) => {
      if (action.payload.reloadAuthResponse) {
        user.googleResponse = action.payload.reloadAuthResponse;
      }
    },
    oauth2Fetched: (user: any, action: PayloadAction<any>) => {
      user.oauth2 = action.payload;
    },
    resetStore: (user: any, action: PayloadAction<any>) => {}
  }
});

export const {
  setCurrentUser,
  userLogged,
  googleCredentialsReceived,
  googleTokenIdSet,
  googleResponseSet,
  oauth2Fetched,
  resetStore
} = slice.actions;
export default slice.reducer;

/**
 * Gets current user
 */
export const getCurrentUser = createSelector(
  (state: any) => state.user,
  (user: any) => user.current
);

/**
 * Gets is the user is with new session
 */
export const getIfNewSession = createSelector(
  (state: any) => state.user,
  (user: any) => user.isNewSession
);

/**
 * Gets current user google credentials.
 */
export const getCurrentUserGoogleCredentials = createSelector(
  (state: any) => state.user,
  (user: any) => user.googleCredentials
);

/**
 * Gets current user google token id.
 */
export const getCurrentUserGoogleTokenId = createSelector(
  (state: any) => state.user,
  (user: any) => user.googleTokenId
);

/**
 * Gets current user google response.
 */
export const getCurrentUserGoogleResponse = createSelector(
  (state: any) => state.user,
  (user: any) => user.googleResponse
);

export const setGoogleData =
  (googleObject: any) => (dispatch: any, getState: any) => {
    dispatch({
      type: googleCredentialsReceived.type,
      payload: googleObject.data
    });
    dispatch({
      type: googleTokenIdSet.type,
      payload: googleObject.tokenId
    });
    dispatch({
      type: googleResponseSet.type,
      payload: googleObject.response
    });
    dispatch({
      type: setCurrentUser.type,
      payload: googleObject.userObject
    });
  };

/**
 * Gets user google credentials.
 * @returns
 */
export const getUserGoogleCredentials =
  () => (dispatch: any, getState: any) => {
    const { googleCredentials } = getState().user;

    return googleCredentials;
  };

/**
 * Gets user google response.
 * @returns
 */
export const getUserGoogleResponse = () => (dispatch: any, getState: any) => {
  const { googleResponse } = getState().user;

  return googleResponse;
};

// OAuth 2.0
export const getOAuth2Headers = () => async (dispatch: any, getState: any) => {
  const { oauth2 } = getState().user;
  let expiration: any;

  // Validating valid that token is valid, if not we get it from /oauth2/token endpoint.
  if (oauth2) {
    expiration = oauth2.expirationDate;

    if (moment(expiration) > moment()) {
      return {
        Authorization: `Bearer ${oauth2.access_token}`
      };
    }
  }

  const data: any = qs.stringify({
    grant_type: process.env.REACT_APP_BIDB_OAUTH2_GRANT_TYPE,
    client_id: process.env.REACT_APP_BIDB_OAUTH2_CLIENT_ID
  });

  const endpoint: string = process.env.REACT_APP_BIDB_ACCESS_TOKEN_URL
    ? process.env.REACT_APP_BIDB_ACCESS_TOKEN_URL
    : "";

  const response: any = await axios.post(endpoint, data, {
    auth: {
      username: process.env.REACT_APP_BIDB_OAUTH2_CLIENT_ID
        ? process.env.REACT_APP_BIDB_OAUTH2_CLIENT_ID
        : "",
      password: process.env.REACT_APP_BIDB_OAUTH2_CLIENT_SECRET
        ? process.env.REACT_APP_BIDB_OAUTH2_CLIENT_SECRET
        : ""
    },
    headers: {
      "content-type": "application/x-www-form-urlencoded"
    }
  });

  const { expires_in } = response.data;

  const expirationDate = moment()
    .add(expires_in.toString(), "seconds")
    .format("YYYY-MM-DD HH:mm:ss");

  let oauth2Data: any = response.data;

  oauth2Data.expirationDate = expirationDate;

  dispatch({
    type: oauth2Fetched.type,
    payload: oauth2Data
  });

  return {
    Authorization: `Bearer ${oauth2Data.access_token}`
  };
};
