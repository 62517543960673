import { makeApiCall, makeRequest } from "../helpers/global.helper";

export const workstationFurniture: any = {
  id: null,
  idPrevious: null,
  idFurniture: null,
  idWorksurface: null,
  abbreviation: null,
  addecuateSize: null,
  adhoc: null,
  adhocFootrest: null,
  adhocSeating: null,
  adhocStanding: null,
  adhocStandingMat: null,
  adjustable: null,
  allowAccess: null,
  backrestAdhoc: null,
  backrestComment: null,
  backrestDesignated: null,
  comment: null,
  designated: null,
  easilyAccessed: null,
  easilyMoved: null,
  employerProvided: null,
  footrestAdhoc: null,
  footrestDesignated: null,
  freeOfGlare: null,
  hasBackrest: null,
  hasDeskPhone: null,
  hasFootrest: null,
  hasSeat: null,
  hasSeatrest: null,
  hasStandingMat: null,
  label: null,
  manufacturer: null,
  model: null,
  movesFreely: null,
  name: null,
  seatType: null,
  seatrestAdhoc: null,
  seatrestComment: null,
  seatrestDesignated: null,
  shared: null,
  stable: null,
  standing: null,
  standingMatAdhoc: null,
  standingMatDesignated: null,
  standingMatShared: null,
  storageLocation: null,
  storgeType: null,
  subtype: null,
  supportsUser: null,
  type: null,
  usesBackrest: null,
  usesFootrest: null,
  usesSeatrest: null,
  worksurfaceSubtype: null,
  worksurfaceType: null
};

/**
 * Patches workstation furniture
 * @param id
 * @param data
 * @returns
 */
export const patchWorkstationFurniture =
  (data: any) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: `/workstationfurnitures`, method: "PATCH", data })
    );

    console.log("workstationFurniture: ", response.status);
  };
