import React, { useRef } from "react";
import { useHistory } from "react-router";
import { IonRow, IonCol, IonText, IonIcon } from "@ionic/react";
import { copyOutline } from "ionicons/icons";
import ReactTooltip from "react-tooltip";

import moment from "moment";
import {
  getLocationEvaluatorIcon,
  getStatusIcon
} from "../../services/evaluation.service";
import RequestPriority from "../common/RequestPriority";
import { useDispatch } from "react-redux";
import { idUpdated } from "../../store/evaluation/Current";

const TableBody: React.FC<any> = React.forwardRef((props: any, ref: any) => {
  const history = useHistory();
  const {
    evaluations,
    setEvaluation,
    selectedIndex,
    disableBaseMethods,
    setTopNavigationEvaluation,
    setShowEvaluationsTable
  } = props;

  let tooltipRefs: any = useRef([]);

  const dispatch = useDispatch();

  /**
   * Handles click
   * @param id
   */
  const handleClick = (index: number) => {
    setEvaluation(index);
  };

  const addRef = (element: any, index: number) => {
    if (element && ref && !ref.current.includes(element)) {
      ref.current.push(element);

      if (parseInt(selectedIndex) === index) {
        setTimeout(function () {
          element.scrollIntoView();
        }, 100);
      }
    }
  };

  /**
   * Changes top navigation evaluation
   * @param evaluation
   */
  const changeTopNavigationEvaluation = (idEvaluation: number) => {
    dispatch({
      type: idUpdated.type,
      payload: idEvaluation
    });
    history.push(`/evaluation/${idEvaluation}/basicinformation`);
    setTopNavigationEvaluation(idEvaluation);
    setShowEvaluationsTable();
  };

  const handleOnClick = (e: any, string: string, index: number) => {
    e.stopPropagation();
    navigator.clipboard.writeText(string);
    ReactTooltip.show(tooltipRefs.current[index]);

    setTimeout(() => {
      ReactTooltip.hide(tooltipRefs.current[index]);
    }, 500);
  };

  return (
    <>
      {evaluations.map((evaluation: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <IonRow
              ref={ref => addRef(ref, index)}
              className="row"
              onClick={e => {
                if (!disableBaseMethods) {
                  handleClick(index);
                } else {
                  changeTopNavigationEvaluation(evaluation.id);
                }
              }}
            >
              <IonCol size=".75">
                <RequestPriority evaluation={evaluation && evaluation} />
              </IonCol>
              <IonCol size=".75">
                <div className="row-data-text main-filter-alignment type-item-center-icon">
                  <img
                    className="row-data-icons"
                    src={
                      evaluation
                        ? getLocationEvaluatorIcon(
                            evaluation.locationEvaluatorDuringEvaluation
                              ? evaluation.locationEvaluatorDuringEvaluation
                              : ""
                          )
                        : ""
                    }
                    alt="logo"
                  />
                </div>
              </IonCol>
              <IonCol size="1.5">
                <div className="filter-text-wrapper">
                  <IonText className="row-data-text">
                    {evaluation &&
                      evaluation.appointmentDate &&
                      moment(evaluation.appointmentDate).format("MM/DD/YYYY")}
                  </IonText>
                </div>
              </IonCol>
              <IonCol size="1">
                <div className="filter-text-wrapper">
                  <IonText className="row-data-text">
                    {evaluation &&
                      evaluation.appointmentDate &&
                      evaluation.appointmentTime &&
                      moment(
                        `${evaluation.appointmentDate} ${evaluation.appointmentTime}`
                      ).format("hh:mm a")}
                  </IonText>
                </div>
              </IonCol>
              <IonCol size="1">
                <div className="filter-text-wrapper justify-center">
                  <IonText>
                    <img
                      src={getStatusIcon(
                        evaluation.appointmentConfirmed,
                        evaluation.appointmentCanceled,
                        evaluation.evalStatus && evaluation.evalStatus
                      )}
                      alt=""
                    />
                  </IonText>
                </div>
              </IonCol>
              <IonCol size="1.75">
                <div className="filter-text-wrapper">
                  <IonText className="row-data-text">
                    {evaluation.workerName}
                  </IonText>
                </div>
              </IonCol>
              <IonCol size="1.75">
                <div className="filter-text-wrapper">
                  <IonText className="row-data-text">
                    {evaluation.organizationName}
                  </IonText>
                </div>
              </IonCol>
              <IonCol size="3.5">
                <div className="filter-text-wrapper ion-text-left">
                  <div className="location-wrapper">
                    <IonText className="row-data-text pt-038">
                      {evaluation && evaluation.locationName}
                    </IonText>
                    {evaluation && evaluation.locationName != "" && (
                      <div className="relative">
                        <IonIcon
                          className="cursor-pointer copy-clipboard"
                          src={copyOutline}
                          onClick={(e: any) => {
                            handleOnClick(e, evaluation.locationName, index);
                          }}
                        ></IonIcon>
                        <p
                          className="absolute"
                          data-event="custom"
                          data-tip="Copied!"
                          ref={el => (tooltipRefs.current[index] = el)}
                          data-for={`${index.toString()}`}
                        ></p>
                        <ReactTooltip
                          id={`${index.toString()}`}
                          className="custom-tooltip"
                          place="left"
                          type="dark"
                          effect="solid"
                          eventOff="click"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="separate-table-rows"></IonRow>
          </React.Fragment>
        );
      })}
    </>
  );
});

export default TableBody;
