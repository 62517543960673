import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { groupList } from "../../services/tabs.service";
import { map, filter, uniqBy } from "lodash";

const slice = createSlice({
  name: "organizationRegionEquipments",
  initialState: {
    list: [],
    originalList: [],
    originalGroupedList: [],
    isFilteredList: false,
    filteredList: [],
    filters: {
      category: "",
      subCategory: "",
      manufacturer: ""
    }
  },
  reducers: {
    organizationRegionEquipmentsReceived(
      organizationRegionEquipments: any,
      action: PayloadAction<any>
    ) {
      const { payload } = action;
      if (payload.rows && payload.rows.length > 0) {
        organizationRegionEquipments.list = payload.rows;
        organizationRegionEquipments.originalList = payload.rows;
      } else {
        organizationRegionEquipments.list = [];
      }
    },
    organizationRegionEquipmentsUpdated(
      organizationRegionEquipments: any,
      action: PayloadAction<any>
    ) {
      organizationRegionEquipments.list = action.payload;
    },
    isFilteredOrganizationRegionEquipmentsSet(
      organizationRegionEquipments: any,
      action: PayloadAction<any>
    ) {
      organizationRegionEquipments.isFilteredList = action.payload;
    },
    organizationRegionEquipmentsFiltered(
      organizationRegionEquipments: any,
      action: PayloadAction<any>
    ) {
      organizationRegionEquipments.filteredList = action.payload;
    },
    organizationRegionEquipmentFiltersUpdated(
      organizationRegionEquipments: any,
      action: PayloadAction<any>
    ) {
      organizationRegionEquipments.filters[action.payload.key] =
        action.payload.value;
    },
    organizationRegionEquipmentsGrouped(
      organizationRegionEquipments: any,
      action: PayloadAction<any>
    ) {
      organizationRegionEquipments.originalGroupedList = action.payload;
    }
  }
});

export const {
  organizationRegionEquipmentsReceived,
  organizationRegionEquipmentsUpdated,
  isFilteredOrganizationRegionEquipmentsSet,
  organizationRegionEquipmentsFiltered,
  organizationRegionEquipmentFiltersUpdated,
  organizationRegionEquipmentsGrouped
} = slice.actions;
export default slice.reducer;

/**
 * Gets organization region equipments.
 */
export const getOrganizationRegionEquipments = createSelector(
  (state: any) => state.evaluation.organizationRegionEquipments,
  (organizationRegionEquipments: any) => organizationRegionEquipments.list
);

/**
 * Gets original organization region equipments.
 */
export const getOriginalOrganizationRegionEquipments = createSelector(
  (state: any) => state.evaluation.organizationRegionEquipments,
  (organizationRegionEquipments: any) =>
    organizationRegionEquipments.originalList
);

/**
 * Gets original grouped list.
 * @returns
 */
export const getOriginalGroupedOrganizationRegionEquipmentsList =
  () => async (dispatch: any, getState: any) => {
    const { originalList } = getState().evaluation.organizationRegionEquipments;

    const groupedAndSorted: any = await groupList(
      originalList,
      {
        columns: ["name"],
        directions: ["asc"]
      },
      "equipments"
    );

    dispatch({
      type: organizationRegionEquipmentsUpdated.type,
      payload: groupedAndSorted
    });

    dispatch({
      type: organizationRegionEquipmentsGrouped.type,
      payload: groupedAndSorted
    });

    return groupedAndSorted;
  };

/**
 * Gets original grouped organization region equipments.
 */
export const getOriginalGroupedOrganizationRegionEquipments = createSelector(
  (state: any) => state.evaluation.organizationRegionEquipments,
  (organizationRegionEquipments: any) =>
    organizationRegionEquipments.originalList
);

/**
 * Sorts list
 * @returns
 */
export const filterCurrentOrganizationRegionEquipments =
  (equipmentFilters: any = undefined) =>
  async (dispatch: any, getState: any) => {
    const { originalList } = getState().evaluation.organizationRegionEquipments;
    let list: any = originalList;

    if (
      equipmentFilters &&
      equipmentFilters.category &&
      equipmentFilters.category.value
    ) {
      list = filter(originalList, {
        category: equipmentFilters.category.value
      });
    }

    if (
      equipmentFilters &&
      equipmentFilters.subCategory &&
      equipmentFilters.subCategory.value
    ) {
      list = filter(originalList, {
        subCategory: equipmentFilters.subCategory.value
      });
    }

    // Filter value does not exist in category values.
    if (equipmentFilters !== undefined && list.length == 0) {
      list = originalList;
    }

    dispatch({
      type: organizationRegionEquipmentsUpdated.type,
      payload: list
    });
  };

/**
 * Gets is filtered list.
 */
export const getIsFilteredOrganizationRegionEquipments = createSelector(
  (state: any) => state.evaluation.organizationRegionEquipments,
  (organizationRegionEquipments: any) =>
    organizationRegionEquipments.isFilteredList
);

/**
 * Gets organization region equipments filters.
 */
export const getOrganizationRegionEquipmentFilters = createSelector(
  (state: any) => state.evaluation.organizationRegionEquipments,
  (organizationRegionEquipments: any) => organizationRegionEquipments.filters
);

/**
 * Gets organization region equipment subcategories.
 */
export const getOrganizationRegionEquipmentSubcategories = createSelector(
  (state: any) => state.evaluation.organizationRegionEquipments,
  (organizationRegionEquipments: any) => {
    const { originalList } = organizationRegionEquipments;

    // Here we remove duplicates
    let filtered = map(uniqBy(originalList, "subCategory"), "subCategory");
    filtered = filter(filtered, function (o) {
      return o !== "";
    });

    return filtered.sort((a, b) => a.localeCompare(b));
  }
);

/**
 * Gets organization region equipment manufacturers.
 */
export const getOrganizationRegionEquipmentManufacturers = createSelector(
  (state: any) => state.evaluation.organizationRegionEquipments,
  (organizationRegionEquipments: any) => {
    const { originalList } = organizationRegionEquipments;

    let filtered = map(uniqBy(originalList, "manufacturer"), "manufacturer");

    filtered = filter(filtered, function (o) {
      return o !== "";
    });

    return filtered.sort((a, b) => a.localeCompare(b));
  }
);
