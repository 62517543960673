import React from "react";

import Badge from "../../../../common/controls/Badge";

const colors: any = {
  category: "bg-color-00cef7",
  Furniture: "bg-color-furniture",
  Equipment: "bg-color-equipment",
  Behavior: "bg-color-behavior",
  Adjustment: "bg-color-adjustment",
  Resource: "bg-color-resource",
  subCategory: "bg-color-subcategory",
  type: "bg-color-9c9c9c"
};

const Pills: React.FC<any> = (props: any) => {
  const { category, subCategory, type } = props;

  return (
    <div className="flex">
      {category && (
        <Badge label={category} color={colors[category]} className="mr-1" />
      )}
      {subCategory && (
        <Badge
          label={subCategory}
          className="mr-1"
          color={colors["subCategory"]}
        />
      )}
      {type && <Badge className="mr-1" label={type} color={colors["type"]} />}
    </div>
  );
};

export default Pills;
