import { makeApiCall, makeRequest } from "../helpers/global.helper";

import { find } from "lodash";

const apiRisks = process.env.REACT_APP_BIDB_API_RISKS;

/**
 * Deletes risk
 * @param id
 * @returns
 */
export const deleteRisk =
  (id: number) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: `${apiRisks}/${id}`, method: "DELETE" })
    );

    console.log(response.status);
  };

/**
 * Patches risk
 * @param data
 * @returns
 */
export const patchRisk =
  (data: any) => async (dispatch: any, getState: any) => {
    const response = await dispatch(
      makeRequest({ endpoint: `${apiRisks}`, method: "PATCH", data })
    );

    console.log(response.status);
  };

/**
 * Saves risk environment
 * @param data
 */
export const saveRiskEnvironment =
  (data: any) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/riskenvironments`,
        method: "POST",
        data: data
      })
    );

    return response.data;
  };

/**
 * Deletes risk environment
 * @param id
 */
export const deleteRiskEnvironment =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({ endpoint: `/riskenvironments/${id}`, method: "DELETE" })
    );

    console.log(response.status);
  };

/**
 * Deletes risk environment
 * @param id
 */
export const deleteRiskRecommendation =
  (id: number) => async (dispatch: any, getState: any) => {
    const response: any = await dispatch(
      makeRequest({
        endpoint: `/riskrecommendations/${id}`,
        method: "DELETE"
      })
    );

    console.log(response.status);
  };

/**
 * Deletes risk environments.
 * @param id
 * @returns
 */
export const deleteRiskEnvironments = async (id: number) => {
  const response = await makeApiCall(`/risks/${id}/environments`, "DELETE");

  return response;
};

/**
 * Gets risk name.
 * @param idRiskv
 * @returns
 */
export const getRiskName =
  (idRiskV: number) => (dispatch: any, getState: any) => {
    const { list } = getState().entities.catalogs.risks;
    const risk = find(list, { id: idRiskV });

    return risk ? risk.name : "";
  };
