import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _, { omit } from "lodash";

const slice = createSlice({
  name: "current",
  initialState: {
    id: -1,
    object: undefined,
    environments: [],
    environment: {},
    environmentEquipments: [],
    sortedEnvironmentEquipments: [],
    isOpenEnvironmentEquipmentModal: false,
    activities: [],
    environmentActivities: [],
    activityTypes: [],
    activityPercentWorkTimes: [],
    activityPercentTimeStanding: [],
    environmentCorrectiveLenses: [],
    environmentSelectedTabIndex: 0,
    comments: [],
    htmlReport: "",
    openEnvironmentSubmenu: false,
    showReportLoader: false,
    showEnvironmentLoader: false,
    worker: undefined,
    evaluator: undefined,
    organization: undefined,
    isOpenScratchPadModal: false,
    scratchPadHeader: {
      workerName: "",
      company: ""
    },
    timestamp: 0
  },
  reducers: {
    setEvaluation(evaluation: any, action: PayloadAction<any>) {
      evaluation.object = action.payload;
    },
    idUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.id = action.payload;
    },
    evaluationUpdated(evaluation: any, action: PayloadAction<any>) {
      for (const [key, value] of Object.entries(action.payload)) {
        evaluation.object[key] = value;
      }
    },
    environmentsReceived(evaluation: any, action: PayloadAction) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluation.environments = payload.rows;
      } else {
        evaluation.environments = [];
      }
    },
    commentsReceived(evaluation: any, action: PayloadAction) {
      const payload: any = action.payload;

      if (payload.rows) {
        evaluation.comments = payload.rows;
      } else {
        evaluation.comments = [];
      }
    },
    setEnvironment(evaluation: any, action: PayloadAction<any>) {
      evaluation.environment = action.payload;
    },
    updateCurrentEnvironment(evaluation: any, action: PayloadAction<any>) {
      for (const [key, value] of Object.entries(action.payload)) {
        evaluation.environment[key] = value;
      }
    },
    environmentsUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.environments = action.payload;
    },
    setEnvironmentIssues(evaluation: any, action: PayloadAction<any>) {
      evaluation.environmentIssues = action.payload;
    },
    addIssueCurrentEnvironment(evaluation: any, action: PayloadAction<any>) {
      if (!evaluation.environmentIssues) evaluation.environmentIssues = [];
      evaluation.environmentIssues.push(action.payload);
    },
    setCurrentIssueEnvironments(evaluation: any, action: PayloadAction<any>) {
      evaluation.currentIssueEnvironments = action.payload;
    },
    addEnvironmentCurrentIssue(evaluation: any, action: PayloadAction<any>) {
      if (!evaluation.currentIssueEnvironments)
        evaluation.currentIssueEnvironments = [];
      evaluation.currentIssueEnvironments.push(action.payload);
    },
    environmentCorrectiveLensesReceived(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      const payload: any = action.payload;

      if (payload.rows && payload.rows.length > 0) {
        evaluation.environmentCorrectiveLenses = payload.rows;
      } else {
        evaluation.environmentCorrectiveLenses = [
          {
            contacts: [],
            glasses: []
          }
        ];
      }
    },
    setEnvironmentCorrectiveLenses(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      let temp: Array<any> =
        evaluation.environmentCorrectiveLenses[0][action.payload.type];

      if (!temp) {
        temp = [];
      }

      const data = omit(action.payload.data, "type");
      temp.push(data);

      evaluation.environmentCorrectiveLenses[0][action.payload.type] = temp;
    },
    updateEnvironmentCorrectiveLenses(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.environmentCorrectiveLenses[0][action.payload.type][
        action.payload.index
      ][action.payload.key] = action.payload.value;
    },
    deleteEnvironmentCorrectiveLenses(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.environmentCorrectiveLenses[0][action.payload.type].splice(
        action.payload.index,
        1
      );
    },
    setShowEnvironmentEquipmentModal(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.isOpenEnvironmentEquipmentModal = action.payload;
    },
    setEnvironmentActivities(evaluation: any, action: PayloadAction<any>) {
      evaluation.environmentActivities = action.payload;
    },
    updateRisks(evaluation: any, action: PayloadAction<any>) {
      evaluation.risks = action.payload;
    },
    setCurrentRiskEnvironments(evaluation: any, action: PayloadAction<any>) {
      evaluation.currentRiskEnvironments = action.payload;
    },
    addEnvironmentCurrentRisk(evaluation: any, action: PayloadAction<any>) {
      if (!evaluation.currentRiskEnvironments)
        evaluation.currentRiskEnvironments = [];
      evaluation.currentRiskEnvironments.push(action.payload);
    },
    setEnvironmentRisks(evaluation: any, action: PayloadAction<any>) {
      evaluation.environmentRisks = action.payload;
    },
    addRiskCurrentEnvironment(evaluation: any, action: PayloadAction<any>) {
      if (!evaluation.environmentRisks) evaluation.environmentRisks = [];
      evaluation.environmentRisks.push(action.payload);
    },
    setCurrentEquipment(evaluation: any, action: PayloadAction<any>) {
      evaluation.currentEquipment = action.payload;
    },
    currentEquipmentUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.currentEquipment[`${action.payload.key}`] =
        action.payload.value;
    },
    updateEquipments(evaluation: any, action: PayloadAction<any>) {
      evaluation.environmentEquipments[`${action.payload.type}`] =
        action.payload.equipments;
    },
    updateSortedEquipments(evaluation: any, action: PayloadAction<any>) {
      evaluation.sortedEnvironmentEquipments = action.payload;
    },
    setEnvironmentTabIndex(evaluation: any, action: PayloadAction<any>) {
      evaluation.environmentSelectedTabIndex = action.payload;
    },
    equipmentSelected(evaluation: any, action: PayloadAction<any>) {
      evaluation.equipmentSelectedIndex = action.payload;
    },
    rowSelectedType(evaluation: any, action: PayloadAction<any>) {
      evaluation.rowType = action.payload;
    },
    updateCurrentEvaluationComments(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.comments = action.payload;
    },
    htmlReportReceived(evaluation: any, action: PayloadAction<any>) {
      evaluation.htmlReport = action.payload;
    },
    commentUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.comments = action.payload;
    },
    environmentSubmenuOpenedClosed(
      evaluation: any,
      action: PayloadAction<any>
    ) {
      evaluation.openEnvironmentSubmenu = action.payload;
    },
    showReportLoaderUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.showReportLoader = action.payload;
    },
    showEnvironmentLoaderUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.showEnvironmentLoader = action.payload;
    },
    workerUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.worker = action.payload;
    },
    evaluatorUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.evaluator = action.payload;
    },
    organizationUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.organization = action.payload;
    },
    isOpenScratchPadModalUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.isOpenScratchPadModal = action.payload;
    },
    scratchPadHeaderUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.scratchPadHeader = action.payload;
    },
    timestampUpdated(evaluation: any, action: PayloadAction<any>) {
      evaluation.timestamp = action.payload;
    }
  }
});

export const {
  setEvaluation,
  evaluationUpdated,
  environmentsReceived,
  commentsReceived,
  setEnvironment,
  updateCurrentEnvironment,
  environmentsUpdated,
  setEnvironmentIssues,
  setCurrentIssueEnvironments,
  addEnvironmentCurrentIssue,
  addIssueCurrentEnvironment,
  environmentCorrectiveLensesReceived,
  setEnvironmentCorrectiveLenses,
  updateEnvironmentCorrectiveLenses,
  deleteEnvironmentCorrectiveLenses,
  setShowEnvironmentEquipmentModal,
  setEnvironmentActivities,
  updateRisks,
  setCurrentRiskEnvironments,
  setEnvironmentRisks,
  addEnvironmentCurrentRisk,
  addRiskCurrentEnvironment,
  updateEquipments,
  updateSortedEquipments,
  setEnvironmentTabIndex,
  equipmentSelected,
  rowSelectedType,
  updateCurrentEvaluationComments,
  htmlReportReceived,
  commentUpdated,
  idUpdated,
  environmentSubmenuOpenedClosed,
  showReportLoaderUpdated,
  showEnvironmentLoaderUpdated,
  workerUpdated,
  evaluatorUpdated,
  organizationUpdated,
  isOpenScratchPadModalUpdated,
  scratchPadHeaderUpdated,
  timestampUpdated
} = slice.actions;
export default slice.reducer;

/**
 * Gets current timestamp.
 */
export const getCurrentTimestamp = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.timestamp
);

export const getCurrentScratchPadHeader = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.scratchPadHeader
);

export const getIsOpenScratchPadModal = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.isOpenScratchPadModal
);

/**
 * Gets current open environment submenu;
 */
export const getCurrentOpenEnvironmentSubmenu = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.openEnvironmentSubmenu
);

export const getCurrentId = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.id
);

/**
 * Gets html report.
 */
export const getCurrentHtmlReport = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.htmlReport
);

/**
 * Gets environments
 */
export const getEnvironments = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.environments
);

/**
 * Gets comments
 */
export const getCurrentEvaluationComments = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.comments
);

/**
 * Gets current evaluation
 */
export const getCurrentEvaluation = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.object
);

/**
 * Gets current evaluation in runtime.
 * @returns
 */
export const getCurrentEvaluationInRuntime =
  () => (dispatch: any, getState: any) => {
    return getState().evaluation.current.object;
  };

/**
 * Gets current evaluation, this is used when getting the value after change its state.
 * @returns
 */
export const getCurrent = () => (dispatch: any, getState: any) => {
  const { object } = getState().evaluation.current;

  return object;
};

/**
 * Gets current evaluation environment
 */
export const getCurrentEvaluationEnvironment = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.environment
);

/**
 * Gets evaluation issues
 */
export const getIssues = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.issues
);

/**
 * Gets environment corrective lenses
 */
export const getEnvironmentCorrectiveLenses = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.environmentCorrectiveLenses
);

/**
 * Shows environment equipment modal
 */
export const showEnvironmentEquipmentModal = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.isOpenEnvironmentEquipmentModal
);

/**
 * Gets environment equipments
 */
export const getEnvironmentEquipments = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.environmentEquipments
);

/**
 * Updates environment equipments with corresponding current environment
 * @returns
 */
export const updateEnvironmentEquipments =
  () => (dispatch: any, getState: any) => {
    const {
      currentEquipment,
      environmentEquipments,
      sortedEnvironmentEquipments
    } = getState().evaluation.current;

    if (environmentEquipments) {
      const equipments = environmentEquipments[`${currentEquipment.type}`];
      let index = _.findIndex(equipments, {
        id: currentEquipment.id
      });
      let temp = [...equipments];
      temp[index] = currentEquipment;

      // Updating the unsorted list
      dispatch({
        type: updateEquipments.type,
        payload: {
          type: currentEquipment.type,
          equipments: temp
        }
      });

      // Updating sorted list
      index = _.findIndex(sortedEnvironmentEquipments, {
        id: currentEquipment.id
      });
      temp = [];
      temp = [...sortedEnvironmentEquipments];
      temp[index] = currentEquipment;

      dispatch({
        type: updateSortedEquipments.type,
        payload: temp
      });
    }
  };

/**
 * Gets environment tab index selected.
 */
export const getEnvironmentSelectedTabIndex = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.environmentSelectedTabIndex
);

/**
 * Gets equipment selected index.
 */
export const getEquipmentSelectedIndex = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.equipmentSelectedIndex
);

/**
 * Gets equipment selected type.
 */
export const getRowSelectedType = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.rowType
);

/**
 * Gets no equipment selected.
 */
export const getNoEquipmentSelected = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.noEquipmentSelected
);

/**
 * Gets current equipment laptops
 */
export const getCurrentEquipmentLaptops = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => {
    const { environmentEquipments } = current;

    return _.filter(environmentEquipments, { type: "laptops" });
  }
);

/**
 * Gets is filtered equipment list.
 */
export const getIsFilteredEquipmentList = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.isFilteredEquipmentList
);

/**
 * Gets furniture selected type.
 */
export const getSelectedFurnitureType = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.selectedFurnitureType
);

/**
 * Gets no furniture selected.
 */
export const getNoSelectedFurniture = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.noSelectedFurniture
);

export const getCurrentEnvironment = () => (dispatch: any, getState: any) => {
  const { environment } = getState().evaluation.current;

  return environment;
};

/**
 * Gets show report loader.
 */
export const getCurrentShowReportLoader = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.showReportLoader
);

/**
 * Gets show environment loader.
 */
export const getCurrentShowEnvironmentLoader = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.showEnvironmentLoader
);

/*
 * Gets current evaluation worker.
 * @returns
 */
export const getCurrentEvaluationWorker = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.worker
);

/**
 * Gets current evaluation worker.
 * @returns
 */
export const getCurrentEvaluationWorkerInRuntime =
  () => (dispatch: any, getState: any) => {
    const { worker } = getState().evaluation.current;

    return worker;
  };

/**
 * Gets current evaluation evaluator.
 * @returns
 */
export const getCurrentEvaluationEvaluator = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.evaluator
);

/**
 * Gets current evaluation evaluator.
 * @returns
 */
export const getCurrentEvaluationEvaluatorInRuntime =
  () => (dispatch: any, getState: any) => {
    const { evaluator } = getState().evaluation.current;

    return evaluator;
  };

/**
 * Gets current evaluation evaluator.
 * @returns
 */
export const getCurrentEvaluationOrganization = createSelector(
  (state: any) => state.evaluation.current,
  (current: any) => current.organization
);

/**
 * Gets current evaluation organization.
 * @returns
 */
export const getCurrentEvaluationOrganizationInRuntime =
  () => (dispatch: any, getState: any) => {
    const { organization } = getState().evaluation.current;

    return organization;
  };
