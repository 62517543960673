import React from "react";
import { IonRow, IonCol, IonImg, IonButton } from "@ionic/react";

import { iconImages } from "../../../../../services/tabs.service";

const Footer: React.FC<any> = (props: any) => {
  const {
    showClearSelectionAlert,
    closeModal,
    save,
    saveButtonLabel,
    clearFilter,
    disabledButton,
    isPicker,
    btnSaveClasses,
    buttonClasses
  } = props;

  return (
    <IonRow className="px-20px py-07">
      <IonCol className="display-flex mt-2">
        <span className="cursor-pointer display-flex" onClick={clearFilter}>
          <IonImg
            className="icon-clearfilter"
            src={iconImages["clearFilterIcon"]}
            alt="clear filter"
          ></IonImg>
          <p className="clear-filters">Clear filters</p>
        </span>
        {!isPicker && (
          <span
            className="cursor-pointer display-flex ml-2"
            onClick={showClearSelectionAlert}
          >
            <IonImg
              className="icon-clearselection"
              src={iconImages["clearSelectionIcon"]}
              alt="clear selection"
            ></IonImg>
            <p className="clear-selection">Clear selection</p>
          </span>
        )}
      </IonCol>
      <IonCol
        className={`display-flex ion-justify-content-end ${
          isPicker ? buttonClasses : ""
        }`}
      >
        <IonButton
          color="medium"
          className="btn-custom-modal btn-cancel ml-44"
          onClick={() => closeModal()}
        >
          CANCEL
        </IonButton>
        <IonButton
          className={`${isPicker ? btnSaveClasses : "btn-add-selected ml-1"}`}
          disabled={disabledButton}
          onClick={() => save()}
        >
          {saveButtonLabel}
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export default Footer;
