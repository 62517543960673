import React from "react";

import { useAppDispatch } from "../../../../../store/Hooks";
import {
  getCurrentSelectedFurniture,
  updateFurnituresList,
  getCurrentFurniture
} from "../../../../../store/evaluation/environment/workstation/Furnitures";

import WorkSurfaceForm from "./WorkSurfaceForm";
import SeatingForm from "./SeatingForm";
import StorageForm from "./StorageForm";
import AccessoryForm from "../workstations/AccessoryForm";

import { patchWorkstationFurniture } from "../../../../../services/workstationfurniture.service";
import { find } from "lodash";

let forms: any = {};

const FormsContainer: React.FC<any> = (props: any) => {
  const { type } = props;
  const dispatch = useAppDispatch();

  const getSelectedText = (worksurfaceIds: any, idWorksurface: number) => {
    const object = find(worksurfaceIds, {
      id: idWorksurface
    });

    if (object) {
      return object.label;
    }

    return "";
  };

  const hasWorkSurfaceValue = (idWorkSurfaceFurniture: any) => {
    if (!idWorkSurfaceFurniture) {
      return false;
    }

    return true;
  };

  /**
   * Updates furniture value
   */
  const updateValue = async (key: string, value: any) => {
    if (value !== undefined) {
      const currentSelectedFurniture = dispatch(getCurrentSelectedFurniture());
      value =
        currentSelectedFurniture && value !== currentSelectedFurniture[key]
          ? value
          : undefined;

      if (value !== undefined) {
        console.log(key, value);
        const { id, type } = currentSelectedFurniture;

        const data = {
          [`${type}`]: [
            {
              id,
              [key]: value
            }
          ]
        };

        dispatch(patchWorkstationFurniture(data));

        let temp = { ...currentSelectedFurniture };
        temp[key] = value;

        dispatch(updateFurnituresList(temp));
      }
    }
  };

  const renderForm = (formType: string) => {
    if (forms[`${formType.toLowerCase()}`] === undefined) {
      forms = {
        worksurfaces: <WorkSurfaceForm updateValue={updateValue} />,
        seats: (
          <SeatingForm
            updateValue={updateValue}
            getSelectedText={getSelectedText}
            hasWorkSurfaceValue={hasWorkSurfaceValue}
          />
        ),
        storage: <StorageForm updateValue={updateValue} />,
        accessories: (
          <AccessoryForm
            updateValue={updateValue}
            getCurrentWorkstation={getCurrentFurniture}
          />
        )
      };
    }

    return forms[`${formType.toLowerCase()}`];
  };

  return <>{type ? renderForm(type) : null}</>;
};

export default FormsContainer;
