import React from "react";
import BaseBand from "../../../../common/BaseBand";
import BandContent from "./BandContent";

const Band: React.FC<any> = (props: any) => {
  const { isRowExpanded, onClick, item, onDelete } = props;

  return (
    <BaseBand
      expandRowClick={onClick}
      onDelete={onDelete}
      isRowExpanded={isRowExpanded}
      message={"Are you sure that you want to delete this activity?"}
      item={item}
      content={
        <BandContent
          bandOnClick={onClick}
          type={item && item.type}
          expandRowClick={onClick}
        />
      }
    />
  );
};

export default Band;
