import React from "react";

import AddRow from "../../../../common/controls/AddRow";
import AddButtons from "./AddButtons";

const TopBar: React.FC<any> = (props: any) => {
  const { handleAddRequest, handleAddDiscomfort } = props;

  return (
    <AddRow
      label="Issues"
      buttons={
        <AddButtons
          handleAddRequest={handleAddRequest}
          handleAddDiscomfort={handleAddDiscomfort}
        />
      }
    />
  );
};

export default TopBar;
