import React, { useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonButton,
  IonIcon,
  IonFooter,
  IonToolbar
} from "@ionic/react";
import { add } from "ionicons/icons";

import BaseSearch from "../../common/BaseSearch";
import Table from "./Table";
import AddWorkerModal from "../AddWorkerModal";
import AddAppointmentModal from "../AddAppointmentModal";

import _ from "lodash";
import ReviewDuplicateModal from "../ReviewDuplicatesModal";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import { getCurrentSearch } from "../../../store/appointments/Index";

const Workers: React.FC<any> = (props: any) => {
  const {
    list,
    isOpenWorkerModal,
    setIsOpenWorkerModal,
    onSaveWorker,
    onClickTableBody,
    selectedIndex,
    isLocationSelected,
    isOpenAppointmentModal,
    setIsOpenAppointmentModal,
    worker,
    location,
    handleInputSearch,
    handleSort,
    sort,
    value,
    isOpenRewiewDuplicatesModal,
    setIsOpenRewiewDuplicatesModal,
    openReviewDuplicates,
    workerData,
    duplicatedRows,
    selectWorker,
    handleSortDuplicates,
    sortDuplicates,
    onDidDismissDuplicates
  } = props;

  const [searchLength, setSearchLength] = useState<number>(0);

  const dispatch = useAppDispatch();

  const currentSearch = useAppSelector(getCurrentSearch);

  useEffect(() => {
    setSearchLength(currentSearch.length);
  }, [currentSearch && currentSearch]);

  const handleOpenWorkerModal = () => {
    setIsOpenWorkerModal(!isOpenWorkerModal);
  };

  const handleOpenAppointmentModal = (
    idEvaluation: number | boolean = false
  ) => {
    setIsOpenAppointmentModal(!isOpenAppointmentModal);

    if (idEvaluation !== false) {
      setTimeout(function () {
        window.location.href = `/evaluation/${idEvaluation}/basicinformation`;
      }, 1000);
    }
  };

  const onSearchInput = (name: any) => {
    if (isLocationSelected) {
      handleInputSearch(name, location);
      setSearchLength(name && name !== "" ? name.length : 0);
    }
  };

  return (
    <>
      <IonContent>
        <AddWorkerModal
          isOpen={isOpenWorkerModal}
          closeModal={setIsOpenWorkerModal}
          onSaveWorker={onSaveWorker}
          isLocationSelected={isLocationSelected}
          location={location}
          openReviewDuplicates={openReviewDuplicates}
        />

        <AddAppointmentModal
          isOpen={isOpenAppointmentModal}
          closeModal={handleOpenAppointmentModal}
          worker={worker}
          location={location}
        />
        <ReviewDuplicateModal
          openDuplicateModal={isOpenRewiewDuplicatesModal}
          setOpenDuplicateModal={setIsOpenRewiewDuplicatesModal}
          workerData={workerData}
          rows={duplicatedRows}
          selectWorker={selectWorker}
          handleSort={handleSortDuplicates}
          sort={sortDuplicates}
          onDidDismiss={onDidDismissDuplicates}
          onSaveWorker={onSaveWorker}
        />
        <IonGrid className="content-grid h-full flex flex-column">
          <IonRow className="ion-align-items-center">
            <IonCol className="flex ion-align-items-center">
              <BaseSearch
                placeholder="Search Employee by name"
                onChange={onSearchInput}
                value={value}
                debounce={3000}
              />

              <IonButton
                className="text-blue-1f3061"
                fill="clear"
                onClick={handleOpenWorkerModal}
                disabled={
                  isLocationSelected === false || searchLength < 3
                    ? true
                    : false
                }
              >
                <IonIcon className="text-blue-1f3061" icon={add}></IonIcon>
                Add Employee
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="worker-table flex-grow">
            <IonCol className="flex flex-column">
              <Table
                rows={list}
                onClickTableBody={onClickTableBody}
                selectedIndex={selectedIndex}
                handleSort={handleSort}
                sort={sort}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonCol className="flex ion-justify-content-end">
            <IonButton
              disabled={list.length === 0 || selectedIndex === -1}
              className="w-240 -mt-4"
              onClick={() => handleOpenAppointmentModal()}
            >
              Appoint
            </IonButton>
          </IonCol>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default Workers;
